import {defaultClientInvokerFactory, HttpClientInvoker} from './httpClientInvoker';
import {HttpClientRequest} from './httpClientRequest';
import {HttpMethod} from './httpMethod';

const clientInvoker: HttpClientInvoker = defaultClientInvokerFactory();

export type HttpGetClient = <R>(
  path: string | string [],
  request?: Omit<HttpClientRequest<R>, 'path' | 'method'>
) => Promise<R>;

export const httpGet: HttpGetClient = <R>(
  path: string | string [],
  request?: Omit<HttpClientRequest<R>, 'path' | 'method'>
): Promise<R> => clientInvoker<R>({path, method: HttpMethod.GET, ...request});

export type HttpPostClient = <R, D = any>(
  path: string | string[],
  data?: D,
  request?: Omit<HttpClientRequest<R, D>, 'path' | 'method' | 'data'>
) => Promise<R>;

export const httpPost: HttpPostClient = <R, D = any>(
  path: string | string[],
  data?: D,
  request?: Omit<HttpClientRequest<R, D>, 'path' | 'method' | 'data'>
): Promise<R> => clientInvoker<R, D>({path, method: HttpMethod.POST, data, ...request});

export type HttpPutClient = <R, D = any>(
  path: string | string[],
  data?: D,
  request?: Omit<HttpClientRequest<R, D>, 'path' | 'method' | 'data'>
) => Promise<R>;

export const httpPut: HttpPutClient = <R, D = any>(
  path: string | string[],
  data?: D,
  request?: Omit<HttpClientRequest<R, D>, 'path' | 'method' | 'data'>
): Promise<R> => clientInvoker<R, D>({path, method: HttpMethod.POST, data, ...request});

export type HttpDeleteClient = <R>(
  path: string | string [],
  request?: Omit<HttpClientRequest<R>, 'path' | 'method'>
) => Promise<R>;

export const httpDelete: HttpGetClient = <R>(
  path: string | string [],
  request?: Omit<HttpClientRequest<R>, 'path' | 'method'>
): Promise<R> => clientInvoker<R>({path, method: HttpMethod.DELETE, ...request});

export type HttpPatchClient = <R, D = any>(
  path: string | string[],
  data?: D,
  request?: Omit<HttpClientRequest<R, D>, 'path' | 'method' | 'data'>
) => Promise<R>;

export const httpPatch: HttpPatchClient = <R, D = any>(
  path: string | string[],
  data?: D,
  request?: Omit<HttpClientRequest<R, D>, 'path' | 'method' | 'data'>
): Promise<R> => clientInvoker<R, D>({path, method: HttpMethod.PATCH, data, ...request});
