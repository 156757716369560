import {
  EntityDetailsProps,
  EntityTableRow,
  FilterGroup,
  FormButtons,
  InfiniteScrollTableCursor,
  SideFilter
} from "@sivis/shared/components/entityView";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";
import {isEqual, useAccountRoleMembershipsApi} from "./api/useAccountRoleMembershipsApi";
import {AccountRoleMembership, identityName, IdentityRoleMembership} from "@sivis/identity/api";
import {useSystemsForAccountsApi} from "../account/useSystemsForAccountsApi";
import React, {useState} from "react";
import {DialogActions} from "@mui/material";
import styles from "./membership.module.scss";
import {dateToString, isoStringToDate} from "@sivis/shared/components/date-picker";
import {InformationCard} from "../components/popup/relationInfoPanel";
import {getTemplateText, renderDate, validateDateRange} from "./membershipUtils";
import {ErrorPopup} from "../components/popup/errorPopup";

const tableRowToMembership = (row: EntityTableRow): AccountRoleMembership => ({
  id: row.id,
  validity: row.validity,
  account: row.account,
  role: row.role,
  active: row.active
});

export const MembershipAccountTab = (props: EntityDetailsProps<IdentityRoleMembership>) => {
  const [error, setError] = useState<null | string>(null);
  const intl = useCustomIntl();
  const parent: IdentityRoleMembership = props.entity;
  const {
    onSearch, pageSize, useAccountRoleMembershipsPageQuery, parseAccountRoleMembershipsPageResult,
    updateMembership, membershipsToUpdate, submitUpdates
  } = useAccountRoleMembershipsApi({
    identityId: parent.identity.id, roleId: parent.role.id
  });
  const {getSystemById} = useSystemsForAccountsApi();
  const template = parent.template;

  const columns: GridColDef[] = [
    {
      field: "accountId",
      flex: 1
    },
    {
      field: "systemName",
      flex: 1
    },
    {
      field: "validity",
      flex: 2,
      renderCell: params => (<>
        {renderDate({
          date: params.row.validity?.validFrom ?? null,
          onChange: onUpdateValidFrom(params.row),
          editMode: props.editMode,
          minDate: parent.validity?.validFrom ?? undefined,
          maxDate: parent.validity?.validTo ?? undefined
        })}
        {props.editMode ? <span>&nbsp;&nbsp;</span> : <span>&nbsp;-&nbsp;</span>}
        {renderDate({
          date: params.row.validity?.validTo ?? null,
          onChange: onUpdateValidTo(params.row),
          editMode: props.editMode,
          minDate: parent.validity?.validFrom ?? undefined,
          maxDate: parent.validity?.validTo ?? undefined
        })}
      </>)
    }
  ];
  const translatedColumns = useTranslateColumns(columns, "fusion.account.propertyName");

  const onUpdateValidFrom = (row: EntityTableRow) => (newDate: Date | null) => {
    const membership = tableRowToMembership(row);
    onSetDateRange(newDate, isoStringToDate(membership.validity?.validTo), membership);
  };

  const onUpdateValidTo = (row: EntityTableRow) => (newDate: Date | null) => {
    const membership = tableRowToMembership(row);
    onSetDateRange(isoStringToDate(membership.validity?.validFrom), newDate, membership);
  };

  const onSetDateRange = (from: Date | null, to: Date | null, membership: AccountRoleMembership) => {
    const result = validateDateRange(from, to, parent, intl.format);
    if (result.validated) {
      updateMembership({
        ...membership,
        validity: {
          validFrom: dateToString(from),
          validTo: dateToString(to)
        }
      });
    } else {
      setError(result.errorMsg);
    }
  };

  const entityToTableRow = (entity: AccountRoleMembership) => {
    const updatedEntity = membershipsToUpdate.find(membership => isEqual(membership, entity)) ?? entity;
    return {
      id: updatedEntity.id,
      accountId: updatedEntity.account.name,
      systemName: getSystemById(updatedEntity.account.systemId)?.systemName ?? updatedEntity.account.systemId,
      validity: updatedEntity.validity,
      active: updatedEntity.active,
      account: updatedEntity.account,
      role: updatedEntity.role
    };
  };

  const renderRows = (data: AccountRoleMembership[]) => {
    return data.map(entityToTableRow);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFilterPlaceholder = () => {};

  const sideFilter = <SideFilter title={intl.format("fusion.general.filter")}>
    <FilterGroup active={true} text={"All Systems"} amountEntries={123}
                 onClick={onFilterPlaceholder}/>
    <FilterGroup active={false} text={"?"} amountEntries={5} onClick={onFilterPlaceholder}/>
    <FilterGroup active={false} text={"?"} amountEntries={456} onClick={onFilterPlaceholder}/>
    <FilterGroup active={false} text={"?"} amountEntries={12} onClick={onFilterPlaceholder}/>
  </SideFilter>;

  const isRowSelected = (row: EntityTableRow) => row.active;

  const onSelectRow = (row: EntityTableRow, selected: boolean) => {
    updateMembership({
      ...tableRowToMembership(row),
      active: selected
    });
  };

  const onSave = () => {
    submitUpdates().then(props.onCancel);
  };

  const templateText = getTemplateText(template);

  const errorPopup = error !== null ? <ErrorPopup
    open={true}
    onClose={() => setError(null)}
    primaryTitle={intl.format(
      "fusion.membership.membershipPopup.title",
      {name: identityName(parent.identity)}
    )}
    secondaryTitle={intl.format(
      "fusion.membership.membershipPopup.description",
      {identity: identityName(parent.identity), role: parent.role.name}
    )}
    message={error}
  /> : null;

  return <div className={styles.tabContainer}>
    <div>
      <div className={styles.informationCardContainer}>
        <InformationCard text={templateText}/>
      </div>
      <InfiniteScrollTableCursor
        columns={translatedColumns}
        pageSize={pageSize}
        usePageQuery={useAccountRoleMembershipsPageQuery}
        renderRows={renderRows}
        parseResult={parseAccountRoleMembershipsPageResult}
        onSearch={onSearch}
        placeholder={intl.format("fusion.general.searchPlaceholder")}
        sideFilter={sideFilter}
        isRowSelected={isRowSelected}
        onSelectRow={onSelectRow}
        disableSelect={!props.editMode}
      />
    </div>
    {props.editMode ? <DialogActions>
      <FormButtons onSave={onSave} onCancel={props.onCancel} saveInsteadOfSubmit={true}/>
    </DialogActions> : null}

    {errorPopup}
  </div>;
};
