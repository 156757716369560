import {createSelector, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {HeaderItem} from './headerItem';

export const HEADER_SLICE_NAME = 'header';

export interface HeaderState {
  headerTitle: string;
  headerItems: HeaderItem[];
}

const initialState: HeaderState = {
  headerTitle: '',
  headerItems: []
}

export interface HeaderSliceState {
  [HEADER_SLICE_NAME]: HeaderState
}

const headerSlice = createSlice({
    name: HEADER_SLICE_NAME,
    initialState,
    reducers: {
      setHeaderTitle: (state: Draft<HeaderState>, action: PayloadAction<string>) => {
        state.headerTitle = action.payload;
      }
    }
  }
);

export const {
  setHeaderTitle
} = headerSlice.actions;

export const headerReducer = headerSlice.reducer;

const selectHeaderSlice = createSelector(
  (state: HeaderSliceState) => state,
  state => state.header
);

export const selectHeaderTitle = createSelector(
  selectHeaderSlice,
  state => state.headerTitle
);
