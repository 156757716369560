import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetIdentityRoleMembershipQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetIdentityRoleMembershipQuery = { __typename?: 'Query', identityRoleMembership?: { __typename?: 'IdentityRoleMembership', id: string, template: boolean, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, identity: { __typename?: 'Identity', id: string, firstName?: string | null, lastName: string, primaryEmail?: string | null, identityType: { __typename?: 'IdentityType', id: string, name: string } }, role: { __typename?: 'Role', id: string, name: string } } | null };

export type GetIdentityRoleMembershipsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetIdentityRoleMembershipsQuery = { __typename?: 'Query', identityRoleMemberships?: { __typename?: 'IdentityRoleMembershipConnection', edges: Array<{ __typename?: 'IdentityRoleMembershipEdge', node: { __typename?: 'IdentityRoleMembership', id: string, allAccounts?: boolean | null, template: boolean, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, identity: { __typename?: 'Identity', id: string, firstName?: string | null, lastName: string, primaryEmail?: string | null, identityType: { __typename?: 'IdentityType', id: string, name: string } }, role: { __typename?: 'Role', id: string, name: string }, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type CreateIdentityRoleMembershipMutationVariables = Types.Exact<{
  identityId: Types.Scalars['ID'];
  roleId: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.IdentityRoleMembershipInput>;
}>;


export type CreateIdentityRoleMembershipMutation = { __typename?: 'Mutation', createIdentityRoleMembership: { __typename?: 'IdentityRoleMembership', id: string, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, identity: { __typename?: 'Identity', id: string, firstName?: string | null, lastName: string, primaryEmail?: string | null, identityType: { __typename?: 'IdentityType', id: string, name: string } }, role: { __typename?: 'Role', id: string, name: string } } };

export type UpdateIdentityRoleMembershipMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.IdentityRoleMembershipInput>;
  id: Types.Scalars['ID'];
}>;


export type UpdateIdentityRoleMembershipMutation = { __typename?: 'Mutation', updateIdentityRoleMembership: { __typename?: 'IdentityRoleMembership', id: string, allAccounts?: boolean | null, template: boolean, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, identity: { __typename?: 'Identity', id: string, firstName?: string | null, lastName: string, primaryEmail?: string | null, identityType: { __typename?: 'IdentityType', id: string, name: string } }, role: { __typename?: 'Role', id: string, name: string }, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } };

export type DeleteIdentityRoleMembershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteIdentityRoleMembershipMutation = { __typename?: 'Mutation', deleteIdentityRoleMembership: { __typename?: 'IdentityRoleMembership', id: string, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, identity: { __typename?: 'Identity', id: string, firstName?: string | null, lastName: string, primaryEmail?: string | null, identityType: { __typename?: 'IdentityType', id: string, name: string } }, role: { __typename?: 'Role', id: string, name: string } } };


export const GetIdentityRoleMembershipDocument = `
    query GetIdentityRoleMembership($id: ID!) {
  identityRoleMembership(id: $id) {
    id
    validity {
      validFrom
      validTo
    }
    template
    identity {
      id
      firstName
      lastName
      identityType {
        id
        name
      }
      primaryEmail
    }
    role {
      id
      name
    }
  }
}
    `;
export const GetIdentityRoleMembershipsDocument = `
    query GetIdentityRoleMemberships($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  identityRoleMemberships(
    first: $first
    after: $after
    sort: $sort
    filter: $filter
  ) {
    edges {
      node {
        id
        validity {
          validFrom
          validTo
        }
        identity {
          id
          firstName
          lastName
          primaryEmail
          identityType {
            id
            name
          }
        }
        role {
          id
          name
        }
        allAccounts
        template
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const CreateIdentityRoleMembershipDocument = `
    mutation CreateIdentityRoleMembership($identityId: ID!, $roleId: ID!, $input: IdentityRoleMembershipInput) {
  createIdentityRoleMembership(
    identityId: $identityId
    roleId: $roleId
    input: $input
  ) {
    id
    validity {
      validFrom
      validTo
    }
    identity {
      id
      firstName
      lastName
      primaryEmail
      identityType {
        id
        name
      }
    }
    role {
      id
      name
    }
  }
}
    `;
export const UpdateIdentityRoleMembershipDocument = `
    mutation UpdateIdentityRoleMembership($input: IdentityRoleMembershipInput, $id: ID!) {
  updateIdentityRoleMembership(input: $input, id: $id) {
    id
    validity {
      validFrom
      validTo
    }
    identity {
      id
      firstName
      lastName
      primaryEmail
      identityType {
        id
        name
      }
    }
    role {
      id
      name
    }
    allAccounts
    template
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const DeleteIdentityRoleMembershipDocument = `
    mutation DeleteIdentityRoleMembership($id: ID!) {
  deleteIdentityRoleMembership(id: $id) {
    id
    validity {
      validFrom
      validTo
    }
    identity {
      id
      firstName
      lastName
      primaryEmail
      identityType {
        id
        name
      }
    }
    role {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetIdentityRoleMembership: build.query<GetIdentityRoleMembershipQuery, GetIdentityRoleMembershipQueryVariables>({
      query: (variables) => ({ document: GetIdentityRoleMembershipDocument, variables })
    }),
    GetIdentityRoleMemberships: build.query<GetIdentityRoleMembershipsQuery, GetIdentityRoleMembershipsQueryVariables | void>({
      query: (variables) => ({ document: GetIdentityRoleMembershipsDocument, variables })
    }),
    CreateIdentityRoleMembership: build.mutation<CreateIdentityRoleMembershipMutation, CreateIdentityRoleMembershipMutationVariables>({
      query: (variables) => ({ document: CreateIdentityRoleMembershipDocument, variables })
    }),
    UpdateIdentityRoleMembership: build.mutation<UpdateIdentityRoleMembershipMutation, UpdateIdentityRoleMembershipMutationVariables>({
      query: (variables) => ({ document: UpdateIdentityRoleMembershipDocument, variables })
    }),
    DeleteIdentityRoleMembership: build.mutation<DeleteIdentityRoleMembershipMutation, DeleteIdentityRoleMembershipMutationVariables>({
      query: (variables) => ({ document: DeleteIdentityRoleMembershipDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetIdentityRoleMembershipQuery, useLazyGetIdentityRoleMembershipQuery, useGetIdentityRoleMembershipsQuery, useLazyGetIdentityRoleMembershipsQuery, useCreateIdentityRoleMembershipMutation, useUpdateIdentityRoleMembershipMutation, useDeleteIdentityRoleMembershipMutation } = injectedRtkApi;

