import { DeleteButton, EditButton, EntityView } from "@sivis/shared/components/entityView";
import { Outlet, useOutletContext } from "react-router-dom";
import { useAccountApi } from "./useAccountApi";
import { useCustomIntl } from "@sivis/intl";
import { getTranslatedErrorMessage } from "@sivis/shared/api-utils";
import { INFORMATION_TAB_PATHNAME } from "../components/routes/constants";
import { AccountInformationTab, AccountInformationTabProps } from "./AccountInformationTab";

export const AccountView = () => {
  const intl = useCustomIntl();
  const {
    account,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
    defaultInput
  } = useAccountApi();


  const tabs = [
    { label: intl.format("fusion.breadcrumb.information"), path: INFORMATION_TAB_PATHNAME }, //information tab
    // Add other tabs if necessary
  ];

  const titleExisting = `${intl.format("fusion.account.information.titleExisting")}${account?.name ?? ''}`;
  const title = isNew ? intl.format("fusion.account.information.titleNew") : titleExisting;

  const buttons = isNew ? undefined : [
    <EditButton onClick={toggleEditMode} disabled={editMode} key="edit" />,
    <DeleteButton onClick={onDelete} key="delete" />
  ];

  return (
    <EntityView
      title={title}
      tabs={tabs}
      actionButtons={buttons}
      error={error ? getTranslatedErrorMessage(error, "account.error.notFound", intl.format) : undefined}
    >
      <Outlet
        context={{
          editMode,
          account,
          isNew,
          onSave,
          onCancel,
          onDelete,
          defaultInput
        }} />
    </EntityView>
  );
};

export const AccountInformationTabWrapperComponent = () => {
  const props = useOutletContext<AccountInformationTabProps>();
  return <AccountInformationTab {...props} />;
};
