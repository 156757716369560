import {AttributeRowConfig} from "@sivis/shared/components/entityView";
import {useCustomIntl} from "@sivis/intl";


export const useTranslateConfigs = (configs: (Omit<AttributeRowConfig, "name"> & {
  name?: string
})[], textIdPrefix: string): AttributeRowConfig[] => {
  const intl = useCustomIntl();

  return configs.map(config => ({
    ...config,
    name: config.name ?? intl.format(`${textIdPrefix}.${config.property}`)
  }))
}
