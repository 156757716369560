import {SerializeQueryArgs} from "@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs";
import {defaultSerializeQueryArgs,} from '@reduxjs/toolkit/query/react';
import {PaginationArgs} from "./generatedTypes";
import _ from "lodash";
import {ActionCreator, AnyAction, ThunkDispatch} from "@reduxjs/toolkit";

/**
 * Append response with cache entry key fields in query args.
 */
export const transformResponseWithArgs = <T>(response: T, _meta: any, arg: void | PaginationArgs & {
  extraKey?: string
}): any => {
  return {
    ...response,
    key: getCacheEntryKeyFromArgs(arg ?? undefined)
  };
};

/**
 * Only fields returned from this function will be compared when deciding
 * if the cache should be reset on query args change.
 * e.g. the cache should be reset when filter is changed, but not reset when a new page is fetched.
 * Note that this will only reset the same cache entry and not create a new entry (in contrary to
 * cacheKey in serializeQueryArgsPaging).
 */
const getCacheEntryKeyFromArgs = (arg?: PaginationArgs & { extraKey?: string }) => {
  return arg ? {filter: arg.filter, extraKey: arg.extraKey} : undefined;
};

/**
 * Only have one cache entry for one endpoint, unless some cache key is specifically defined
 */
export const serializeQueryArgsPaging: SerializeQueryArgs<any, any> = ({
                                                                         queryArgs,
                                                                         endpointDefinition,
                                                                         endpointName
                                                                       }) => {
  // Endpoint is called without paging, restore default behavior
  if (!queryArgs?.first && !queryArgs?.after) {
    return defaultSerializeQueryArgs({queryArgs, endpointDefinition, endpointName});
  }
  return {endpointName, cacheKey: queryArgs?.cacheKey};
};

/**
 * Merge new entries from response with existing entries in cache
 */
export const mergePaging: (path: string) => (currentCacheData: any, responseData: any, otherArgs: any) => void = (path) => (currentCacheData, responseData, otherArgs) => {
  // Endpoint is called without paging, replace cache data with incoming data
  if (!otherArgs.arg.first && !otherArgs.arg.after) {
    return responseData;
  }

  // Endpoint is called with a different key, replace cache data with incoming data
  if (!_.isEqual(responseData?.key, currentCacheData?.key)) {
    return responseData;
  }

  if (currentCacheData?.[path] && responseData?.[path]) {
    currentCacheData[path].edges.push(...responseData[path].edges);
    // For the case when the cache is cleared manually and pageInfo doesn't exist
    // e.g. after mutation
    if (!currentCacheData[path].pageInfo) {
      currentCacheData[path].pageInfo = responseData[path].pageInfo;
    }
    currentCacheData[path].pageInfo = {
      ...currentCacheData[path].pageInfo,
      hasNextPage: responseData[path].pageInfo.hasNextPage,
      // set endCursor to null to indicate last page
      endCursor: responseData[path].pageInfo.hasNextPage ? responseData[path].pageInfo.endCursor : null
    }
  }
}

/**
 * Decides whether a new request should be made or cache data should be used.
 * Necessary when using serializeQueryArgsPaging,
 * otherwise cache is never updated for the same endpoint.
 */
export const forceRefetchPaging: (params: {
  currentArg: any;
  previousArg: any;
}) => boolean = ({currentArg, previousArg}) => {
  const isNewPage = !!currentArg?.after && currentArg?.after !== previousArg?.after;
  const shouldResetCache = !_.isEqual(getCacheEntryKeyFromArgs(currentArg), getCacheEntryKeyFromArgs(previousArg));
  return isNewPage || shouldResetCache;
};

/**
 * Used to invalidate cache of infinite scroll endpoints after mutation instead of invalidateTags,
 * which doesn't work as intended with custom merge function.
 */
export const executeAfterQueryFulfilled = <T>(actionCreators: ActionCreator<any>[]) => async (arg: unknown, {
  dispatch,
  queryFulfilled
}: {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  queryFulfilled: Promise<{ data: T }>
}) => {
  const {data} = await queryFulfilled;
  actionCreators.forEach(actionCreator => dispatch(actionCreator(data, arg)));
};
