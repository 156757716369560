

interface AccountTypeAccountsTabProps {

}

const AccountTypeAccountsTab = (props: AccountTypeAccountsTabProps) => {
  return <div>
    Placeholder
  </div>
}

export default AccountTypeAccountsTab;

