import {Link} from "react-router-dom";
import {OrganizationalEntityType} from "@sivis/identity/api";
import {useOrganizationalEntityTypesPageApi} from "./api/useOrganizationalEntityTypesPageApi";
import {GridColDef} from '@mui/x-data-grid';
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: OrganizationalEntityType) => {
  return {
    id: entity.id,
    name: entity.name,
    apiName: entity.apiName,
    description: entity.description
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "apiName",
    flex: 1,
  },
  {
    field: "description",
    flex: 1,
  }
];

export const OrganizationalEntityTypeList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useOrganizationalEntityTypesPageQuery,
    parseOrganizationalEntityTypesPageResult,
    pageSize
  } = useOrganizationalEntityTypesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.organizationalEntityType.propertyName");

  return <EntityView
    title={intl.format("fusion.organizationalEntityType.list.title")}
    actionButtons={[<CreateButton key={"create"}/>]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useOrganizationalEntityTypesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseOrganizationalEntityTypesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
