import {Address, AddressInput, emptyAddressInput} from "@sivis/identity/api";
import {defaults, omit} from "lodash";

export const addressInputFactory = (address?: Address | AddressInput | null): AddressInput => {
  if (!address) {
    return emptyAddressInput();
  }

  return defaults({}, omit(address, ['id', 'meta', 'organizationalEntities']), emptyAddressInput());
}
