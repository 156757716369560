import { SIDEBAR_SLICE_NAME, sidebarReducer } from '../components/sidebar/sidebarSlice';
import { LOGO_SLICE_NAME, logoReducer } from '../../../../shared/components/logo/src/lib/logoSlice';
import { APP_SLICE_NAME, appReducer } from './appSlice';
import { HEADER_SLICE_NAME, headerReducer } from '../components/header/headerSlice';

export const appShellReducers = {
  [SIDEBAR_SLICE_NAME]: sidebarReducer,
  [LOGO_SLICE_NAME]: logoReducer,
  [APP_SLICE_NAME]: appReducer,
  [HEADER_SLICE_NAME]: headerReducer
};


export const APP_SHELL_BLACKLIST = [APP_SLICE_NAME];
