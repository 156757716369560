import {AttributeRows, RowGroup} from "@sivis/shared/components/entityView";
import {ResourceInput} from "@sivis/identity/api";
import styles from "./resource.module.scss";
import {useTranslateConfigs} from "@sivis/intl";

interface ResourceInformationTabProps {
  resourceInput: ResourceInput,
}

export const configs = [
  {
    property: 'name',
    required: true
  },
  {
    property: 'description',
  },
];

const ResourceInformationTab = (props: ResourceInformationTabProps) => {
  const translatedConfigs = useTranslateConfigs(configs, "fusion.resource.propertyName");

  const entityInformation = <RowGroup>
    <AttributeRows
      configs={translatedConfigs}
      value={props.resourceInput}
    />
  </RowGroup>;

  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
};

export default ResourceInformationTab;

