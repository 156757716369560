import {
  Resource,
  ResourceSet,
  resourceSetInputFactory,
  searchResourcesFilter,
  useGetResourceSetWithResourcesQuery,
  useGetResourcesQuery,
  useUpdateResourceSetMutation
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {useListSearch} from "../utils/filterUtils";

// TODO: refactor after adding pagination to ListModifyView (SIVIS-8037)
export const useResourcesForResourceSetApi = (resourceSet?: ResourceSet) => {
  const resourceSetId = resourceSet?.id;
  const {
    filter: resourceFilter,
    onSearch: onSearchForResources
  } = useListSearch(searchResourcesFilter);
  const {data: dataResources, refetch} = useGetResourceSetWithResourcesQuery(
    {id: resourceSetId ?? "", filter: resourceFilter},
    {refetchOnMountOrArgChange: true, skip: !resourceSetId}
  );
  const {
    filter: availableResourceFilter,
    onSearch: onSearchForAvailableResources
  } = useListSearch(searchResourcesFilter);
  const {data: dataResourceOptions} = useGetResourcesQuery({filter: availableResourceFilter}, {refetchOnMountOrArgChange: true});
  const [updateResourceSet] = useUpdateResourceSetMutation();
  const [currentlyContainedResources, setCurrentlyContainedResources] = useState<Resource[]>([]);
  const [availableResources, setAvailableResources] = useState<Resource[]>([]);

  useEffect(() => {
    // TODO: filter available resources in backend
    const filteredResources: Resource[] = dataResourceOptions?.resources?.edges.filter(edge =>
      edge?.node && !currentlyContainedResources.some(resource => resource.id === edge.node.id))
    .map(edge => edge!.node as Resource) || [];
    setAvailableResources(filteredResources);
  }, [dataResourceOptions?.resources, currentlyContainedResources]);

  useEffect(() => {
    setCurrentlyContainedResources((dataResources?.resourceSet?.resources?.edges ?? [])
    .filter(edge => edge?.node)
    .map(edge => edge!.node));
  }, [dataResources?.resourceSet]);

  const onSave = (added: Resource[], deleted: Resource[]) => {
    if (resourceSetId) {
      const resourceSetInput = resourceSetInputFactory(resourceSet);
      currentlyContainedResources.forEach(resource => resourceSetInput.resources?.push(resource!.id));
      deleted.forEach(deletedResource => {
        const index = resourceSetInput.resources?.indexOf(deletedResource.id);
        if (index && index > 0) {
          resourceSetInput.resources?.splice(index, 1);
        }
      })
      added.forEach(addedResource => resourceSetInput.resources?.push(addedResource.id));

      updateResourceSet({id: resourceSetId, input: resourceSetInput}).then(refetch);
    }
  };

  return {
    currentlyContainedResources: currentlyContainedResources,
    onSave,
    onSearchForAvailableResources,
    availableResources,
    onSearch: onSearchForResources
  };
};

