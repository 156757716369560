import {EOetRelationType, EOetRelationTypeSelectOption} from '../enums/EOetRelationType';
import {OetRelation, OetRelationType, OrganizationalEntityType} from '@sivis/identity/api';
import {CustomIntlType} from '@sivis/intl';
import {EntityTableRow} from '@sivis/shared/components/entityView';


export const intlIdRelationButtonSubtitleNotRequired = 'fusion.organizationalEntityType.relations.createRelationButtonSubtitle.notRequired';
export const intlIdRelationButtonSubtitleRequired = 'fusion.organizationalEntityType.relations.createRelationButtonSubtitle.required';
export const intlPrefixRelationButtonSubtitle = 'fusion.organizationalEntityType.relations.createRelationButtonSubtitle.';

export const OET_RELATION_TYPE_OPTIONS: EOetRelationTypeSelectOption[] = [
  EOetRelationTypeSelectOption.parent,
  EOetRelationTypeSelectOption.child,
  EOetRelationTypeSelectOption.default_value
];

export const generateOetRelationId = (idParts: string[]): string => {
  return idParts.join('_');
};

export const entityToOetRelationTableRow = (entity: OetRelation, organizationalEntityTypeId: string, intl: CustomIntlType): EntityTableRow => {
  return {
    id: entity.id,
    organizationalEntityType: entity.ownerType.id === organizationalEntityTypeId ? entity.assignedType.name : entity.ownerType.name,
    oetRelationType: intl.format('fusion.organizationalEntityTypeRelation.property.' + getRoleOfOrganizationalEntityTypeInOetRelation(organizationalEntityTypeId, entity)),
    isRequired: entity.isRequired ? intl.format('fusion.organizationalEntityType.relations.property.isRequired') : intl.format('fusion.organizationalEntityType.relations.property.isNotRequired')
  };
};

export const getRoleOfOrganizationalEntityTypeInOetRelation = (
  organizationalEntityTypeId: string,
  oetRelation?: OetRelation
): string => {

  if (!oetRelation) {
    return EOetRelationTypeSelectOption.default_value.toLowerCase();
  }

  switch (oetRelation.oetRelationType.type) {
    case EOetRelationType.parent_child.toUpperCase():
      return oetRelation.ownerType.id === organizationalEntityTypeId ? EOetRelationTypeSelectOption.child.toLowerCase() : EOetRelationTypeSelectOption.parent.toLowerCase();
    case EOetRelationType.default_value.toUpperCase():
      return EOetRelationTypeSelectOption.default_value.toLowerCase();
    default:
      return 'notFound';
  }
};

export const getOetRelationTypeSelectOptionForOetRelationAndOrganizationalEntityType = (
  organizationalEntityTypeId: string,
  oetRelation?: OetRelation
): EOetRelationTypeSelectOption => {
  if (!oetRelation) {
    return EOetRelationTypeSelectOption.default_value;
  }

  switch (oetRelation.oetRelationType.type) {
    case EOetRelationType.parent_child.toUpperCase():
      return oetRelation.ownerType.id === organizationalEntityTypeId ? EOetRelationTypeSelectOption.child : EOetRelationTypeSelectOption.parent;
    case EOetRelationType.default_value.toUpperCase():
      return EOetRelationTypeSelectOption.default_value;
    default:
      return EOetRelationTypeSelectOption.default_value;
  }
};


export interface OetRelationOrganizationalEntityTypeTextFieldProps {
  value: string;
}


export interface OetRelationPopupInput {
  organizationalEntityType: OrganizationalEntityType;
  oetRelationType: EOetRelationTypeSelectOption;
  isRequired: boolean;
}


export const getSelectedOrganizationalEntityType = (value: Record<string, any>, organizationalEntityType: OrganizationalEntityType, organizationalEntityTypes: OrganizationalEntityType[]): OrganizationalEntityType => {
  return organizationalEntityTypes.find(
    organizationalEntityType => organizationalEntityType.id === value.organizationalEntityType
  ) ?? {
    id: '',
    name: ''
  };
};

export const convertInputRecordsIntoOetRelation = (
  value: OetRelationPopupInput,
  organizationalEntityType: OrganizationalEntityType,
  organizationalEntityTypes: OrganizationalEntityType[],
  oetRelationTypes: OetRelationType[],
  // ID of the oetRelation being edited. If no ID provided, then a new entity is being created.
  id?: string
): OetRelation => {
  const ownerType = value.oetRelationType === EOetRelationTypeSelectOption.parent ?
    getSelectedOrganizationalEntityType(value, organizationalEntityType, organizationalEntityTypes) :
    organizationalEntityType;
  const assignedType = value.oetRelationType === EOetRelationTypeSelectOption.parent ?
    organizationalEntityType :
    getSelectedOrganizationalEntityType(value, organizationalEntityType, organizationalEntityTypes);
  const oetRelationType = getOetRelationTypeForOetRelationTypeSelectOption(value.oetRelationType, oetRelationTypes);
  return {
    id: id ?? generateOetRelationId([ownerType.id, assignedType.id, oetRelationType.id ?? ""]),
    ownerType,
    assignedType,
    oetRelationType,
    isRequired: value.isRequired
  };
};


export const getOetRelationTypeForOetRelationTypeSelectOption = (
  oetRelationSelectOption: EOetRelationTypeSelectOption,
  oetRelationTypes: OetRelationType[]
): OetRelationType => {

  if (!oetRelationTypes.length) {
    return {
      id: '',
      type: ''
    };
  }

  return oetRelationTypes.find(
    oetRelationType => oetRelationType.type?.includes(
      oetRelationSelectOption
    )
  ) ?? oetRelationTypes[0];
};


export const getRelationDefaultInput = (
  isEdit: boolean | undefined,
  oetRelation: OetRelation | undefined,
  organizationalEntityType: OrganizationalEntityType | undefined,
  createdOetRelation: OetRelation | undefined
): OetRelationPopupInput | undefined => {
  if (!organizationalEntityType) return undefined;

  const ownerType = ((isEdit && !createdOetRelation) ?
    oetRelation?.ownerType : createdOetRelation?.ownerType) || {id: '', name: ''};
  const assignedType = ((isEdit && !createdOetRelation) ?
    oetRelation?.assignedType : createdOetRelation?.assignedType) || ownerType;

  return {
    organizationalEntityType: organizationalEntityType.id === ownerType.id ? assignedType : ownerType,
    oetRelationType: getOetRelationTypeSelectOptionForOetRelationAndOrganizationalEntityType(
      organizationalEntityType.id,
      isEdit ? oetRelation : createdOetRelation
    ) ?? EOetRelationTypeSelectOption.default_value,
    isRequired: !!(isEdit ? (createdOetRelation?.isRequired ?? oetRelation?.isRequired) : createdOetRelation?.isRequired)
  }
};


export const getSecondaryTitle = (
  intl: CustomIntlType,
  oetRelationForSecondaryTitle: OetRelation | undefined,
  organizationalEntityType: OrganizationalEntityType
) => {

  let type: string = organizationalEntityType?.name;
  let relatedOrganizationalEntityType: string = organizationalEntityType?.id === oetRelationForSecondaryTitle?.ownerType.id ?
    oetRelationForSecondaryTitle?.assignedType?.name : oetRelationForSecondaryTitle?.ownerType?.name ?? '...';
  let oetRelationType: string = intl.format(intlPrefixRelationButtonSubtitle + getRoleOfOrganizationalEntityTypeInOetRelation(organizationalEntityType?.id ?? '', oetRelationForSecondaryTitle));
  let required: string = oetRelationForSecondaryTitle?.isRequired ? intl.format(intlIdRelationButtonSubtitleRequired) :
    intl.format(intlIdRelationButtonSubtitleNotRequired);

  return intl.format('fusion.organizationalEntityType.relations.createRelationButtonSubtitle',
    {
      type: type,
      required: required,
      oetRelationType: oetRelationType,
      relatedOrganizationalEntityType: relatedOrganizationalEntityType
    });
};

