import {CreatePopupProps, Popup} from '@sivis/shared/components/entityView';
import {OetRelation, OetRelationType, OrganizationalEntityType} from '@sivis/identity/api';
import React, {useEffect, useState} from 'react';
import {useCustomIntl} from '@sivis/intl';
import {
  getRelationDefaultInput,
  getSecondaryTitle,
  OetRelationPopupInput
} from '../utils/oetRelationUtils';
import OetRelationsPopup from './oetRelationsPopup';
import {EOetRelationTypeSelectOption} from '../enums/EOetRelationType';
import {useGetOetRelation} from "../hooks/oetRelationHooks";

interface CreateOetrPopupProps {
  organizationalEntityType: OrganizationalEntityType;
  oetRelationTypes: OetRelationType[];
  organizationalEntityTypes: OrganizationalEntityType[];
  createdOetRelation: OetRelation | undefined;
  setCreatedOetRelation: (createdOetRelation: OetRelation | undefined) => void;
  oetRelationTypeSelectOptions: EOetRelationTypeSelectOption[];
  onWatch: (value: Record<string, any>) => void;
  id?: string;
  handleOnSave: () => void;
  isEdit?: boolean;
}

export const CreateOetRelationPopup = (props: CreateOetrPopupProps) => function Comp({
                                                                                       open,
                                                                                       onClose
                                                                                     }: CreatePopupProps<OetRelation>) {

  const {oetRelation, loading} = useGetOetRelation(props.id);

  /*TODO:
   - if a has child b, b can't have child a
   */

  const intl = useCustomIntl();

  const [oetRelationInput, setOetRelationInput] = useState<OetRelationPopupInput | undefined>(undefined);
  const [primaryTitle, setPrimaryTitle] = useState<string>('');
  const [secondaryTitle, setSecondaryTitle] = useState<string>('');

  const handleOnSubmit = (value: Record<string, any>) => {
    props.handleOnSave();
  };

  useEffect(() => {

    setOetRelationInput(
      getRelationDefaultInput(
        props.isEdit,
        oetRelation,
        props.organizationalEntityType,
        props.createdOetRelation
      )
    );

    setPrimaryTitle(
      props.isEdit ?
        intl.format('fusion.organizationalEntityType.relations.editRelationButtonTitle') :
        intl.format('fusion.organizationalEntityType.relations.createRelationButtonTitle')
    );

    setSecondaryTitle(
      (oetRelation && !props.createdOetRelation) ? getSecondaryTitle(intl, oetRelation, props.organizationalEntityType) :
        getSecondaryTitle(intl, props.createdOetRelation, props.organizationalEntityType)
    );

  }, [props.organizationalEntityType, oetRelation, props.createdOetRelation, props.id]);





  let content = <OetRelationsPopup
    open={open}
    onClose={onClose}
    onSubmit={handleOnSubmit}
    organizationalEntityTypes={props.organizationalEntityTypes}
    organizationalEntityType={props.organizationalEntityType}
    oetRelationTypeSelectOptions={props.oetRelationTypeSelectOptions}
    oetRelationTypes={props.oetRelationTypes}
    onWatch={props.onWatch}
    oetRelation={oetRelation}
    createdOetRelation={props.createdOetRelation}
    oetRelationInput={oetRelationInput}
    isEdit={props.isEdit}
    isLoading={loading}
  />;


  return <Popup
    open={open}
    onClose={onClose}
    primaryTitle={primaryTitle}
    secondaryTitle={secondaryTitle}
    styleType="edit"
    hideButtons
  >
    {content}
  </Popup>;
}



