import React, { useState, useEffect } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { ModifiedAutocompleteField } from '../autocompleteField/ModifiedAutocompleteField';
import { AttributeConfig } from '../form/attributeRowConfig';
import { SearchConfig } from '../form/entityForm';

interface FormAutocompleteProps {
  fieldProps: ControllerRenderProps<Record<string, any>, string>;
  config: AttributeConfig;
  searchConfig?: SearchConfig;
  globalSearchFunction?: (searchTerm: string) => any;
  globalSearchResults?: any;
  globalLoadingEntities?: boolean;
}

interface CurrentValue {
  id: string;
  label: string;
}


export const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
                                                                    fieldProps,
                                                                    config,
                                                                    searchConfig,
                                                                    globalSearchFunction,
                                                                    globalSearchResults,
                                                                    globalLoadingEntities,
                                                                  }) => {
  const [disableRefetch, setDisableRefetch] = useState(false);

  const fieldKey = config.property.toLowerCase();

  let currentValue: CurrentValue | undefined;

  useEffect(() => {
    if (!fieldProps.value && currentValue) {
      fieldProps.onChange(currentValue.id);
    }
  }, [currentValue, fieldProps]);

  if (searchConfig) {
    if (fieldKey === 'identity' && searchConfig.currentValue) {
      currentValue = {
        id: searchConfig.currentValue.id,
        label: `${searchConfig.currentValue.firstName ?? ''} ${searchConfig.currentValue.lastName} (${searchConfig.currentValue.primaryEmail ?? 'No email'})`,
      };
    } else if (fieldKey === 'system' && searchConfig.currentValue) {
      currentValue = {
        id: searchConfig.currentValue.id,
        label: searchConfig.currentValue.systemName,
      };
    }
    return (
      <ModifiedAutocompleteField
        searchFunction={searchConfig.searchFunction}
        fieldProps={fieldProps}
        disableRefetch={disableRefetch}
        setDisableRefetch={setDisableRefetch}
        searchResults={searchConfig.searchResults}
        loadingEnities={searchConfig.loadingState}
        fieldType={fieldKey}
        currentValue={currentValue}
      />
    );
  } else if (globalSearchFunction) {
    return (
      <ModifiedAutocompleteField
        searchFunction={globalSearchFunction}
        fieldProps={fieldProps}
        disableRefetch={disableRefetch}
        setDisableRefetch={setDisableRefetch}
        searchResults={globalSearchResults}
        loadingEnities={globalLoadingEntities ?? false}
      />
    );
  }

  return null;
};
