import {
  PermissionInput,
  permissionInputFactory,
  useCreatePermissionMutation,
  useDeletePermissionMutation,
  useGetPermissionQuery,
  useUpdatePermissionMutation
} from "@sivis/security/api";
import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";

//TODO: move this to a library
export enum EntityCommand {
  create = 'create',
  update = 'update',
  delete = 'delete'
}

export const newId = 'new'

export const usePermissionApi = () => {
  const {id = newId} = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useGetPermissionQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [deletePermission] = useDeletePermissionMutation()
  const [updatePermission] = useUpdatePermissionMutation();
  const [createPermission] = useCreatePermissionMutation();
  const [permission, setPermission] = useState<PermissionInput>({});
  const {editMode, toggleEditMode} = useSingleEditMode(EntityType.PERMISSION, isNew);

  useEffect(() => {
    setPermission(permissionInputFactory(data?.permission));
  }, [data?.permission]);
  const goBack = () => navigate("/permission");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {

    onSave = (value: PermissionInput) => {
      createPermission({input: value}).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {

    onSave = (value: PermissionInput) => {
      updatePermission({id, input: value})
      .then(() => {
        toggleEditMode();
        refetch();
      });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deletePermission({id}).then(goBack);
    };

  }


  return {
    permission,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
}
