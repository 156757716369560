import { ThunkAction } from "@reduxjs/toolkit";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GeneratedResourceTypes,
  GeneratedResourceTypeTypes,
  GeneratedSystemTypes,
  resourceApi,
  ResourceType,
  resourceTypeApi,
  System,
  systemApi
} from "@sivis/identity/api";
import { combineFilter, FilterOperator } from "@sivis/shared/api-utils";
import { GeneratedIdentityTypes, identityApi } from "../identity";
import { Entitlement, FilterEntry } from "../identityApi.types";
import { GeneratedRoleTypes, roleApi } from "../role";
import {
  CreateEntitlementMutation,
  GetEntitlementsQuery,
  GetEntitlementsResourceTypesQuery,
  GetEntitlementsResourceTypesQueryVariables,
  GetEntitlementsSystemsQuery,
  GetEntitlementsSystemsQueryVariables,
  UpdateEntitlementMutation
} from "./entitlement.generated";
import { entitlementApi, injectedEntitlementApi } from "./entitlementEnhanced";

export enum EntitlementType {
  IDENTITY_RESOURCE_ENTITLEMENT = "IDENTITY_RESOURCE_ENTITLEMENT",
  ACCOUNT_RESOURCE_ENTITLEMENT = "ACCOUNT_RESOURCE_ENTITLEMENT",
  IDENTITY_RESOURCE_SET_ENTITLEMENT = "IDENTITY_RESOURCE_SET_ENTITLEMENT",
  ROLE_RESOURCE_ENTITLEMENT = "ROLE_RESOURCE_ENTITLEMENT",
  ROLE_RESOURCE_SET_ENTITLEMENT = "ROLE_RESOURCE_SET_ENTITLEMENT",
  ROLE_SYSTEM_ENTITLEMENT = "ROLE_SYSTEM_ENTITLEMENT"
}

// To display entitlement with associated resource in the list
export interface ExtendedResourceEntitlement extends Entitlement {
  resourceName?: string;
  resourceTypeName?: string;
  context?: string;
}

export interface ExtendedIdentityEntitlement extends Entitlement {
  identityFirstName?: string;
  identityLastName?: string;
  identityPrimaryEmail?: string;
  identityAccounts?: number;
}

export interface ExtendedRoleEntitlement extends Entitlement {
  roleName?: string;
}

export interface ExtendedSystemEntitlement extends Entitlement {
  systemName?: string;
}

export const extendResourceEntitlements = (dataEntitlements: GetEntitlementsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const resourceIds = dataEntitlements.entitlements?.edges.filter(edge => edge?.node.targetId)
    .map(edge => edge?.node.targetId ?? "") ?? [];
    const resourceFilter = resourceIds.length ?
      combineFilter(FilterOperator.OR, ...resourceIds.map(id => ({
        name: "id",
        operator: FilterOperator.EQ,
        uuidValue: id
      }))) : undefined;
    const promise = dispatch(resourceApi.endpoints.GetResources.initiate({filter: resourceFilter}));
    const {data: dataResources} = await promise;
    promise.unsubscribe();

    if (dataResources) {
      dispatch(patchEntitlementsCacheWithResources(dataResources, arg.cacheKey));
    }
  }
}

const patchEntitlementsCacheWithResources = (resources: GeneratedResourceTypes.GetResourcesQuery, entitlementType: string) => {
  return entitlementApi.util.updateQueryData("GetResourceEntitlements", {
    endpointName: entitlementApi.endpoints.GetResourceEntitlements.name,
    cacheKey: entitlementType
  } as any, currentCacheData => {
    (currentCacheData?.entitlements?.edges ?? []).forEach(edge => {
      if (edge?.node && !(edge.node as ExtendedResourceEntitlement).resourceName) {
        const resource = resources.resources?.edges.find(resourceEdge => resourceEdge?.node?.id === edge.node.targetId)?.node;
        edge.node = {
          ...edge.node,
          resourceName: resource?.name ?? "",
          resourceTypeName: resource?.resourceType?.name ?? "",
          context: ""
        } as ExtendedResourceEntitlement
      }
    });
  })
}

export const extendIdentityEntitlements = (dataEntitlements: GetEntitlementsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const identityIds = dataEntitlements.entitlements?.edges.filter(edge => edge?.node.entitledId)
    .map(edge => edge?.node.entitledId ?? "") ?? [];
    const identityFilter = identityIds.length ?
      combineFilter(FilterOperator.OR, ...identityIds.map(id => ({
        name: "id",
        operator: FilterOperator.EQ,
        uuidValue: id
      }))) : undefined;
    const promise = dispatch(identityApi.endpoints.GetIdentities.initiate({filter: identityFilter}));
    const {data: dataIdentities} = await promise;
    promise.unsubscribe();

    if (dataIdentities) {
      dispatch(patchEntitlementsCacheWithIdentities(dataIdentities, arg.cacheKey));
    }
  }
}

const patchEntitlementsCacheWithIdentities = (identities: GeneratedIdentityTypes.GetIdentitiesQuery, entitlementType: string) => {
  return entitlementApi.util.updateQueryData("GetIdentityEntitlements", {
    endpointName: entitlementApi.endpoints.GetIdentityEntitlements.name,
    cacheKey: entitlementType
  } as any, currentCacheData => {
    (currentCacheData?.entitlements?.edges ?? []).forEach(edge => {
      if (edge?.node && !(edge.node as ExtendedIdentityEntitlement).identityLastName) {
        const identity = identities.identities?.edges.find(identityEdge => identityEdge?.node?.id === edge.node.entitledId)?.node;
        edge.node = {
          ...edge.node,
          identityFirstName: identity?.firstName ?? "",
          identityLastName: identity?.lastName ?? "",
          identityPrimaryEmail: identity?.primaryEmail ?? "",
        } as ExtendedIdentityEntitlement
      }
    });
  })
}

export const extendRoleEntitlements = (dataEntitlements: GetEntitlementsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const roleIds = dataEntitlements.entitlements?.edges.filter(edge => edge?.node.entitledId)
    .map(edge => edge?.node.entitledId ?? "") ?? [];
    const roleFilter = roleIds.length ?
        combineFilter(FilterOperator.OR, ...roleIds.map(id => ({
          name: "id",
          operator: FilterOperator.EQ,
          uuidValue: id
        }))) : undefined;
    const promise = dispatch(roleApi.endpoints.GetRoles.initiate({filter: roleFilter}));
    const {data: dataRoles} = await promise;
    promise.unsubscribe();

    if (dataRoles) {
      dispatch(patchEntitlementsCacheWithRoles(dataRoles, arg.cacheKey));
    }
  }
}

const patchEntitlementsCacheWithRoles = (roles: GeneratedRoleTypes.GetRolesQuery, entitlementType: string) => {
  return entitlementApi.util.updateQueryData("GetRoleEntitlements", {
    endpointName: entitlementApi.endpoints.GetRoleEntitlements.name,
    cacheKey: entitlementType
  } as any, currentCacheData => {
    (currentCacheData?.entitlements?.edges ?? []).forEach(edge => {
      if (edge?.node && !(edge.node as ExtendedRoleEntitlement).roleName) {
        const role = roles.roles?.edges.find(roleEdge => roleEdge?.node?.id === edge.node.entitledId)?.node;
        edge.node = {
          ...edge.node,
          roleName: role?.name ?? ""
        } as ExtendedRoleEntitlement
      }
    });
  })
}

export interface ExtendedResourceTypeFilterEntry extends FilterEntry {
  resourceType?: ResourceType;
}

export const extendResourceTypesFilter = (
  dataResourceTypeFilterEntries: GetEntitlementsResourceTypesQuery,
  arg: GetEntitlementsResourceTypesQueryVariables
): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const resourceTypeIds = dataResourceTypeFilterEntries.entitlementsResourceTypes
    ?.filter(entry => entry?.id).map(entry => entry?.id ?? "");
    const resourceTypeFilter = resourceTypeIds.length ?
      combineFilter(FilterOperator.OR, ...resourceTypeIds.map(id => ({
        name: "id",
        operator: FilterOperator.EQ,
        uuidValue: id
      }))) : undefined;
    const promise = dispatch(resourceTypeApi.endpoints.GetResourceTypes.initiate({filter: resourceTypeFilter}));
    const {data: dataResourceTypes} = await promise;
    promise.unsubscribe();

    if (dataResourceTypes) {
      dispatch(patchResourceTypesFilterCache(dataResourceTypes, arg));
    }
  }
}

const patchResourceTypesFilterCache = (
  resourceTypes: GeneratedResourceTypeTypes.GetResourceTypesQuery,
  arg: GetEntitlementsResourceTypesQueryVariables
) => {
  return entitlementApi.util.updateQueryData("GetEntitlementsResourceTypesEnhanced", arg, currentCacheData => {
    (currentCacheData?.entitlementsResourceTypes ?? []).forEach(entry => {
      if (entry) {
        (entry as ExtendedResourceTypeFilterEntry).resourceType = resourceTypes.resourceTypes?.edges.find(edge => edge?.node?.id === entry?.id)?.node;
      }
    });
  })
}

export interface ExtendedSystemFilterEntry extends FilterEntry {
  system?: System;
}

export const extendSystemsFilter = (
  dataSystemFilterEntries: GetEntitlementsSystemsQuery,
  arg: GetEntitlementsSystemsQueryVariables
): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const systemIds = dataSystemFilterEntries.entitlementsSystems
    ?.filter(entry => entry?.id).map(entry => entry?.id ?? "");
    const systemFilter = systemIds.length ?
      combineFilter(FilterOperator.OR, ...systemIds.map(id => ({
        name: "id",
        operator: FilterOperator.EQ,
        uuidValue: id
      }))) : undefined;
    const promise = dispatch(systemApi.endpoints.GetSystems.initiate({filter: systemFilter}));
    const {data: dataSystems} = await promise;
    promise.unsubscribe();

    if (dataSystems) {
      dispatch(patchSystemsFilterCache(dataSystems, arg));
    }
  }
}

const patchSystemsFilterCache = (
  systems: GeneratedSystemTypes.GetSystemsQuery,
  arg: GetEntitlementsSystemsQueryVariables
) => {
  return entitlementApi.util.updateQueryData("GetEntitlementsSystemsEnhanced", arg, currentCacheData => {
    (currentCacheData?.entitlementsSystems ?? []).forEach(entry => {
      if (entry) {
        (entry as ExtendedSystemFilterEntry).system = systems.systems?.edges.find(edge => edge?.node?.id === entry?.id)?.node;
      }
    });
  })
}

export const resetGetResourceEntitlementsActionCreator = (_data: CreateEntitlementMutation, arg: {
  type: string
}): ReturnType<typeof injectedEntitlementApi.util.upsertQueryData> => injectedEntitlementApi.util.upsertQueryData('GetResourceEntitlements', {
  endpointName: 'GetResourceEntitlements',
  cacheKey: arg.type
} as any, {
  entitlements: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

export const resetGetIdentityEntitlementsActionCreator = (_data: CreateEntitlementMutation, arg: {
  type: string
}): ReturnType<typeof injectedEntitlementApi.util.upsertQueryData> => injectedEntitlementApi.util.upsertQueryData('GetIdentityEntitlements', {
  endpointName: 'GetIdentityEntitlements',
  cacheKey: arg.type
} as any, {
  entitlements: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

export const resetGetRoleEntitlementsActionCreator = (_data: CreateEntitlementMutation, arg: {
  type: string
}): ReturnType<typeof injectedEntitlementApi.util.upsertQueryData> => injectedEntitlementApi.util.upsertQueryData('GetRoleEntitlements', {
  endpointName: 'GetRoleEntitlements',
  cacheKey: arg.type
} as any, {
  entitlements: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

const updateEntitlementsCache = (data: UpdateEntitlementMutation, currentCacheData: MaybeDrafted<GetEntitlementsQuery>) => {
  const updatedEntitlement = data.updateEntitlement;
  if (updatedEntitlement) {
    (currentCacheData?.entitlements?.edges ?? []).forEach(entitlement => {
      if (entitlement?.node.id === updatedEntitlement.id) {
        entitlement.node = {
          ...entitlement.node,
          ...updatedEntitlement
        };
      }
    });
  }
}

export const updateGetResourceEntitlementsActionCreator = (data: UpdateEntitlementMutation): ReturnType<typeof injectedEntitlementApi.util.updateQueryData> =>
  injectedEntitlementApi.util.updateQueryData('GetResourceEntitlements', {
    endpointName: injectedEntitlementApi.endpoints.GetResourceEntitlements.name,
    cacheKey: data.updateEntitlement.type
  } as any, currentCacheData => {
    updateEntitlementsCache(data, currentCacheData);
  });

export const updateGetIdentityEntitlementsActionCreator = (data: UpdateEntitlementMutation): ReturnType<typeof injectedEntitlementApi.util.updateQueryData> =>
  injectedEntitlementApi.util.updateQueryData('GetIdentityEntitlements', {
    endpointName: injectedEntitlementApi.endpoints.GetIdentityEntitlements.name,
    cacheKey: data.updateEntitlement.type
  } as any, currentCacheData => {
    updateEntitlementsCache(data, currentCacheData);
  });

export const updateGetRoleEntitlementsActionCreator = (data: UpdateEntitlementMutation): ReturnType<typeof injectedEntitlementApi.util.updateQueryData> =>
    injectedEntitlementApi.util.updateQueryData('GetRoleEntitlements', {
      endpointName: injectedEntitlementApi.endpoints.GetRoleEntitlements.name,
      cacheKey: data.updateEntitlement.type
    } as any, currentCacheData => {
      updateEntitlementsCache(data, currentCacheData);
    });
