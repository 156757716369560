import {
  emptyResourceSetInput,
  ResourceSet,
  ResourceSetInput,
  resourceSetInputFactory,
  useCreateResourceSetMutation,
  useDeleteResourceSetMutation,
  useGetResourceSetQuery,
  useUpdateResourceSetMutation
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";

export const newId = 'new';

export const useResourceSetApi = () => {

  const {id = newId} = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const {
    data,
    error,
    isLoading,
    refetch
  } = useGetResourceSetQuery({id}, {skip: isNew, refetchOnMountOrArgChange: true});
  const [deleteResourceSet] = useDeleteResourceSetMutation()
  const [updateResourceSet] = useUpdateResourceSetMutation();
  const [createResourceSet] = useCreateResourceSetMutation();
  const navigate = useNavigate();
  const [resourceSetInput, setResourceSetInput] = useState<ResourceSetInput>(emptyResourceSetInput());
  const [resourceSet, setResourceSet] = useState<ResourceSet>();
  const {editMode, toggleEditMode} = useSingleEditMode(EntityType.RESOURCE_SET, isNew);

  useEffect(() => {
    setResourceSetInput(resourceSetInputFactory(data?.resourceSet));
    setResourceSet(data?.resourceSet ?? undefined);
  }, [data?.resourceSet]);

  const goBack = () => navigate("/resource-set");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {
    onSave = (value: ResourceSetInput) => {
      createResourceSet({input: value}).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {
    onSave = (value: ResourceSetInput) => {
      updateResourceSet({id, input: value})
      .then(() => {
        toggleEditMode();
        refetch();
      });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deleteResourceSet({id}).then(goBack);
    };
  }

  return {
    id,
    resourceSet,
    resourceSetInput,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
};

