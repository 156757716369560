import {either, Either} from './Either';

type ParseException = { text: string };
const extendedDecodeURIComponent = (component: string) => decodeURIComponent(component);
const extendedEncodeURIComponent = (component: string) => encodeURIComponent(component).replace(/\(/g, '%28').replace(/\)/g, '%29');
export const NestedArraysException = Error('Nested Arrays not Supported!');
const OBJECT_PARENTHESIS_OPEN = '(';
const OBJECT_PARENTHESIS_CLOSE = ')';
const ARRAY_DELIMITER = ',';
const PARAM_DELIMITER = '&';
const ASSIGNMENT = '=';
const ARRAY_INDICATOR = '$';
const terminalTokens = [OBJECT_PARENTHESIS_OPEN, OBJECT_PARENTHESIS_CLOSE, PARAM_DELIMITER, ASSIGNMENT, ARRAY_DELIMITER, ARRAY_INDICATOR];
const queryEncoderInstanceCreator = (skipUndefined = true) => {
  const convertObject = (value: object): string => OBJECT_PARENTHESIS_OPEN + convert(value) + OBJECT_PARENTHESIS_CLOSE;

  const convertSingleValue = (value: any): string => {
    if (Array.isArray(value)) {
      throw NestedArraysException;
    }
    if (typeof value === 'object') {
      return convertObject(value);
    } else {
      return extendedEncodeURIComponent(value);
    }
  };

  const convertValue = (value: any): string => {
    if (Array.isArray(value)) {
      const array = value as any[];
      let mapped = array.map(x => convertSingleValue(x)).join(ARRAY_DELIMITER);
      if (array.length < 2) {
        mapped += ARRAY_INDICATOR;
      }
      return mapped;
    } else {
      return convertSingleValue(value);
    }
  };

  const convert = (object: object) => {
    return Object.entries(object).filter(([, value]) => {
      return skipUndefined ? value !== undefined : true;
    }).map(([key, value]) => {
      return extendedEncodeURIComponent(key) + ASSIGNMENT + convertValue(value);
    }).join(PARAM_DELIMITER);

  };

  const stringify = (object: object): Either<string, Error> => {
    try {
      const string = convert(object);
      return either.left(string);
    } catch (error) {
      return either.right(NestedArraysException);
    }
  };

  return stringify;
};
const queryDecoderInstanceCreator = (failOnError = true) => {

  const terminalSet = new Set<string>();
  terminalTokens.forEach(x => terminalSet.add(x));

  const parseExceptions: ParseException[] = [];
  const tokenStream: string[] = [];
  let curToken = '';

  const findTokens = (string: string) => {
    let token = '';
    for (const char of string) {
      if (terminalSet.has(char)) {
        if (token !== '') {
          tokenStream.push(token);
        }
        tokenStream.push(char);

        token = '';
      } else {
        token += char;
      }
    }
    if (token !== '') {
      tokenStream.push(token);
    }
  };

  const advanceToken = () => {
    curToken = tokenStream.shift() ?? '';
  };

  const expect = (token: string): boolean => {
    if (accept(token)) {
      return true;
    }
    parseExceptions.push({
      text: 'Expected token "' + token + '" got "' + curToken + '"'
    });
    return false;
  };

  const accept = (token: string): boolean => {
    if (curToken === token) {
      advanceToken();
      return true;
    }
    return false;
  };

  const isValue = (token: string): boolean => !isTerminal(token);
  const isTerminal = (token: string): boolean => terminalSet.has(token);

  const variableName = () => {
    if (!isValue(curToken)) {
      return;
    }

    const name = extendedDecodeURIComponent(curToken);
    advanceToken();
    return name;
  };

  const variableValue = () => {
    if (curToken === ARRAY_INDICATOR) {
      return;
    }

    if (curToken === PARAM_DELIMITER) {
      return '';
    }

    const object = objectValue();

    if (!object) {
      const value = curToken;
      if (isValue(value)) {
        advanceToken();
        return extendedDecodeURIComponent(value);
      }
      return;
    } else {
      return object;
    }

  };

  const objectValue = () => {
    if (accept(OBJECT_PARENTHESIS_OPEN)) {
      const object = assignmentList();
      expect(OBJECT_PARENTHESIS_CLOSE);
      return object;
    }
    return undefined;
  };

  const arrayValue = () => {
    const array = [];
    const value = variableValue();
    if (value) {
      array.push(value);
    }
    while (accept(ARRAY_DELIMITER)) {
      const val = variableValue();
      array.push(val);
    }
    if (accept(ARRAY_INDICATOR) || array.length > 1) {
      return array;
    } else {
      return value;
    }
  };

  const assignment = () => {
    if (curToken === OBJECT_PARENTHESIS_CLOSE) {
      return {};
    }
    const name = variableName();
    if (name) {
      expect(ASSIGNMENT);
      const value = arrayValue();
      return {[name]: value};
    }

    return null;
  };

  const assignmentList = (): object => {
    // assignment: varName=value
    // assignmentList: (assignment(&assignment)*)?
    // value:  Object | Array | encoded...
    // Object: \(assignmentList\)
    // Array: ($) | (value(,value)+)$? | (value$)
    let object = {};
    const value = assignment();
    if (value) {
      object = {...object, ...value};
    }
    while (accept(PARAM_DELIMITER)) {
      const nextValue = assignment();
      if (nextValue) {
        object = {...object, ...nextValue};
      }
    }
    return object;
  };

  const parse = (string: string): Either<object, ParseException[]> => {
    findTokens(string);
    if (tokenStream.length === 0) {
      return either.left({});
    }
    advanceToken();
    const object = assignmentList();
    if (parseExceptions.length === 0 || !failOnError) {
      return either.left(object);
    } else {
      return either.right(parseExceptions);
    }
  };
  return parse;
};
export const UrlQueryEncoder = {
  stringify: (object: object, skipUndefined = true) => queryEncoderInstanceCreator(skipUndefined)(object),
  parse: (string: string, failOnError = true) => queryDecoderInstanceCreator(failOnError)(string)
};
export type UrlObjectEncoder<T extends object = object> = (value: T) => string;
export type UrlObjectDecoder<T extends object = object> = (urlComponent: string) => T;
