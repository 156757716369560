interface ResourceSetRolesTabProps {

}

const ResourceSetRolesTab = (props: ResourceSetRolesTabProps) => {

  return <div>
    Placeholder for roles
  </div>
}

export default ResourceSetRolesTab;
