import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../../common/parsePageResult';
import { CursorBasedPageResult, refetchTable } from '@sivis/shared/components/entityView';
import {
  EOwnershipEntity,
  EOwnershipType,
  ExtendedOwnership,
  FilterExpression,
  GeneratedOwnershipTypes,
  Ownership,
  OwnershipQueryType,
  useCreateOwnershipMutation,
  useDeleteOwnershipMutation,
  useGetRoleOwnershipsQuery
} from '@sivis/identity/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { parseValidityInput } from '../../utils/validityUtils';

export const parseOwnershipsPageResult = (res?: GeneratedOwnershipTypes.GetOwnershipsQuery): CursorBasedPageResult<Ownership> => {
  return parseCursorBasedPageResult('ownerships', res);
};

export const useRoleOwnershipsApi = (
  cacheKey: EOwnershipEntity,
  ownershipsFilter: FilterExpression,
  queryType: OwnershipQueryType,
  roleId?: string
) => {
  const [deleteOwnership] = useDeleteOwnershipMutation();
  const [createOwnership] = useCreateOwnershipMutation();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const useOwnershipsPageQuery = (cursor: string | null) =>
    useGetRoleOwnershipsQuery(
      {
        first: DEFAULT_PAGE_SIZE,
        after: cursor,
        sort: DEFAULT_ENTITY_SORT,
        filter: ownershipsFilter,
        cacheKey: cacheKey
      },
      {skip: !roleId}
    );

  const onSave = (added: ExtendedOwnership[], deleted: ExtendedOwnership[]) => {
    if (!roleId) {
      return Promise.resolve();
    }
    return Promise.all([
      ...added.map(ownership => createOwnership({
        ownerId: ownership.type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP ? ownership.ownerId : roleId,
        targetId: ownership.type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP ? roleId : ownership.targetId,
        type: ownership.type,
        input: {
          validity: parseValidityInput(ownership.validity ?? undefined)
        }
      })),
      ...deleted.map(ownership => deleteOwnership({
        id: ownership.id
      }))
    ]).then(() => {
      setSubmitted(true);
      dispatch(refetchTable(queryType)());
    });
  };

  return {
    onSave,
    pageSize: DEFAULT_PAGE_SIZE,
    useOwnershipsPageQuery,
    parseOwnershipsPageResult,
    submitted,
    setSubmitted
  };
};
