import {Link} from "react-router-dom";
import {HasIntlId, selectMessageTextById} from "@sivis/intl";
import {useSelector} from "react-redux";

export const getSimpleBreadcrumb = ({text}: {
  text: string;
}) => (props: Omit<SimpleBreadcrumbProps, 'text'>) => {
  return <SimpleBreadcrumb text={text} {...props} />;
};

export interface SimpleBreadcrumbProps {
  text: string;
  path: string;
}

export const SimpleBreadcrumb = ({path, text}: SimpleBreadcrumbProps) => {
  return <Link to={path}>{text}</Link>;
};

// Put intlSimpleBreadcrumb here as workaround:
// If it is exported from @sivis/intl and has dependency from this lib,
// breadcrumb.module.scss from this lib is always loaded, resulting in
// unwanted global style for the breadcrumb bar.
export const intlSimpleBreadcrumb = ({intlId}: HasIntlId) => (props: Omit<SimpleBreadcrumbProps, 'text'>) => {
  const text = useSelector(state => selectMessageTextById(state, intlId));
  return <SimpleBreadcrumb text={text} {...props} />;
};
