import { FilterOperator } from "@sivis/shared/api-utils";
import { defaults, omit } from "lodash";
import { FilterExpression, Resource, ResourceInput } from "../identityApi.types";

export const emptyResourceInput = (): ResourceInput => ({
  name: '',
  description: '',
  resourceId: '',
  resourceType: '',
  system: '',
});

export const isResourceEqual = (a: Resource, b: Resource) =>
  a.id === b.id;

export const resourceInputFactory = (resource?: Resource | ResourceInput | Record<string, any> | null): ResourceInput => {

  if (!resource) {
    return emptyResourceInput();
  }

  if (resource.hasOwnProperty('id')) {
    const resourceCasted = resource as Resource;
    return {
      name: resourceCasted.name,
      description: resourceCasted.description,
      resourceId: resourceCasted.resourceId,
      resourceType: resourceCasted.resourceType?.id ?? '',
      system: resourceCasted.system?.id ?? '',
    }
  }

  if (resource.hasOwnProperty('name')) {
    return resource as ResourceInput;
  }

  return defaults({}, omit(resource, ['id']), emptyResourceInput());
}

export const searchResourcesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "resourceId", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "resourceType.name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "system.systemName", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});
