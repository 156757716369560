import {
  GeneratedResourceSetTypes,
  ResourceSet,
  searchResourceSetsFilter,
  useGetResourceSetsQuery
} from "@sivis/identity/api";
import {CursorBasedPageResult} from "@sivis/shared/components/entityView";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";

export const useResourceSetPageApi = () => {
  const { filter, onSearch } = useListSearch(searchResourceSetsFilter);

  const useResourceSetsPageQuery = (cursor: string | null) =>
    useGetResourceSetsQuery({ first: DEFAULT_PAGE_SIZE, after: cursor, sort: DEFAULT_ENTITY_SORT, filter });

  const parseResourceSetsPageResult = (res?: GeneratedResourceSetTypes.GetResourceSetsQuery): CursorBasedPageResult<ResourceSet> => {
    return parseCursorBasedPageResult('resourceSets', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useResourceSetsPageQuery,
    parseResourceSetsPageResult
  };
};
