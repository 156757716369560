import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthAuthenticated } from '../../redux/authSlice';
import { Navigate, Outlet } from 'react-router-dom';
import { LOGIN_URL } from './sivisWebApi';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useSelector(selectAuthAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to={LOGIN_URL} />;
  }
  return children;
};

export const ProtectedOutlet = () => (<ProtectedRoute><Outlet /></ProtectedRoute>);
