import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import { APP_SHELL_BLACKLIST, appShellReducers } from '@sivis/app-shell';
import { attributeSyncBaseApi } from '@sivis/attributesync/api';
import { identityBaseApi } from '@sivis/identity/api';
import { INTL_SLICE_NAME, intlReducer } from '@sivis/intl';
import { appConfigureRedux } from '@sivis/redux';
import { securityApi } from '@sivis/security/api';
import { THEME_SLICE_NAME, themeReducer } from '@sivis/shared/theme';
import { ThunkAction } from 'redux-thunk';
import { AUTH_SLICE_NAME, authReducer } from "./authSlice";
import { preloadedState } from './preloadedState';

const rootReducer = combineReducers({
  [THEME_SLICE_NAME]: themeReducer,
  [INTL_SLICE_NAME]: intlReducer,
  [AUTH_SLICE_NAME]: authReducer,
  [securityApi.reducerPath]: securityApi.reducer,
  [identityBaseApi.reducerPath]: identityBaseApi.reducer,
  [attributeSyncBaseApi.reducerPath]: attributeSyncBaseApi.reducer,
  ...appShellReducers
});
export const {store, persistor} = appConfigureRedux({
  rootReducer,
  persistKey: 'fusion',
  preloadedState: preloadedState(),
  blacklist: [...APP_SHELL_BLACKLIST, 'auth', securityApi.reducerPath, identityBaseApi.reducerPath],
  middleware: [
    securityApi.middleware,
    identityBaseApi.middleware,
    attributeSyncBaseApi.middleware
  ]
});

export type GlobalAppState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export type AppThunkAction<R = void, E = unknown> = ThunkAction<R, GlobalAppState, E, PayloadAction<any>>;
