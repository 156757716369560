import {enumFromValue} from '@sivis/shared/utils';
import {detectBrowserLanguage} from './dateFormatter';

export enum ELocales {
  DE = 'de',
  EN = 'en',
  AR = 'ar',
  BG = 'bg',
  BS = 'bs',
  CA = 'ca',
  CS = 'cs',
  DA = 'da',
  EL = 'el',
  ET = 'et',
  FI = 'fi',
  FIL = 'fil',
  FR = 'fr',
  GA = 'ga',
  GL = 'gl',
  HE = 'he',
  HI = 'hi',
  HR = 'hr',
  HU = 'hu',
  ID = 'id',
  IS = 'is',
  IT = 'it',
  JA = 'ja',
  KK = 'kk',
  KO = 'ko',
  LT = 'lt',
  LV = 'lv',
  MS = 'ms',
  MT = 'mt',
  NB = 'nb',
  NL = 'nl',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  SK = 'sk',
  SL = 'sl',
  SR = 'sr',
  SV = 'sv',
  TH = 'th',
  TR = 'tr',
  UK = 'uk',
  VI = 'vi',
  ZH = 'zh',
  ZZ = 'zz', // customer language
}

export const DEFAULT_LOCALE = ELocales.EN;

export const toELocale = (locale?: string, fallbackToDefault = true): ELocales | null => {
  if (!locale) {
    return fallbackToDefault ? DEFAULT_LOCALE : null;
  }

  if (isELocale(locale)) {
    return locale;
  }

  const fromLowercase = enumFromValue(locale, ELocales);

  if (fromLowercase) {
    return fromLowercase;
  }

  return fallbackToDefault ? DEFAULT_LOCALE : null;
}

export const isELocale = (source?: string): source is ELocales => {
  if (!source) {
    return false;
  }
  return source in ELocales;
}

export const detectBrowserLocale = (locales: ELocales[]) => {
  // get the currently used language from the browser
  const navigotorLang = detectBrowserLanguage();
  // Split locales with a region code
  const locale = toELocale(navigotorLang.toUpperCase().split(/[_-]+/)[0]);

  return locales.find((l: ELocales) => l === locale) || // 1. check if language is active
    locales.find((l: ELocales) => l === ELocales.EN) || // 2. check if EN is possible as fallback
    locales[0] || // 3. check if there is any active language
    ELocales.EN; // 4. use english
}

export enum ELocaleText {
  de = 'Deutsch',
  en = 'English',
  ar = 'العربية',
  bg = 'Български',
  bs = 'Босански',
  ca = 'Català',
  cs = 'Čeština',
  da = 'Dansk',
  el = 'Ελληνικά',
  et = 'Eesti',
  fi = 'Suomi',
  fil = 'Filipino',
  fr = 'Français',
  ga = 'Gaeilge',
  gl = 'Galego',
  he = 'עברית‏',
  hi = 'हिंदी<',
  hr = 'Hrvatski',
  hu = 'Magyar',
  id = 'Bahasa Indonesia',
  is = 'Íslenska',
  it = 'Italiano',
  ja = '日本語',
  kk = 'қазақ тілі',
  ko = '한국어',
  lt = 'Lietuvių',
  lv = 'Latviešu',
  ms = 'Bahasa Melayu',
  mt = 'Malti',
  nb = 'Norsk Bokmål',
  nl = 'Nederlands',
  pl = 'Polski',
  pt = 'Português',
  ro = 'Română',
  ru = 'Русский',
  sk = 'Slovenčina',
  sl = 'Slovenski',
  sr = 'Srpski',
  sv = 'Svenska',
  th = 'ไทย',
  tr = 'Türkçe',
  uk = 'Українська',
  vi = 'TiếngViệt',
  zh = '中文',
  zz = 'Custom',
}

