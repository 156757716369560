import styles from './identity.module.scss';
import { EntityTableRow, ListModifyViewUpdated } from '@sivis/shared/components/entityView';
import { IconButton } from '@mui/material';
import {
  Identity,
  identityName,
  IdentityRoleMembership,
  RelationQueryType
} from '@sivis/identity/api';
import { GridColDef } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import {
  deleteMembershipMessage,
  getAccountsText,
  getMembershipFlags,
  mapTableRowToMembership
} from '../membership/membershipUtils';
import { IdentityRoleMembershipDetails } from '../membership/identityRoleMembershipDetails';
import { useRoleIdentityMembershipApi } from '../membership/api/useRoleIdentityMembershipsApi';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { ConfirmationPopup } from '../components/popup/confirmationPopup';
import { createMembershipPopup } from '../membership/createMembershipPopup';
import { isEqualById } from '@sivis/shared/utils';

interface IdentityRoleViewProps {
  identity: Identity;
  editMode: boolean;
}

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "accounts",
    flex: 1
  },
  {
    field: "flags",
    flex: 1,
    sortable: false,
    renderCell: params => params.row.flags
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const IdentityRoleView = ({identity, editMode}: IdentityRoleViewProps) => {
  const intl = useCustomIntl();
  const translatedColumns = useTranslateColumns(columns, "fusion.identity.role.propertyName");
  const {
    onSave,
    submitted,
    setSubmitted,
    onSearch,
    pageSize,
    useMembershipsPageQuery,
    parseMembershipsPageResult
  } = useRoleIdentityMembershipApi(identity);

  const entityToTableRow = (
    entity: IdentityRoleMembership,
    isNew: boolean,
    onEdit?: (entity: IdentityRoleMembership) => void,
    onDelete?: (entity: IdentityRoleMembership) => void
  ): EntityTableRow => {
    return {
      id: entity.id,
      name: entity.role.name,
      isNew: isNew,
      accounts: getAccountsText(entity.allAccounts ?? undefined, intl.format),
      flags: getMembershipFlags(entity, intl.format),
      action: <div className={styles.deleteButtonContainer}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onEdit?.(entity);
          }}
          aria-label={intl.format("general.edit")}>
          <EditIcon/>
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete?.(entity);
          }}
          aria-label={intl.format("general.delete")}>
          <ClearIcon/>
        </IconButton>
      </div>
    };
  };

  const getMembershipPopupTitle = (membership: IdentityRoleMembership) => ({
    primaryTitle: intl.format(
      "fusion.membership.membershipPopup.title",
      {name: identityName(membership.identity)}
    ),
    secondaryTitle: intl.format(
      "fusion.membership.membershipPopup.description",
      {identity: identityName(membership.identity), role: membership.role.name}
    )
  });

  const shownColumns = editMode ? translatedColumns : translatedColumns.filter(column => column.field !== "action");

  const confirmationPopup = identity ? <ConfirmationPopup
    open={submitted}
    onClose={() => setSubmitted(false)}
    primaryTitle={intl.format("fusion.membership.membershipPopup.title", {name: identityName(identity)})}
    message={intl.format("fusion.message.submitted", {entity: identityName(identity)})}
  /> : null;

  const createPopup = createMembershipPopup({
    primaryTitle: intl.format("fusion.identity.role.createPopup.title"),
    secondaryTitle: intl.formatBold(
      "fusion.identity.role.createPopup.description",
      {identity: identity ? identityName(identity) : ""}
    ),
    identity
  });

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
      columns={shownColumns}
      onSave={onSave}
      entityToTableRow={entityToTableRow}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getMembershipPopupTitle}
      isEqual={isEqualById}
      entityDetails={IdentityRoleMembershipDetails}
      deleteMessage={deleteMembershipMessage}
      mapTableRowToEntity={mapTableRowToMembership}
      pageSize={pageSize}
      usePageQuery={useMembershipsPageQuery}
      parseResult={parseMembershipsPageResult}
      createButtonText="fusion.identity.role.add"
      createPopup={createPopup}
      tableType={RelationQueryType.IDENTITY_ROLE_MEMBERSHIPS}
    />
  </div>;
};
