import { Identity, Resource, ResourceSet, Role, System } from "@sivis/identity/api";
import { SelectedAccountDetails } from "../../system/systemAccountsTab";
import {
  AccountCard,
  IdentityCard,
  ResourceCard,
  ResourceSetCard,
  RoleCard,
  SystemCard
} from "./relationInfoPanel";

export type RelationEntity =
  Identity
  | Role
  | Resource
  | ResourceSet
  | System
  | SelectedAccountDetails;

export enum RelationEntityType {
  IDENTITY = "IDENTITY",
  ROLE = "ROLE",
  RESOURCE = "RESOURCE",
  RESOURCE_SET = "RESOURCE_SET",
  SYSTEM = "SYSTEM",
  ACCOUNT = "ACCOUNT"
}

const relationEntityCards = {
  [RelationEntityType.IDENTITY]: IdentityCard,
  [RelationEntityType.ROLE]: RoleCard,
  [RelationEntityType.RESOURCE]: ResourceCard,
  [RelationEntityType.RESOURCE_SET]: ResourceSetCard,
  [RelationEntityType.SYSTEM]: SystemCard,
  [RelationEntityType.ACCOUNT]: AccountCard
};

export const getRelationEntityCard = (entity: RelationEntity, type: RelationEntityType, linkTo?: string | undefined) => {
  const Card = relationEntityCards[type];
  return <Card entity={entity} linkTo={linkTo}/>;
};
