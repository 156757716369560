import React, {useRef} from 'react';
import {OetRelation, OetRelationType, OrganizationalEntityType} from '@sivis/identity/api';
import {useCustomIntl, useTranslateConfigs} from '@sivis/intl';
import {
  AttributeRowConfig,
  EntityForm,
  EntityFormHandle,
  FormButtons,
  TabBar
} from '@sivis/shared/components/entityView';
import styles from './oetRelationCreationPopup.module.scss';
import {EOetRelationTypeSelectOption} from '../enums/EOetRelationType';
import {OetRelationPopupInput} from '../utils/oetRelationUtils';
import {Spinner} from '@sivis/app-shell';
import {
  OrganizationalEntityTypeCard,
  VerticalRelationInfoPanel
} from "../components/popup/verticalRelationInfoPanel";


export interface OetRelationPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: Record<string, any>) => void;
  organizationalEntityType: OrganizationalEntityType;
  organizationalEntityTypes: OrganizationalEntityType[];
  oetRelationTypeSelectOptions: EOetRelationTypeSelectOption[];
  onWatch: (value: Record<string, any>) => void;
  oetRelationTypes: OetRelationType[];
  createdOetRelation: OetRelation | undefined;
  isLoading: boolean;
  oetRelationInput: OetRelationPopupInput | undefined;
  oetRelation?: OetRelation;
  isEdit?: boolean;
}


const OetRelationsPopup = (props: OetRelationPopupProps) => {
  const intl = useCustomIntl();
  const ref = useRef<EntityFormHandle>(null);

  const submitFormFromOutside = () => {
    ref.current?.submit();
  };


  const configs: (Omit<AttributeRowConfig, 'name'> & {
    name?: string
  })[] = [
    {
      property: 'organizationalEntityType',
      required: true,
      disabled: props.isEdit,
      options: props.organizationalEntityTypes.filter(
        organizationalEntityType => organizationalEntityType.id !== props.organizationalEntityType.id
      ).map(organizationalEntityType => ({
        value: organizationalEntityType.id,
        text: organizationalEntityType.name
      })),
      placeholder: intl.formatLight(`fusion.organizationalEntityTypeRelation.selectOetRelationType.${
        props.oetRelationInput?.oetRelationType ?? ''
      }`)
    },
    {
      property: 'oetRelationType',
      required: true,
      disabled: props.isEdit,
      options: props.oetRelationTypeSelectOptions.map(oetRelationType => ({
        value: oetRelationType,
        text: intl.formatLight(`fusion.organizationalEntityTypeRelation.setOetRelationType.${oetRelationType}`,
          {
            type: props.oetRelationTypes.find(type => type.type?.includes(oetRelationType))?.type ?? ''
          })
      }))
    },
    {
      property: 'isRequired',
      //TODO: if oetRelation already exists, change isRequired text in UI only! to "required for new entities"
      options: [
        {
          value: true,
          text: intl.format('fusion.organizationalEntityTypeRelation.required')

        },
        {
          value: false,
          text: intl.format('fusion.organizationalEntityTypeRelation.notRequired')
        }
      ]
    }
  ];

  const translatedConfigs = useTranslateConfigs(configs, 'fusion.organizationalEntityTypeRelation.propertyName');


  const transformOetRelationInputForEntityForm = (oetRelationInput: OetRelationPopupInput) => {
    return {
      ...props.oetRelationInput,
      organizationalEntityType: props.oetRelationInput?.organizationalEntityType.id,
    }
  }

  const relationDirectionText = intl.format("fusion.organizationalEntityType.relations.relationDirectionText",
    {
      required: props.oetRelationInput?.isRequired ?
        intl.format("fusion.organizationalEntityType.relations.createRelationButtonSubtitle.required") :
        intl.format("fusion.organizationalEntityType.relations.createRelationButtonSubtitle.notRequired") ?? "",
      oetRelationType: intl.format("fusion.organizationalEntityType.relations.createRelationButtonSubtitle." +
        (props.oetRelationInput?.oetRelationType.toLowerCase() ?? "")
      )
    });

  const tabs = props.oetRelationInput ? [
    {
      label: intl.format('fusion.breadcrumb.information'),
      content: (
        <div className={styles.oetRelationCreationPopupContainer}>
          <div className={styles.oetRelationFormFields}>
            <EntityForm
              value={transformOetRelationInputForEntityForm(props.oetRelationInput)}
              configs={translatedConfigs}
              onSubmit={props.onSubmit}
              onCancel={props.onClose}
              ref={ref}
              onWatch={props.onWatch}
              hideButtons={true}
            />
            <div className={styles.relationDetailsContainer}>
              <VerticalRelationInfoPanel
                upperCard={
                  <OrganizationalEntityTypeCard
                    entity={props.oetRelationInput.oetRelationType.toUpperCase() !== EOetRelationTypeSelectOption.parent ?
                      props.organizationalEntityType : props.oetRelationInput.organizationalEntityType
                    }
                  />}
                lowerCard={<OrganizationalEntityTypeCard
                  entity={props.oetRelationInput.oetRelationType.toUpperCase() === EOetRelationTypeSelectOption.parent ?
                    props.organizationalEntityType : props.oetRelationInput.organizationalEntityType}
                />}
                arrowText={relationDirectionText}
                arrowDown={props.oetRelationInput.oetRelationType.toUpperCase() !== EOetRelationTypeSelectOption.parent}
              />
            </div>
          </div>
          <FormButtons onCancel={props.onClose} onSave={submitFormFromOutside}/>
        </div>
      )
    }
  ] : [];

  return (
    props.isLoading || !props.oetRelationInput ? <Spinner/> : <TabBar tabs={tabs}/>
  );
};

export default OetRelationsPopup;
