import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQueryWithErrorHandling } from '@sivis/shared/api-utils';
import { GraphQLClient } from 'graphql-request';


export const identityClient: GraphQLClient = new GraphQLClient(
  '/graphql/identity'
);

export const identityBaseApi = createApi({
  baseQuery: createBaseQueryWithErrorHandling('/graphql/identity'),
  reducerPath: 'identity',
  endpoints: () => ({}),
  tagTypes: [
    'role',
    'identity',
    'identityType',
    'identityRoleMembership',
    'entitlement',
    'account',
    'accountType',
    'oeRelation',
    'oetRelation',
    'accountRoleMembership',
    'ownership',
    'identityOwnerships',
    'roleOwnerships',
    'resourceOwnerships',
    'resourceSetOwnerships',
    'systemOwnerships',
    'businessRule',
    'resource',
    'resourceSet',
    'reourceType',
    'system',
    'systemType'
  ]
});

export const testMethodForCoverage = () => {
  return 'test';
};
