import {CreatePopupProps, FormButtons, Popup} from "@sivis/shared/components/entityView";
import {Identity, IdentityRoleMembership, Role} from "@sivis/identity/api";
import {DialogActions} from "@mui/material";
import React, {ReactNode, useState} from "react";
import {CreateMembershipForIdentity} from "./createMembershipForIdentity";
import {CreateMembershipForRole} from "./createMembershipForRole";

interface CreateMembershipPopupProps {
  primaryTitle: string;
  secondaryTitle: ReactNode;
  identity?: Identity;
  role?: Role;
}

export const createMembershipPopup = (props: CreateMembershipPopupProps) => function Comp({
                                                                                            open,
                                                                                            onClose,
                                                                                            onSave: onSaveFromList
                                                                                          }: CreatePopupProps<IdentityRoleMembership>) {
  const [added, setAdded] = useState<IdentityRoleMembership[]>([]);

  const onSave = () => {
    if (onSaveFromList) {
      onSaveFromList(added);
    }
  };

  let content = <></>;
  if (props.identity) {
    content = <CreateMembershipForIdentity identity={props.identity} onAdd={setAdded}/>
  } else if (props.role) {
    content = <CreateMembershipForRole role={props.role} onAdd={setAdded}/>
  }

  return <Popup
    open={open}
    onClose={onClose}
    primaryTitle={props.primaryTitle}
    secondaryTitle={props.secondaryTitle}
    hideButtons
    styleType="edit"
  >
    {content}
    <DialogActions>
      <FormButtons onCancel={onClose} onSave={onSave} saveInsteadOfSubmit={true}/>
    </DialogActions>
  </Popup>;
};
