import { useState, useEffect } from "react";
import {
    SystemField,
    useGetSystemFieldsForSystemQuery
} from "@sivis/attributesync/api";

export const useSystemFieldApi = (systemId: string) => {
    const { data, error, isLoading, refetch } = useGetSystemFieldsForSystemQuery({ systemId }, {
        refetchOnMountOrArgChange: true
    });

    const [systemFields, setSystemFields] = useState<SystemField[]>([]);

    useEffect(() => {
        if (data?.systemFieldsForSystem) {
            const sortedFields = [...data.systemFieldsForSystem].sort((a, b) => {
                // Ensure field exists and is a string before comparing to avoid error
                const fieldA = a.field ?? "";
                const fieldB = b.field ?? "";
                return fieldA.localeCompare(fieldB);
            });
            setSystemFields(sortedFields);
        };
    }, [data?.systemFieldsForSystem]);

    return {
        systemFields,
        error,
        isLoading,
        refetch
    };
};