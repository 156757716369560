import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import { api as generatedResourceSetApi } from './resourceSet.generated';

export const resourceSetApi = generatedResourceSetApi.enhanceEndpoints({
  addTagTypes: ['resourceSet'],
  endpoints: {
    GetResourceSets: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("resourceSets"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ['resourceSet']
    },
    CreateResourceSet: { invalidatesTags: ["resourceSet"] },
    UpdateResourceSet: { invalidatesTags: ["resourceSet"] },
    DeleteResourceSet: { invalidatesTags: ["resourceSet"] },
  }
})

export const {
  useGetResourceSetQuery,
  useLazyGetResourceSetQuery,
  useGetResourceSetsQuery,
  useLazyGetResourceSetsQuery,
  useGetResourceSetWithResourcesQuery,
  useLazyGetResourceSetWithResourcesQuery,
  useCreateResourceSetMutation,
  useUpdateResourceSetMutation,
  useDeleteResourceSetMutation
} = resourceSetApi;
