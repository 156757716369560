import {createAction, createListenerMiddleware} from '@reduxjs/toolkit';
import {Action, Reducer} from 'redux';
import {LoggingService} from '@sivis/shared/utils';
import {Persistor} from 'redux-persist/lib/types';

export const SESSION_ACTION_PREFIX = 'session'
export const destroySession = createAction(`${SESSION_ACTION_PREFIX}/destroy`)

const RESET_STATE = 'RESET_STATE';
const resetState = createAction<any>(RESET_STATE);

export const resetStateProxy = <S, A extends Action = Action>(rootReducer: Reducer<S, A>): Reducer<S, A> => {
  return (state, action) => {
    if (resetState.match(action)) {
      LoggingService.info('Refreshing Store store', 'Reducer Index');
      state = action.payload;
    }

    return rootReducer(state, action);
  }
}

export const sessionListener = createListenerMiddleware();

export const startSessionListener = (persistor: Persistor, preloadedState?: any) => {
  sessionListener.startListening({
    actionCreator: destroySession,
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(resetState(preloadedState));
      const purgeResult = await persistor.purge();
      LoggingService.trace("PersistStore purged: " +
        Object.keys(purgeResult).reduce((a, b) => b ? `${a}\n${b}` : a, ""),
        "SessionMiddleware")
    }
  });
}


