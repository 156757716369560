import {createAsyncThunk} from '@reduxjs/toolkit';
import {BACKEND_SLICE_NAME, BackendSliceState} from './BackendState';
import {selectBackendSystem, selectDefaultSystem} from './backendSelectors';
import {HealthCheckRequest, HealthCheckRequestType, HealthCheckResult} from './HealthCheck';
import {HealthState} from './HealthState';
import {httpGet, HttpMethod, httpPost} from '@sivis/http-client';
import {getMessageOrSelf, LoggingService} from '@sivis/shared/utils';

const normalizeRequest = (request: HealthCheckRequestType): Required<HealthCheckRequest> => {
  const resultTransformer = (result: any) => HealthState.up;

  if (typeof request === 'string') {
    return {
      id: request,
      resultTransformer
    }
  }

  return {
    resultTransformer,
    ...request
  }

}

const checkBackend = (baseUrl: string, healthCheckPath: string, method: HttpMethod) => {
  if (method === HttpMethod.GET) {
    return httpGet(healthCheckPath, {baseUrl});
  }

  if (method === HttpMethod.POST) {
    return httpPost(healthCheckPath, null, {baseUrl});
  }

  throw new Error('Only GET ans POST are allowed');

}
export const backendCheckHealth = createAsyncThunk<HealthCheckResult, HealthCheckRequestType | undefined, {
  state: BackendSliceState
}>(
  `${BACKEND_SLICE_NAME}/checkHealthState`,
  async (request: HealthCheckRequestType | undefined, {getState, rejectWithValue}) => {
    const requestOrDefault = request ?? selectDefaultSystem(getState());
    const {id, resultTransformer} = normalizeRequest(requestOrDefault);

    const backend = selectBackendSystem(getState(), id);

    if (!backend) {
      return rejectWithValue({id, healthState: HealthState.down, error: `Unknown system: ${id}`});
    }

    const {baseUrl, healthCheckPath, healthCheckMethod = HttpMethod.GET} = backend

    try {
      const result = await checkBackend(baseUrl, healthCheckPath || '', healthCheckMethod);
      const healthState = resultTransformer(result);

      return {
        id,
        healthState
      }

    } catch (e) {

      const error = getMessageOrSelf(e);
      LoggingService.error(error, "BackendActions");
      return rejectWithValue({id, healthState: HealthState.down, error})

    }

  }
)
