import {IconButton, InputBase, Paper} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useCallback, useState} from "react";
import {isEnterEvent} from '@sivis/shared/utils';

export interface SearchFieldProps {
  placeholder: string;
  onSearch: (searchStr: string) => void;
}

export const SearchField = ({placeholder, onSearch}: SearchFieldProps) => {
  const [input, setInput] = useState("");

  const onChangeInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(e.target.value);
  }, []);

  const searchInput = useCallback(() => onSearch(input), [onSearch, input]);

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (isEnterEvent(e)) {
      e.preventDefault();
      searchInput();
    }
  }, [searchInput]);

  return (
    <Paper
      component="form"
      elevation={0}
      variant="outlined"
      sx={{p: "2px 4px", display: "flex", alignItems: "center", width: 400}}
    >
      <InputBase
        sx={{ml: 1, flex: 1}}
        placeholder={placeholder}
        inputProps={{"aria-label": placeholder}}
        value={input}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
      />
      <IconButton
        type="button" sx={{p: "5px"}} aria-label="search"
        onClick={searchInput}
      >
        <SearchIcon color="primary"/>
      </IconButton>
    </Paper>
  );
}
