import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  AccountType,
  AccountTypeInput,
  accountTypeFactory,
  accountTypeInputFactory,
  useCreateAccountTypeMutation,
  useDeleteAccountTypeMutation,
  useGetAccountTypeQuery,
  useUpdateAccountTypeMutation,
} from '@sivis/identity/api';
import { newId } from "../common/newId";
import { EntityType, useSingleEditMode } from '../components/breadcrumb/editModeContext';

export const useAccountTypeApi = () => {
  const { id = newId } = useParams<{ id: string }>();
  const isNew = id === newId;
  const { data, error, isLoading, refetch } = useGetAccountTypeQuery({ id }, { skip: isNew });
  const navigate = useNavigate();

  const [createAccountType] = useCreateAccountTypeMutation();
  const [updateAccountType] = useUpdateAccountTypeMutation();
  const [deleteAccountType] = useDeleteAccountTypeMutation();

  const [accountTypeInput, setAccountTypeInput] = useState<AccountTypeInput>(accountTypeInputFactory());
  const [accountType, setAccountType] = useState<AccountType>();
  const { editMode, toggleEditMode } = useSingleEditMode(EntityType.IDENTITY_TYPE, isNew);

  useEffect(() => {
    if (data?.accountType) {
      const initialInput = accountTypeInputFactory(data.accountType);
      setAccountTypeInput(initialInput);
      setAccountType(data?.accountType as AccountType ?? undefined);
    } else {
      setAccountTypeInput(accountTypeInputFactory());
      setAccountType(accountTypeFactory(accountTypeInput));
    }
  }, [data?.accountType]);

  const goBack = () => navigate("/account-type");

  let onSave, onDelete, onCancel;

  if (isNew) {
    onSave = (value: AccountTypeInput | AccountType) => {
      createAccountType({ input: accountTypeInputFactory(value) }).then(goBack);
    };
    onCancel = goBack;
  } else {
    onSave = (value: AccountTypeInput | AccountType) => {
      updateAccountType({ id, input: accountTypeInputFactory(value) })
        .then(() => {
          toggleEditMode();
          refetch();
        });
    };
    onCancel = () => {
      if (editMode) toggleEditMode();
      else goBack();
    };
    onDelete = () => deleteAccountType({ id }).then(goBack);
  }

  return {
    id,
    accountType,
    accountTypeInput,
    defaultInput: accountTypeInputFactory(),
    isNew,
    error,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
};
