import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetAddressQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAddressQuery = { __typename?: 'Query', address?: { __typename?: 'Address', id: string, name: string, searchTerm?: string | null, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, defaultTimeZone?: string | null, defaultLanguage?: string | null, phone?: string | null, phoneExtension?: string | null, mobilePhone?: string | null, fax?: string | null, faxExtension?: string | null, email?: string | null, standardCommunicationMethod?: string | null, description?: string | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type GetAddressesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetAddressesQuery = { __typename?: 'Query', addresses?: { __typename?: 'AddressConnection', edges?: Array<{ __typename?: 'AddressEdge', node?: { __typename?: 'Address', id: string, name: string, searchTerm?: string | null, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, defaultTimeZone?: string | null, defaultLanguage?: string | null, phone?: string | null, phoneExtension?: string | null, mobilePhone?: string | null, fax?: string | null, faxExtension?: string | null, email?: string | null, standardCommunicationMethod?: string | null, description?: string | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } | null } | null };

export type CreateAddressMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AddressInput>;
}>;


export type CreateAddressMutation = { __typename?: 'Mutation', createAddress: { __typename?: 'Address', id: string, name: string, searchTerm?: string | null, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, defaultTimeZone?: string | null, defaultLanguage?: string | null, phone?: string | null, phoneExtension?: string | null, mobilePhone?: string | null, fax?: string | null, faxExtension?: string | null, email?: string | null, standardCommunicationMethod?: string | null, description?: string | null } };

export type UpdateAddressMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.AddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress: { __typename?: 'Address', id: string, name: string, searchTerm?: string | null, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, defaultTimeZone?: string | null, defaultLanguage?: string | null, phone?: string | null, phoneExtension?: string | null, mobilePhone?: string | null, fax?: string | null, faxExtension?: string | null, email?: string | null, standardCommunicationMethod?: string | null, description?: string | null } };

export type DeleteAddressMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteAddressMutation = { __typename?: 'Mutation', deleteAddress: { __typename?: 'Address', id: string, name: string } };


export const GetAddressDocument = `
    query GetAddress($id: ID!) {
  address(id: $id) {
    id
    name
    searchTerm
    address1
    address2
    address3
    zipcode
    city
    region
    state
    country
    defaultTimeZone
    defaultLanguage
    phone
    phoneExtension
    mobilePhone
    fax
    faxExtension
    email
    standardCommunicationMethod
    description
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const GetAddressesDocument = `
    query GetAddresses($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  addresses(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        name
        searchTerm
        address1
        address2
        address3
        zipcode
        city
        region
        state
        country
        defaultTimeZone
        defaultLanguage
        phone
        phoneExtension
        mobilePhone
        fax
        faxExtension
        email
        standardCommunicationMethod
        description
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const CreateAddressDocument = `
    mutation CreateAddress($input: AddressInput) {
  createAddress(input: $input) {
    id
    name
    searchTerm
    address1
    address2
    address3
    zipcode
    city
    region
    state
    country
    defaultTimeZone
    defaultLanguage
    phone
    phoneExtension
    mobilePhone
    fax
    faxExtension
    email
    standardCommunicationMethod
    description
  }
}
    `;
export const UpdateAddressDocument = `
    mutation UpdateAddress($id: ID!, $input: AddressInput!) {
  updateAddress(id: $id, input: $input) {
    id
    name
    searchTerm
    address1
    address2
    address3
    zipcode
    city
    region
    state
    country
    defaultTimeZone
    defaultLanguage
    phone
    phoneExtension
    mobilePhone
    fax
    faxExtension
    email
    standardCommunicationMethod
    description
  }
}
    `;
export const DeleteAddressDocument = `
    mutation DeleteAddress($id: ID!) {
  deleteAddress(id: $id) {
    id
    name
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetAddress: build.query<GetAddressQuery, GetAddressQueryVariables>({
      query: (variables) => ({ document: GetAddressDocument, variables })
    }),
    GetAddresses: build.query<GetAddressesQuery, GetAddressesQueryVariables | void>({
      query: (variables) => ({ document: GetAddressesDocument, variables })
    }),
    CreateAddress: build.mutation<CreateAddressMutation, CreateAddressMutationVariables | void>({
      query: (variables) => ({ document: CreateAddressDocument, variables })
    }),
    UpdateAddress: build.mutation<UpdateAddressMutation, UpdateAddressMutationVariables>({
      query: (variables) => ({ document: UpdateAddressDocument, variables })
    }),
    DeleteAddress: build.mutation<DeleteAddressMutation, DeleteAddressMutationVariables>({
      query: (variables) => ({ document: DeleteAddressDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAddressQuery, useLazyGetAddressQuery, useGetAddressesQuery, useLazyGetAddressesQuery, useCreateAddressMutation, useUpdateAddressMutation, useDeleteAddressMutation } = injectedRtkApi;

