import {
  GeneratedSystemTypeTypes,
  searchSystemTypesFilter,
  SystemType,
  useGetSystemTypesQuery
} from "@sivis/identity/api";
import {CursorBasedPageResult} from "@sivis/shared/components/entityView";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";

export const useSystemTypesPageApi = () => {
  const { filter, onSearch } = useListSearch(searchSystemTypesFilter);

  const useSystemTypesPageQuery = (cursor: string | null) =>
    useGetSystemTypesQuery({ first: DEFAULT_PAGE_SIZE, after: cursor, sort: DEFAULT_ENTITY_SORT, filter });


  const parseSystemTypesPageResult = (res?: GeneratedSystemTypeTypes.GetSystemTypesQuery): CursorBasedPageResult<SystemType> => {
    return parseCursorBasedPageResult('systemTypes', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useSystemTypesPageQuery,
    parseSystemTypesPageResult
  };
};
