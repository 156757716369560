import { OetRelation, OrganizationalEntityType, RelationQueryType } from '@sivis/identity/api';
import { GridColDef } from '@mui/x-data-grid';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import {
  CreateButtonWithIcon,
  EntityView,
  InfiniteScrollTableCursor
} from '@sivis/shared/components/entityView';
import {
  useOetRelationsForOrganizationalEntityTypePageApi
} from './api/useOetRelationsForOrganizationalEntityTypePageApi';
import { entityToOetRelationTableRow } from '../utils/oetRelationUtils';


const columns: GridColDef[] = [
  {
    field: 'organizationalEntityType',
    flex: 1
  },
  {
    field: 'oetRelationType',
    flex: 1
  },
  {
    field: 'isRequired',
    flex: 1
  }
];


interface OrganizationalEntityTypeRelationsListProps {
  organizationalEntityType: OrganizationalEntityType;
  createOetRelation: () => void;
  editOetRelation: (id: string) => void;
  editMode?: boolean;
}


export const OrganizationalEntityTypeRelationsList = (props: OrganizationalEntityTypeRelationsListProps) => {
  const intl = useCustomIntl();

  const entityToTableRow = (entity: OetRelation) => {
    return entityToOetRelationTableRow(
      entity,
      props.organizationalEntityType?.id ?? '',
      intl
    );
  };

  const {
    pageSize,
    parseOetRelationsPageResult,
    useOetRelationsPageQuery
  } = useOetRelationsForOrganizationalEntityTypePageApi({
    organizationalEntityType: props.organizationalEntityType
  });

  const translatedColumns = useTranslateColumns(columns, 'fusion.organizationalEntityTypeRelation.propertyName');

  return <EntityView
    title={""}>
    <InfiniteScrollTableCursor
      onRowClick={(id: string) => props.editOetRelation(id)}
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useOetRelationsPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseOetRelationsPageResult}
      onSearch={() => null}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
      tableType={RelationQueryType.OET_RELATIONS}
      toolbarButtons={[
        <CreateButtonWithIcon key={'create'}
                              intlId={'fusion.organizationalEntityType.relations.createRelationButtonTitle'}
                              onClick={() => props.createOetRelation()}
                              disabled={!props.editMode}/>
      ]}
    />
  </EntityView>
};
