export const normalizePath = (...pathParts: (string | number)[]): string => {
  //console.log(pathParts);
  if (!pathParts) {
    return '';
  }

  const splittedParts: string[] = [];

  for (const part of pathParts) {
    if (!part) {
      continue;
    }

    if (typeof part === 'number') {
      splittedParts.push(part.toString());
      continue;
    }

    const splitted = part.split('/').filter(p => !!p);
    splittedParts.push(...splitted);
  }

  if (splittedParts.length == 0) {
    return '';
  }
  return pathToUrl(['', ...splittedParts]);
};

export const pathToUrl = (path: string | string[]): string => {
  if (typeof path === 'string') {
    return path;
  }

  return path.join('/');
}
