import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';
import { CombinedState, PreloadedState } from 'redux';
import { initializeFusionTheme } from '../components/theme/fusionTheme';
import { GlobalAppState } from './store';
import defaultLogoLogin from './pointsharp-horizontal-logo-almost-white.png';
import { SIDEBAR_SLICE_NAME } from '@sivis/app-shell';
import { THEME_SLICE_NAME } from '@sivis/shared/theme';
import { initialTextState } from '@sivis/intl';
import { initialMessages } from './message';
import { ELogoPlace, LOGO_SLICE_NAME } from '@sivis/shared/components/logo';
import { AUTH_SLICE_NAME, getInitialAuthState } from './authSlice';

export const preloadedState = (): PreloadedState<CombinedState<NoInfer<GlobalAppState>>> => ({
  intl: {
    ...initialTextState(),
    messages: initialMessages()
  },
  [LOGO_SLICE_NAME]: {
    logos: {
      [ELogoPlace.LOGIN]: {image: defaultLogoLogin, place: ELogoPlace.LOGIN},
      [ELogoPlace.NAVBAR]: {image: defaultLogoLogin, place: ELogoPlace.NAVBAR}
    },
    loading: false,
    error: null
  },
  [THEME_SLICE_NAME]: initializeFusionTheme(),
  [SIDEBAR_SLICE_NAME]: {
    closed: false
  },
  [AUTH_SLICE_NAME]: getInitialAuthState()
});
