import {forceRefetchPaging, mergePaging, serializeQueryArgsPaging, transformResponseWithArgs} from "@sivis/shared/api-utils";
import {api as generatedAttributeSyncDefinitionApi} from './attributeSyncDefinition.generated';

export const attributeSyncDefinitionApi = generatedAttributeSyncDefinitionApi.enhanceEndpoints({
  addTagTypes: ["attributeSync", "draftAttributeSync"],
  endpoints: {
    GetAttributeSyncDefinitions: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("asDefinitions"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs
    },
    GetAttributeSyncDefinitionsForSystem: {
      merge: mergePaging("asDefinitions"),
      providesTags: ["attributeSync"],
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      serializeQueryArgs: serializeQueryArgsPaging
    },
    GetDraftForAttributeSyncDefinition: {
      providesTags: ["draftAttributeSync"],
    },
    CreateAttributeSyncDefinition: { invalidatesTags: ["attributeSync"] },
    UpdateAttributeSyncDefinition: { invalidatesTags: ["attributeSync"] },
    DeleteAttributeSyncDefinition: { invalidatesTags: ["attributeSync"] },
    SaveDraftAttributeSyncDefinition: { invalidatesTags: ["draftAttributeSync"] },
    DiscardDraftAttributeSyncDefinition: { invalidatesTags: ["draftAttributeSync"] }
  }
})

export const {
  useGetAttributeSyncDefinitionsQuery,
  useLazyGetAttributeSyncDefinitionsQuery,
  useGetAttributeSyncDefinitionsForSystemQuery,
  useLazyGetAttributeSyncDefinitionsForSystemQuery,
  useGetAttributeSyncDefinitionQuery,
  useLazyGetAttributeSyncDefinitionQuery,
  useGetDraftForAttributeSyncDefinitionQuery,
  useLazyGetDraftForAttributeSyncDefinitionQuery,
  useDiscardDraftAttributeSyncDefinitionMutation,
  usePublishDraftAttributeSyncDefinitionMutation,
  useSaveDraftAttributeSyncDefinitionMutation,
  useCreateAttributeSyncDefinitionMutation,
  useUpdateAttributeSyncDefinitionMutation,
  useDeleteAttributeSyncDefinitionMutation
} = attributeSyncDefinitionApi;
