import {Fragment, FunctionComponent} from "react";
import {Params, useMatches} from "react-router-dom";
import styles from "./breadcrumb.module.scss";

export type DynamicBreadcrumb = FunctionComponent<{ params: Params<string>, path: string }>;

export interface MatchedCrumb {
  crumb: JSX.Element | DynamicBreadcrumb;
  path: string;
  params: Params<string>;
}

interface BreadcrumbsProps {
  /**
   * Overrides the default breadcrumbs renderer.
   * @param crumbs the matched crumbs from current path
   */
  renderer?: (crumbs: MatchedCrumb[]) => JSX.Element;
}

export const Breadcrumbs = ({renderer}: BreadcrumbsProps) => {
  const matches = useMatches();

  const crumbs = matches
  .filter(match => Boolean((match.handle as any)?.crumb))
  .map(match => ({crumb: (match as any).handle.crumb, path: match.pathname, params: match.params}));

  return renderer ? renderer(crumbs) : (
    <div className={styles.breadcrumbContainer}>
      {crumbs.map((crumb, index) => {
        const divider = <span>/</span>;
        const isLast = index === crumbs.length - 1;
        return <Fragment key={crumb.path}>
          <span className={styles.breadcrumb}>{crumb.crumb}</span>
          {!isLast && divider}
        </Fragment>;
      })}
    </div>
  );
};
