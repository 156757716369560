import styles from './form.module.scss';
import { IntlButton } from '@sivis/intl';

export interface FormButtonsProps {
  /**
   * Handle submit automatically with a form if onSave is not set
   */
  onSave?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onSaveDraft?: () => void;
  disabled?: boolean;
  saveInsteadOfSubmit?: boolean;
  isDraftable?: boolean;
}

export const FormButtons = (props: FormButtonsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSaveFallback = () => {};
  const saveButtonIntlId = props.saveInsteadOfSubmit
    ? 'general.save'
    : props.isDraftable
    ? 'general.publish'
    : 'general.submit';
  return (
    <div className={styles.entityFooter}>
      <IntlButton
        variant="contained"
        type="submit"
        disabled={props.disabled}
        onClick={props.onSave ?? onSaveFallback}
        intlId={saveButtonIntlId}
      />
      {props.onDelete ? (
        <IntlButton
          variant="contained"
          onClick={props.onDelete}
          color="error"
          disabled={props.disabled}
          intlId="general.delete"
        />
      ) : null}
      {props.isDraftable ? (
        <IntlButton
          variant="contained"
          onClick={props.onSaveDraft}
          disabled={props.disabled}
          intlId="general.saveDraft"
        />
      ) : null}
      <IntlButton
        variant="text"
        onClick={props.onCancel}
        disabled={props.disabled}
        intlId="general.cancel"
      />
    </div>
  );
};
