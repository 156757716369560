import {Registry, RegistryEntry} from '@sivis/shared/components/registry';
import {UserRegistryOptionsType} from './UseRegistryOptions';

export const useRegistry = <ID extends string = string>() => {
  return (options: UserRegistryOptionsType, throwErrorOnMissing = false): RegistryEntry[] => {
    if (typeof options === 'string') {
      return [Registry.get(options, throwErrorOnMissing)];
    }

    const {byType, ordered} = options;

    if (byType) {
      return Registry.getByType(byType, ordered);
    }

    return [];
  }
}
