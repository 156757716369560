import * as Types from '../attributeSyncApi.types';

import { attributeSyncBaseApi } from '../attributeSyncBaseApi';

            /* eslint-disable */
              /**
               *
               * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
               *
               * instead, edit one of the `.graphql` files in this project and run
               *
               * npm run graphql-codegen
               *
               * for this file to be re-created
               */
               
export type GetAttributeSyncDefinitionsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAttributeSyncDefinitionsQuery = { __typename?: 'Query', asDefinitions?: { __typename?: 'AsDefinitionsConnection', edges?: Array<{ __typename?: 'AsDefinitionsEdge', node: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, draftAction: Types.DraftActions, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } }> | null, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetAttributeSyncDefinitionsForSystemQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  systemId: Types.Scalars['UUID'];
}>;


export type GetAttributeSyncDefinitionsForSystemQuery = { __typename?: 'Query', asDefinitions?: { __typename?: 'AsDefinitionsConnection', edges?: Array<{ __typename?: 'AsDefinitionsEdge', node: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, draftAction: Types.DraftActions, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } }> | null, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetAttributeSyncDefinitionQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetAttributeSyncDefinitionQuery = { __typename?: 'Query', asDefinition: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, draftAction: Types.DraftActions, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } };

export type GetDraftForAttributeSyncDefinitionQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetDraftForAttributeSyncDefinitionQuery = { __typename?: 'Query', draftForASDefinition?: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, draftAction: Types.DraftActions, originalMappingId?: any | null, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } | null };

export type CreateAttributeSyncDefinitionMutationVariables = Types.Exact<{
  input: Types.AsdInput;
}>;


export type CreateAttributeSyncDefinitionMutation = { __typename?: 'Mutation', addASDefinition: { __typename?: 'AsDefinitionPayload', asDefinition: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } } };

export type UpdateAttributeSyncDefinitionMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.AsdInput;
}>;


export type UpdateAttributeSyncDefinitionMutation = { __typename?: 'Mutation', updateASDefinition: { __typename?: 'AsDefinitionPayload', asDefinition: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } } };

export type SaveDraftAttributeSyncDefinitionMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.AsdInput;
}>;


export type SaveDraftAttributeSyncDefinitionMutation = { __typename?: 'Mutation', saveDraftASDefinition: { __typename?: 'AsDefinitionPayload', asDefinition: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } } };

export type DiscardDraftAttributeSyncDefinitionMutationVariables = Types.Exact<{
  draftId: Types.Scalars['UUID'];
}>;


export type DiscardDraftAttributeSyncDefinitionMutation = { __typename?: 'Mutation', discardDraftASDefinition: any };

export type PublishDraftAttributeSyncDefinitionMutationVariables = Types.Exact<{
  draftId: Types.Scalars['UUID'];
  input: Types.AsdInput;
}>;


export type PublishDraftAttributeSyncDefinitionMutation = { __typename?: 'Mutation', publishDraftASDefinition: { __typename?: 'AsDefinitionPayload', asDefinition: { __typename?: 'ASDefinition', id: any, name?: string | null, description?: string | null, systemId: any, priority?: number | null, isActive: boolean, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null, inheritingFrom?: { __typename?: 'ASDefinition', id: any, name?: string | null, systemId: any, priority?: number | null, isPublished: boolean, publishedId?: any | null, updatedAt: any, updatedBy?: string | null } | null, asMappings: Array<{ __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any, transformations: Array<{ __typename?: 'Transformation', id: any, kind: Types.TransformationKind, value?: string | null, mappingId: any }> }> } } };

export type DeleteAttributeSyncDefinitionMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteAttributeSyncDefinitionMutation = { __typename?: 'Mutation', deleteASDefinition: any };


export const GetAttributeSyncDefinitionsDocument = `
    query GetAttributeSyncDefinitions($first: Int, $after: String) {
  asDefinitions(first: $first, after: $after) {
    edges {
      node {
        id
        name
        description
        systemId
        priority
        isActive
        isPublished
        publishedId
        updatedAt
        updatedBy
        inheritingFrom {
          id
          name
          systemId
          priority
          isPublished
          publishedId
          updatedAt
          updatedBy
        }
        asMappings {
          id
          fieldType
          internalAttribute
          externalAttribute
          direction
          synchronizationMethod
          priority
          asDefinitionId
          draftAction
          transformations {
            id
            kind
            value
            mappingId
          }
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetAttributeSyncDefinitionsForSystemDocument = `
    query GetAttributeSyncDefinitionsForSystem($first: Int, $after: String, $systemId: UUID!) {
  asDefinitions(
    where: {systemId: {eq: $systemId}, publishedId: {eq: null}}
    first: $first
    after: $after
  ) {
    edges {
      node {
        id
        name
        description
        systemId
        priority
        isActive
        isPublished
        publishedId
        updatedAt
        updatedBy
        inheritingFrom {
          id
          name
          systemId
          priority
          isPublished
          publishedId
          updatedAt
          updatedBy
        }
        asMappings {
          id
          fieldType
          internalAttribute
          externalAttribute
          direction
          synchronizationMethod
          priority
          asDefinitionId
          draftAction
          transformations {
            id
            kind
            value
            mappingId
          }
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetAttributeSyncDefinitionDocument = `
    query GetAttributeSyncDefinition($id: UUID!) {
  asDefinition(id: $id) {
    id
    name
    description
    systemId
    priority
    isActive
    isPublished
    publishedId
    updatedAt
    updatedBy
    inheritingFrom {
      id
      name
      systemId
      priority
      isPublished
      publishedId
      updatedAt
      updatedBy
    }
    asMappings {
      id
      fieldType
      internalAttribute
      externalAttribute
      direction
      synchronizationMethod
      priority
      asDefinitionId
      draftAction
      transformations {
        id
        kind
        value
        mappingId
      }
    }
  }
}
    `;
export const GetDraftForAttributeSyncDefinitionDocument = `
    query GetDraftForAttributeSyncDefinition($id: UUID!) {
  draftForASDefinition(id: $id) {
    id
    name
    description
    systemId
    priority
    isActive
    isPublished
    publishedId
    updatedAt
    updatedBy
    inheritingFrom {
      id
      name
      systemId
      priority
      isPublished
      publishedId
      updatedAt
      updatedBy
    }
    asMappings {
      id
      fieldType
      internalAttribute
      externalAttribute
      direction
      synchronizationMethod
      priority
      asDefinitionId
      draftAction
      originalMappingId
      transformations {
        id
        kind
        value
        mappingId
      }
    }
  }
}
    `;
export const CreateAttributeSyncDefinitionDocument = `
    mutation CreateAttributeSyncDefinition($input: AsdInput!) {
  addASDefinition(input: $input) {
    asDefinition {
      id
      name
      description
      systemId
      priority
      isActive
      isPublished
      publishedId
      updatedAt
      updatedBy
      inheritingFrom {
        id
        name
        systemId
        priority
        isPublished
        publishedId
        updatedAt
        updatedBy
      }
      asMappings {
        id
        fieldType
        internalAttribute
        externalAttribute
        direction
        synchronizationMethod
        priority
        asDefinitionId
        transformations {
          id
          kind
          value
          mappingId
        }
      }
    }
  }
}
    `;
export const UpdateAttributeSyncDefinitionDocument = `
    mutation UpdateAttributeSyncDefinition($id: UUID!, $input: AsdInput!) {
  updateASDefinition(id: $id, input: $input) {
    asDefinition {
      id
      name
      description
      systemId
      priority
      isActive
      isPublished
      publishedId
      updatedAt
      updatedBy
      inheritingFrom {
        id
        name
        systemId
        priority
        isPublished
        publishedId
        updatedAt
        updatedBy
      }
      asMappings {
        id
        fieldType
        internalAttribute
        externalAttribute
        direction
        synchronizationMethod
        priority
        asDefinitionId
        transformations {
          id
          kind
          value
          mappingId
        }
      }
    }
  }
}
    `;
export const SaveDraftAttributeSyncDefinitionDocument = `
    mutation SaveDraftAttributeSyncDefinition($id: UUID!, $input: AsdInput!) {
  saveDraftASDefinition(id: $id, input: $input) {
    asDefinition {
      id
      name
      description
      systemId
      priority
      isActive
      isPublished
      publishedId
      updatedAt
      updatedBy
      inheritingFrom {
        id
        name
        systemId
        priority
        isPublished
        publishedId
        updatedAt
        updatedBy
      }
      asMappings {
        id
        fieldType
        internalAttribute
        externalAttribute
        direction
        synchronizationMethod
        priority
        asDefinitionId
        transformations {
          id
          kind
          value
          mappingId
        }
      }
    }
  }
}
    `;
export const DiscardDraftAttributeSyncDefinitionDocument = `
    mutation DiscardDraftAttributeSyncDefinition($draftId: UUID!) {
  discardDraftASDefinition(draftId: $draftId)
}
    `;
export const PublishDraftAttributeSyncDefinitionDocument = `
    mutation PublishDraftAttributeSyncDefinition($draftId: UUID!, $input: AsdInput!) {
  publishDraftASDefinition(draftId: $draftId, input: $input) {
    asDefinition {
      id
      name
      description
      systemId
      priority
      isActive
      isPublished
      publishedId
      updatedAt
      updatedBy
      inheritingFrom {
        id
        name
        systemId
        priority
        isPublished
        publishedId
        updatedAt
        updatedBy
      }
      asMappings {
        id
        fieldType
        internalAttribute
        externalAttribute
        direction
        synchronizationMethod
        priority
        asDefinitionId
        transformations {
          id
          kind
          value
          mappingId
        }
      }
    }
  }
}
    `;
export const DeleteAttributeSyncDefinitionDocument = `
    mutation DeleteAttributeSyncDefinition($id: UUID!) {
  deleteASDefinition(id: $id)
}
    `;

const injectedRtkApi = attributeSyncBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetAttributeSyncDefinitions: build.query<GetAttributeSyncDefinitionsQuery, GetAttributeSyncDefinitionsQueryVariables | void>({
      query: (variables) => ({ document: GetAttributeSyncDefinitionsDocument, variables })
    }),
    GetAttributeSyncDefinitionsForSystem: build.query<GetAttributeSyncDefinitionsForSystemQuery, GetAttributeSyncDefinitionsForSystemQueryVariables>({
      query: (variables) => ({ document: GetAttributeSyncDefinitionsForSystemDocument, variables })
    }),
    GetAttributeSyncDefinition: build.query<GetAttributeSyncDefinitionQuery, GetAttributeSyncDefinitionQueryVariables>({
      query: (variables) => ({ document: GetAttributeSyncDefinitionDocument, variables })
    }),
    GetDraftForAttributeSyncDefinition: build.query<GetDraftForAttributeSyncDefinitionQuery, GetDraftForAttributeSyncDefinitionQueryVariables>({
      query: (variables) => ({ document: GetDraftForAttributeSyncDefinitionDocument, variables })
    }),
    CreateAttributeSyncDefinition: build.mutation<CreateAttributeSyncDefinitionMutation, CreateAttributeSyncDefinitionMutationVariables>({
      query: (variables) => ({ document: CreateAttributeSyncDefinitionDocument, variables })
    }),
    UpdateAttributeSyncDefinition: build.mutation<UpdateAttributeSyncDefinitionMutation, UpdateAttributeSyncDefinitionMutationVariables>({
      query: (variables) => ({ document: UpdateAttributeSyncDefinitionDocument, variables })
    }),
    SaveDraftAttributeSyncDefinition: build.mutation<SaveDraftAttributeSyncDefinitionMutation, SaveDraftAttributeSyncDefinitionMutationVariables>({
      query: (variables) => ({ document: SaveDraftAttributeSyncDefinitionDocument, variables })
    }),
    DiscardDraftAttributeSyncDefinition: build.mutation<DiscardDraftAttributeSyncDefinitionMutation, DiscardDraftAttributeSyncDefinitionMutationVariables>({
      query: (variables) => ({ document: DiscardDraftAttributeSyncDefinitionDocument, variables })
    }),
    PublishDraftAttributeSyncDefinition: build.mutation<PublishDraftAttributeSyncDefinitionMutation, PublishDraftAttributeSyncDefinitionMutationVariables>({
      query: (variables) => ({ document: PublishDraftAttributeSyncDefinitionDocument, variables })
    }),
    DeleteAttributeSyncDefinition: build.mutation<DeleteAttributeSyncDefinitionMutation, DeleteAttributeSyncDefinitionMutationVariables>({
      query: (variables) => ({ document: DeleteAttributeSyncDefinitionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAttributeSyncDefinitionsQuery, useLazyGetAttributeSyncDefinitionsQuery, useGetAttributeSyncDefinitionsForSystemQuery, useLazyGetAttributeSyncDefinitionsForSystemQuery, useGetAttributeSyncDefinitionQuery, useLazyGetAttributeSyncDefinitionQuery, useGetDraftForAttributeSyncDefinitionQuery, useLazyGetDraftForAttributeSyncDefinitionQuery, useCreateAttributeSyncDefinitionMutation, useUpdateAttributeSyncDefinitionMutation, useSaveDraftAttributeSyncDefinitionMutation, useDiscardDraftAttributeSyncDefinitionMutation, usePublishDraftAttributeSyncDefinitionMutation, useDeleteAttributeSyncDefinitionMutation } = injectedRtkApi;

