import {
  FilterExpression,
  GeneratedSystemTypes,
  searchSystemsFilter,
  System,
  useGetSystemsQuery
} from '@sivis/identity/api';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../common/parsePageResult';
import { getExistingOrCombinedFilter, useListSearch } from '../utils/filterUtils';


export const parseSystemsPageResult = (res?: GeneratedSystemTypes.GetSystemsQuery): CursorBasedPageResult<System> => {
  return parseCursorBasedPageResult('systems', res);
};

export const useSystemsPageApi = (extraFilter?: FilterExpression) => {
  const { filter, onSearch } = useListSearch(searchSystemsFilter);
  let systemsFilter = getExistingOrCombinedFilter(filter, extraFilter);

  const useSystemsPageQuery = (cursor: string | null) =>
    useGetSystemsQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: systemsFilter
    });

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useSystemsPageQuery,
    parseSystemsPageResult
  };
};
