import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';

interface FileDownloadProps {
  data: any;
  fileName: string;
  mediaType?: string;
}

export const fileDownload = (props: FileDownloadProps) => {

  let blobdata;
  props.mediaType ? blobdata = new Blob([props.data], {type: props.mediaType}) : blobdata = new Blob([props.data]);

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blobdata);
  a.target = '_blank';
  a.download = props.fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

interface DownloadBase64Props {
  data: string;
  fileName: string;
  mediaType: string;
}

export const downloadBase64 = (props: DownloadBase64Props) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${props.mediaType};base64,` + encodeURIComponent(props.data));
  element.setAttribute('download', props.fileName);
  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export interface OverviewTable {
  head: string[];
  body: string[][];
}

export interface OverviewLine {
  field: string;
  value: string;
}

export interface OverviewCategory {
  title: string;
  table?: OverviewTable;
  lines?: OverviewLine[];
}

export interface RequestOverview {
  title?: string;
  categories: OverviewCategory[];
  wizardOverviewId?: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const downloadOverview = async (data: RequestOverview[], wizardOverviewTitle: string, fileName: string) => {
  const marginX = 15;
  const marginY = 15;

  const doc = new jsPDF('p', 'mm');
  doc.setFontSize(14);
  let finalY = marginY;

  for (let i = 0; i < data.length; i++) {
    const overview = data[i];

    if (i > 0) {
      doc.addPage();
    }
    finalY = marginY;

    if (overview.title) {
      doc.text(overview.title, marginX, finalY + 10);
      finalY += 10;
    }

    overview.categories.forEach(category => {
      doc.text(category.title, marginX, finalY + 10);

      if (category.lines) {
        autoTable(doc, {
          body: category.lines.map(line => ([line.field, line.value])),
          startY: finalY + 15
        });
      } else if (category.table) {
        autoTable(doc, {
          head: [category.table.head],
          body: category.table.body,
          startY: finalY + 15,
          headStyles: {fillColor: '#fff', textColor: '#262626'},
          styles: {
            overflow: 'linebreak',
            minCellWidth: 20
          }
        });
      }

      finalY = (doc as any).lastAutoTable?.finalY ?? marginY + 10;
      finalY += 2;
    });

    if (overview.wizardOverviewId) {
      const wizardOverview = document.getElementById(overview.wizardOverviewId);
      if (wizardOverview) {
        const canvas = await html2canvas(wizardOverview, {
          onclone: clonedDoc => {
            const clonedElement = clonedDoc.getElementById(overview.wizardOverviewId ?? '');
            if (clonedElement) {
              clonedElement.style.display = 'block';
              clonedElement.style.width = '700px';
            }
          }
        });

        const imgData = canvas.toDataURL('image/png');
        const pageWidth = 210;
        const imgWidth = pageWidth - 2 * marginX;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let position = finalY + 10;

        doc.text(wizardOverviewTitle, marginX, position);
        position += 5;
        doc.addImage(imgData, 'PNG', marginX, position, imgWidth, imgHeight);
        heightLeft -= pageHeight - position;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + marginY;
          doc.addPage();
          doc.addImage(imgData, 'PNG', marginX, position, imgWidth, imgHeight);
          heightLeft -= pageHeight - marginY;
        }
      }
    }
  }

  doc.save(fileName + '.pdf');
};
export const downloadJson = (obj: Record<string, unknown>, name: string) => {
  const blobdata = new Blob([JSON.stringify(obj)], {type: 'application/json'});
  const fileName = name + '.json';

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blobdata);
  a.target = '_blank';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
