import {searchResourcesFilter, useGetResourcesQuery} from "@sivis/identity/api";
import {DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";
import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";
import {parseResourcePageResult} from "../resource/useResourcePageApi";

export const useResourcePageForResourceTypeApi = (resourceTypeId?: string) => {
  const { filter: searchFilter, onSearch } = useListSearch(searchResourcesFilter);
  const resourceTypeFilter = {
    operator: FilterOperator.EQ,
    name: "resourceType.id",
    uuidValue: resourceTypeId
  };
  const filter = searchFilter ? combineFilter(FilterOperator.AND, searchFilter, resourceTypeFilter)
    : resourceTypeFilter;

  const useResourcePageForResourceTypePageQuery = (cursor: string | null) =>
    useGetResourcesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    }, {skip: !resourceTypeId});

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useResourcePageForResourceTypePageQuery,
    parseResourcePageForResourceTypePageResult: parseResourcePageResult
  };
};

