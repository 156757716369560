import {EntityDetailsProps, TabBar} from "@sivis/shared/components/entityView";
import {EntitlementInformationTab} from "./entitlementInformationTab";
import {useRoleResourceEntitlementApi} from "./api/useRoleResourceEntitlementApi";
import {RelationEntityType} from "../components/popup/relationUtils";
import {useCustomIntl} from "@sivis/intl";
import {ExtendedResourceEntitlement} from "@sivis/identity/api";

export const RoleResourceEntitlementDetails = (props: EntityDetailsProps<ExtendedResourceEntitlement>) => {
  const intl = useCustomIntl();
  const tabs = [
    {
      label: intl.format("fusion.breadcrumb.information"), content: <EntitlementInformationTab
        {...props}
        useEntitlementQuery={useRoleResourceEntitlementApi}
        entitledType={RelationEntityType.ROLE}
        targetType={RelationEntityType.RESOURCE}
      />
    }
  ];

  return <TabBar tabs={tabs}/>;
};
