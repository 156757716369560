import {
  Box,
  MenuItem,
  TextField,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import { useCustomIntl } from '@sivis/intl';
import { CreateButtonWithIcon } from '@sivis/shared/components/entityView';
import {
  AsMapping,
  TransformationInput,
  TransformationKind,
} from '@sivis/attributesync/api';
import { useSystemFieldApi } from '../api/useSystemFieldApi';
import { useState, useEffect } from 'react';
import styles from './attributeSyncMappingForm.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { random } from 'lodash';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { IFormInput } from './attributeSyncMappingForm';

export interface AttributeSyncMappingSourceFieldProps {
  errors: FieldErrors<IFormInput>;
  register: UseFormRegister<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
  editedMapping: AsMapping | null;
  onTransformationsChange: (transformations: TransformationField[]) => void;
}

interface TransformationField extends TransformationInput {
  selectId: number;
}

const transformationOptions = [
  { value: TransformationKind.Trim, label: 'Trim' },
  { value: TransformationKind.Uppercase, label: 'Uppercase' },
  { value: TransformationKind.Lowercase, label: 'Lowercase' },
];

export const AttributeSyncMappingSourceField = (
  props: AttributeSyncMappingSourceFieldProps
) => {
  const intl = useCustomIntl();

  const [selectedValue, setSelectedValue] = useState<string>('');
  const [transformationFields, setTransformationFields] = useState<
    TransformationField[]
  >(
    props.editedMapping?.transformations?.map((transformation, index) => ({
      selectId: index,
      value: transformation.kind,
      kind: transformation.kind,
      id: transformation.id,
      mappingId: transformation.mappingId,
    })) || []
  );

  const { systemFields } = useSystemFieldApi(
    '00000000-0000-0000-0000-000000000000'
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    // Wait until systemFields are loaded before setting selected value to avoid out-of-range error
    if (systemFields.length > 0 && props.editedMapping?.internalAttribute) {
      const value = props.editedMapping.internalAttribute;
      if (systemFields.some((field) => field.field === value)) {
        setSelectedValue(value);
        props.setValue('internalAttribute', value);
      } else {
        setSelectedValue('');
      }
    }
  }, [props.editedMapping, systemFields]);

  const handleAddTransformation = () => {
    // Generate a random selectId that is not already in use
    let newSelectId: number;
    do {
      newSelectId = random(1, 100);
    } while (
      transformationFields.some((field) => field.selectId === newSelectId)
    );
    const newTransformation = {
      selectId: random(1, 100),
      id: '',
      value: '',
      kind: '',
      mappingId: '',
    };
    const updatedTransformations = [...transformationFields, newTransformation];
    setTransformationFields(updatedTransformations);
    props.onTransformationsChange(updatedTransformations);
  };

  const handleRemoveTransformation = (selectId: number) => {
    const updatedTransformations = transformationFields.filter(
      (field) => field.selectId !== selectId
    );
    setTransformationFields(updatedTransformations);
    props.onTransformationsChange(updatedTransformations);
  };

  const handleTransformationChange = (
    selectId: number,
    kind: TransformationKind
  ) => {
    const updatedTransformations = transformationFields.map((field) =>
      field.selectId === selectId ? { ...field, kind } : field
    );
    setTransformationFields(updatedTransformations);
    props.onTransformationsChange(updatedTransformations);
  };

  const getAvailableOptions = (currentId: number) => {
    const selectedValues = transformationFields
      .filter((field) => field.selectId !== currentId)
      .map((field) => field.kind);
    return transformationOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const availableOptions = getAvailableOptions(-1);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Typography
        variant="h6"
        gutterBottom
        color={'black'}
        className={styles.header}
      >
        {intl.format(
          'fusion.attributesynchronizationmapping.propertyName.pointsharpField'
        )}
      </Typography>
      <Box className={styles.card}>
        <Box className={styles.inputWrapper}>
          <TextField
            select
            required
            label={intl.format(
              'fusion.attributesynchronizationmapping.popup.selectField'
            )}
            {...props.register('internalAttribute', { required: true })}
            margin="normal"
            className={styles.cardField}
            error={!!props.errors.internalAttribute}
            helperText={props.errors.internalAttribute?.message}
            value={selectedValue}
            onChange={handleChange}
          >
            {systemFields.map((field) => (
              <MenuItem key={field.id} value={field.field!}>
                {field.field}
              </MenuItem>
            ))}
          </TextField>
          {transformationFields.length < 1 && (
            <Box className={styles.buttonWrapper}>
              <CreateButtonWithIcon
                intlId="fusion.attributesynchronizationmapping.popup.transformation"
                onClick={handleAddTransformation}
              ></CreateButtonWithIcon>
            </Box>
          )}
        </Box>
        {transformationFields.length > 0 && (
          <Box className={styles.transformationHeaderWrapper}>
            <Typography variant="h6" gutterBottom color={'black'}>
              {intl.format(
                'fusion.attributesynchronizationmapping.popup.transformation'
              )}
            </Typography>
            {availableOptions.length > 0 &&
              transformationFields.length < transformationOptions.length && (
                <CreateButtonWithIcon
                  intlId="fusion.attributesynchronizationmapping.popup.transformation"
                  onClick={handleAddTransformation}
                ></CreateButtonWithIcon>
              )}
          </Box>
        )}
        {transformationFields.map((field, index) => (
          <Box key={field.id} className={styles.transformationFieldWrapper}>
            <Typography variant="body1" className={styles.fieldNumber}>
              {index + 1}
            </Typography>
            <TextField
              select
              label={intl.format(
                'fusion.attributesynchronizationmapping.popup.transformationLabel'
              )}
              margin="normal"
              className={styles.cardField}
              value={field.kind}
              onChange={(e) =>
                handleTransformationChange(
                  field.selectId,
                  e.target.value as TransformationKind
                )
              }
            >
              {getAvailableOptions(field.selectId).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              aria-label="delete"
              onClick={() => handleRemoveTransformation(field.selectId)}
              size="small"
              className={styles.deleteButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Divider className={styles.dividerMargin} />
    </Box>
  );
};
