import {Alert} from "@mui/material";
import {Popup} from "@sivis/shared/components/entityView";
import styles from "./popup.module.scss";

interface ConfirmationPopupProps {
  open: boolean;
  onClose: () => void;
  primaryTitle: string;
  secondaryTitle?: string;
  message: string;
}

export const ConfirmationPopup = (props: ConfirmationPopupProps) => {
  return <Popup {...props} styleType="message" hideButtons>
    <div className={styles.confirmationMessageContainer}>
      <Alert severity="success">{props.message}</Alert>
    </div>
  </Popup>;
}
