import {AttributeRows, RowGroup} from "@sivis/shared/components/entityView";
import {SystemType} from "@sivis/identity/api";
import styles from "./systemType.module.scss";
import {useTranslateConfigs} from "@sivis/intl";

export interface SystemTypeInformationTabProps {
  systemType?: SystemType,
}

const SystemTypeInformationTab = (props: SystemTypeInformationTabProps) => {

  const configs = [
    {
      property: 'systemTypeName',
      description: 'System type name',
      required: true
    },
    {
      property: 'systemTypeDescription',
    }
  ];

  const translatedConfigs = useTranslateConfigs(configs, "fusion.systemType.propertyName");

  const entityInformation = props.systemType ? <RowGroup>
    <AttributeRows
      configs={translatedConfigs}
      value={props.systemType}
    />
  </RowGroup> : <></>;

  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
};

export default SystemTypeInformationTab;

