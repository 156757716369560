import {usePermissionApi} from "./usePermissionApi";
import {
  DeleteButton,
  EditButton,
  EntityInformation,
  EntityView
} from "@sivis/shared/components/entityView";
import {useCustomIntl, useTranslateConfigs} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";

// TODO: add UI tests for permission view and permission list
export const PermissionView = () => {
  const {
    permission,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
  } = usePermissionApi();
  const intl = useCustomIntl();

  const configs = [
    {
      property: 'name',
      required: true

    },
    {
      property: 'description',
    }
  ]

  const translatedConfigs = useTranslateConfigs(configs, "fusion.permission.propertyName");

  const entityDetails = <EntityInformation
    editMode={editMode}
    configs={translatedConfigs}
    value={permission}
    onSubmit={onSave}
    onCancel={onCancel}
  />;

  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit"/>,
    <DeleteButton onClick={onDelete} key="delete"/>
  ];

  return <EntityView
    title={""}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.permission.error.notFound", intl.format) : undefined}>
    {entityDetails}
  </EntityView>;
}
