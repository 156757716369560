import {DeleteButton, EditButton, EntityView} from "@sivis/shared/components/entityView";
import {useAddressApi} from "./useAddressApi";
import {AddressInformationTab, AddressInformationTabProps} from "./addressInformationTab";
import AddressOrganizationalEntitiesTab, {
  AddressOrganizationalEntitiesTabProps
} from "./addressOrganizationalEntitiesTab";
import {
  INFORMATION_TAB_PATHNAME,
  ORGANIZATIONAL_ENTITIES_TAB_PATHNAME
} from "../components/routes/constants";
import {Outlet, useOutletContext} from "react-router-dom";
import {useCustomIntl} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";

export const AddressView = () => {
  const intl = useCustomIntl();
  const {
    defaultInput,
    address,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
  } = useAddressApi();

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };
  const organizationalEntitiesTab = {
    label: intl.format("fusion.organizationalEntity.list.name"),
    path: ORGANIZATIONAL_ENTITIES_TAB_PATHNAME
  };

  const tabs = isNew ? [informationTab] : [informationTab, organizationalEntitiesTab];

  const titleExisting = `${intl.format("fusion.address.information.titleExisting")}${address?.name}`;
  const title = isNew ? intl.format("fusion.address.information.titleNew") : titleExisting;
  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit"/>,
    <DeleteButton onClick={onDelete} key="delete"/>
  ];

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.address.error.notFound", intl.format) : undefined}>
    <Outlet context={{editMode, address, isNew, onSave, onCancel, onDelete, defaultInput}}/>
  </EntityView>;
};

export const AddressInformationTabWrapperComponent = () => {
  const props = useOutletContext<AddressInformationTabProps>();
  return <AddressInformationTab {...props} />;
};

export const AddressOrganizationalEntitiesTabWrapperComponent = () => {
  const {address} = useOutletContext<AddressOrganizationalEntitiesTabProps>();
  return <AddressOrganizationalEntitiesTab address={address}/>;
};
