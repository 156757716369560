import {StrictMode} from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/es/integration/react';
import {persistor, store} from './app/redux/store';
import {registerComponents} from './app/registry';
import {Routes} from './app/components/routes/routes';
import {LicenseInfo} from '@mui/x-license-pro';
import {createRoot} from "react-dom/client";

// set MUI pro license before any React rendering
LicenseInfo.setLicenseKey("acbd12e37509c5d8eb1d366a6384f40dTz05MTM4OCxFPTE3NDg0MjQ4OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

registerComponents();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <StrictMode>
        <Routes/>
      </StrictMode>
    </PersistGate>
  </Provider>
);
