import _ from "lodash";
import {useEffect, useRef} from "react";

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useDeepEffect = (effect: React.EffectCallback, deps: React.DependencyList) => {
  const wrapperObject = {...deps};

  const prevWrapperObject = usePrevious(wrapperObject);
  useEffect(() => {
    if (
      !_.isEqual(
        prevWrapperObject,
        wrapperObject
      )
    ) {
      effect();
    }
  }, [wrapperObject, prevWrapperObject]);
};

export const useDeepMemo = <TKey, TValue>(memoFn: () => TValue, key: TKey): TValue => {
  const ref = useRef<{ key: TKey; value: TValue }>();

  if (!ref.current || !_.isEqual(key, ref.current.key)) {
    ref.current = {key, value: memoFn()};
  }
  return ref.current.value;
};
