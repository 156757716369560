import { System, useGetSystemsQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";

export const useSystemsForAccountsApi = () => {
  const {data} = useGetSystemsQuery();
  const [systems, setSystems] = useState<System[]>([]);

  useEffect(() => {
    setSystems(
      (data?.systems?.edges ?? [])
      .filter(edge => edge?.node)
      .map(edge => edge!.node)
    );
  }, [data?.systems]);

  const getSystemById = (id: string): System | undefined =>
    systems.find(system => system && system.id === id) ?? undefined;

  return {
    systems,
    getSystemById
  };
};
