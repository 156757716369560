import {CursorBasedPageResult} from "@sivis/shared/components/entityView";
import {
  GeneratedIdentityTypes,
  IdentityType,
  searchIdentityTypesFilter,
  useGetIdentityTypesQuery,
} from "@sivis/identity/api";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";

export const useIdentityTypesPageApi = () => {
  const { filter, onSearch } = useListSearch(searchIdentityTypesFilter);

  const useIdentityTypesPageQuery = (cursor: string | null) => {
    const queryResult = useGetIdentityTypesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    });

    return {
      ...queryResult,
      refetch: queryResult.refetch,
    };
  };

  const parseIdentityTypesPageResult = (res?: GeneratedIdentityTypes.GetIdentityTypesQuery): CursorBasedPageResult<IdentityType> => {
    return parseCursorBasedPageResult('identityTypes', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useIdentityTypesPageQuery,
    parseIdentityTypesPageResult
  };
};
