import { EOwnershipType, SideFilterQueryType, System } from "@sivis/identity/api";
import { useTranslateColumns } from "@sivis/intl";
import { ListAddView } from "@sivis/shared/components/entityView";
import { addButton, removeButton } from "../membership/createMembershipForIdentity";
import { useSystemsPageApi } from "../system/useSystemsPageApi";
import { useSystemsSideFilter } from "./components/useSystemsSideFilter";
import { CreateOwnershipProps } from "./createOwnership";
import styles from "./ownership.module.scss";
import { isIdentity, systemOwnershipColumns } from "./ownershipUtils";

export const CreateOwnershipSystemsList = (props: CreateOwnershipProps) => {
  const {activeFilter, filterComponent} = useSystemsSideFilter(SideFilterQueryType.SYSTEMS);
  const {
    onSearch,
    useSystemsPageQuery,
    parseSystemsPageResult,
    pageSize
  } = useSystemsPageApi(activeFilter ?? undefined);
  const translatedColumns = useTranslateColumns(systemOwnershipColumns, "fusion.system.propertyName");

  const entityToTableRow = (entity: System, isSelected: boolean) => {
    return {
      id: entity.id,
      systemName: entity.systemName,
      systemType: entity.systemType?.systemTypeName,
      dataObject: entity,
      isNew: isSelected,
      action: isSelected ? removeButton : addButton
    };
  };

  const onAdd = (systems: System[]) => {
    props.onAdd(systems.map(system => ({
      id: props.selectedEntity.id + system.id,
      ownerId: props.selectedEntity.id,
      targetId: system.id,
      type: isIdentity(props.selectedEntity) ? EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP : EOwnershipType.ROLE_SYSTEM_OWNERSHIP,
      targetName: system.systemName
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={entityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useSystemsPageQuery}
      parseResult={parseSystemsPageResult}
      sideFilter={filterComponent}
      excludedItems={[]}
      tableType={SideFilterQueryType.SYSTEMS}
    />
  </div>;
};
