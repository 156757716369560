import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  OrganizationalEntity,
  OrganizationalEntityInput,
  organizationalEntityInputFactory,
  useCreateOrganizationalEntityMutation,
  useDeleteOrganizationalEntityMutation,
  useGetAddressesQuery,
  useGetOrganizationalEntityTypesQuery,
  useOrganizationalEntityQuery,
  useUpdateOrganizationalEntityMutation,
} from "@sivis/identity/api";
import {newId} from "../../common/newId";
import {EntityType, useSingleEditMode} from "../../components/breadcrumb/editModeContext";


interface OrganizationalEntityType {
  id: string;
  name: string;
  apiName?: string | null;
  description?: string | null;
}

interface Address {
  id: string;
  name: string;
  searchTerm?: string | null;
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  zipcode?: string | null;
  city?: string | null;
  region?: string | null;
  state?: string | null;
  country?: string | null;
  defaultTimeZone?: string | null;
  defaultLanguage?: string | null;
  phone?: string | null;
  phoneExtension?: string | null;
  mobilePhone?: string | null;
  fax?: string | null;
  faxExtension?: string | null;
  email?: string | null;
  standardCommunicationMethod?: string | null;
  description?: string | null;
}


export const useOrganizationalEntityApi = () => {
  const {id = newId} = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useOrganizationalEntityQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const {
    data: organizationalEntityTypesData,
    refetch: fetchOrganizationalEntityTypes
  } = useGetOrganizationalEntityTypesQuery({});
  const {
    data: addressesData,
    refetch: fetchAddresses
  } = useGetAddressesQuery({});
  const navigate = useNavigate();
  const [deleteOrganizationalEntity] = useDeleteOrganizationalEntityMutation();
  const [updateOrganizationalEntity] = useUpdateOrganizationalEntityMutation();
  const [createOrganizationalEntity] = useCreateOrganizationalEntityMutation();
  const [input, setInput] = useState<OrganizationalEntityInput>(organizationalEntityInputFactory());
  const [organizationalEntity, setOrganizationalEntity] = useState<OrganizationalEntity>();

  const [organizationalEntityTypes, setOrganizationalEntityTypes] = useState<OrganizationalEntityType[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const {editMode, toggleEditMode} = useSingleEditMode(EntityType.ORGANIZATIONAL_ENTITY, isNew);

  useEffect(() => {
    setInput(organizationalEntityInputFactory(data?.organizationalEntity));
    setOrganizationalEntity(data?.organizationalEntity ?? undefined);

    // Extract the organizationalEntitiesType array from organizationalEntityTypesData and filter out null values
    const extractedOrganizationalEntityTypes = (organizationalEntityTypesData?.organizationalEntityTypes?.edges ?? [])
    .filter(edge => edge?.node)
    .map(edge => ({
      id: edge!.node.id,
      name: edge!.node.name,
      apiName: edge!.node.apiName,
      description: edge!.node.description,
    }));

    setOrganizationalEntityTypes(extractedOrganizationalEntityTypes);

    const extractedAddresses = (addressesData?.addresses?.edges ?? [])
    .filter(edge => edge?.node)
    .map(edge => ({
      id: edge!.node.id,
      name: edge!.node.name,
      description: edge!.node.description
    }));

    setAddresses(extractedAddresses);

  }, [data?.organizationalEntity, organizationalEntityTypesData, addressesData]);

  const goBack = () => navigate("/organizational-entity");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {
    onSave = (value: Record<string, any>) => {
      createOrganizationalEntity({input: organizationalEntityInputFactory(value)}).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {
    onSave = (value: Record<string, any>) => {
      updateOrganizationalEntity({id, input: organizationalEntityInputFactory(value)})
      .then(() => {
        toggleEditMode();
        refetch();
      });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deleteOrganizationalEntity({id}).then(goBack);
    };
  }

  return {
    id,
    organizationalEntity,
    organizationalEntityTypes,
    addresses,
    defaultInput: input,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    fetchOrganizationalEntityTypes,
    fetchAddresses,
    editMode,
    toggleEditMode,
  };
};
