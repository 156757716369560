import {defaults, omit} from "lodash";
import {AsDefinition, AsdInput} from "../attributeSyncApi.types";
import {FilterOperator} from "@sivis/shared/api-utils";

const emptyAsdInput = (): AsdInput => ({
  name: '',
  priority: 0,
  systemId: crypto.randomUUID(),
  isActive: false,
  inheritingFromId: null,
  isPublished: false,
  asMappings: []
});

export const AsdInputFactory = (definition?: AsDefinition | AsdInput | null): AsdInput => {
  if (!definition) {
    return emptyAsdInput();
  }

  if (typeof definition.priority === 'string') {
    definition.priority = parseInt(definition.priority, 10);
  }
  return defaults({}, omit(definition, ['id', 'inheritingFrom', 'updatedAt']), emptyAsdInput());
}

export const searchAsdFilter = (searchStr: string) => ({
    operator: FilterOperator.OR,
  logicalValue: [
    {name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "description", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});
