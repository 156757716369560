import {
  executeAfterQueryFulfilled,
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import { RelationQueryType } from '../action';
import { api as generatedOeRelationApi } from './oeRelation.generated';
import { refetchTable } from '@sivis/shared/components/entityView';
import { resetGetOeRelationsActionCreator } from './oeRelationUtils';

export const oeRelationApi = generatedOeRelationApi.enhanceEndpoints({
  endpoints: {
    GetOeRelations: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("oeRelations"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs
    },
    CreateOeRelation: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetOeRelationsActionCreator,
        refetchTable(RelationQueryType.OE_RELATIONS)
      ])
    },
    UpdateOeRelation: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetOeRelationsActionCreator,
        refetchTable(RelationQueryType.OE_RELATIONS)
      ])
    },
    DeleteOeRelation: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetOeRelationsActionCreator,
        refetchTable(RelationQueryType.OE_RELATIONS)
      ])
    }
  }
});

export const {
  useGetOeRelationQuery,
  useLazyGetOeRelationQuery,
  useGetOeRelationsQuery,
  useLazyGetOeRelationsQuery,
  useCreateOeRelationMutation,
  useUpdateOeRelationMutation,
  useDeleteOeRelationMutation
} = oeRelationApi;
