import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  System,
  SystemInput,
  systemInputFactory,
  useCreateSystemMutation,
  useDeleteSystemMutation,
  useGetSystemQuery,
  useGetSystemTypesQuery,
  useUpdateSystemMutation
} from "@sivis/identity/api";
import {newId} from "../common/newId";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";

interface SystemType {
  id: string;
  systemTypeName: string;
  systemTypeDescription?: string | null;
}

export const useSystemApi = () => {

  const {id = newId} = useParams<{ id: string }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useGetSystemQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const {
    data: systemTypesData,
    refetch: fetchSystemTypes
  } = useGetSystemTypesQuery();
  const navigate = useNavigate();
  const [deleteSystem] = useDeleteSystemMutation();
  const [updateSystem] = useUpdateSystemMutation();
  const [createSystem] = useCreateSystemMutation();
  const [input, setInput] = useState<SystemInput>(systemInputFactory());
  const [system, setSystem] = useState<System>();
  const [systemTypes, setSystemTypes] = useState<SystemType[]>([]);
  const {editMode, toggleEditMode} = useSingleEditMode(EntityType.SYSTEM, isNew);

  useEffect(() => {
    setInput(systemInputFactory(data?.system));
    setSystem(data?.system ?? undefined);

    // Extract the systemsType array from systemTypesData and filter out null values
    const extractedSystemTypes = (systemTypesData?.systemTypes?.edges ?? [])
    .filter((edge): edge is { node: SystemType } => !!edge?.node)
    .map(edge => ({
      id: edge.node.id,
      systemTypeName: edge.node.systemTypeName,
      systemTypeDescription: edge.node.systemTypeDescription,
    }));

    setSystemTypes(extractedSystemTypes);

  }, [data?.system, systemTypesData]);

  const goBack = () => navigate("/system");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {
    onSave = (value: SystemInput | System) => {
      createSystem({input: systemInputFactory(value)}).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {
    onSave = (value: SystemInput | System) => {
      updateSystem({id, input: systemInputFactory(value)})
      .then(() => {
        toggleEditMode();
        refetch();
      });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deleteSystem({id}).then(goBack);
    };
  }


  return {
    id,
    system,
    systemTypes,
    defaultInput: input,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    fetchSystemTypes,
    editMode,
    toggleEditMode,
  };
};
