import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import { api as generatedAddressApi } from './address.generated';

export const addressApi = generatedAddressApi.enhanceEndpoints({
  addTagTypes: ['address'],
  endpoints: {
    GetAddresses: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("addresses"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ['address']
    },
    CreateAddress: { invalidatesTags: ["address"] },
    UpdateAddress: { invalidatesTags: ["address"] },
    DeleteAddress: { invalidatesTags: ["address"] },
  },
},
)

export const {
  useGetAddressQuery, useLazyGetAddressQuery,
  useGetAddressesQuery, useLazyGetAddressesQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation
} = addressApi;
