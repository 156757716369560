import {Permission, PermissionInput} from "@sivis/security/api";
import {defaults, omit} from "lodash";

const emptyPermissionInput = (): PermissionInput => ({
  name: '',
  description: ''
});

export const permissionInputFactory = (permission?: Permission | PermissionInput | null): PermissionInput => {
  if (!permission) {
    return emptyPermissionInput();
  }

  return defaults({}, omit(permission, ['id']), emptyPermissionInput());
}
