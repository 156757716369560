import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  UUID: any;
};

/** Represents the flow between one or many Fusion systems and the external system. */
export type AsDefinition = {
  __typename?: 'ASDefinition';
  /** The mappings for this definition. */
  asMappings?: Maybe<Array<AsMapping>>;
  /** The description of the definition. */
  description?: Maybe<Scalars['String']>;
  /** The unique identifier of the definition. */
  id: Scalars['UUID'];
  /** The definition that this definition inherits from. */
  inheritingFrom?: Maybe<AsDefinition>;
  inheritingFromId?: Maybe<Scalars['UUID']>;
  /** If the definition is active or not. */
  isActive?: Scalars['Boolean'];
  /** If the definition is published or not. */
  isPublished?: Scalars['Boolean'];
  /** The name of the definition. */
  name?: Maybe<Scalars['String']>;
  /** The priority of the definition. If the Identity is connected to multiple ASDefinitions either through the system or segments, the highest wins. */
  priority?: Maybe<Scalars['Int']>;
  /** The ID of the published definition. Will be empty if an ASDefinition was never saved and only existed as a draft. */
  publishedId?: Maybe<Scalars['UUID']>;
  /** The ID of the external system. */
  systemId: Scalars['UUID'];
  /** The date and time when the definition was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The user that last updated the definition. */
  updatedBy?: Maybe<Scalars['String']>;
};

export type AsDefinitionFilterInput = {
  and?: InputMaybe<Array<AsDefinitionFilterInput>>;
  asMappings?: InputMaybe<ListFilterInputTypeOfAsMappingFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  inheritingFrom?: InputMaybe<AsDefinitionFilterInput>;
  inheritingFromId?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isPublished?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AsDefinitionFilterInput>>;
  priority?: InputMaybe<IntOperationFilterInput>;
  publishedId?: InputMaybe<UuidOperationFilterInput>;
  systemId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  updatedBy?: InputMaybe<StringOperationFilterInput>;
};

export enum AsDirections {
  Export = 'EXPORT',
  Import = 'IMPORT',
  MatchMapping = 'MATCH_MAPPING',
  UniqueIdMapping = 'UNIQUE_ID_MAPPING'
}

export type AsDirectionsOperationFilterInput = {
  eq?: InputMaybe<AsDirections>;
  in?: InputMaybe<Array<AsDirections>>;
  neq?: InputMaybe<AsDirections>;
  nin?: InputMaybe<Array<AsDirections>>;
};

/** Represents the mapping between an attribute in Fusion and in an external system. */
export type AsMapping = {
  __typename?: 'ASMapping';
  /** The Attribute Synchronization Definition for this mapping. */
  asDefinition?: Maybe<AsDefinition>;
  asDefinitionId?: Scalars['UUID'];
  direction: AsDirections;
  draftAction?: DraftActions;
  /** The name of the field in the external system. */
  externalAttribute?: Maybe<Scalars['String']>;
  /** The type of the field in the external system. */
  fieldType?: Maybe<Scalars['String']>;
  /** The unique identifier of the mapping. */
  id: Scalars['UUID'];
  /** The name of the field in Fusion. */
  internalAttribute?: Maybe<Scalars['String']>;
  originalMappingId?: Maybe<Scalars['UUID']>;
  /** The priority of the mapping. */
  priority?: Maybe<Scalars['Int']>;
  /** The method of synchronization defined by an enumeration. */
  synchronizationMethod: AsMethods;
  /** The transformations to apply to the value before synchronization. */
  transformations?: Maybe<Array<Transformation>>;
};

export type AsMappingFilterInput = {
  and?: InputMaybe<Array<AsMappingFilterInput>>;
  asDefinition?: InputMaybe<AsDefinitionFilterInput>;
  asDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  direction?: InputMaybe<AsDirectionsOperationFilterInput>;
  draftAction?: InputMaybe<DraftActionsOperationFilterInput>;
  externalAttribute?: InputMaybe<StringOperationFilterInput>;
  fieldType?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  internalAttribute?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AsMappingFilterInput>>;
  originalMappingId?: InputMaybe<UuidOperationFilterInput>;
  priority?: InputMaybe<IntOperationFilterInput>;
  synchronizationMethod?: InputMaybe<AsMethodsOperationFilterInput>;
  transformations?: InputMaybe<ListFilterInputTypeOfTransformationFilterInput>;
};

export enum AsMethods {
  Create = 'CREATE',
  IgnorePreviousChanges = 'IGNORE_PREVIOUS_CHANGES',
  Update = 'UPDATE',
  UpdateAndCreate = 'UPDATE_AND_CREATE'
}

export type AsMethodsOperationFilterInput = {
  eq?: InputMaybe<AsMethods>;
  in?: InputMaybe<Array<AsMethods>>;
  neq?: InputMaybe<AsMethods>;
  nin?: InputMaybe<Array<AsMethods>>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

/** The payload for the ASDefinition type. */
export type AsDefinitionPayload = {
  __typename?: 'AsDefinitionPayload';
  asDefinition: AsDefinition;
};

/** A connection to a list of items. */
export type AsDefinitionsConnection = {
  __typename?: 'AsDefinitionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AsDefinitionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AsDefinition>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AsDefinitionsEdge = {
  __typename?: 'AsDefinitionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AsDefinition;
};

/** The payload for the ASMapping type. */
export type AsMappingPayload = {
  __typename?: 'AsMappingPayload';
  asMapping: AsMapping;
};

/** A connection to a list of items. */
export type AsMappingsConnection = {
  __typename?: 'AsMappingsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AsMappingsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AsMapping>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AsMappingsEdge = {
  __typename?: 'AsMappingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AsMapping;
};

export type AsdInput = {
  asMappings?: InputMaybe<Array<AsmInput>>;
  description?: InputMaybe<Scalars['String']>;
  inheritingFromId?: InputMaybe<Scalars['UUID']>;
  isActive: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  publishedId?: InputMaybe<Scalars['UUID']>;
  systemId: Scalars['UUID'];
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type AsmInput = {
  asDefinitionId: Scalars['UUID'];
  direction: AsDirections;
  externalAttribute: Scalars['String'];
  fieldType: Scalars['String'];
  id: Scalars['UUID'];
  internalAttribute: Scalars['String'];
  originalMappingId?: InputMaybe<Scalars['UUID']>;
  priority: Scalars['Int'];
  synchronizationMethod: AsMethods;
  transformations?: InputMaybe<Array<TransformationInput>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export enum DraftActions {
  Added = 'ADDED',
  Deleted = 'DELETED',
  None = 'NONE',
  Updated = 'UPDATED'
}

export type DraftActionsOperationFilterInput = {
  eq?: InputMaybe<DraftActions>;
  in?: InputMaybe<Array<DraftActions>>;
  neq?: InputMaybe<DraftActions>;
  nin?: InputMaybe<Array<DraftActions>>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfAsMappingFilterInput = {
  all?: InputMaybe<AsMappingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AsMappingFilterInput>;
  some?: InputMaybe<AsMappingFilterInput>;
};

export type ListFilterInputTypeOfTransformationFilterInput = {
  all?: InputMaybe<TransformationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TransformationFilterInput>;
  some?: InputMaybe<TransformationFilterInput>;
};

/** The mutation root for the API, that provides CRUD operations. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Adds a new Attribute Synchronization Definition */
  addASDefinition: AsDefinitionPayload;
  /** Adds a new Attribute Synchronization Mapping */
  addASMapping: AsMappingPayload;
  /** Adds a new System Field */
  addSystemField: SystemFieldPayload;
  /** Deletes an existing Attribute Synchronization Definition */
  deleteASDefinition: Scalars['UUID'];
  /** Deletes an existing Attribute Synchronization Mapping */
  deleteASMapping: Scalars['UUID'];
  /** Deletes an existing System Field */
  deleteSystemField: Scalars['UUID'];
  /** Discards a draft of an Attribute Synchronization Definition */
  discardDraftASDefinition: Scalars['UUID'];
  /** Publishes a draft of an Attribute Synchronization Definition */
  publishDraftASDefinition: AsDefinitionPayload;
  /** Saves a new draft of an Attribute Synchronization Definition */
  saveDraftASDefinition: AsDefinitionPayload;
  /** Updates an existing Attribute Synchronization Definition */
  updateASDefinition: AsDefinitionPayload;
  /** Updates an existing Attribute Synchronization Mapping */
  updateASMapping: AsMappingPayload;
  /** Updates an existing System Field */
  updateSystemField: SystemFieldPayload;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationAddAsDefinitionArgs = {
  input: AsdInput;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationAddAsMappingArgs = {
  input: AsmInput;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationAddSystemFieldArgs = {
  input: SystemFieldInput;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationDeleteAsDefinitionArgs = {
  id: Scalars['UUID'];
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationDeleteAsMappingArgs = {
  id: Scalars['UUID'];
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationDeleteSystemFieldArgs = {
  id: Scalars['UUID'];
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationDiscardDraftAsDefinitionArgs = {
  draftId: Scalars['UUID'];
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationPublishDraftAsDefinitionArgs = {
  draftId: Scalars['UUID'];
  input: AsdInput;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationSaveDraftAsDefinitionArgs = {
  id: Scalars['UUID'];
  input: AsdInput;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationUpdateAsDefinitionArgs = {
  id: Scalars['UUID'];
  input: AsdInput;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationUpdateAsMappingArgs = {
  id: Scalars['UUID'];
  input: AsmInput;
};


/** The mutation root for the API, that provides CRUD operations. */
export type MutationUpdateSystemFieldArgs = {
  id: Scalars['UUID'];
  input: SystemFieldInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** The query root for the API. */
export type Query = {
  __typename?: 'Query';
  asDefinition: AsDefinition;
  asDefinitions?: Maybe<AsDefinitionsConnection>;
  asMapping: AsMapping;
  asMappings?: Maybe<AsMappingsConnection>;
  draftForASDefinition?: Maybe<AsDefinition>;
  systemFieldsForSystem: Array<SystemField>;
};


/** The query root for the API. */
export type QueryAsDefinitionArgs = {
  id: Scalars['UUID'];
};


/** The query root for the API. */
export type QueryAsDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AsDefinitionFilterInput>;
};


/** The query root for the API. */
export type QueryAsMappingArgs = {
  id: Scalars['UUID'];
};


/** The query root for the API. */
export type QueryAsMappingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AsMappingFilterInput>;
};


/** The query root for the API. */
export type QueryDraftForAsDefinitionArgs = {
  id: Scalars['UUID'];
};


/** The query root for the API. */
export type QuerySystemFieldsForSystemArgs = {
  systemId: Scalars['UUID'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

/** Represents a field in Fusion or an external system. */
export type SystemField = {
  __typename?: 'SystemField';
  /** The name of the field. */
  field?: Maybe<Scalars['String']>;
  /** The unique identifier of the field. */
  id: Scalars['UUID'];
  /** The ID of the external system. Is an empty GUID for Fusion fields. */
  systemId: Scalars['UUID'];
};

export type SystemFieldInput = {
  field: Scalars['String'];
  systemId: Scalars['UUID'];
};

/** The payload for the SystemField type. */
export type SystemFieldPayload = {
  __typename?: 'SystemFieldPayload';
  systemField: SystemField;
};

export type Transformation = {
  __typename?: 'Transformation';
  id: Scalars['UUID'];
  kind: TransformationKind;
  mapping?: Maybe<AsMapping>;
  mappingId: Scalars['UUID'];
  value?: Maybe<Scalars['String']>;
};

export type TransformationFilterInput = {
  and?: InputMaybe<Array<TransformationFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  kind?: InputMaybe<TransformationKindOperationFilterInput>;
  mapping?: InputMaybe<AsMappingFilterInput>;
  mappingId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<TransformationFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type TransformationInput = {
  id: Scalars['UUID'];
  kind: TransformationKind | string;
  mappingId: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};

export enum TransformationKind {
  Lowercase = 'LOWERCASE',
  Trim = 'TRIM',
  Uppercase = 'UPPERCASE'
}

export type TransformationKindOperationFilterInput = {
  eq?: InputMaybe<TransformationKind>;
  in?: InputMaybe<Array<TransformationKind>>;
  neq?: InputMaybe<TransformationKind>;
  nin?: InputMaybe<Array<TransformationKind>>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ASDefinition: ResolverTypeWrapper<AsDefinition>;
  ASDefinitionFilterInput: AsDefinitionFilterInput;
  ASDirections: AsDirections;
  ASDirectionsOperationFilterInput: AsDirectionsOperationFilterInput;
  ASMapping: ResolverTypeWrapper<AsMapping>;
  ASMappingFilterInput: AsMappingFilterInput;
  ASMethods: AsMethods;
  ASMethodsOperationFilterInput: AsMethodsOperationFilterInput;
  ApplyPolicy: ApplyPolicy;
  AsDefinitionPayload: ResolverTypeWrapper<AsDefinitionPayload>;
  AsDefinitionsConnection: ResolverTypeWrapper<AsDefinitionsConnection>;
  AsDefinitionsEdge: ResolverTypeWrapper<AsDefinitionsEdge>;
  AsMappingPayload: ResolverTypeWrapper<AsMappingPayload>;
  AsMappingsConnection: ResolverTypeWrapper<AsMappingsConnection>;
  AsMappingsEdge: ResolverTypeWrapper<AsMappingsEdge>;
  AsdInput: AsdInput;
  AsmInput: AsmInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BooleanOperationFilterInput: BooleanOperationFilterInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  DraftActions: DraftActions;
  DraftActionsOperationFilterInput: DraftActionsOperationFilterInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  IntOperationFilterInput: IntOperationFilterInput;
  ListFilterInputTypeOfASMappingFilterInput: ListFilterInputTypeOfAsMappingFilterInput;
  ListFilterInputTypeOfTransformationFilterInput: ListFilterInputTypeOfTransformationFilterInput;
  Mutation: ResolverTypeWrapper<{}>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
  StringOperationFilterInput: StringOperationFilterInput;
  SystemField: ResolverTypeWrapper<SystemField>;
  SystemFieldInput: SystemFieldInput;
  SystemFieldPayload: ResolverTypeWrapper<SystemFieldPayload>;
  Transformation: ResolverTypeWrapper<Transformation>;
  TransformationFilterInput: TransformationFilterInput;
  TransformationInput: TransformationInput;
  TransformationKind: TransformationKind;
  TransformationKindOperationFilterInput: TransformationKindOperationFilterInput;
  UUID: ResolverTypeWrapper<Scalars['UUID']>;
  UuidOperationFilterInput: UuidOperationFilterInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ASDefinition: AsDefinition;
  ASDefinitionFilterInput: AsDefinitionFilterInput;
  ASDirectionsOperationFilterInput: AsDirectionsOperationFilterInput;
  ASMapping: AsMapping;
  ASMappingFilterInput: AsMappingFilterInput;
  ASMethodsOperationFilterInput: AsMethodsOperationFilterInput;
  AsDefinitionPayload: AsDefinitionPayload;
  AsDefinitionsConnection: AsDefinitionsConnection;
  AsDefinitionsEdge: AsDefinitionsEdge;
  AsMappingPayload: AsMappingPayload;
  AsMappingsConnection: AsMappingsConnection;
  AsMappingsEdge: AsMappingsEdge;
  AsdInput: AsdInput;
  AsmInput: AsmInput;
  Boolean: Scalars['Boolean'];
  BooleanOperationFilterInput: BooleanOperationFilterInput;
  DateTime: Scalars['DateTime'];
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  DraftActionsOperationFilterInput: DraftActionsOperationFilterInput;
  Int: Scalars['Int'];
  IntOperationFilterInput: IntOperationFilterInput;
  ListFilterInputTypeOfASMappingFilterInput: ListFilterInputTypeOfAsMappingFilterInput;
  ListFilterInputTypeOfTransformationFilterInput: ListFilterInputTypeOfTransformationFilterInput;
  Mutation: {};
  PageInfo: PageInfo;
  Query: {};
  String: Scalars['String'];
  StringOperationFilterInput: StringOperationFilterInput;
  SystemField: SystemField;
  SystemFieldInput: SystemFieldInput;
  SystemFieldPayload: SystemFieldPayload;
  Transformation: Transformation;
  TransformationFilterInput: TransformationFilterInput;
  TransformationInput: TransformationInput;
  TransformationKindOperationFilterInput: TransformationKindOperationFilterInput;
  UUID: Scalars['UUID'];
  UuidOperationFilterInput: UuidOperationFilterInput;
};

export type AsDefinitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ASDefinition'] = ResolversParentTypes['ASDefinition']> = {
  asMappings?: Resolver<Array<ResolversTypes['ASMapping']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  inheritingFrom?: Resolver<Maybe<ResolversTypes['ASDefinition']>, ParentType, ContextType>;
  inheritingFromId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  publishedId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AsMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ASMapping'] = ResolversParentTypes['ASMapping']> = {
  asDefinition?: Resolver<Maybe<ResolversTypes['ASDefinition']>, ParentType, ContextType>;
  asDefinitionId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  direction?: Resolver<ResolversTypes['ASDirections'], ParentType, ContextType>;
  draftAction?: Resolver<ResolversTypes['DraftActions'], ParentType, ContextType>;
  externalAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  internalAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalMappingId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  synchronizationMethod?: Resolver<ResolversTypes['ASMethods'], ParentType, ContextType>;
  transformations?: Resolver<Array<ResolversTypes['Transformation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AsDefinitionPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AsDefinitionPayload'] = ResolversParentTypes['AsDefinitionPayload']> = {
  asDefinition?: Resolver<ResolversTypes['ASDefinition'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AsDefinitionsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AsDefinitionsConnection'] = ResolversParentTypes['AsDefinitionsConnection']> = {
  edges?: Resolver<Maybe<Array<ResolversTypes['AsDefinitionsEdge']>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<ResolversTypes['ASDefinition']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AsDefinitionsEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AsDefinitionsEdge'] = ResolversParentTypes['AsDefinitionsEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ASDefinition'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AsMappingPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AsMappingPayload'] = ResolversParentTypes['AsMappingPayload']> = {
  asMapping?: Resolver<ResolversTypes['ASMapping'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AsMappingsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AsMappingsConnection'] = ResolversParentTypes['AsMappingsConnection']> = {
  edges?: Resolver<Maybe<Array<ResolversTypes['AsMappingsEdge']>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<ResolversTypes['ASMapping']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AsMappingsEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AsMappingsEdge'] = ResolversParentTypes['AsMappingsEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ASMapping'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addASDefinition?: Resolver<ResolversTypes['AsDefinitionPayload'], ParentType, ContextType, RequireFields<MutationAddAsDefinitionArgs, 'input'>>;
  addASMapping?: Resolver<ResolversTypes['AsMappingPayload'], ParentType, ContextType, RequireFields<MutationAddAsMappingArgs, 'input'>>;
  addSystemField?: Resolver<ResolversTypes['SystemFieldPayload'], ParentType, ContextType, RequireFields<MutationAddSystemFieldArgs, 'input'>>;
  deleteASDefinition?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationDeleteAsDefinitionArgs, 'id'>>;
  deleteASMapping?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationDeleteAsMappingArgs, 'id'>>;
  deleteSystemField?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationDeleteSystemFieldArgs, 'id'>>;
  discardDraftASDefinition?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationDiscardDraftAsDefinitionArgs, 'draftId'>>;
  publishDraftASDefinition?: Resolver<ResolversTypes['AsDefinitionPayload'], ParentType, ContextType, RequireFields<MutationPublishDraftAsDefinitionArgs, 'draftId' | 'input'>>;
  saveDraftASDefinition?: Resolver<ResolversTypes['AsDefinitionPayload'], ParentType, ContextType, RequireFields<MutationSaveDraftAsDefinitionArgs, 'id' | 'input'>>;
  updateASDefinition?: Resolver<ResolversTypes['AsDefinitionPayload'], ParentType, ContextType, RequireFields<MutationUpdateAsDefinitionArgs, 'id' | 'input'>>;
  updateASMapping?: Resolver<ResolversTypes['AsMappingPayload'], ParentType, ContextType, RequireFields<MutationUpdateAsMappingArgs, 'id' | 'input'>>;
  updateSystemField?: Resolver<ResolversTypes['SystemFieldPayload'], ParentType, ContextType, RequireFields<MutationUpdateSystemFieldArgs, 'id' | 'input'>>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  asDefinition?: Resolver<ResolversTypes['ASDefinition'], ParentType, ContextType, RequireFields<QueryAsDefinitionArgs, 'id'>>;
  asDefinitions?: Resolver<Maybe<ResolversTypes['AsDefinitionsConnection']>, ParentType, ContextType, Partial<QueryAsDefinitionsArgs>>;
  asMapping?: Resolver<ResolversTypes['ASMapping'], ParentType, ContextType, RequireFields<QueryAsMappingArgs, 'id'>>;
  asMappings?: Resolver<Maybe<ResolversTypes['AsMappingsConnection']>, ParentType, ContextType, Partial<QueryAsMappingsArgs>>;
  draftForASDefinition?: Resolver<Maybe<ResolversTypes['ASDefinition']>, ParentType, ContextType, RequireFields<QueryDraftForAsDefinitionArgs, 'id'>>;
  systemFieldsForSystem?: Resolver<Array<ResolversTypes['SystemField']>, ParentType, ContextType, RequireFields<QuerySystemFieldsForSystemArgs, 'systemId'>>;
};

export type SystemFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemField'] = ResolversParentTypes['SystemField']> = {
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemFieldPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemFieldPayload'] = ResolversParentTypes['SystemFieldPayload']> = {
  systemField?: Resolver<ResolversTypes['SystemField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transformation'] = ResolversParentTypes['Transformation']> = {
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['TransformationKind'], ParentType, ContextType>;
  mapping?: Resolver<ResolversTypes['ASMapping'], ParentType, ContextType>;
  mappingId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID';
}

export type Resolvers<ContextType = any> = {
  ASDefinition?: AsDefinitionResolvers<ContextType>;
  ASMapping?: AsMappingResolvers<ContextType>;
  AsDefinitionPayload?: AsDefinitionPayloadResolvers<ContextType>;
  AsDefinitionsConnection?: AsDefinitionsConnectionResolvers<ContextType>;
  AsDefinitionsEdge?: AsDefinitionsEdgeResolvers<ContextType>;
  AsMappingPayload?: AsMappingPayloadResolvers<ContextType>;
  AsMappingsConnection?: AsMappingsConnectionResolvers<ContextType>;
  AsMappingsEdge?: AsMappingsEdgeResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  SystemField?: SystemFieldResolvers<ContextType>;
  SystemFieldPayload?: SystemFieldPayloadResolvers<ContextType>;
  Transformation?: TransformationResolvers<ContextType>;
  UUID?: GraphQLScalarType;
};

