import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetResourceSetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetResourceSetQuery = { __typename?: 'Query', resourceSet?: { __typename?: 'ResourceSet', id: string, name: string, description?: string | null, resources?: { __typename?: 'ResourceConnection', edges: Array<{ __typename?: 'ResourceEdge', node: { __typename?: 'Resource', id: string, name: string } } | null> } | null } | null };

export type GetResourceSetWithResourcesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetResourceSetWithResourcesQuery = { __typename?: 'Query', resourceSet?: { __typename?: 'ResourceSet', id: string, resources?: { __typename?: 'ResourceConnection', edges: Array<{ __typename?: 'ResourceEdge', node: { __typename?: 'Resource', id: string, name: string, description?: string | null, resourceId?: string | null, resourceType?: { __typename?: 'ResourceType', id: string, name: string } | null, system?: { __typename?: 'System', id: string, systemName: string } | null } } | null> } | null } | null };

export type GetResourceSetsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetResourceSetsQuery = { __typename?: 'Query', resourceSets?: { __typename?: 'ResourceSetConnection', edges: Array<{ __typename?: 'ResourceSetEdge', node: { __typename?: 'ResourceSet', id: string, name: string, description?: string | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null, resources?: { __typename?: 'ResourceConnection', edges: Array<{ __typename?: 'ResourceEdge', node: { __typename?: 'Resource', id: string } } | null> } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type CreateResourceSetMutationVariables = Types.Exact<{
  input: Types.ResourceSetInput;
}>;


export type CreateResourceSetMutation = { __typename?: 'Mutation', createResourceSet: { __typename?: 'ResourceSet', id: string, name: string, description?: string | null } };

export type UpdateResourceSetMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.ResourceSetInput;
}>;


export type UpdateResourceSetMutation = { __typename?: 'Mutation', updateResourceSet: { __typename?: 'ResourceSet', id: string, name: string, description?: string | null } };

export type DeleteResourceSetMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteResourceSetMutation = { __typename?: 'Mutation', deleteResourceSet: { __typename?: 'ResourceSet', id: string, name: string, description?: string | null } };


export const GetResourceSetDocument = `
    query GetResourceSet($id: ID!) {
  resourceSet(id: $id) {
    id
    name
    description
    resources {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export const GetResourceSetWithResourcesDocument = `
    query GetResourceSetWithResources($id: ID!, $filter: FilterExpression) {
  resourceSet(id: $id) {
    id
    resources(filter: $filter) {
      edges {
        node {
          id
          name
          description
          resourceId
          resourceType {
            id
            name
          }
          system {
            id
            systemName
          }
        }
      }
    }
  }
}
    `;
export const GetResourceSetsDocument = `
    query GetResourceSets($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  resourceSets(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        name
        description
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        resources {
          edges {
            node {
              id
            }
          }
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const CreateResourceSetDocument = `
    mutation CreateResourceSet($input: ResourceSetInput!) {
  createResourceSet(input: $input) {
    id
    name
    description
  }
}
    `;
export const UpdateResourceSetDocument = `
    mutation UpdateResourceSet($id: ID!, $input: ResourceSetInput!) {
  updateResourceSet(id: $id, input: $input) {
    id
    name
    description
  }
}
    `;
export const DeleteResourceSetDocument = `
    mutation DeleteResourceSet($id: ID!) {
  deleteResourceSet(id: $id) {
    id
    name
    description
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetResourceSet: build.query<GetResourceSetQuery, GetResourceSetQueryVariables>({
      query: (variables) => ({ document: GetResourceSetDocument, variables })
    }),
    GetResourceSetWithResources: build.query<GetResourceSetWithResourcesQuery, GetResourceSetWithResourcesQueryVariables>({
      query: (variables) => ({ document: GetResourceSetWithResourcesDocument, variables })
    }),
    GetResourceSets: build.query<GetResourceSetsQuery, GetResourceSetsQueryVariables | void>({
      query: (variables) => ({ document: GetResourceSetsDocument, variables })
    }),
    CreateResourceSet: build.mutation<CreateResourceSetMutation, CreateResourceSetMutationVariables>({
      query: (variables) => ({ document: CreateResourceSetDocument, variables })
    }),
    UpdateResourceSet: build.mutation<UpdateResourceSetMutation, UpdateResourceSetMutationVariables>({
      query: (variables) => ({ document: UpdateResourceSetDocument, variables })
    }),
    DeleteResourceSet: build.mutation<DeleteResourceSetMutation, DeleteResourceSetMutationVariables>({
      query: (variables) => ({ document: DeleteResourceSetDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetResourceSetQuery, useLazyGetResourceSetQuery, useGetResourceSetWithResourcesQuery, useLazyGetResourceSetWithResourcesQuery, useGetResourceSetsQuery, useLazyGetResourceSetsQuery, useCreateResourceSetMutation, useUpdateResourceSetMutation, useDeleteResourceSetMutation } = injectedRtkApi;

