import { Entitlement, Resource, Role, useGetResourceQuery, useGetRoleQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";
import { useEntitlementApi } from "./useEntitlementApi";

export const useRoleResourceEntitlementApi = ({id, entitledId, targetId}: Entitlement) => {
  const {data: dataEntitled} = useGetRoleQuery({id: entitledId}, {refetchOnMountOrArgChange: true});
  const {data: dataTarget} = useGetResourceQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [entitled, setEntitled] = useState<Role>();
  const [target, setTarget] = useState<Resource>();

  useEffect(() => {
    setEntitled(dataEntitled?.role ?? undefined);
    setTarget(dataTarget?.resource ?? undefined);
  }, [dataEntitled?.role, dataTarget?.resource]);

  const {entitlement, onSave} = useEntitlementApi(id);

  return {
    entitlement,
    entitled,
    target,
    onSave
  }
};
