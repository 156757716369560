import {EOwnershipType, Ownership} from "@sivis/identity/api";
import {EntityDetailsProps, TabBar} from "@sivis/shared/components/entityView";
import {useCustomIntl} from "@sivis/intl";
import {OwnershipInformationTab} from "./ownershipInformationTab";
import {RelationEntityType} from "../components/popup/relationUtils";
import {useRoleResourceOwnershipApi} from "./api/useRoleResourceOwnershipApi";
import {useRoleResourceSetOwnershipApi} from "./api/useRoleResourceSetOwnershipApi";
import {useRoleSystemOwnershipApi} from "./api/useRoleSystemOwnershipApi";
import {useIdentityRoleOwnershipApi} from "./api/useIdentityRoleOwnershipApi";

export const RoleOwnershipDetails = (props: EntityDetailsProps<Ownership>) => {
  const intl = useCustomIntl();
  const resourceTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useRoleResourceOwnershipApi}
        ownerType={RelationEntityType.ROLE}
        targetType={RelationEntityType.RESOURCE}
      />
    }
  ]

  const resourceSetTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useRoleResourceSetOwnershipApi}
        ownerType={RelationEntityType.ROLE}
        targetType={RelationEntityType.RESOURCE_SET}
      />
    }
  ]

  const systemTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useRoleSystemOwnershipApi}
        ownerType={RelationEntityType.ROLE}
        targetType={RelationEntityType.SYSTEM}
      />
    }
  ]

  const identitiesTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useIdentityRoleOwnershipApi}
        ownerType={RelationEntityType.IDENTITY}
        targetType={RelationEntityType.ROLE}
      />
    }
  ]

  let tabs = resourceTabs;
  if (props.entity.type === EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP) {
    tabs = resourceSetTabs;
  } else if (props.entity.type === EOwnershipType.ROLE_SYSTEM_OWNERSHIP) {
    tabs = systemTabs;
  } else if (props.entity.type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP) {
    tabs = identitiesTabs;
  }

  return <TabBar tabs={tabs}/>;
}
