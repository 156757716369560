import {Outlet, RouteObject, useParams} from "react-router-dom";
import {
    AttributeSyncDefinitionView,
    AttributeSyncDefinitionFieldsTabWrapperComponent,
    AttributeSyncDefinitionInformationTabWrapperComponent
} from "../attributeSynchronizationDefinition/attributeSyncDefinitionView";
import { DynamicEntityViewBreadcrumb, intlSimpleBreadcrumbEditMode } from "../components/breadcrumb/breadcrumb";
import {
    ATTRIBUTE_SYNCHRONIZATION_DEFINITION_TAB_PATHNAME,
    FIELDS_TAB_PATHNAME,
    INFORMATION_TAB_PATHNAME
} from "../components/routes/constants";
import { useGetAttributeSyncDefinitionQuery } from "@sivis/attributesync/api";
import { EntityType } from "../components/breadcrumb/editModeContext";

export const useGetAsDefinitionName = (id: string, isNew: boolean) => {
    const { asDefinitionId } = useParams<{ asDefinitionId: string }>();
    const isNewValue = isNew;
    isNew = asDefinitionId === "new" ? isNewValue : asDefinitionId === "new";
    const { data } = useGetAttributeSyncDefinitionQuery({ id: asDefinitionId }, { skip: isNew });
    return data?.asDefinition?.name ?? "";
};

export const attributeSyncDefinitionRoutes: RouteObject = {
    path: ATTRIBUTE_SYNCHRONIZATION_DEFINITION_TAB_PATHNAME,
    element: <Outlet />,
    handle: {
        crumb: intlSimpleBreadcrumbEditMode({ 
            intlId: "fusion.attributesynchronizationdefinition.list.name", 
            entity: EntityType.ATTRIBUTE_SYNC_DEFINITION, 
            intlIdEdit: "fusion.attributesynchronizationdefinition.list.name"
        })
    },
    children: [
        {
            path: ":asDefinitionId",
            element: <Outlet />,
            handle: {
                crumb: DynamicEntityViewBreadcrumb({
                    useGetEntityName: useGetAsDefinitionName,
                    pathSuffix: "/" + INFORMATION_TAB_PATHNAME
                })
            },
            children: [
                {
                    index: true,
                    element: <AttributeSyncDefinitionView />,
                },
                {
                    element: <AttributeSyncDefinitionView />,
                    children: [
                        {
                            path: INFORMATION_TAB_PATHNAME,
                            element: <AttributeSyncDefinitionInformationTabWrapperComponent />,
                            handle: {
                                crumb: intlSimpleBreadcrumbEditMode({ 
                                    intlId: "fusion.breadcrumb.information",
                                    intlIdEdit: "fusion.breadcrumb.information.edit",
                                    entity: EntityType.ATTRIBUTE_SYNC_DEFINITION 
                                })
                            }
                        },
                        {
                            path: FIELDS_TAB_PATHNAME,
                            element: <AttributeSyncDefinitionFieldsTabWrapperComponent />,
                            handle: {
                                crumb: intlSimpleBreadcrumbEditMode({ 
                                    intlId: "fusion.attributesynchronizationdefinition.fields",
                                    intlIdEdit: "fusion.attributesynchronizationdefinition.fields.edit",
                                    entity: EntityType.ATTRIBUTE_SYNC_DEFINITION 
                                })
                            }
                        }
                    ]
                }
            ]
        },
    ]
};