import { createContext, useCallback, useContext, useEffect, useState } from 'react';

export enum EntityType {
  PERMISSION = "PERMISSION",
  IDENTITY = "IDENTITY",
  ACCOUNT = "ACCOUNT",
  ROLE = "ROLE",
  RESOURCE = "RESOURCE",
  RESOURCE_SET = "RESOURCE_SET",
  SYSTEM = "SYSTEM",
  ORGANIZATIONAL_ENTITY = "ORGANIZATIONAL_ENTITY",
  ORGANIZATIONAL_ENTITY_TYPE = "ORGANIZATIONAL_ENTITY_TYPE",
  ADDRESS = "ADDRESS",
  IDENTITY_TYPE = "IDENTITY_TYPE",
  ACCOUNT_TYPE = "ACCOUNT_TYPE",
  FIELD_DEFINITION = "FIELD_DEFINITION",
  ORGANIZATIONAL_ENTITY_TYPE_RELATION = "ORGANIZATIONAL_ENTITY_TYPE_RELATION",
  ATTRIBUTE_SYNC_DEFINITION = "ATTRIBUTE_SYNC_DEFINITION",
  ORGANIZATIONAL_ENTITY_RELATION = 'ORGANIZATIONAL_ENTITY_RELATION',
  CUSTOM_RULE = 'CUSTOM_RULE',
}

export const EditModeContext = createContext({
  editMode: new Map<string, boolean>(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEditMode: (entity: EntityType, edit: boolean) => { }
});

export const useCreateEditModeContext = () => {
  const [editMode, setEditMode] = useState(new Map<EntityType, boolean>());

  const setEditModeForEntity = (entity: EntityType, edit: boolean) => {
    setEditMode(new Map(editMode.set(entity, edit)));
  };

  return { editMode, setEditMode: setEditModeForEntity };
};

export const useSingleEditMode = (entity: EntityType, startValue?: boolean) => {
  const { editMode, setEditMode } = useContext(EditModeContext);
  const thisEditMode = editMode.get(entity) ?? false;

  useEffect(() => {
    if (startValue !== undefined) {
      setEditMode(entity, startValue);
    }
  }, []);

  const toggleEditMode = useCallback(() => {
    setEditMode(entity, !thisEditMode);
  }, [thisEditMode]);

  return {
    editMode: thisEditMode,
    toggleEditMode
  }
};
