import {useEffect} from "react";
import {LoggingService} from "@sivis/shared/utils";

export interface MountTesterProps {
  label?: string;
}

export const useMountTester = (props?: MountTesterProps) => {
  const label = props?.label ? `MountTester - ${props.label}` : "MountTester";

  useEffect(() => {
    LoggingService.trace("post-mount", label);
    return () => {
      LoggingService.trace("destroy", label);
    }
  }, []);
};

export const MountTester = (props: MountTesterProps) => {
  useMountTester(props);
  return null;
};

