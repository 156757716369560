import { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import styles from '../form/form.module.scss';
import { ControllerRenderProps } from 'react-hook-form';

interface AutocompleteFieldProps {
  searchFunction: (searchTerm: string) => any;
  fieldProps: ControllerRenderProps<Record<string, any>, string>;
  disableRefetch: boolean;
  setDisableRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  searchResults: any;
  loadingEnities: boolean;
  fieldType?: string;
  currentValue?: {
    id: string;
    label: string;
  };
}


export const ModifiedAutocompleteField: React.FC<AutocompleteFieldProps> = ({
                                                               searchFunction,
                                                               fieldProps,
                                                               setDisableRefetch,
                                                               searchResults,
                                                               loadingEnities,
                                                               fieldType,
                                                               currentValue,
                                                             }) => {
  const isSelecting = useRef<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(
    currentValue ? { value: currentValue.id, label: currentValue.label } : null
  );

  const hasSetSelectedOptionFromCurrentValue = useRef(false);

  useEffect(() => {
    if (currentValue && !hasSetSelectedOptionFromCurrentValue.current) {
      const option = { value: currentValue.id, label: currentValue.label };
      setSelectedOption(option);
      // Ensure the form value is set
      fieldProps.onChange(currentValue.id);
      hasSetSelectedOptionFromCurrentValue.current = true;
    }
  }, [currentValue, fieldProps]);


  // Ensure form value is always synced with selected option
  useEffect(() => {
    if (selectedOption && fieldProps.value !== selectedOption.value) {
      fieldProps.onChange(selectedOption.value);
    }
  }, [selectedOption, fieldProps]);

  const handleInputChange = useCallback(
    (_: any, newInputValue: string, reason: string) => {
      setInputValue(newInputValue);
      if (reason === 'input' && !isSelecting.current) {
        setDisableRefetch(false);
        searchFunction(newInputValue);
      }
      isSelecting.current = false;
    },
    [setDisableRefetch, searchFunction]
  );

  const getOptions = () => {
    const searchedOptions =
      fieldType === 'identity'
        ? searchResults?.identities?.edges
      ?.filter((edge: { node: any }) => edge !== null)
      .map(
        (edge: {
          node: {
            id: string;
            firstName?: string;
            lastName: string;
            primaryEmail?: string;
          };
        }) => ({
          value: edge.node.id,
          label: `${edge.node.firstName ?? ''} ${edge.node.lastName} (${
            edge.node.primaryEmail ?? 'No email'
          })`,
        })
      ) || []
        : fieldType === 'system'
          ? searchResults?.systems?.edges
        ?.filter((edge: { node: any }) => edge !== null)
        .map(
          (edge: {
            node: {
              id: string;
              systemName: string;
            };
          }) => ({
            value: edge.node.id,
            label: edge.node.systemName,
          })
        ) || []
          : [];

    // Include current selection in options if it exists and isn't already in the list
    if (
      selectedOption &&
      !searchedOptions.some((opt: { value: string }) => opt.value === selectedOption.value)
    ) {
      return [selectedOption, ...searchedOptions];
    }

    return searchedOptions;
  };

  const options = getOptions();

  return (
    <Autocomplete
      options={options}
      loading={loadingEnities}
      onChange={(_, value) => {
        isSelecting.current = true;
        setSelectedOption(value);
        fieldProps.onChange(value ? value.value : null);
        setDisableRefetch(true);
      }}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Select ${fieldType ? fieldType.charAt(0).toUpperCase() + fieldType.slice(1) : ''}`}
          variant="outlined"
          className={styles.input}
        />
      )}
      value={selectedOption}
      defaultValue={
        currentValue ? { value: currentValue.id, label: currentValue.label } : null
      }
    />
  );
};
