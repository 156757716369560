import {Identity, Role, useGetIdentityRoleMembershipsQuery} from "@sivis/identity/api";
import {FilterOperator} from "@sivis/shared/api-utils";

// TODO: Existing memberships should be filtered out in backend

export const useGetIdentitiesForRoleApi = (roleId: string) => {
  const roleFilter = {name: "id.roleId", operator: FilterOperator.EQ, uuidValue: roleId};
  const {data} = useGetIdentityRoleMembershipsQuery({filter: roleFilter}, {skip: !roleId});
  const identities: Identity[] = data?.identityRoleMemberships?.edges
  .filter(edge => edge?.node.identity !== undefined)
  .map(edge => edge!.node.identity) ?? [];

  return {
    identities
  };
};

export const useGetRolesForIdentityApi = (identityId: string) => {
  const identityFilter = {
    name: "id.identityId",
    operator: FilterOperator.EQ,
    uuidValue: identityId
  };
  const {data} = useGetIdentityRoleMembershipsQuery({filter: identityFilter}, {skip: !identityId});
  const roles: Role[] = data?.identityRoleMemberships?.edges
  .filter(edge => edge?.node.role !== undefined)
  .map(edge => edge!.node.role) ?? [];

  return {
    roles
  };
};
