import {
  FilterEntry,
  FilterExpression,
  SideFilterQueryType,
  useGetIdentityCreationDatesQuery,
  useGetIdentityUpdateDatesQuery
} from '@sivis/identity/api';
import { placeholderFilter } from '../../utils/filterUtils';
import { useEffect, useState } from 'react';
import { useCustomIntl } from '@sivis/intl';
import { useDispatch } from 'react-redux';
import { resetTable, SideFilterNew } from '@sivis/shared/components/entityView';


const buildFilterGroup = (id: string, filters: FilterEntry[], title: string, translate: (id: string) => string) => ({
  id: id,
  title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: translate("fusion.general." + entry.id),
    count: entry.count,
    filter: entry.filter ?? placeholderFilter
  }))
});

export const useIdentitiesSideFilter = (queryType: SideFilterQueryType) => {
  const [filter, setFilter] = useState<FilterExpression | null>(null);
  const [creationDatesFilters, setCreationDatesFilters] = useState<FilterEntry[]>([]);
  const [updateDatesFilters, setUpdateDatesFilters] = useState<FilterEntry[]>([]);
  const intl = useCustomIntl();

  const {data: dataIdentityCreationDates} = useGetIdentityCreationDatesQuery();

  const {data: dataIdentityUpdateDates} = useGetIdentityUpdateDatesQuery();

  useEffect(() => {
    const newCreationDatesFilter = (dataIdentityCreationDates?.identityCreationDates.filter(entry => entry != null) ?? []) as FilterEntry[];
    setCreationDatesFilters(newCreationDatesFilter);

    const newUpdateDatesFilter = (dataIdentityUpdateDates?.identityUpdateDates?.filter(entry => entry !== null) ?? []) as FilterEntry[];
    setUpdateDatesFilters(newUpdateDatesFilter);
  }, [dataIdentityCreationDates?.identityCreationDates, dataIdentityUpdateDates?.identityUpdateDates]);

  const dispatch = useDispatch();
  const onSetFilter = (newFilter: FilterExpression | null) => {
    dispatch(resetTable(queryType)());
    setFilter(newFilter);
  }

  const identitiesFilter = <SideFilterNew
    title={(intl.format("fusion.general.filter")).toUpperCase()}
    onFilterChange={onSetFilter}
    filterGroups={[
      buildFilterGroup(
        "createdAt",
        creationDatesFilters,
        intl.format("fusion.general.filter.created"),
        intl.format
      ),
      buildFilterGroup(
        "updatedAt",
        updateDatesFilters,
        intl.format("fusion.general.filter.changed"),
        intl.format
      )
    ]}
  />;

  return {
    activeFilter: filter,
    filterComponent: identitiesFilter
  }
}
