import { AttributeRowConfig, AttributeRowProperty } from './attributeRowConfig';

export const splitConfigsInTwoColumns = (configs: AttributeRowConfig[]) => {
  return [
    configs.filter((_, index) => index % 2 === 0),
    configs.filter((_, index) => index % 2 === 1)
  ]
};

export const splitConfigsInThreeColumns = (configs: AttributeRowConfig[]) => {
  return [
    configs.filter((_, index) => index % 3 === 0),
    configs.filter((_, index) => index % 3 === 1),
    configs.filter((_, index) => index % 3 === 2)
  ]
};

export const getSingleProperty = (property: AttributeRowProperty) =>
  Array.isArray(property) ? property[0]?.name : property;

