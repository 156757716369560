import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./popup.module.scss";
import React, {PropsWithChildren} from "react";

type StyleType = "message" | "edit" | "confirm";

interface PopupProps {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  primaryTitle: string;
  secondaryTitle?: React.ReactNode;
  hideButtons?: boolean;
  styleType?: StyleType;
}

const styleClassMap = {
  "edit": styles.editPopup,
  "message": styles.messagePopup,
  "confirm": styles.confirmPopup
};

export const Popup = (props: PropsWithChildren<PopupProps>) => {
  const confirmButton = !props.hideButtons && props.onConfirm ?
    <Button autoFocus onClick={props.onConfirm} variant="contained">
      Confirm
    </Button> : null;

  const closeButton = !props.hideButtons && props.onClose ?
    <Button onClick={props.onClose} variant="outlined">
      Cancel
    </Button> : null;

  const styleClass = props.styleType ? styleClassMap[props.styleType] : "";

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg" className={styleClass}>
      <DialogTitle className={styles.header}>
        <div className={styles.title}>
          <div>{props.primaryTitle}</div>
          <div>{props.secondaryTitle}</div>
        </div>
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          className={styles.closeIcon}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.popupContent}>
        {props.children}
      </DialogContent>
      {confirmButton || closeButton ? <DialogActions>
        {confirmButton}
        {closeButton}
      </DialogActions> : null}
    </Dialog>
  );
};
