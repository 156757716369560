import { FilterEntry, FilterExpression, SideFilterQueryType, useGetResourceTypeFilterEntriesQuery } from '@sivis/identity/api';
import { useCustomIntl } from '@sivis/intl';
import { resetTable, SideFilterNew } from '@sivis/shared/components/entityView';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { compareFilterEntries } from './useOwnershipsSideFilter';

const buildResourceTypeFilterGroup = (filters: FilterEntry[], title: string) => ({
  id: "resourceType",
  title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: entry.text ?? "",
    count: entry.count,
    filter: {
      name: "resourceType.id",
      operator: "EQ",
      uuidValue: entry.id
    }
  }))
});

export const useResourcesSideFilter = (queryType: SideFilterQueryType) => {
  const [filter, setFilter] = useState<FilterExpression | null>(null);
  const [resourceTypeFilters, setResourceTypeFilters] = useState<FilterEntry[]>([]);
  const intl = useCustomIntl();

  const {data: dataResourceTypeFilters} = useGetResourceTypeFilterEntriesQuery();

  useEffect(() => {
    const newResourceTypeFilters = (dataResourceTypeFilters?.resourceTypeFilterEntries?.filter(entry => entry != null) ?? []) as FilterEntry[];
    newResourceTypeFilters.sort(compareFilterEntries);
    setResourceTypeFilters(newResourceTypeFilters);
  }, [dataResourceTypeFilters?.resourceTypeFilterEntries]);

  const dispatch = useDispatch();
  const onSetFilter = (newFilter: FilterExpression | null) => {
    dispatch(resetTable(queryType)());
    setFilter(newFilter);
  }

  const resourcesFilter = <SideFilterNew
    title={(intl.format("fusion.general.filter")).toUpperCase()}
    onFilterChange={onSetFilter}
    filterGroups={[
      buildResourceTypeFilterGroup(resourceTypeFilters, intl.format("fusion.resource.propertyName.resourceType"))
    ]}
  />;

  return {
    activeFilter: filter,
    filterComponent: resourcesFilter
  }
}

