import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Entitlement,
  ExtendedIdentityEntitlement,
  ExtendedResourceEntitlement,
  ExtendedRoleEntitlement,
  Identity,
  identityName,
  Resource,
  Role,
  Validity
} from "@sivis/identity/api";
import { CustomIntlType, FormatMessageFunction, useCustomIntl } from "@sivis/intl";
import { EntityTableRow } from "@sivis/shared/components/entityView";
import { FlagDefinition, Flags, validityIcon } from "../components/flag/flags";
import { addButton, removeButton } from "../membership/createMembershipForIdentity";
import { dateDiffInDays, getValidityFieldConfigs, showValidityFlag } from "../utils/validityUtils";

export const useEntitlementMessages = (): {
  deleteEntitlementMessage: string;
  addResourceMessage: string;
  addRoleMessage: string;
  addSystemMessage: string;
} => {
  const intl = useCustomIntl();
  const deleteEntitlementMessage = intl.format("fusion.entitlement.message.deleteEntitlement");
  const addResourceMessage = intl.format("fusion.entitlement.message.addResource");
  const addRoleMessage = intl.format("fusion.entitlement.message.addRole");
  const addSystemMessage = intl.format("fusion.entitlement.message.addSystem");

  return {deleteEntitlementMessage, addResourceMessage, addRoleMessage, addSystemMessage};
}

export const entitlementIdentityName = (identity: ExtendedIdentityEntitlement) => {
  return identity.identityFirstName ? identity.identityFirstName + " " + identity.identityLastName : identity.identityLastName;
};

export const parseDateString = (date: string | null, placeholder?: string) => {
  const placeholderText = placeholder ?? "";
  return date ? new Date(date).toLocaleDateString() : placeholderText;
}

export const resourceEntitlementColumns: GridColDef[] = [
  {
    field: "resourceName",
    flex: 1
  },
  {
    field: "resourceTypeName",
    flex: 1
  },
  {
    field: "context",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const getResourceEntitlementColumnsWithFlags = () => {
  const columnsWithFlags = [...resourceEntitlementColumns];
  columnsWithFlags.splice(resourceEntitlementColumns.length - 1, 0, {
    field: "flags",
    flex: 1,
    renderCell: params => params.row.flags
  });
  return columnsWithFlags;
};

export const identityEntitlementColumns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "email",
    flex: 1
  },
  {
    field: "accounts",
    flex: 1
  },
  {
    field: "flags",
    flex: 1,
    renderCell: params => params.row.flags
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const addIdentityEntitlementColumns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "accounts",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const roleEntitlementColumns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "flags",
    flex: 1,
    renderCell: params => params.row.flags
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const addRoleEntitlementColumns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "description",
    flex: 1
  },
  {
    field: "identities",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const systemEntitlementColumns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "flags",
    flex: 1,
    sortable: false,
    renderCell: params => params.row.flags
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const isEntitlement = (value: Record<string, any>): value is Entitlement => {
  return value['entitledId'] && value['targetId'];
};

export interface RoleEntitlementOption extends Role {
  targetId: string;
}

export const roleToOption = (role: Role, targetId: string): RoleEntitlementOption => ({
  ...role,
  targetId
});

export const renderRoleOption = (role?: RoleEntitlementOption) => {
  return role ? role.name : "";
}

export const roleOptionToEntitlement = (option: RoleEntitlementOption): ExtendedRoleEntitlement => ({
  id: option.id + option.targetId,
  entitledId: option.id,
  targetId: option.targetId,
  type: "",
  roleName: option.name
});

interface EntitlementFlagParams {
  validity?: Validity | null;
}

export const getEntitlementFlags = (params: EntitlementFlagParams, format: FormatMessageFunction) => {
  const flags: FlagDefinition[] = [];
  if (showValidityFlag(params.validity)) {
    flags.push({
      text: format("fusion.membership.expiresIn", {expiresIn: dateDiffInDays(new Date(), new Date(params.validity?.validTo ?? ""))}),
      icon: validityIcon
    });
  }

  if (flags.length) {
    return <Flags flags={flags}/>
  }
  return null;
};

const generalEntitlementToTableRow = (
  entity: Entitlement,
  isNew: boolean,
  onEdit?: (entity: Entitlement) => void,
  onDelete?: (entity: Entitlement) => void): EntityTableRow => {
  return {
    id: entity.id,
    isNew: isNew,
    action: <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onEdit?.(entity);
        }}
        aria-label="edit">
        <EditIcon/>
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onDelete?.(entity);
        }}
        aria-label="delete">
        <ClearIcon/>
      </IconButton>
    </Box>
  };
};

export const resourceEntitlementToTableRow = (
  entity: ExtendedResourceEntitlement,
  isNew: boolean,
  onEdit?: (entity: ExtendedResourceEntitlement) => void,
  onDelete?: (entity: ExtendedResourceEntitlement) => void): EntityTableRow => {
  return {
    ...generalEntitlementToTableRow(entity, isNew, onEdit, onDelete),
    resourceName: entity.resourceName,
    resourceTypeName: entity.resourceTypeName,
    context: entity.context
  };
};

export const identityEntitlementToTableRow = (
  entity: ExtendedIdentityEntitlement,
  isNew: boolean,
  onEdit?: (entity: ExtendedIdentityEntitlement) => void,
  onDelete?: (entity: ExtendedIdentityEntitlement) => void): EntityTableRow => {
  return {
    ...generalEntitlementToTableRow(entity, isNew, onEdit, onDelete),
    name: entitlementIdentityName(entity),
    email: entity.identityPrimaryEmail,
    accounts: ""
  };
};

export const roleEntitlementToTableRow = (
  entity: ExtendedRoleEntitlement,
  isNew: boolean,
  onEdit?: (entity: ExtendedRoleEntitlement) => void,
  onDelete?: (entity: ExtendedRoleEntitlement) => void): EntityTableRow => {
  return {
    ...generalEntitlementToTableRow(entity, isNew, onEdit, onDelete),
    name: entity.roleName
  };
};

export const mapTableRowToEntitlement = (rowId: string, entitlement: Entitlement) => rowId === entitlement.id;


export const createEntitlementFormConfigs = (translate: CustomIntlType["format"]) => {
  return [
    getValidityFieldConfigs(translate)
  ];
}

const concatElement = (e1: JSX.Element, e2: JSX.Element) => <div>{e1}{e2}</div>;

const formatBoldText = (prefix: string, boldText: string, suffix: string) =>
  <span>{prefix}<strong>{boldText}</strong>{suffix}</span>;

const getDateElement = (entitlement: Entitlement) =>
  <span><strong>{parseDateString(entitlement.validity?.validFrom ?? null)}</strong>-&nbsp;
    <strong>{parseDateString(entitlement.validity?.validTo ?? null)}</strong></span>;

export const getIdentityResourceEntitlementDescription = (
  entitlement: Entitlement,
  identityName: string,
  numAccounts: number,
  resourceName: string,
  translateMsg: FormatMessageFunction
): JSX.Element => {
  const prefix = `${identityName} (${numAccounts} ${translateMsg("fusion.entitlement.resourceEntitlement.prefixAccounts")})${translateMsg("fusion.entitlement.resourceEntitlement.has")} `;
  return getEntitlementDescription(entitlement, prefix, resourceName, translateMsg);
};

export const getRoleResourceEntitlementDescription = (
  entitlement: Entitlement,
  roleName: string,
  resourceName: string,
  translateMsg: FormatMessageFunction
): JSX.Element => {
  const prefix = `${translateMsg("fusion.entitlement.resourceEntitlement.prefix")}${roleName}${translateMsg("fusion.entitlement.resourceEntitlement.have")} `;
  return getEntitlementDescription(entitlement, prefix, resourceName, translateMsg);
};

export const getRoleSystemEntitlementDescription = (
  entitlement: Entitlement,
  roleName: string,
  systemName: string,
  translateMsg: FormatMessageFunction
): JSX.Element => {
  const prefix = `${translateMsg("fusion.entitlement.systemEntitlement.prefix")}${roleName}${translateMsg("fusion.entitlement.resourceEntitlement.have")} `;
  return getEntitlementDescription(entitlement, prefix, systemName, translateMsg);
}

const getEntitlementDescription = (entitlement: Entitlement, prefix: string, targetName: string, translateMsg: FormatMessageFunction) => {
  const bold = translateMsg("fusion.entitlement.entitlementForm.access");
  const suffix = ` ${translateMsg("fusion.entitlement.entitlementForm.to")} ${targetName}${translateMsg("fusion.entitlement.resourceEntitlement.from")} `;
  return concatElement(formatBoldText(prefix, bold, suffix), getDateElement(entitlement));
};

export const resourceEntityToTableRow = (entity: Resource, isSelected: boolean) => {
  return {
    id: entity.id,
    resourceName: entity.name,
    resourceTypeName: entity.resourceType?.name ?? "",
    context: "",
    dataObject: entity,
    isNew: isSelected,
    action: isSelected ? removeButton : addButton
  };
};

export const identityEntityToTableRow = (entity: Identity, isSelected: boolean) => {
  return {
    id: entity.id,
    name: identityName(entity),
    accounts: entity.accountsCount,
    dataObject: entity,
    isNew: isSelected,
    action: isSelected ? removeButton : addButton
  };
};

export const roleEntityToTableRow = (entity: Role, isSelected: boolean) => {
  return {
    id: entity.id,
    name: entity.name,
    description: entity.description,
    identities: entity.identitiesCount,
    dataObject: entity,
    isNew: isSelected,
    action: isSelected ? removeButton : addButton
  };
};
