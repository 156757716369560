import {
  FilterExpression,
  GeneratedOetRelationTypes,
  OetRelation,
  OrganizationalEntityType,
  useGetOetRelationsQuery,
} from '@sivis/identity/api';
import { FilterOperator } from "@sivis/shared/api-utils";
import { CursorBasedPageResult } from "@sivis/shared/components/entityView";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../../common/parsePageResult";


export const useOetRelationsForOrganizationalEntityTypePageApi = (organizationalEntityType: {
  organizationalEntityType: OrganizationalEntityType | undefined
}) => {

  const ownerTypeOrAssignedTypeFilter: FilterExpression = {
    logicalValue: [
      {
        logicalValue:
          [
            {
              uuidValue: organizationalEntityType.organizationalEntityType?.id,
              operator: FilterOperator.EQ,
              name: "assignedType.id"
            },
            {
              stringValue: "parent",
              operator: FilterOperator.CONTAINS,
              name: "oetRelationType.name"
            },
          ],
        operator: FilterOperator.AND
      },
      {
        uuidValue: organizationalEntityType.organizationalEntityType?.id,
        operator: FilterOperator.EQ,
        name: "ownerType.id"
      },
    ],
    operator: FilterOperator.OR
  }


  const useOetRelationsPageQuery = (cursor: string | null) => {

    const queryResult = useGetOetRelationsQuery(
      {
        first: DEFAULT_PAGE_SIZE,
        after: cursor,
        sort: DEFAULT_ENTITY_SORT,
        filter: ownerTypeOrAssignedTypeFilter
      }
    );

    return {
      ...queryResult,
      refetch: queryResult.refetch,
    };
  };

  const parseOetRelationsPageResult = (res?: GeneratedOetRelationTypes.GetOetRelationsQuery): CursorBasedPageResult<OetRelation> => {
    return parseCursorBasedPageResult('oetRelations', res);
  };


  return {
    pageSize: DEFAULT_PAGE_SIZE,
    parseOetRelationsPageResult,
    useOetRelationsPageQuery
  }
};
