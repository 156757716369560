import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {useMobileView} from "@sivis/shared/utils";
import styles from './Login.module.scss';
import {ELogoPlace, Logo} from '@sivis/shared/components/logo';
import LoginForward from "./LoginForward";
import {isSmallLoginView, LoginForm, LoginTabsProps} from "./LoginForms";
import {EAuthProvider} from "@sivis/shared/types";
import {LoginErrors} from "./LoginError";

export interface LoginProps extends Omit<LoginTabsProps, "isMobile" | "locationIsLoginPage"> {
  loginUrl: string;
  errors: LoginErrors;
  onLogoClick: () => void;
  onSamlLogin: (path: string) => void;
  directSSO?: boolean;
  skipDirectSSOViaQuery?: () => boolean;
}

export const Login = (props: LoginProps) => {
  const location = useLocation();
  const locationIsLoginPage = location.pathname === props.loginUrl || location.pathname === "/";
  const isMobile = useMobileView();
  const intl = useIntl();

  const shouldSkipDirectSSOViaQuery = props.skipDirectSSOViaQuery?.();
  if (props.authProvider === EAuthProvider.SAML && props.directSSO && !shouldSkipDirectSSOViaQuery) {
    return <LoginForward onForward={props.onSamlLogin}/>
  }

  const smallView = isSmallLoginView(isMobile, locationIsLoginPage);

  const loginForm = <LoginForm
    {...props}
    isMobile={isMobile}
    locationIsLoginPage={locationIsLoginPage}
  />;

  const SLOGAN_HIGHLIGHTED_WORD_TEXT_ID = "login.slogan.highlightedWord";
  const sloganText = <div className={styles.textRightSide}>
    {
      intl
      .formatMessage({id: "login.slogan"})
      .split(intl.formatMessage({id: SLOGAN_HIGHLIGHTED_WORD_TEXT_ID}))[0]
    }
    <br/>
    <span style={{color: "var(--primaryColor)"}}>
      {intl.formatMessage({id: SLOGAN_HIGHLIGHTED_WORD_TEXT_ID})}
    </span>
    <br/>
    {
      intl
      .formatMessage({id: "login.slogan"})
      .split(intl.formatMessage({id: SLOGAN_HIGHLIGHTED_WORD_TEXT_ID}))[1]
    }
  </div>;

  return smallView ? <>{loginForm}</> : <div className={styles.loginSite}>
    <div className={styles.containerDiv}>
      <div className={styles.leftSide}>
        {loginForm}
      </div>
      <div className={styles.rightSide}>
        <Logo
          className={styles.logo}
          place={ELogoPlace.LOGIN}
          alt="Pointsharp GmbH"
          onDoubleClick={props.onLogoClick}
        />
        {sloganText}
        <div className={styles.backgroundRightSide}></div>
      </div>
    </div>
  </div>;
};
