import {Entitlement, EntitlementType, Role} from "@sivis/identity/api";
import React from "react";
import {useCustomIntl} from "@sivis/intl";
import {TabBar} from "@sivis/shared/components/entityView";
import {CreateEntitlementResourceList} from "./createResourceEntitlementForIdentity";

interface CreateResourceEntitlementForRoleProps {
  role: Role;
  onAdd: (added: Entitlement[]) => void;
}

export const CreateResourceEntitlementForRole = (props: CreateResourceEntitlementForRoleProps) => {
  const intl = useCustomIntl();

  const tabs = [
    {
      label: intl.format("fusion.resource.list.name"),
      content: <CreateEntitlementResourceList
          {...props}
          entitledId={props.role.id}
          type={EntitlementType.ROLE_RESOURCE_ENTITLEMENT}/>
    }
  ];

  return <TabBar tabs={tabs}/>;
};
