import {
  OetRelation,
  OrganizationalEntityType,
  useCreateOetRelationMutation,
  useUpdateOetRelationMutation
} from '@sivis/identity/api';
import {useEffect, useState} from 'react';
import {useOetRelationTypeApi} from './api/useOetRelationTypeApi';
import {useOrganizationalEntityApi} from '../organizationalEntity/api/useOrganizationalEntityApi';
import {
  convertInputRecordsIntoOetRelation,
  OET_RELATION_TYPE_OPTIONS,
  OetRelationPopupInput
} from '../utils/oetRelationUtils';
import {CreateOetRelationPopup} from './createOetRelationPopup';
import {OrganizationalEntityTypeRelationsList} from "./organizationalEntityTypeRelationsList";


export interface OrganizationalEntityTypeOetRelationsTabProps {
  organizationalEntityType: OrganizationalEntityType,
  editMode?: boolean,
}

const OrganizationalEntityTypeOetRelationsTab = (props: OrganizationalEntityTypeOetRelationsTabProps) => {

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCreation, setIsCreation] = useState<boolean>(false);
  const [selectedOetRelationId, setSelectedOetRelationId] = useState<string | undefined>(undefined);
  const [createdOetRelation, setCreatedOetRelation] = useState<OetRelation | undefined>(undefined);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const {oetRelationTypes} = useOetRelationTypeApi();

  const {organizationalEntityTypes} = useOrganizationalEntityApi();
  const [createOetRelation] = useCreateOetRelationMutation();
  const [updateOetRelation] = useUpdateOetRelationMutation();


  const onWatch = (value: Record<string, any>) => {
    setCreatedOetRelation(
      convertInputRecordsIntoOetRelation(
        value as OetRelationPopupInput,
        props.organizationalEntityType,
        organizationalEntityTypes,
        oetRelationTypes,
        selectedOetRelationId)
    );
  };

  const closePopup = () => {
    setCreatedOetRelation(undefined);
    setIsEdit(false);
    setIsCreation(false);
    setOpenPopup(false);
  };


  const handleOnSave = () => {

    if (createdOetRelation) {
      if (isEdit) {
        updateOetRelation({
          id: createdOetRelation.id,
          input: {
            isRequired: createdOetRelation.isRequired
          }
        }).then(
          () => {
            closePopup();
          }
        ).catch((error: any) => {
            console.error(error);
          }
        );
      } else {
        createOetRelation({
          ownerTypeId: createdOetRelation.ownerType.id,
          assignedTypeId: createdOetRelation.assignedType.id,
          oetRelationTypeId: createdOetRelation.oetRelationType.id!
        }).then(
          () => {
            closePopup();
          }
        ).catch((error: any) => {
            console.error(error);
          }
        );
      }
    }
  }


  useEffect(() => {
    if (isEdit && selectedOetRelationId) {
      setOpenPopup(true);
    }

    if (isCreation && !selectedOetRelationId) {
      setOpenPopup(true);
    }
  }, [selectedOetRelationId, isEdit, isCreation]);


  const oetRelationPopup = CreateOetRelationPopup({
    organizationalEntityType: props.organizationalEntityType,
    oetRelationTypes: oetRelationTypes,
    organizationalEntityTypes: organizationalEntityTypes,
    createdOetRelation: createdOetRelation,
    setCreatedOetRelation: setCreatedOetRelation,
    oetRelationTypeSelectOptions: OET_RELATION_TYPE_OPTIONS,
    onWatch: onWatch,
    id: selectedOetRelationId,
    handleOnSave: handleOnSave,
    isEdit: isEdit
  })({
      open: openPopup,
      onClose: () => {
        closePopup();
      }
    }
  );

  const editOetRelation = (id: string) => {
    setSelectedOetRelationId(id);
    setIsEdit(true);
  };

  const handleCreateOetRelationClick = () => {
    setIsCreation(true);
    setSelectedOetRelationId(undefined);
  }

  return (
    <div>
      {oetRelationPopup}
      <OrganizationalEntityTypeRelationsList
        organizationalEntityType={props.organizationalEntityType}
        editOetRelation={editOetRelation}
        createOetRelation={handleCreateOetRelationClick}
        editMode={props.editMode}
      />
    </div>
  );
};


export default OrganizationalEntityTypeOetRelationsTab;
