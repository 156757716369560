import {defaults, omit} from "lodash";
import {
  FilterExpression,
  OrganizationalEntity,
  OrganizationalEntityInput,
  OrganizationalEntityType,
  OrganizationalEntityTypeInput
} from "../identityApi.types";
import {FilterOperator} from "@sivis/shared/api-utils";

export const emptyOrganizationalEntityInput = (): OrganizationalEntityInput => ({
  name: '',
  alternativeName: '',
  customID: '',
  organizationalEntityType: '',
  address: '',
});

export const emptyOrganizationalEntityTypeInput = (): OrganizationalEntityTypeInput => ({
  name: '',
  apiName: '',
  description: '',
});

export const organizationalEntityInputFactory = (organizationalEntity?: OrganizationalEntity | OrganizationalEntityInput | null): OrganizationalEntityInput => {

  if (!organizationalEntity) {
    return emptyOrganizationalEntityInput();
  }

  let organizationalEntityWithStrippedFields = {...organizationalEntity};

  if (typeof organizationalEntity.organizationalEntityType === "string") {
    organizationalEntityWithStrippedFields.organizationalEntityType = organizationalEntity.organizationalEntityType;
  } else if (organizationalEntity.organizationalEntityType && 'id' in organizationalEntity.organizationalEntityType) {
    organizationalEntityWithStrippedFields.organizationalEntityType = organizationalEntity.organizationalEntityType.id;
  }

  if (typeof organizationalEntity.address === "string") {
    organizationalEntityWithStrippedFields.address = organizationalEntity.address;
  } else if (organizationalEntity.address && 'id' in organizationalEntity.address) {
    organizationalEntityWithStrippedFields.address = organizationalEntity.address.id;
  }

  return defaults({}, omit(organizationalEntityWithStrippedFields, ['id', 'meta', "organizationalEntityTypeName"]), emptyOrganizationalEntityInput());
}

export const organizationalEntityTypeInputFactory = (organizationalEntityType?: OrganizationalEntityType | OrganizationalEntityTypeInput | null): OrganizationalEntityTypeInput => {

  if (!organizationalEntityType) {
    return emptyOrganizationalEntityTypeInput();
  }

  return defaults({}, omit(organizationalEntityType, ['id', 'meta', 'organizationalEntities']), emptyOrganizationalEntityTypeInput());
}

export const searchOrganizationalEntitiesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "alternativeName", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "customID", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});

export const searchOrganizationalEntityTypesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "apiName", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "description", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});
