import {createSelector} from 'reselect';
import {createIntl} from 'react-intl';
import {textToJSON} from './utils';
import {IntlSliceState, UpperCaseLanguage} from './types';
import {isStringMessageId} from '@sivis/redux';

const selectIntlState = createSelector(
  (state: IntlSliceState) => state,
  state => state.intl
);

export const selectIntlLoading = createSelector(
  selectIntlState,
  intl => intl.loading
);

export const selectLanguage = createSelector(
  selectIntlState,
  intl => intl.language
);

export const selectLanguageUppercase = createSelector(
  selectLanguage,
  language => language.toUpperCase() as UpperCaseLanguage
);
export const selectMessages = createSelector(
  selectIntlState,
  intl => intl.messages
);

export const selectAvailableLanguages = createSelector(
  selectIntlState,
  intl => intl.availableLanguages
);

export const messageMapSelector = createSelector(
  selectMessages, selectAvailableLanguages, (messages, availableLocales) => {
    return availableLocales.map(locale => ({
      [locale]: textToJSON(messages, locale)
    })).reduce((prev, cur) => ({...prev, ...cur}), {});
  }
);

export const selectActiveMessages = createSelector(
  selectLanguage,
  selectMessages,
  (language, messages) => messages.filter(message => message.locale === language)
);

export const selectMessageById = createSelector(
  [
    selectActiveMessages,
    (state, messageId) => messageId
  ],
  (messages, messageId) => messages.find(message => message.id === messageId)
);

export const selectMessageTextById = createSelector(
  [
    selectActiveMessages,
    (state, messageId) => messageId
  ],
  (messages, messageId) => {
    if (!isStringMessageId(messageId)) {
      return messageId;
    }

    const message = messages.find(message => message.id === messageId);

    return message?.message || messageId;
  }
);

export const intlSelector = createSelector(
  messageMapSelector,
  selectAvailableLanguages,
  (messageMap, availableLocales) => {
    return availableLocales.map(locale => {
      return {
        locale,
        intl: createIntl({
          messages: messageMap[locale],
          locale: locale
        })
      };
    });
  }
);

export const selectIntlMessages = createSelector(
  selectLanguage,
  selectMessages,
  (language, messages) => textToJSON(messages, language));
