import { EntitlementType, RelationQueryType, Role } from '@sivis/identity/api';
import { useResourceEntitlementsApi } from './useResourceEntitlementsApi';
import { combineFilter, FilterExpression, FilterOperator } from '@sivis/shared/api-utils';

export const useRoleResourceEntitlementsApi = (role?: Role, extraFilter?: FilterExpression) => {
  const roleId = role?.id;
  // TODO: search entitlements
  const roleEntitlementsFilter = combineFilter(FilterOperator.AND, {
    name: "type",
    operator: FilterOperator.EQ,
    stringValue: EntitlementType.ROLE_RESOURCE_ENTITLEMENT
  }, {
    name: "entitledId",
    operator: FilterOperator.EQ,
    uuidValue: roleId
  });

  const filter = extraFilter ? combineFilter(FilterOperator.AND, extraFilter, roleEntitlementsFilter) : roleEntitlementsFilter;

  return useResourceEntitlementsApi(
    EntitlementType.ROLE_RESOURCE_ENTITLEMENT,
    filter,
    RelationQueryType.ROLE_RESOURCE_ENTITLEMENTS,
    roleId);
};
