import {Link} from "react-router-dom";
import {Role} from "@sivis/identity/api";
import {useRolesPageApi} from "./useRolesPageApi";
import {GridColDef} from '@mui/x-data-grid';
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: Role) => {
  return {
    id: entity.id,
    name: entity.name
  };
};

const columns: GridColDef[] = [{
  field: "name",
  flex: 1,
  renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
}];

export const RoleList = () => {
  const intl = useCustomIntl();
  const {onSearch, useRolesPageQuery, parseRolesPageResult, pageSize} = useRolesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.role.propertyName");

  return <EntityView
    title={intl.format("fusion.role.list.title")}
    actionButtons={[<CreateButton key={"create"}/>]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useRolesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseRolesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
