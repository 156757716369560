import { Ownership, Resource, Role, useGetResourceQuery, useGetRoleQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";
import { useOwnershipApi } from "./useOwnershipApi";

export const useRoleResourceOwnershipApi = ({id, ownerId, targetId}: Ownership) => {
  const {data: dataOwner} = useGetRoleQuery({id: ownerId}, {refetchOnMountOrArgChange: true});
  const {data: dataTarget} = useGetResourceQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [owner, setOwner] = useState<Role>();
  const [target, setTarget] = useState<Resource>();

  useEffect(() => {
    setOwner(dataOwner?.role ?? undefined);
    setTarget(dataTarget?.resource ?? undefined);
  }, [dataOwner?.role, dataTarget?.resource]);

  const {ownership, onSave} = useOwnershipApi(id);

  return {ownership, owner, target, onSave}
}
