import {Outlet, RouteObject} from "react-router-dom";
import {OrganizationalEntityList} from "./organizationalEntityList";
import {
  OrganizationalEntityChildrenTabWrapperComponent,
  OrganizationalEntityInformationTabWrapperComponent,
  OrganizationalEntityView
} from "./organizationalEntityView";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import {
  CHILD_ENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME
} from "../components/routes/constants";
import {EntityType} from "../components/breadcrumb/editModeContext";
import {useOrganizationalEntityQuery} from "@sivis/identity/api";


const useGetOrganizationalEntityName = (id: string, isNew: boolean) => {
  const {data} = useOrganizationalEntityQuery({id}, {skip: isNew});
  return data?.organizationalEntity?.name;
};

export const organizationalEntityRoutes: RouteObject[] = [
  {
    index: true,
    element: <OrganizationalEntityList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetOrganizationalEntityName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <OrganizationalEntityView/>,
      },
      {
        element: <OrganizationalEntityView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <OrganizationalEntityInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.ORGANIZATIONAL_ENTITY
              })
            }
          },
          {
            path: CHILD_ENTITIES_TAB_PATHNAME,
            element: <OrganizationalEntityChildrenTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.childEntities",
                intlIdEdit: "fusion.breadcrumb.childEntities.edit",
                entity: EntityType.ORGANIZATIONAL_ENTITY
              })
            }
          }
        ]
      }
    ]
  },
];
