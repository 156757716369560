import {Registry} from './Registry';
import {normalizePath} from '@sivis/shared/utils';
import {useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';

const buildPath = (...pathParts: (string | number)[]) => {
  const resolvedParts = pathParts.map(partOrId => {
    const url = Registry.getUrl(partOrId.toString(), '');
    return url || partOrId;
  });

  return normalizePath(...resolvedParts);
}

const getPostNavigationActions = (...pathParts: (string | number)[]) => {
  return pathParts.flatMap(partOrId => {
    const entry = Registry.get(partOrId.toString(), false);
    return entry?.postNavigationAction ?? [];
  });
};

export const useRegistryNavigator = (fixedId?: string) => {
  const routerNavigate = useNavigate();
  const dispatch = useDispatch();

  const navigate = (...pathParts: (string | number)[]) => {
    const path = buildPath(...pathParts);
    const postNavigationActions = getPostNavigationActions(...pathParts);

    if (!path) {
      return;
    }

    routerNavigate(path);
    postNavigationActions.forEach(dispatch);
  }

  if (fixedId) {
    return (...extraPats: (string | number)[]) => navigate(fixedId, ...extraPats)
  }

  return navigate;
}
