import {IdentityType} from "@sivis/identity/api";

interface IdentityTypeIdentitysTabProps {
  identityType: IdentityType,
}

const IdentityTypeIdentitysTab = (props: IdentityTypeIdentitysTabProps) => {
  return <div>
    Placeholder
  </div>
}

export default IdentityTypeIdentitysTab;

