import {
  EntitlementType,
  ExtendedResourceEntitlement,
  Identity,
  identityName,
  RelationQueryType
} from '@sivis/identity/api';
import styles from './identity.module.scss';
import { EntityTableRow, ListModifyViewUpdated } from '@sivis/shared/components/entityView';
import {
  IdentityResourceEntitlementDetails
} from '../entitlement/identityResourceEntitlementDetails';
import {
  getEntitlementFlags,
  getIdentityResourceEntitlementDescription,
  getResourceEntitlementColumnsWithFlags,
  mapTableRowToEntitlement,
  resourceEntitlementToTableRow,
  useEntitlementMessages
} from '../entitlement/entitlementUtils';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { ConfirmationPopup } from '../components/popup/confirmationPopup';
import React from 'react';
import { createEntitlementPopup } from '../entitlement/createEntitlementPopup';
import {
  useIdentityResourceEntitlementsApi
} from '../entitlement/api/useIdentityResourceEntitlementsApi';
import { EntityType, useSingleEditMode } from '../components/breadcrumb/editModeContext';
import {
  useResourceEntitlementFilter
} from '../entitlement/components/useResourceEntitlementFilter';
import { isEqualById } from '@sivis/shared/utils';

interface IdentityResourceViewProps {
  identity: Identity;
  editMode: boolean;
}

export const IdentityResourceView = ({identity, editMode}: IdentityResourceViewProps) => {
  const intl = useCustomIntl();
  const {deleteEntitlementMessage} = useEntitlementMessages();
  const {
    activeFilter,
    filterComponent
  } = useResourceEntitlementFilter(
    EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT,
    RelationQueryType.IDENTITY_RESOURCE_ENTITLEMENTS,
    identity?.id
  );
  const {
    onSave,
    submitted,
    setSubmitted,
    pageSize,
    useEntitlementsPageQuery,
    parseEntitlementsPageResult
  } = useIdentityResourceEntitlementsApi(identity, activeFilter ?? undefined);
  const {toggleEditMode} = useSingleEditMode(EntityType.IDENTITY);

  const getDescription = (entitlement: ExtendedResourceEntitlement, identity: Identity) =>
    getIdentityResourceEntitlementDescription(entitlement, identityName(identity), 0, entitlement.resourceName ?? "", intl.format);

  const getPopupTitle = (entitlement: ExtendedResourceEntitlement) => ({
    primaryTitle: intl.format("fusion.entitlement.title.edit"),
    secondaryTitle: getDescription(entitlement, identity)
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {
  };

  const translatedResourceEntitlementColumns = useTranslateColumns(getResourceEntitlementColumnsWithFlags(), "fusion.entitlement.resourceEntitlement.propertyName");
  const shownColumns = editMode ? translatedResourceEntitlementColumns : translatedResourceEntitlementColumns.filter(column => column.field !== "action");

  const resourceEntitlementToTableRowWithFlags = (
    entity: ExtendedResourceEntitlement,
    isNew: boolean,
    onEdit?: (entity: ExtendedResourceEntitlement) => void,
    onDelete?: (entity: ExtendedResourceEntitlement) => void): EntityTableRow => {
    return {
      ...resourceEntitlementToTableRow(entity, isNew, onEdit, onDelete),
      flags: getEntitlementFlags({validity: entity.validity}, intl.format)
    };
  };

  const confirmationPopup = identity ? <ConfirmationPopup
    open={submitted}
    onClose={() => {
      setSubmitted(false);
      toggleEditMode();
    }}
    primaryTitle={intl.format("fusion.entitlement.popup.title", {entity: identityName(identity)})}
    message={intl.format("fusion.message.submitted", {entity: identityName(identity)})}
  /> : null;

  const createPopup = createEntitlementPopup({
    primaryTitle: intl.format("fusion.entitlement.resourceEntitlement.identity.createPopup.title"),
    secondaryTitle: intl.formatBold(
        "fusion.identity.role.createPopup.description",
        {identity: identity ? identityName(identity) : ""}
    ),
    type: EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT,
    identity
  });

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
      columns={shownColumns}
      onSave={onSave}
      entityToTableRow={resourceEntitlementToTableRowWithFlags}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getPopupTitle}
      isEqual={isEqualById}
      entityDetails={IdentityResourceEntitlementDetails}
      deleteMessage={deleteEntitlementMessage}
      mapTableRowToEntity={mapTableRowToEntitlement}
      pageSize={pageSize}
      usePageQuery={useEntitlementsPageQuery}
      parseResult={parseEntitlementsPageResult}
      createButtonText="fusion.entitlement.resourceEntitlement.add"
      createPopup={createPopup}
      sideFilter={filterComponent}
      tableType={RelationQueryType.IDENTITY_RESOURCE_ENTITLEMENTS}
    />
  </div>;
};
