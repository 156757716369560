import { useState, useRef } from 'react';
import {
  EditButton,
  ActivateButton,
  DeactivateButton,
  EntityView,
  DeleteButton,
} from '@sivis/shared/components/entityView';
import { ActionPopup } from '../components/popup/actionPopup';
import { useAttributeSyncDefinitionApi } from './api/useAttributeSyncDefinitionApi';
import { Outlet, useOutletContext } from 'react-router-dom';
import {
  INFORMATION_TAB_PATHNAME,
  FIELDS_TAB_PATHNAME,
} from '../components/routes/constants';
import { useCustomIntl } from '@sivis/intl';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';
import { AsDefinition, AsdInput } from '@sivis/attributesync/api';
import AttributeSyncDefinitionInformationTab, {
  AttributeSyncDefinitionInformationTabHandle,
} from './attributeSyncDefinitionInformationTab';
import {
  AttributeSyncMappingTab,
  AttributeSyncMappingTabHandle,
} from '../attributeSynchronizationMapping/attributeSyncMappingTab';
import { useSystemApi } from '../system/useSystemApi';
import { System } from '@sivis/identity/api';
import { Alert } from '@mui/material';

export const AttributeSyncDefinitionView = () => {
  const intl = useCustomIntl();

  const { system } = useSystemApi();

  const {
    asDefinition,
    defaultInput,
    error,
    isNew,
    editMode,
    toggleEditMode,
    onChangeStatus,
    onSave,
    onCancel,
    onDelete,
    draftData,
    onSaveDraft,
    onDiscardDraft,
    onPublishDraft,
  } = useAttributeSyncDefinitionApi(system?.id);

  const [statusPopupOpen, setStatusPopupOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [publishPopupOpen, setPublishPopupOpen] = useState(false);
  const [publishSource, setPublishSource] = useState('');

  const informationTab = {
    label: intl.format('fusion.breadcrumb.information'),
    path: INFORMATION_TAB_PATHNAME,
  };
  const fieldsTab = {
    label: intl.format('fusion.attributesynchronizationdefinition.fields'),
    path: FIELDS_TAB_PATHNAME,
  };

  const tabs = isNew ? [informationTab] : [informationTab, fieldsTab];

  const changeStatus = () => {
    if (asDefinition?.isActive) {
      onChangeStatus?.(asDefinition, false);
      toggleStatusPopup();
    } else if (asDefinition !== undefined && !asDefinition.isActive) {
      onChangeStatus?.(asDefinition, true);
      toggleStatusPopup();
    }
  };

  const refInformation =
    useRef<AttributeSyncDefinitionInformationTabHandle>(null);
  const refFields = useRef<AttributeSyncMappingTabHandle>(null);

  const onPublish = () => {
    if (publishSource === 'information') {
      refInformation.current?.submit();
    } else if (publishSource === 'fields') {
      refFields.current?.submit();
    }
    closePublishPopup();
  };
  const toggleStatusPopup = () => setStatusPopupOpen(!statusPopupOpen);
  const toggleDeletePopup = () => setDeletePopupOpen(!deletePopupOpen);
  const openPublishPopup = (source: string) => {
    setPublishSource(source);
    setPublishPopupOpen(true);
  };
  const closePublishPopup = () => {
    setPublishPopupOpen(false);
  };

  const titleExisting = `${intl.format(
    'fusion.attributesynchronizationdefinition.titleExisting'
  )}${asDefinition?.name}`;
  const title = isNew
    ? intl.format('fusion.attributesynchronizationdefinition.titleNew')
    : titleExisting;
  const buttons = isNew
    ? undefined
    : [
        <EditButton key="edit" onClick={toggleEditMode} disabled={editMode} />,
        asDefinition?.isActive ? (
          <DeactivateButton key="deactivate" onClick={toggleStatusPopup} />
        ) : (
          <ActivateButton key="activate" onClick={toggleStatusPopup} />
        ),
        <DeleteButton key="delete" onClick={toggleDeletePopup} />,
      ];

  const deletionAlert = (
    <Alert severity="warning">
      {intl.format('fusion.attributesynchronizationmapping.popup.deleteAlert')}
    </Alert>
  );
  const statusAlert = (
    <Alert severity="info">
      {intl.format(
        'fusion.attributesynchronizationdefinition.statuspopup.alert'
      )}
    </Alert>
  );
  const publishAlert = (
    <Alert severity="info">
      {intl.format(
        'fusion.attributesynchronizationdefinition.publishpopup.infoBox'
      )}
    </Alert>
  );
  const statusPopup = (
    <ActionPopup
      open={statusPopupOpen}
      onConfirm={changeStatus}
      onClose={toggleStatusPopup}
      primaryTitle={`${intl.format(
        'fusion.attributesynchronizationdefinition.statuspopup.title'
      )}${
        asDefinition?.isActive
          ? intl.format('fusion.general.inactive').toLocaleLowerCase()
          : intl.format('fusion.general.active').toLocaleLowerCase()
      }.`}
      secondaryTitle={intl.format(
        'fusion.attributesynchronizationdefinition.statuspopup.secondaryTitle'
      )}
      alert={statusAlert}
    ></ActionPopup>
  );
  const deletionPopup = (
    <ActionPopup
      open={deletePopupOpen}
      onConfirm={onDelete}
      onClose={toggleDeletePopup}
      primaryTitle={intl.format(
        'fusion.attributesynchronizationdefinition.deletepopup.title'
      )}
      secondaryTitle={intl.format(
        'fusion.attributesynchronizationdefinition.deletepopup.secondaryTitle'
      )}
      alert={deletionAlert}
    ></ActionPopup>
  );
  const publishPopup = (
    <ActionPopup
      open={publishPopupOpen}
      primaryTitle={intl.format(
        'fusion.attributesynchronizationdefinition.publishpopup.title'
      )}
      secondaryTitle={intl.format(
        'fusion.attributesynchronizationdefinition.publishpopup.secondaryTitle'
      )}
      onClose={closePublishPopup}
      onConfirm={onPublish}
      alert={publishAlert}
    ></ActionPopup>
  );

  return (
    <EntityView
      title={title}
      tabs={tabs}
      actionButtons={buttons}
      error={
        error
          ? getTranslatedErrorMessage(
              error,
              'fusion.attributesynchronizationdefinition.error.notFound',
              intl.format
            )
          : undefined
      }
    >
      <Outlet
        context={{
          editMode,
          asDefinition,
          onSave,
          onCancel,
          isNew,
          system,
          openPublishPopup,
          refInformation,
          refFields,
          draftData,
          onSaveDraft,
          onDiscardDraft,
          onPublishDraft,
          defaultInput,
        }}
      />
      {deletionPopup}
      {statusPopup}
      {publishPopup}
    </EntityView>
  );
};

export type ContextType = {
  editMode: boolean;
  isNew: boolean;
  onSave: (value: Record<string, any>) => void;
  asDefinition: AsDefinition;
  defaultInput: AsdInput;
  onCancel: () => void;
  system: System;
  openPublishPopup: () => void;
  refFields: React.Ref<AttributeSyncMappingTabHandle>;
  refInformation: React.Ref<AttributeSyncDefinitionInformationTabHandle>;
  draftData: AsDefinition;
  onSaveDraft: () => void;
  onDiscardDraft: () => void;
  onPublishDraft: () => void;
};

export const AttributeSyncDefinitionInformationTabWrapperComponent = () => {
  const props = useOutletContext<ContextType>();
  return (
    <AttributeSyncDefinitionInformationTab
      {...props}
      ref={props.refInformation}
    />
  );
};

export const AttributeSyncDefinitionFieldsTabWrapperComponent = () => {
  const props = useOutletContext<ContextType>();
  return <AttributeSyncMappingTab {...props} ref={props.refFields} />;
};
