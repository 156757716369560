import {Link} from "react-router-dom";
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import {Identity} from "@sivis/identity/api";
import {useIdentitiesPageApi} from "./useIdentitiesPageApi";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: Identity) => {
  return {
    id: entity.id,
    fullName: entity.firstName + " " + entity.lastName
  };
};

const columns: GridColDef[] = [{
  field: "fullName",
  minWidth: 200,
  flex: 1,
  renderCell: params => <Link to={`${params.row.id}`}>{params.row.fullName}</Link>
}];


export const IdentityList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    pageSize,
    useIdentitiesPageQuery,
    parseIdentitiesPageResult
  } = useIdentitiesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.identity.propertyName");

  return <EntityView
    title={intl.format("fusion.identity.list.title")}
    actionButtons={[<CreateButton key={"create"}/>]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useIdentitiesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseIdentitiesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
