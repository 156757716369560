import {FilterExpression, FilterOperator} from "@sivis/shared/api-utils";

export const searchMembershipsInIdentityFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "role.name", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});

export const searchMembershipsInRoleFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "identity.firstName", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "identity.lastName", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});
