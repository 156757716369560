import { GridColDef } from '@mui/x-data-grid';
import { Resource, SideFilterQueryType } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import { EntityView, InfiniteScrollTableCursor } from "@sivis/shared/components/entityView";
import { Link } from "react-router-dom";
import { useResourcesSideFilter } from '../ownership/components/useResourcesSideFilter';
import { useResourcePageApi } from "./useResourcePageApi";

const entityToTableRow = (entity: Resource) => {
  return {
    id: entity.id,
    name: entity.name,
    resourceId: entity.resourceId,
    resourceTypeName: entity.resourceType?.name,
    systemName: entity.system?.systemName,
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "resourceId",
    flex: 1
  },
  {
    field: "resourceTypeName",
    flex: 1
  },
  {
    field: "systemName",
    flex: 1
  },
];

export const ResourceList = () => {
  const intl = useCustomIntl();
  const {activeFilter, filterComponent} = useResourcesSideFilter(SideFilterQueryType.RESOURCES);
  const {
    onSearch,
    useResourcePageQuery,
    parseResourcePageResult,
    pageSize
  } = useResourcePageApi(activeFilter ?? undefined);
  const translatedColumns = useTranslateColumns(columns, "fusion.resource.propertyName");

  return <EntityView title={intl.format("fusion.resource.list.title")}>
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useResourcePageQuery}
      renderRow={entityToTableRow}
      parseResult={parseResourcePageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
      sideFilter={filterComponent}
      tableType={SideFilterQueryType.RESOURCES}
    />
  </EntityView>;
};

