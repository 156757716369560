import { Entitlement, EntitlementType, Resource, Role, SideFilterQueryType } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import { ListAddView, TabBar } from "@sivis/shared/components/entityView";
import { useRolesSideFilter } from "../ownership/components/useRolesSideFilter";
import { useRolesPageApi } from "../role/useRolesPageApi";
import styles from "./entitlement.module.scss";
import { addRoleEntitlementColumns, roleEntityToTableRow } from "./entitlementUtils";

interface CreateRoleEntitlementForResourceProps {
  resource: Resource;
  onAdd: (added: Entitlement[]) => void;
}

export const CreateRoleEntitlementForResource = (props: CreateRoleEntitlementForResourceProps) => {
  const intl = useCustomIntl();

  const tabs = [
    {
      label: intl.format("fusion.role.list.name"),
      content: <CreateEntitlementRoleList {...props} />
    }
  ];

  return <TabBar tabs={tabs}/>;
};

const CreateEntitlementRoleList = (props: CreateRoleEntitlementForResourceProps) => {
  // TODO: should we remove existing roles from the list or grey them out?
  const {activeFilter, filterComponent} = useRolesSideFilter(SideFilterQueryType.ROLES);
  const {
    onSearch,
    useRolesWithIdentitiesCountPageQuery,
    parseRolesPageResult,
    pageSize
  } = useRolesPageApi(activeFilter ?? undefined);
  const translatedColumns = useTranslateColumns(addRoleEntitlementColumns, "fusion.entitlement.roleEntitlement.propertyName");

  const onAdd = (roles: Role[]) => {
    props.onAdd(roles.map(role => ({
      id: role.id + props.resource.id,
      entitledId: role.id,
      targetId: props.resource.id,
      type: EntitlementType.ROLE_RESOURCE_ENTITLEMENT,
      roleName: role.name
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={roleEntityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useRolesWithIdentitiesCountPageQuery}
      parseResult={parseRolesPageResult}
      sideFilter={filterComponent}
      excludedItems={[]}
      tableType={SideFilterQueryType.ROLES}
    />
  </div>;
};
