import { Ownership, Role, System, useGetRoleQuery, useGetSystemQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";
import { useOwnershipApi } from "./useOwnershipApi";

export const useRoleSystemOwnershipApi = ({id, ownerId, targetId}: Ownership) => {
  const {data: dataOwner} = useGetRoleQuery({id: ownerId}, {refetchOnMountOrArgChange: true});
  const {data: dataTarget} = useGetSystemQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [owner, setOwner] = useState<Role>();
  const [target, setTarget] = useState<System>();

  useEffect(() => {
    setOwner(dataOwner?.role ?? undefined);
    setTarget(dataTarget?.system ?? undefined);
  }, [dataOwner?.role, dataTarget?.system]);

  const {ownership, onSave} = useOwnershipApi(id);

  return {ownership, owner, target, onSave}
}
