import React from 'react';
import {
  Checkbox,
  Stack,
  Switch,
  TextField,
  Typography,
  Card,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FormSelect } from '../formTypes/FormSelect';
import { FormAutocomplete } from '../formTypes/FormAutocomplete';
import { ControllerRenderProps } from 'react-hook-form';
import styles from './form.module.scss';
import {
  AttributeConfig,
  FieldType,
} from './attributeRowConfig';
import { SearchConfig } from './entityForm';

interface RenderFieldProps {
  config: AttributeConfig;
  fieldProps: ControllerRenderProps<Record<string, any>, string>;
  searchFunctions?: {
    [key: string]: SearchConfig;
  };
  searchFunction?: (searchTerm: string) => any;
  searchResults?: any;
  loadingEnities?: boolean;
  value?: Record<string, any>;
}

const RenderField: React.FC<RenderFieldProps> = ({
                                                   config,
                                                   fieldProps,
                                                   searchFunctions,
                                                   searchFunction,
                                                   searchResults,
                                                   loadingEnities,
                                                   value,
                                                 }) => {
  if (config.options && !config.useSwitch) {
    return (
      <FormSelect
        fieldProps={fieldProps}
        config={config}
        className={styles.input}
      />
    );
  }

  if (config.useSwitch && config.options && config.options.length === 2) {
    const [firstOption, secondOption] = config.options;
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{secondOption.text}</Typography>
        <Switch
          disabled={config.disabled}
          {...fieldProps}
          id={config.name}
          checked={fieldProps.value === firstOption.value}
          onChange={(e) => {
            fieldProps.onChange(
              e.target.checked ? firstOption.value : secondOption.value
            );
          }}
        />
        <Typography>{firstOption.text}</Typography>
      </Stack>
    );
  }

  if (config.fieldType === FieldType.DATE) {
    return (
      <DatePicker
        label={config.name}
        {...fieldProps}
        value={fieldProps.value ?? null}
        renderInput={(params) => (
          <TextField {...params} size="small" className={styles.input} />
        )}
      />
    );
  }

  if (config.fieldType === FieldType.BOOLEAN) {
    return <Checkbox {...fieldProps} checked={!!fieldProps.value} />;
  }

  if (config.fieldType === FieldType.AUTOCOMPLETE) {
    if (searchFunctions) {
      const fieldKey = config.property.toLowerCase();
      const searchConfig = searchFunctions[fieldKey];

      return (
        <FormAutocomplete
          fieldProps={fieldProps}
          config={config}
          searchConfig={searchConfig}
        />
      );
    } else if (searchFunction) {
      return (
        <FormAutocomplete
          fieldProps={fieldProps}
          config={config}
          globalSearchFunction={searchFunction}
          globalSearchResults={searchResults}
          globalLoadingEntities={loadingEnities}
        />
      );
    }
  }

  if (config.fieldType === FieldType.NUMBER) {
    return (
      <TextField
        disabled={config.disabled}
        variant="outlined"
        size="small"
        type="number"
        {...fieldProps}
        id={config.name}
        value={fieldProps.value ?? 0}
      />
    );
  }

  if (config.fieldType === FieldType.TEXTAREA) {
    return (
      <TextField
        disabled={config.disabled}
        variant="outlined"
        size="small"
        multiline
        rows={5}
        {...fieldProps}
        id={config.name}
        value={fieldProps.value ?? ''}
      />
    );
  }

  if (config.fieldType === FieldType.READONLY) {
    return (
      <Card variant="outlined" sx={{ padding: '10px' }}>
        {config.renderRow?.(value)}
      </Card>
    );
  }

  return (
    <TextField
      disabled={config.disabled}
      variant="outlined"
      size="small"
      {...fieldProps}
      id={config.name}
      value={fieldProps.value ?? ''}
      className={styles.input}
    />
  );
};

export default RenderField;
