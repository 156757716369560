import {
  api as generatedOwnershipApi,
  GetOwnershipsDocument,
  GetOwnershipsQuery,
  GetOwnershipsQueryVariables,
} from "./ownership.generated";
import {
  executeAfterQueryFulfilled,
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from "@sivis/shared/api-utils";
import {
  extendIdentityOwnerships,
  extendResourceOwnerships,
  extendResourceSetOwnerships,
  extendRoleOwnerships,
  extendSystemOwnerships
} from "./extendOwnershipUtils";
import {
  resetGetIdentityOwnershipsActionCreator,
  resetGetResourceOwnershipsActionCreator,
  resetGetResourceSetOwnershipsActionCreator,
  resetGetRoleOwnershipsActionCreator,
  resetGetSystemOwnershipsActionCreator,
  updateGetIdentityOwnershipsActionCreator,
  updateGetResourceOwnershipsActionCreator,
  updateGetResourceSetOwnershipsActionCreator,
  updateGetRoleOwnershipsActionCreator,
  updateGetSystemOwnershipsActionCreator
} from "./ownershipUtils";

export const injectedOwnershipApi = generatedOwnershipApi.injectEndpoints({
  endpoints: (build) => ({
    GetIdentityOwnerships: build.query<GetOwnershipsQuery, GetOwnershipsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetOwnershipsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("ownerships"),
      forceRefetch: forceRefetchPaging,
      onQueryStarted: executeAfterQueryFulfilled([extendIdentityOwnerships]),
      transformResponse: transformResponseWithArgs
    }),
    GetRoleOwnerships: build.query<GetOwnershipsQuery, GetOwnershipsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetOwnershipsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("ownerships"),
      forceRefetch: forceRefetchPaging,
      onQueryStarted: executeAfterQueryFulfilled([extendRoleOwnerships]),
      transformResponse: transformResponseWithArgs
    }),
    GetResourceOwnerships: build.query<GetOwnershipsQuery, GetOwnershipsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetOwnershipsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("ownerships"),
      forceRefetch: forceRefetchPaging,
      onQueryStarted: executeAfterQueryFulfilled([extendResourceOwnerships]),
      transformResponse: transformResponseWithArgs
    }),
    GetResourceSetOwnerships: build.query<GetOwnershipsQuery, GetOwnershipsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetOwnershipsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("ownerships"),
      forceRefetch: forceRefetchPaging,
      onQueryStarted: executeAfterQueryFulfilled([extendResourceSetOwnerships]),
      transformResponse: transformResponseWithArgs
    }),
    GetSystemOwnerships: build.query<GetOwnershipsQuery, GetOwnershipsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetOwnershipsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("ownerships"),
      forceRefetch: forceRefetchPaging,
      onQueryStarted: executeAfterQueryFulfilled([extendSystemOwnerships]),
      transformResponse: transformResponseWithArgs
    }),
  })
});

export const ownershipApi = injectedOwnershipApi.enhanceEndpoints({
  endpoints: {
    GetOwnerships: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("ownerships"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ["ownership"]
    },
    GetOwnership: {providesTags: ["ownership"]},
    GetOwnershipsValidity: {providesTags: ["ownership"]},
    GetOwnershipTypes: {providesTags: ["ownership"]},
    CreateOwnership: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetIdentityOwnershipsActionCreator,
        resetGetRoleOwnershipsActionCreator,
        resetGetResourceOwnershipsActionCreator,
        resetGetResourceSetOwnershipsActionCreator,
        resetGetSystemOwnershipsActionCreator
      ]),
      invalidatesTags: ["ownership"]
    },
    UpdateOwnership: {
      onQueryStarted: executeAfterQueryFulfilled([
        updateGetIdentityOwnershipsActionCreator,
        updateGetRoleOwnershipsActionCreator,
        updateGetResourceOwnershipsActionCreator,
        updateGetResourceSetOwnershipsActionCreator,
        updateGetSystemOwnershipsActionCreator
      ]),
      invalidatesTags: ["ownership"]
    },
    DeleteOwnership: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetIdentityOwnershipsActionCreator,
        resetGetRoleOwnershipsActionCreator,
        resetGetResourceOwnershipsActionCreator,
        resetGetResourceSetOwnershipsActionCreator,
        resetGetSystemOwnershipsActionCreator
      ]),
      invalidatesTags: ["ownership"]
    },
  },
});

export const {
  useGetOwnershipsQuery,
  useLazyGetOwnershipsQuery,
  useGetIdentityOwnershipsQuery,
  useGetRoleOwnershipsQuery,
  useGetResourceOwnershipsQuery,
  useGetResourceSetOwnershipsQuery,
  useGetSystemOwnershipsQuery,
  useGetOwnershipQuery,
  useLazyGetOwnershipQuery,
  useCreateOwnershipMutation,
  useUpdateOwnershipMutation,
  useDeleteOwnershipMutation,
  useGetOwnershipTypesQuery,
  useGetOwnershipsValidityQuery
} = ownershipApi;

