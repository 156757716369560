import { DialogActions } from "@mui/material";
import { Entitlement, EntitlementType, Identity, Resource, Role } from "@sivis/identity/api";
import { CreatePopupProps, FormButtons, Popup } from "@sivis/shared/components/entityView";
import { ReactNode, useState } from "react";
import { CreateIdentityEntitlementForResource } from "./createIdentityEntitlementForResource";
import { CreateResourceEntitlementForIdentity } from "./createResourceEntitlementForIdentity";
import { CreateResourceEntitlementForRole } from "./createResourceEntitlementForRole";
import { CreateRoleEntitlementForResource } from "./createRoleEntitlementForResource";

interface CreateEntitlementPopupProps {
  primaryTitle: string;
  secondaryTitle: ReactNode;
  type: EntitlementType;
  identity?: Identity;
  resource?: Resource;
  role?: Role;
}

export const createEntitlementPopup = (props: CreateEntitlementPopupProps) =>
  function Comp({open, onClose, onSave: onSaveFromList}: CreatePopupProps<Entitlement>) {
    const [added, setAdded] = useState<Entitlement[]>([]);

    const onSave = () => {
      if (onSaveFromList) {
        onSaveFromList(added);
      }
    };

    let content = <></>;
    if (props.type === EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT && props.identity) {
      content = <CreateResourceEntitlementForIdentity identity={props.identity} onAdd={setAdded}/>
    } else if (props.type === EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT && props.resource) {
      content = <CreateIdentityEntitlementForResource resource={props.resource} onAdd={setAdded}/>
    } else if (props.type === EntitlementType.ROLE_RESOURCE_ENTITLEMENT && props.role) {
      content = <CreateResourceEntitlementForRole role={props.role} onAdd={setAdded}/>
    } else if (props.type === EntitlementType.ROLE_RESOURCE_ENTITLEMENT && props.resource) {
      content = <CreateRoleEntitlementForResource resource={props.resource} onAdd={setAdded}/>
    }

    return <Popup
      open={open}
      onClose={onClose}
      primaryTitle={props.primaryTitle}
      secondaryTitle={props.secondaryTitle}
      hideButtons
      styleType="edit"
    >
      {content}
      <DialogActions>
        <FormButtons onCancel={onClose} onSave={onSave} saveInsteadOfSubmit={true}/>
      </DialogActions>
    </Popup>;
  };
