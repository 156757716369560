import {GridColDef} from "@mui/x-data-grid-pro";
import {
  EntityDetailsProps,
  EntityTableRow,
  InfiniteScrollTableCursor,
  Popup,
  TabBar
} from "@sivis/shared/components/entityView";
import {System, SystemType, useGetSystemQuery} from "@sivis/identity/api";
import {useSystemPageForSystemTypeApi} from "./useSystemPageForSystemTypeApi";
import {useCustomIntl, useTranslateColumns, useTranslateConfigs} from "@sivis/intl";
import {useEffect, useState} from "react";
import {ConnectionInformationTab} from "../components/popup/connectionInformationTab";
import {RelationEntityType} from "../components/popup/relationUtils";

export interface SystemTypeSystemsTabProps {
  systemType?: SystemType,
}

const entityToTableRow = (entity: System): EntityTableRow => {
  return {
    id: entity.id,
    systemName: entity.systemName,
    systemDescription: entity.systemDescription,
  };
};

const columns: GridColDef[] = [
  {
    field: "systemName",
    flex: 1,
  },
  {
    field: "systemDescription",
    flex: 1,
  }
];

const EntityDetails = ({entity}: EntityDetailsProps<System>) => {
  const intl = useCustomIntl();
  const configs = [{property: "systemName"}, {property: "systemDescription"}];
  const translatedConfigs = useTranslateConfigs(configs, "fusion.system.propertyName");
  const tabs = [
    {
      label: intl.format("fusion.system.propertyName.systemName"),
      content: <ConnectionInformationTab
        configs={translatedConfigs}
        entity={entity}
        entityType={RelationEntityType.SYSTEM}
      />
    }
  ];
  return entity ? <TabBar tabs={tabs}/> : null;
};

const SystemTypeSystemsTab = (props: SystemTypeSystemsTabProps) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [systemId, setSystemId] = useState<string>();
  const {data} = useGetSystemQuery({id: (systemId ? systemId : "")});
  const [selectedSystem, setSelectedSystem] = useState<System | null>(null);

  const intl = useCustomIntl();
  const translatedColumns = useTranslateColumns(columns, "fusion.system.propertyName");

  const {
    onSearch,
    useSystemPageForSystemTypePageQuery,
    parseSystemsPageResult,
    pageSize
  } = useSystemPageForSystemTypeApi(props.systemType?.id);

  useEffect(() => {
    if (data?.system) {
      setSelectedSystem(data.system);
    }
  }, [data?.system]);

  const onRowClick = (id: string) => {
    if (id !== systemId) {
      setSelectedSystem(null);
    }
    setSystemId(id);
    setPopupOpen(true);
  }

  const onClosePopup = () => {
    setPopupOpen(false);
  }

  return (
    <>
      <InfiniteScrollTableCursor
        columns={translatedColumns}
        pageSize={pageSize}
        usePageQuery={useSystemPageForSystemTypePageQuery}
        renderRow={entityToTableRow}
        parseResult={parseSystemsPageResult}
        onSearch={onSearch}
        placeholder={intl.format("fusion.general.searchPlaceholder")}
        onRowClick={onRowClick}
      />
      <Popup
        open={popupOpen}
        primaryTitle={selectedSystem?.systemName ?? ""}
        secondaryTitle={intl.format("fusion.systemType.system.popup.secondaryTitle", {
          system: selectedSystem?.systemName,
          systemType: props.systemType?.systemTypeName
        })}
        onClose={onClosePopup}
        hideButtons
        styleType="edit"
      >
        {selectedSystem ? <EntityDetails
          entity={selectedSystem}
          editMode={false}
        /> : null}
      </Popup>
    </>
  );
}

export default SystemTypeSystemsTab;
