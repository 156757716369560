import {useIdentitiesPageApi} from "../identity/useIdentitiesPageApi";
import {useTranslateColumns} from "@sivis/intl";
import {
  identityOwnershipColumns,
  isResource,
  isResourceSet,
  isRole,
  isSystem
} from "./ownershipUtils";
import {EOwnershipType, Identity, identityName} from "@sivis/identity/api";
import {addButton, removeButton} from "../membership/createMembershipForIdentity";
import styles from "./ownership.module.scss";
import {ListAddView} from "@sivis/shared/components/entityView";
import {CreateOwnershipProps} from "./createOwnership";

export const CreateOwnershipIdentitiesList = (props: CreateOwnershipProps) => {
  const {
    onSearch,
    useIdentitiesPageQuery,
    parseIdentitiesPageResult,
    pageSize
  } = useIdentitiesPageApi();
  const translatedColumns = useTranslateColumns(identityOwnershipColumns, "fusion.identity.propertyName");

  const entityToTableRow = (entity: Identity, isSelected: boolean) => {
    return {
      id: entity.id,
      fullName: identityName(entity),
      numAccounts: entity.accountsCount,
      dataObject: entity,
      isNew: isSelected,
      action: isSelected ? removeButton : addButton
    };
  };

  let type: EOwnershipType;
  if (isResource(props.selectedEntity)) {
    type = EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP;
  } else if (isResourceSet(props.selectedEntity)) {
    type = EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP;
  } else if (isSystem(props.selectedEntity)) {
    type = EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP;
  } else if (isRole(props.selectedEntity)) {
    type = EOwnershipType.IDENTITY_ROLE_OWNERSHIP;
  } else {
    throw new Error("Unknown entity type");
  }

  const onAdd = (identities: Identity[]) => {
    props.onAdd(identities.map(identity => ({
      id: identity.id + props.selectedEntity.id,
      ownerId: identity.id,
      targetId: props.selectedEntity.id,
      type: type,
      targetName: identityName(identity)
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={entityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useIdentitiesPageQuery}
      parseResult={parseIdentitiesPageResult}
      excludedItems={[]}
    />
  </div>;
};
