import { Outlet, RouteObject } from "react-router-dom";
import { IdentityTypeList } from "./identityTypeList";
import {
  IdentityTypeIdentitysTabWrapperComponent,
  IdentityTypeInformationTabWrapperComponent,
  IdentityTypeFieldDefinitionTabWrapperComponent,
  IdentityTypeView
} from "./identityTypeView";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import { IDENTITIES_TAB_PATHNAME, INFORMATION_TAB_PATHNAME, FIELDDEFINITION_TAB_PATHNAME } from "../components/routes/constants";
import { EntityType } from "../components/breadcrumb/editModeContext";
import { useGetIdentityTypeQuery } from "@sivis/identity/api";

const useGetIdentityTypeName = (id: string, isNew: boolean) => {
  const { data } = useGetIdentityTypeQuery({ id }, { skip: isNew });
  return data?.identityType?.name;
};

export const identityTypeRoutes: RouteObject[] = [
  {
    index: true,
    element: <IdentityTypeList />
  },
  {
    path: ":id",
    element: <Outlet />,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetIdentityTypeName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <IdentityTypeView />,
      },
      {
        element: <IdentityTypeView />,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <IdentityTypeInformationTabWrapperComponent />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.IDENTITY_TYPE
              })
            }
          },
          {
            path: IDENTITIES_TAB_PATHNAME,
            element: <IdentityTypeIdentitysTabWrapperComponent />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.identities",
                intlIdEdit: "fusion.breadcrumb.identities.edit",
                entity: EntityType.IDENTITY_TYPE
              })
            }
          },
          {
            path: FIELDDEFINITION_TAB_PATHNAME,
            element: <IdentityTypeFieldDefinitionTabWrapperComponent />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.fielddefinitions",
                intlIdEdit: "fusion.breadcrumb.fielddefinitions.edit",
                entity: EntityType.FIELD_DEFINITION
              })
            }
          }
        ]
      }
    ]
  },
];
