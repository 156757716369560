import {normalizePath} from '../HttpUtils';

export enum ApiServices {
  core = 'core',
  reduction_manager = 'reduction-manager',
  auth_robot = 'auth-robot',
  compliance_manager = 'compliance-manager',
  koex = ''
}

export const WEB_API_PATH_PREFIX = '/sivisweb_api';
export const API_PATH_PREFIX = '/api';
export const prefixedWebApiPath = (subPath: string): string => {
  return normalizePath(WEB_API_PATH_PREFIX, subPath);
}
export const prefixedApiPath = (subPath: string): string => {
  return normalizePath(API_PATH_PREFIX, subPath);
}
export const CORE_BASE_PATH = prefixedWebApiPath(ApiServices.core);
export const REDUCTION_MANAGER_BASE_PATH = prefixedWebApiPath(ApiServices.reduction_manager);
export const AUTH_ROBOT_BASE_PATH = prefixedWebApiPath(ApiServices.auth_robot);
export const COMPLIANCE_MANAGER_BASE_PATH = prefixedWebApiPath(ApiServices.compliance_manager);
export const corePath = (...pathParts: string[]): string => normalizePath(CORE_BASE_PATH, ...pathParts);
export const KOEX_BASE_PATH = prefixedApiPath(ApiServices.koex);
