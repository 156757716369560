import {forceRefetchPaging, mergePaging, serializeQueryArgsPaging, transformResponseWithArgs} from "@sivis/shared/api-utils";
import {api as generatedAttributeSyncMappingApi} from './attributeSyncMapping.generated';

export const attributeSyncMappingApi = generatedAttributeSyncMappingApi.enhanceEndpoints({
  addTagTypes: ["attributeSyncMapping"],
  endpoints: {
    GetAttributeSyncMappings: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("asMapping"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs
    },
    CreateAttributeSyncMapping: { invalidatesTags: ["attributeSyncMapping"] },
    UpdateAttributeSyncMapping: { invalidatesTags: ["attributeSyncMapping"] },
    DeleteAttributeSyncMapping: { invalidatesTags: ["attributeSyncMapping"] }
  }
})

export const {
    useGetAttributeSyncMappingsQuery,
    useLazyGetAttributeSyncMappingsQuery,
    useCreateAttributeSyncMappingMutation,
    useUpdateAttributeSyncMappingMutation,
    useDeleteAttributeSyncMappingMutation
    } = attributeSyncMappingApi;