import React from "react";
import styles from "./form.module.scss";

export interface AttributeRowProps {
  name: string;
  value: string | JSX.Element;
}

export const AttributeRow = React.memo((props: AttributeRowProps) => {
  const {
    value,
    name
  } = props;

  return <div className={styles.row} role="listitem">
    <div>
      <label className={styles.rowName} htmlFor={name}>{name}</label>
    </div>
    {value}
  </div>;
});
