import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { ExtendedRoleEntitlement, System } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import { EntityTableRow, ListModifyView } from "@sivis/shared/components/entityView";
import { isEqualById } from "@sivis/shared/utils";
import { ConfirmationPopup } from "../components/popup/confirmationPopup";
import { useSystemRoleEntitlementsApi } from "../entitlement/api/useSystemRoleEntitlementsApi";
import {
  getEntitlementFlags,
  getRoleSystemEntitlementDescription,
  mapTableRowToEntitlement,
  renderRoleOption,
  roleOptionToEntitlement,
  roleToOption,
  systemEntitlementColumns,
  useEntitlementMessages
} from "../entitlement/entitlementUtils";
import { RoleSystemEntitlementDetails } from "../entitlement/roleSystemEntitlementDetails";
import styles from "./system.module.scss";

interface SystemRolesTabProps {
  system: System;
  editMode: boolean;
}

const SystemRolesTab = ({system, editMode}: SystemRolesTabProps) => {
  const intl = useCustomIntl();
  const {deleteEntitlementMessage, addRoleMessage} = useEntitlementMessages();
  const {
    entitlements,
    onSave,
    onSearchForAvailableRoles,
    availableRoles,
    submitted,
    setSubmitted
  } = useSystemRoleEntitlementsApi(system);

  const getDescription = (entitlement: ExtendedRoleEntitlement, system: System) =>
    getRoleSystemEntitlementDescription(entitlement, entitlement.roleName ?? "", system.systemName, intl.format);

  const getPopupTitle = (entitlement: ExtendedRoleEntitlement) => ({
    primaryTitle: intl.format("fusion.entitlement.title.edit"),
    secondaryTitle: getDescription(entitlement, system)
  });

  const onSearch = () => {
  };

  const translatedSystemEntitlementColumns = useTranslateColumns(systemEntitlementColumns, "fusion.entitlement.systemEntitlement.propertyName")
  const shownColumns = editMode ? translatedSystemEntitlementColumns : translatedSystemEntitlementColumns.filter(column => column.field !== "action");

  const confirmationPopup = system ? <ConfirmationPopup
    open={submitted}
    onClose={() => setSubmitted(false)}
    primaryTitle={intl.format("fusion.entitlement.popup.title", {entity: system.systemName})}
    message={intl.format("fusion.message.submitted", {entity: system.systemName})}
  /> : null;

  const systemEntitlementToTableRow = (
    entity: ExtendedRoleEntitlement,
    isNew: boolean,
    onEdit?: (entity: ExtendedRoleEntitlement) => void,
    onDelete?: (entity: ExtendedRoleEntitlement) => void,
  ): EntityTableRow => {
    return {
      id: entity.entitledId + entity.targetId,
      name: entity.roleName,
      validTo: entity.validity?.validTo,
      isNew: isNew,
      flags: getEntitlementFlags({validity: entity.validity}, intl.format),
      action: <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onEdit?.(entity);
          }}
          aria-label="edit">
          <EditIcon/>
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete?.(entity);
          }}
          aria-label="delete">
          <ClearIcon/>
        </IconButton>
      </Box>
    };
  }

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyView
      columns={shownColumns}
      entities={entitlements}
      options={availableRoles.map(role => roleToOption(role, system.id))}
      onSearchForOptions={onSearchForAvailableRoles}
      renderOption={renderRoleOption}
      onSave={onSave}
      entityToTableRow={systemEntitlementToTableRow}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getPopupTitle}
      optionToEntity={roleOptionToEntitlement}
      isEqual={isEqualById}
      entityDetails={RoleSystemEntitlementDetails}
      optionPlaceholder={addRoleMessage}
      deleteMessage={deleteEntitlementMessage}
      mapTableRowToEntity={mapTableRowToEntitlement}
    />
  </div>
}

export default SystemRolesTab;
