import {Outlet, RouteObject} from "react-router-dom";
import {OrganizationalEntityTypeList} from "./organizationalEntityTypeList";
import {
  OrganizationalEntityTypeInformationTabWrapperComponent,
  OrganizationalEntityTypeOrganizationalEntitiesTabWrapperComponent,
  OrganizationalEntityTypeRelationsTabWrapperComponent,
  OrganizationalEntityTypeView
} from "./organizationalEntityTypeView";
import {useGetOrganizationalEntityTypeQuery} from "@sivis/identity/api";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import {EntityType} from "../components/breadcrumb/editModeContext";
import {
  INFORMATION_TAB_PATHNAME,
  ORGANIZATIONAL_ENTITIES_TAB_PATHNAME,
  TYPE_RELATIONS_TAB_PATHNAME
} from "../components/routes/constants";


const useGetOrganizationalEntityTypeName = (id: string, isNew: boolean) => {
  const {data} = useGetOrganizationalEntityTypeQuery({id}, {skip: isNew});
  return data?.organizationalEntityType?.name;
};

export const organizationalEntityTypeRoutes: RouteObject[] = [
  {
    index: true,
    element: <OrganizationalEntityTypeList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetOrganizationalEntityTypeName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <OrganizationalEntityTypeView/>,
      },
      {
        element: <OrganizationalEntityTypeView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <OrganizationalEntityTypeInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.ORGANIZATIONAL_ENTITY_TYPE
              })
            }
          },
          {
            path: ORGANIZATIONAL_ENTITIES_TAB_PATHNAME,
            element: <OrganizationalEntityTypeOrganizationalEntitiesTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.organizationalEntity.list.name",
                intlIdEdit: "fusion.breadcrumb.organizationalEntities.edit",
                entity: EntityType.ORGANIZATIONAL_ENTITY_TYPE
              })
            }
          },
          {
            path: TYPE_RELATIONS_TAB_PATHNAME,
            element: <OrganizationalEntityTypeRelationsTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.organizationalEntityTypeRelation.list.name",
                intlIdEdit: "fusion.breadcrumb.organizationalEntityTypeRelation.edit",
                entity: EntityType.ORGANIZATIONAL_ENTITY_TYPE_RELATION
              })
            }
          }
        ]
      }
    ]
  },
];

