import {createSelector, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {AppLoadingState} from './AppLoadingState';

export const APP_SLICE_NAME = 'app';

export interface AppState {
  /**
   * the application title
   */
  title?: string;
  /**
   * the application version
   */
  version?: string;
  /**
   * the home component id registered in the Registry
   */
  home?: string;
  loadingState: AppLoadingState;
}

const initialState: AppState = {
  title: 'SIVIS',
  loadingState: AppLoadingState.starting
}

export interface AppSliceState {
  app: AppState
}

const appSlice = createSlice({
  name: APP_SLICE_NAME,
  initialState,
  reducers: {
    setTitle: (state: Draft<AppState>, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setHome: (state: Draft<AppState>, action: PayloadAction<string>) => {
      state.home = action.payload;
    },
    setVersion: (state: Draft<AppState>, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    setLoadingState: (state: Draft<AppState>, action: PayloadAction<AppLoadingState>) => {
      state.loadingState = action.payload;
    },
    setInitializing: (state: Draft<AppState>) => {
      state.loadingState = AppLoadingState.initializing;
    },
    setRefreshing: (state: Draft<AppState>) => {
      state.loadingState = AppLoadingState.refreshing;
    },
    setReady: (state: Draft<AppState>) => {
      state.loadingState = AppLoadingState.ready;
    }
  }
});

export const {
  setTitle,
  setVersion,
  setHome,
  setInitializing,
  setRefreshing,
  setReady,
  setLoadingState
} = appSlice.actions;

export const appReducer = appSlice.reducer;

const selectAppSlice = createSelector(
  (state: AppSliceState) => state,
  state => state.app
);

export const selectTitle = createSelector(
  selectAppSlice,
  state => state.title
);

export const selectVersion = createSelector(
  selectAppSlice,
  state => state.version
);

export const selectHome = createSelector(
  selectAppSlice,
  state => state.home
);

export const selectIsStarting = createSelector(
  selectAppSlice,
  state => state.loadingState = AppLoadingState.starting
);

export const selectLoadingState = createSelector(
  selectAppSlice,
  state => state.loadingState
)
export const selectIsInitializing = createSelector(
  selectLoadingState,
  loadingState => loadingState === AppLoadingState.initializing
);

export const selectIsRefreshing = createSelector(
  selectLoadingState,
  loadingState => loadingState = AppLoadingState.refreshing
);

export const selectIsReady = createSelector(
  selectLoadingState,
  loadingState => loadingState === AppLoadingState.ready
);

