import { Entitlement, EntitlementType, Identity, Resource, SideFilterQueryType } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import { ListAddView, TabBar } from "@sivis/shared/components/entityView";
import { useIdentitiesSideFilter } from "../ownership/components/useIdentitiesSideFilter";
import { useIdentitiesForCreateEntitlementApi } from "./api/useIdentitiesForCreateEntitlementApi";
import styles from "./entitlement.module.scss";
import { addIdentityEntitlementColumns, identityEntityToTableRow } from "./entitlementUtils";

interface CreateIdentityEntitlementForResourceProps {
  resource: Resource;
  onAdd: (added: Entitlement[]) => void;
}

export const CreateIdentityEntitlementForResource = (props: CreateIdentityEntitlementForResourceProps) => {
  const intl = useCustomIntl();

  const tabs = [
    {
      label: intl.format("fusion.identity.list.name"),
      content: <CreateEntitlementIdentityList {...props} />
    }
  ];

  return <TabBar tabs={tabs}/>;
};

const CreateEntitlementIdentityList = (props: CreateIdentityEntitlementForResourceProps) => {
  const {activeFilter, filterComponent} = useIdentitiesSideFilter(SideFilterQueryType.IDENTITIES);
  const {
    onSearch,
    useIdentitiesPageQuery,
    parseIdentitiesPageResult,
    pageSize
  } = useIdentitiesForCreateEntitlementApi(props.resource.id, activeFilter ?? undefined);
  const translatedColumns = useTranslateColumns(addIdentityEntitlementColumns, "fusion.entitlement.identityEntitlement.propertyName");

  const onAdd = (identities: Identity[]) => {
    props.onAdd(identities.map(identity => ({
      id: identity.id + props.resource.id,
      entitledId: identity.id,
      targetId: props.resource.id,
      type: EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT,
      identityFirstName: identity.firstName,
      identityLastName: identity.lastName,
      identityPrimaryEmail: identity.primaryEmail,
      identityAccounts: 0
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={identityEntityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useIdentitiesPageQuery}
      parseResult={parseIdentitiesPageResult}
      sideFilter={filterComponent}
      excludedItems={[]}
      tableType={SideFilterQueryType.IDENTITIES}
    />
  </div>;
};
