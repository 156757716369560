import {GridColDef} from '@mui/x-data-grid';
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import {ResourceSet} from "@sivis/identity/api";
import {Link} from "react-router-dom";
import {useResourceSetPageApi} from './useResourceSetPageApi';
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: ResourceSet) => {
  return {
    id: entity.id,
    name: entity.name,
    // TODO: calculate amount of resources in backend
    amountOfResources: entity.resources?.edges.length,
    description: entity.description
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "amountOfResources",
    flex: 1
  },
  {
    field: "description",
    flex: 1
  },
];

export const ResourceSetList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useResourceSetsPageQuery,
    parseResourceSetsPageResult,
    pageSize
  } = useResourceSetPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.resourceSet.propertyName");

  return <EntityView
    title={intl.format("fusion.resourceSet.list.title")}
    actionButtons={[<CreateButton key={"create"}/>]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useResourceSetsPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseResourceSetsPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};

