import {RegistryEntry} from './RegistryEntry';
import {LoggingService} from '@sivis/shared/utils';

export class Registry {
  private static entries: Map<string, RegistryEntry> = new Map();

  public static has<ID extends string = string>(id: ID): boolean {
    return this.entries.has(id);
  }

  public static add<ID extends string = string, P = unknown>(entry: RegistryEntry<ID, P>, forceOverwrite = false): boolean {
    if (!entry?.id) {
      throw new Error('Cannot add registry entry without id')
    }

    if (!entry.component) {
      throw new Error('Cannot add registry entry without component')
    }

    if (forceOverwrite) {
      this.entries.set(entry.id, entry as RegistryEntry<ID>);
      return true
    }

    if (this.has(entry.id)) {
      throw new Error(`Entry with id (${entry.id}) is already registered`);
    }

    this.entries.set(entry.id, entry as RegistryEntry<ID>);
    return true

  }

  public static get<ID extends string = string, P = unknown>(id: ID, throwErrorOnMissing = true): RegistryEntry<ID, P> {
    if (!id) {
      throw new Error('id may not be null or empty')
    }

    const entry = this.entries.get(id) as RegistryEntry<ID, P>;

    if (!entry && throwErrorOnMissing) {
      throw new Error(`Entry with id: ${id} not found`);
    }

    return entry;
  }

  public static getUrl<ID extends string = string>(id: ID, fallback = '/') {
    const entry = this.get(id, false);
    if (!entry) {
      LoggingService.warn(`Entry with ${id} not found`, 'useRegistryNavigator');
      return fallback;
    }

    const {url} = entry;

    if (!url) {
      LoggingService.warn(`Entry with id: ${id} has no url`, 'useRegistryNavigator');
      return fallback;
    }

    return url;
  }

  public static getByType<ID extends string = string>(componentType: string, ordered?: boolean): RegistryEntry[] {
    const entriesByType = [];
    for (const entry of this.entries.values()) {
      if (entry.type !== componentType || !entry.component) {
        continue;
      }

      entriesByType.push(entry);
    }

    if (ordered) {
      return entriesByType.sort((left, right) => (left.order ?? 0) - (right.order ?? 0))
    }

    return entriesByType;
  }
}
