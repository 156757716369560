import { useOutletContext } from 'react-router-dom';
import { IntlButton, useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import {
  calculateDescriptionForRule,
  calculateDescriptionForRuleList,
  emptyBusinessRuleWithJSXElementCriteriaGroups,
  emptyBusinessRuleWithStringCriteriaGroups,
  updateWatchValues
} from './ruleUtils';
import { useEffect, useState } from 'react';
import { EntityInformation, Popup, TabBar } from '@sivis/shared/components/entityView';
import styles from './rule.module.scss';
import { ContextType } from './ruleView';
import { RuleAffectedIdentitiesTab } from './ruleAffectedIdentitiesTab';
import { RuleEditCreatePopupInformationTab } from './ruleEditCreatePopupInformationTab';
import { Spinner } from '@sivis/app-shell';
import { BusinessRule, RuleStatus } from '@sivis/identity/api';
import DraftStatusBar from '../components/draft/draftStatusBar';
import DraftCard from '../components/draft/draftCard';

const configsGeneral = [
  {
    property: 'name',
    required: true
  },
  {
    property: 'status',
    disabled: true
  },
  {
    property: 'targetValue',
    required: true
  },
  {
    property: 'criteriaGroups',
    disabled: true
  }
];

export const RuleInformationTab = () => {
  const {
    editMode,
    isLoading,
    toggleEditMode,
    isNew,
    businessRule,
    setBusinessRule,
    onSave,
    onPublish,
    onCancel,
    watchValues,
    setWatchValues,
    draftData,
    setDraftData,
    onDeleteDraft
  } = useOutletContext<ContextType>();
  const intl = useCustomIntl();
  const [openEditCriteriaPopup, setOpenEditCriteriaPopup] = useState(false);
  const [flattenedBusinessRule, setFlattenedBusinessRule] = useState(editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups);
  const [flattenedDraft, setFlattenedDraft] = useState(editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups);
  const translatedConfigs = useTranslateConfigs(configsGeneral, 'fusion.rule.propertyName');
  const primaryTitleRuleCriteriaPopup = isNew ? intl.format('fusion.rule.createRule.title') : intl.format('fusion.rule.editRule.title', { rule: businessRule?.name });
  const secondaryTitleRuleCriteriaPopup = isNew ? intl.format('fusion.rule.createRule.secondaryTitle') : intl.format('fusion.rule.editRule.secondaryTitle', { rule: businessRule?.name });

  const toggleOpenEditCriteriaPopupMode = () => {
    setOpenEditCriteriaPopup(!openEditCriteriaPopup);
  };

  const handleOnPublish = () => {
    if (businessRule) {
      const updatedBusinessRule: BusinessRule = {
        ...businessRule,
        ...watchValues['mainForm'],
        criteriaGroups: businessRule?.criteriaGroups ?? [],
        isPublished: true
      };
      setBusinessRule(updatedBusinessRule);
      onPublish(updatedBusinessRule);
      if (!isNew) {
        toggleEditMode();
      }
    }
  };

  const handleOnSaveDraft = () => {
    let updated: BusinessRule;
    if (draftData) {
      // update existing draft
      updated = {
        ...draftData,
        ...watchValues['mainForm'],
        criteriaGroups: draftData?.criteriaGroups ?? [],
        isPublished: false
      };
    } else {
      // create new draft
      updated = {
        ...businessRule,
        ...watchValues['mainForm'],
        criteriaGroups: businessRule?.criteriaGroups ?? [],
        isPublished: false
      };
    }
    setDraftData(updated);
    onSave(updated);
    if (!isNew) {
      toggleEditMode();
    }
  };

  useEffect(() => {
    const updatedRule = businessRule ? {
      ...businessRule,
      criteriaGroups: editMode
        ? calculateDescriptionForRuleList((businessRule.criteriaGroups ?? []), intl.format, businessRule.operatorOutsideGroup).toString()
        : calculateDescriptionForRule((businessRule.criteriaGroups ?? []), intl.format, businessRule.operatorOutsideGroup)
    } : editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups;
    setFlattenedBusinessRule(updatedRule);
  }, [businessRule, editMode]);

  useEffect(() => {
    const updatedDraft = draftData ? {
      ...draftData,
      criteriaGroups: editMode
        ? calculateDescriptionForRuleList((draftData.criteriaGroups ?? []), intl.format, draftData.operatorOutsideGroup).toString()
        : calculateDescriptionForRule((draftData.criteriaGroups ?? []), intl.format, draftData.operatorOutsideGroup)
    } : flattenedBusinessRule;
    setFlattenedDraft(updatedDraft);
  }, [draftData, editMode]);

  if (isLoading) {
    return <Spinner />;
  }

  const rulePopupTabs = [
    {
      label: intl.format('fusion.rule.ruleCriteria'),
      content: <RuleEditCreatePopupInformationTab
        togglePopupOpen={toggleOpenEditCriteriaPopupMode} />
    },
    {
      label: intl.format('fusion.rule.affectedIdentities.list.name'),
      content: <RuleAffectedIdentitiesTab />
    }
  ];

  const ruleCriteriaPopup = <Popup
    open={openEditCriteriaPopup}
    onClose={() => setOpenEditCriteriaPopup(false)}
    primaryTitle={primaryTitleRuleCriteriaPopup}
    secondaryTitle={secondaryTitleRuleCriteriaPopup}
    styleType="edit"
    hideButtons
  >
    <TabBar tabs={rulePopupTabs} />
  </Popup>;

  const entityDetailsGeneral = <>
    <DraftStatusBar
      isActive={businessRule.status === RuleStatus.Active}
      draftData={draftData ? !draftData.isPublished : !businessRule.isPublished}
      additionalInformationLabel={'fusion.rule.propertyName.changes'}
      additionalInformationText={businessRule.changes ?? 'Not active'}
    />
    {editMode && draftData?.publishedId != null &&
      <DraftCard
        draftData={{
          updatedAt: draftData.meta?.updatedAt ?? Date.now().toString(),
          updatedBy: draftData.meta?.updatedBy ?? Date.now().toString()
        }}
        onDiscardDraft={() => onDeleteDraft(draftData.id)}
      />
    }
    <EntityInformation
      key={JSON.stringify(flattenedBusinessRule)}
      editMode={editMode}
      configs={translatedConfigs}
      value={editMode && draftData ? flattenedDraft : flattenedBusinessRule}
      onSubmit={handleOnPublish}
      onSaveDraft={handleOnSaveDraft}
      onCancel={onCancel}
      onWatch={(watchValues) => {
        updateWatchValues('mainForm', watchValues, setWatchValues);
      }}
      displayInTwoColumns={false}
      isDraftable={true}
    />
  </>;

  // TODO: fix layout of button and criteria
  const editCriteriaButton = <IntlButton
    variant="outlined"
    onClick={toggleOpenEditCriteriaPopupMode}
    disabled={!editMode}
    intlId="fusion.rule.editCriteria"
  />;

  return <div className={styles.listContainer}>
    {entityDetailsGeneral}
    {editMode && editCriteriaButton}
    {openEditCriteriaPopup && ruleCriteriaPopup}
  </div>;
};
