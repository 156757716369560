import { Controller, useForm } from 'react-hook-form';
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import styles from './form.module.scss';
import { FormButtons, FormButtonsProps } from './FormButtons';
import { getSingleProperty, splitConfigsInThreeColumns, splitConfigsInTwoColumns } from './utils';
import { isEqual } from 'lodash';
import {
  AttributeConfig,
  AttributeRowConfig,
} from './attributeRowConfig';
import RenderField from './RenderFieldComponent';
import RenderRowComponent from './RenderRowComponent';

export interface SearchConfig {
  searchFunction: (searchTerm: string) => any;
  searchResults: any;
  loadingState: boolean;
  currentValue?: {
    id: string;
    [key: string]: any; // Allows different properties for identity and system
  };
}

export interface EntityFormProps extends FormButtonsProps {
  value: Record<string, any>;
  configs: AttributeRowConfig[];
  onSubmit: (value: Record<string, any>) => void;
  hideButtons?: boolean;
  displayInTwoColumns?: boolean;
  displayInThreeColumns?: boolean;
  onWatch?: (value: Record<string, any>) => void;
  searchFunction?: (searchTerm: string) => any;
  searchResults?: any;
  loadingEntities?: boolean;
  searchFunctions?: {
    [key: string]: SearchConfig;
  };
}

export type EntityFormHandle = {
  // Allow submitting form data from outside
  submit: () => void;
};

export const EntityForm = forwardRef<EntityFormHandle, EntityFormProps>(
  (props: EntityFormProps, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
    } = useForm({ defaultValues: props.value });

    const watchAllFields = watch((value: Record<string, any>) => {
      if (props.onWatch) {
        props.onWatch(value);
      }
    });

    const onSubmit = (data: Record<string, any>) => {
      props.onSubmit(data);
    };

    const prevValueRef = useRef(props.value);

    useEffect(() => {
      if (!isEqual(prevValueRef.current, props.value)) {
        reset(props.value);
        prevValueRef.current = props.value;
      }
    }, [props.value, reset]);

    const submitForm = handleSubmit(onSubmit);

    useImperativeHandle(
      ref,
      () => ({
        submit() {
          submitForm();
        },
      }),
      [submitForm]
    );

    const renderController = (config: AttributeConfig) => (
      <Controller
        name={config.property}
        control={control}
        rules={{
          required: config.required ? `${config.name} is required!` : undefined,
          validate: config.validate,
        }}
        render={({ field }) => (
          <RenderField
            config={config}
            fieldProps={field}
            searchFunctions={props.searchFunctions}
            searchFunction={props.searchFunction}
            searchResults={props.searchResults}
            loadingEnities={props.loadingEntities}
            value={props.value}
          />
        )}
      />
    );

    const renderRow = (config: AttributeRowConfig) => (
      <RenderRowComponent
        key={getSingleProperty(config.property)}
        config={config}
        errors={errors}
        renderController={renderController}
        watchAllFields={watchAllFields}
      />
    );

    const getRenderedRows = () => {
      if (props.displayInTwoColumns) {
        return (
          <div className={styles.twoColumnsContainer}>
            {splitConfigsInTwoColumns(props.configs).map((group, index) => (
              <div key={index}>{group.map(renderRow)}</div>
            ))}
          </div>
        );
      } else if (props.displayInThreeColumns) {
        return (
          <div className={styles.threeColumnsContainer}>
            {splitConfigsInThreeColumns(props.configs).map((group, index) => (
              <div key={index}>{group.map(renderRow)}</div>
            ))}
          </div>
        );
      } else {
        return props.configs.map(renderRow);
      }
    };

    const renderRows = getRenderedRows();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderRows}
        {props.hideButtons ? null : <FormButtons {...props} />}
      </form>
    );
  }
);
