import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import styles from "./LoginForward.module.scss";
import {Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {ELogoPlace, Logo} from "@sivis/shared/components/logo";

interface LoginForwardProps {
  onForward: (path: string) => void;
}

const LoginForward = ({onForward}: LoginForwardProps) => {
  const location = useLocation();

  const getLocationWithQuery = () => {
    // Query parameters should not be discarded, even when logging in via SSO.
    // Inbox $linkToTask$ Email field relies on query parameters to be present.
    const query = location.search;
    return location.pathname + (query ?? "");
  };


  useEffect(() => {
    setTimeout(() => {
      onForward(getLocationWithQuery());
    }, 3000)
  }, [])

  return (
    <div className={styles.forwardContainer}>
      <Logo
        place={ELogoPlace.LOGIN}
        alt="SIVIS GmbH"
      />
      <Typography variant={"body1"}><FormattedMessage id={"login.sso.forward"}/> </Typography>
    </div>
  )
}
export default LoginForward;
