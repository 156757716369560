import {
  GeneratedResourceTypeTypes,
  ResourceType,
  searchResourceTypesFilter,
  useGetResourceTypesQuery
} from "@sivis/identity/api";
import {CursorBasedPageResult} from "@sivis/shared/components/entityView";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";

export const useResourceTypesPageApi = () => {
  const { filter, onSearch } = useListSearch(searchResourceTypesFilter);

  const useResourceTypesPageQuery = (cursor: string | null) =>
    useGetResourceTypesQuery({ first: DEFAULT_PAGE_SIZE, after: cursor, sort: DEFAULT_ENTITY_SORT, filter });


  const parseResourceTypesPageResult = (res?: GeneratedResourceTypeTypes.GetResourceTypesQuery): CursorBasedPageResult<ResourceType> => {
    return parseCursorBasedPageResult('resourceTypes', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useResourceTypesPageQuery,
    parseResourceTypesPageResult
  };
};
