import {ELogoPlace, selectLogoByPlace} from './logoSlice';
import {useSelector} from 'react-redux';
import {MouseEventHandler} from 'react';

export interface LogoProps {
  place: ELogoPlace
  alt?: string
  className?: string,
  onDoubleClick?: MouseEventHandler<unknown>
}

export const Logo = ({place, className, alt, onDoubleClick}: LogoProps) => {
  const logoData = useSelector(state => selectLogoByPlace(state, place));
  return <img src={logoData.image} alt={alt} className={className ?? ''}
              onDoubleClick={onDoubleClick}/>
}
