import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    AsDefinition,
    AsdInput,
    AsdInputFactory,
    useCreateAttributeSyncDefinitionMutation,
    useDeleteAttributeSyncDefinitionMutation,
    useGetAttributeSyncDefinitionQuery,
    useGetDraftForAttributeSyncDefinitionQuery,
    useUpdateAttributeSyncDefinitionMutation,
    useSaveDraftAttributeSyncDefinitionMutation,
    useDiscardDraftAttributeSyncDefinitionMutation,
    usePublishDraftAttributeSyncDefinitionMutation
} from "@sivis/attributesync/api";
import { newId } from "../../common/newId";
import { EntityType, useSingleEditMode } from "../../components/breadcrumb/editModeContext";

export const useAttributeSyncDefinitionApi = (systemId?: string) => {
    const { asDefinitionId = newId } = useParams<{ asDefinitionId: string }>();
    const isNew = asDefinitionId === newId;
    const { data, error, isLoading, refetch } = useGetAttributeSyncDefinitionQuery({ id: asDefinitionId }, {
        skip: isNew,
        // This is set to false for now to avoid unnecessary refetching of the data
        // If this causes issues, set it to true
        refetchOnMountOrArgChange: false
    });
    const {data: draftRawData, refetch: refetchDraft} = useGetDraftForAttributeSyncDefinitionQuery({id: asDefinitionId}, {
        refetchOnMountOrArgChange: false
    });
    const navigate = useNavigate();
    const [deleteAsDefinition] = useDeleteAttributeSyncDefinitionMutation();
    const [updateAsDefinition] = useUpdateAttributeSyncDefinitionMutation();
    const [createAsDefinition] = useCreateAttributeSyncDefinitionMutation();
    const [saveDraft] = useSaveDraftAttributeSyncDefinitionMutation();
    const [discardDraft] = useDiscardDraftAttributeSyncDefinitionMutation();
    const [publishDraft] = usePublishDraftAttributeSyncDefinitionMutation();
    const [input, setInput] = useState<AsdInput>(AsdInputFactory());
    const [asDefinition, setAsDefinition] = useState<AsDefinition>();
    const [draftData, setDraftData] = useState<AsDefinition>();
    const { editMode, toggleEditMode } = useSingleEditMode(EntityType.ATTRIBUTE_SYNC_DEFINITION, isNew);

    useEffect(() => {
        setInput(AsdInputFactory(data?.asDefinition));
        setAsDefinition(data?.asDefinition ?? undefined);
        setDraftData(draftRawData?.draftForASDefinition ?? undefined);
    }, [data?.asDefinition, draftRawData?.draftForASDefinition]);

    let onSave;
    let onDelete;
    let onChangeStatus;
    let onCancel;
    let onSaveDraft;
    let onPublishDraft;
    let onDiscardDraft;

    if (isNew) {
        onSave = async (value: AsdInput | AsDefinition) => {
            value.systemId = systemId;
            value.isPublished = true;
            await createAsDefinition({ input: AsdInputFactory(value) })
                .then(() => {
                    navigate(`/system/${systemId}/attribute-synchronization-definition`);
                });
        };
        onCancel = () => {
            navigate(`/system/${systemId}/attribute-synchronization-definition`);
        };
    } else {
        onSave = async (value: AsdInput | AsDefinition) => {
            await updateAsDefinition({ id: asDefinitionId, input: AsdInputFactory(value) })
                .then(() => {
                    toggleEditMode();
                    refetch();
                });
            }
        };
        onCancel = () => {
            toggleEditMode();
            refetch();
        };
        onDelete = async () => {
            await deleteAsDefinition({ id: asDefinitionId }).then(() => navigate(`/system/${systemId}/attribute-synchronization-definition`));
        };
        onChangeStatus = async (value: AsdInput | AsDefinition, status: boolean) => {
            const updatedValue = { ...value, isActive: status };
            await updateAsDefinition({ id: asDefinitionId, input: AsdInputFactory(updatedValue) }).then(refetch);
        };

        onSaveDraft = async (value: AsdInput | AsDefinition) => {
            await saveDraft({ id: asDefinitionId, input: AsdInputFactory(value) }).then(() => {
                toggleEditMode();
                refetchDraft();
            });
        };

        onDiscardDraft = async (draftId: string) => {
            await discardDraft({ draftId: draftId }).then(() => {
                toggleEditMode();
                refetchDraft();
            });
        };

        onPublishDraft = async (draftId: string, value: AsdInput | AsDefinition) => {
            await publishDraft({ draftId: draftId, input: AsdInputFactory(value) }).then(() => {
                toggleEditMode();
                refetch();
                refetchDraft();
            });
        };
        
    return {
        id: asDefinitionId,
        asDefinition,
        draftData,
        onSaveDraft,
        onDiscardDraft,
        onPublishDraft,
        defaultInput: input,
        error,
        isNew,
        onSave,
        onDelete,
        onChangeStatus,
        onCancel,
        isLoading,
        refetch,
        editMode,
        toggleEditMode
    };
}