import {GridColDef} from "@mui/x-data-grid-pro";
import {EntityTable, EntityTableRow} from "@sivis/shared/components/entityView";
import {Address, OrganizationalEntity} from "@sivis/identity/api";
import {useOrganizationalEntityPageForAddressApi} from "./useOrganizationalEntityPageForAddressApi";
import {useEffect, useState} from "react";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

export interface AddressOrganizationalEntitiesTabProps {
  address?: Address
}

const entityToTableRow = (entity: OrganizationalEntity): EntityTableRow => {
  return {
    id: entity.id,
    organizationalEntityName: entity.name,
    alternativeName: entity.alternativeName,
    customID: entity.customID,
  };
};

const columns: GridColDef[] = [
  {
    field: "organizationalEntityName",
    flex: 1,
  },
  {
    field: "alternativeName",
    flex: 1,
  },
  {
    field: "customID",
    flex: 1,
  }
];

// TODO: pagination with filter
const AddressOrganizationalEntitiesTab = (props: AddressOrganizationalEntitiesTabProps) => {
  const intl = useCustomIntl();
  const {
    onSearch,
    organizationalEntities
  } = useOrganizationalEntityPageForAddressApi({address: props.address});
  const [rows, setRows] = useState<EntityTableRow[]>([]);

  const translatedColumns = useTranslateColumns(columns, "fusion.organizationalEntity.propertyName");

  useEffect(() => {
    setRows(organizationalEntities.map(entityToTableRow));
  }, [organizationalEntities]);

  return <EntityTable
    columns={translatedColumns}
    rows={rows}
    onSearch={onSearch}
    placeholder={intl.format("fusion.general.searchPlaceholder")}
    totalEntries={rows.length}
  />;
}

export default AddressOrganizationalEntitiesTab;

