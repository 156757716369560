import {newId} from "../common/newId";
import {useNavigate, useParams} from "react-router-dom";
import {
  Address,
  AddressInput,
  useCreateAddressMutation,
  useDeleteAddressMutation,
  useGetAddressQuery,
  useUpdateAddressMutation
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {addressInputFactory} from "./addressUtils";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";


export const useAddressApi = () => {
  const {id = newId} = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useGetAddressQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [deleteAddress] = useDeleteAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [createAddress] = useCreateAddressMutation();
  const [input, setInput] = useState<AddressInput>(addressInputFactory());
  const [address, setAddress] = useState<Address>();
  const {editMode, toggleEditMode} = useSingleEditMode(EntityType.ADDRESS, isNew);

  useEffect(() => {
    setInput(addressInputFactory(data?.address))
    setAddress(data?.address ?? undefined);
  }, [data?.address]);

  const goBack = () => navigate("/address");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {
    onSave = (value: Record<string, any>) => {
      createAddress({input: addressInputFactory(value)}).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {
    onSave = (value: Record<string, any>) => {
      updateAddress({id, input: addressInputFactory(value)})
      .then(() => {
        toggleEditMode();
        refetch();
      });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deleteAddress({id}).then(goBack);
    };
  }


  return {
    id,
    address,
    defaultInput: input,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
};
