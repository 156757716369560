import {api as generatedSystemFieldApi} from './systemField.generated';

export const systemFieldApi = generatedSystemFieldApi.enhanceEndpoints({
    addTagTypes: ["systemField"],
    endpoints: {
        GetSystemFieldsForSystem: { providesTags: ["systemField"] },
        CreateSystemField: { invalidatesTags: ["systemField"] },
        UpdateSystemField: { invalidatesTags: ["systemField"] },
        DeleteSystemField: { invalidatesTags: ["systemField"] }
    }
});

export const {
    useGetSystemFieldsForSystemQuery,
    useLazyGetSystemFieldsForSystemQuery,
    useCreateSystemFieldMutation,
    useUpdateSystemFieldMutation,
    useDeleteSystemFieldMutation
    } = systemFieldApi;