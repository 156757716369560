import {
  OrganizationalEntityType,
  OrganizationalEntityTypeInput,
  organizationalEntityTypeInputFactory,
  useCreateOrganizationalEntityTypeMutation,
  useDeleteOrganizationalEntityTypeMutation,
  useGetOrganizationalEntityTypeQuery,
  useUpdateOrganizationalEntityTypeMutation
} from '@sivis/identity/api';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {EntityType, useSingleEditMode} from "../../components/breadcrumb/editModeContext";
import {newId} from "../../common/newId";


export const useOrganizationalEntityTypeApi = () => {
  const {id = newId} = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useGetOrganizationalEntityTypeQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [deleteOrganizationalEntityType] = useDeleteOrganizationalEntityTypeMutation();
  const [updateOrganizationalEntityType] = useUpdateOrganizationalEntityTypeMutation();
  const [createOrganizationalEntityType] = useCreateOrganizationalEntityTypeMutation();
  const [input, setInput] = useState<OrganizationalEntityTypeInput>(organizationalEntityTypeInputFactory());
  const [organizationalEntityType, setOrganizationalEntityType] = useState<OrganizationalEntityType>();
  const {
    editMode,
    toggleEditMode
  } = useSingleEditMode(EntityType.ORGANIZATIONAL_ENTITY_TYPE, isNew);

  useEffect(() => {
    setInput(organizationalEntityTypeInputFactory(data?.organizationalEntityType))
    setOrganizationalEntityType(data?.organizationalEntityType ?? undefined);
  }, [data?.organizationalEntityType]);

  const goBack = () => navigate("/organizational-entity-type");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {
    onSave = (value: Record<string, any>) => {
      createOrganizationalEntityType({input: organizationalEntityTypeInputFactory(value)}).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {
    onSave = (value: Record<string, any>) => {
      updateOrganizationalEntityType({id, input: organizationalEntityTypeInputFactory(value)})
      .then(() => {
        toggleEditMode();
        refetch();
      });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deleteOrganizationalEntityType({id}).then(goBack);
    };
  }

  return {
    id,
    organizationalEntityType,
    defaultInput: input,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
};
