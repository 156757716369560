import {useEffect, useState} from "react";
import {
  inboxLargeDesktopScreenSize,
  sivisLargeDesktopScreenSize,
  sivisMobileScreenSize
} from "./constants/constants";

export const useMobileView = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < sivisMobileScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < sivisMobileScreenSize);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export const useScreenWidth = () => {
  const width = window.innerWidth;
  const [isMobile, setIsMobile] = useState(width < sivisMobileScreenSize);
  const [isDesktop, setIsDesktop] = useState(width >= sivisMobileScreenSize && width < sivisLargeDesktopScreenSize);
  const [isLargeDesktop, setIsLargeDesktop] = useState(width >= sivisLargeDesktopScreenSize);
  // special case for inbox width check
  const [isInboxLargeDesktop, setIsInboxLargeDesktop] = useState(width >= inboxLargeDesktopScreenSize);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < sivisMobileScreenSize);
      setIsDesktop(width >= sivisMobileScreenSize && width < sivisLargeDesktopScreenSize);
      setIsLargeDesktop(width >= sivisLargeDesktopScreenSize);
      setIsInboxLargeDesktop(width >= inboxLargeDesktopScreenSize);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {isMobile, isDesktop, isLargeDesktop, isInboxLargeDesktop};
};

export const useContainerWidth = (ref: React.MutableRefObject<any>) => {
  const [containerWidth, setContainerWidth] = useState(0);

  const getContainerWidth = () => {
    if (ref && ref.current) {
      const current: any = ref.current;
      return current.offsetWidth;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(getContainerWidth());
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return containerWidth;
};
