import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  AsDefinition,
  AsMapping,
  DraftActions,
} from '@sivis/attributesync/api';
import { useCustomIntl } from '@sivis/intl';
import { useAttributeSyncMappingPageApi } from './api/useAttributeSyncMappingPageApi';
import { useAttributeSyncDefinitionApi } from '../attributeSynchronizationDefinition/api/useAttributeSyncDefinitionApi';
import {
  CreateButtonWithIcon,
  EntityTable,
  EntityTableRow,
  FormButtons,
  Popup,
} from '@sivis/shared/components/entityView';
import { useHandleSystemNameInExternalField } from './api/attributeSynchronizationMappingUtils';
import { System } from '@sivis/identity/api';
import DeleteMappingButton from './deleteMappingButton';
import { Edit as EditIcon } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid-pro';
import { AttributeSyncMappingForm } from './mappingForm/attributeSyncMappingForm';
import DraftCard from '../components/draft/draftCard';

interface AttributeSyncMappingTabProps {
  editMode: boolean;
  asDefinition: AsDefinition;
  draftData: AsDefinition;
  system: System;
  onCancel: () => void;
  openPublishPopup: (source: string) => void;
  onSaveDraft: (value: Record<string, any>) => void;
  onDiscardDraft: (draftId: string) => void;
  onPublishDraft: (draftId: string, value: Record<string, any>) => void;
}

export type AttributeSyncMappingTabHandle = {
  submit: () => void;
};

export const AttributeSyncMappingTab = forwardRef<
  AttributeSyncMappingTabHandle,
  AttributeSyncMappingTabProps
>((props: AttributeSyncMappingTabProps, ref) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [asDefinition, setAsDefinition] = useState<AsDefinition>(
    props.asDefinition
  );
  const [asMappings, setAsMappings] = useState<AsMapping[]>(
    asDefinition?.asMappings ?? []
  );
  const [editedMapping, setEditedMapping] = useState<AsMapping | null>(null);

  useImperativeHandle(ref, () => ({
    submit() {
      onSubmit();
    },
  }));

  const attributeSynchronizationMappingColumnsWithActions: GridColDef[] = [
    {
      field: 'pointsharpField',
      flex: 1,
    },
    {
      field: 'asMethods',
      flex: 1,
    },
    {
      field: 'externalField',
      flex: 1,
    },
    {
      field: 'priority',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key={id}
            className="textPrimary"
            onClick={onEdit(id)}
          />,
          <DeleteMappingButton
            id={id}
            key={id}
            intl={intl}
            asMappings={asMappings}
            onRemoveMapping={removeMapping}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (props.editMode && props.draftData) {
      setAsMappings(
        props.draftData.asMappings?.filter(
          (mapping) => mapping.draftAction !== DraftActions.Deleted
        ) ?? []
      );
    } else {
      setAsMappings(props.asDefinition?.asMappings ?? []);
    }
  }, [props.asDefinition, props.draftData, props.editMode]);

  const intl = useCustomIntl();
  const { onSearch } = useAttributeSyncMappingPageApi();
  const { onSave } = useAttributeSyncDefinitionApi();

  const translatedColumns = useHandleSystemNameInExternalField(
    attributeSynchronizationMappingColumnsWithActions,
    props.system?.systemName
  );

  const shownColumns = props.editMode
    ? translatedColumns
    : translatedColumns.filter((column) => column.field !== 'actions');

  const onCreate = () => {
    setPopupOpen(true);
    setAsDefinition(props.asDefinition);
    setEditedMapping(null);
  };

  const handleSubmit = () => {
    props.openPublishPopup('fields');
  };

  const handleDraft = () => {
    const updatedAsDefinition = !props.draftData
      ? {
          ...props.asDefinition,
          asMappings: asMappings,
          isPublished: false,
        }
      : {
          ...props.asDefinition,
          name: props.draftData.name,
          priority: props.draftData.priority,
          description: props.draftData.description,
          asMappings: asMappings,
        };
    props.onSaveDraft(updatedAsDefinition);
  };

  const handleDiscardDraft = () => {
    props.onDiscardDraft(props.draftData.id);
  };

  const onSubmit = () => {
    const updatedAsDefinition = !props.draftData
      ? {
          ...props.asDefinition,
          asMappings: asMappings,
          isPublished: false,
        }
      : {
          ...props.asDefinition,
          name: props.draftData.name,
          priority: props.draftData.priority,
          description: props.draftData.description,
          asMappings: asMappings,
        };
    props.draftData
      ? props.onPublishDraft(props.draftData.id, updatedAsDefinition)
      : onSave(updatedAsDefinition);
  };

  const onSubmitMappingForm = (value: Record<string, any>) => {
    if (editedMapping !== null && Object.keys(editedMapping).length > 0) {
      editMapping(value as AsMapping);
    } else {
      addMapping(value as AsMapping);
    }
    onClosePopup();
  };

  const onClosePopup = () => setPopupOpen(false);

  const addMapping = (mapping: AsMapping) => {
    setAsMappings([...asMappings, mapping]);
  };

  const editMapping = (mapping: AsMapping) => {
    setAsMappings(asMappings.map((m) => (m.id === mapping.id ? mapping : m)));
  };

  const removeMapping = (mapping: AsMapping) => {
    setAsMappings(asMappings.filter((m) => m.id !== mapping.id));
  };

  const onEdit = (id: GridRowId) => () => {
    setPopupOpen(true);
    const mapping = asMappings.find((mapping) => mapping.id === id);
    if (mapping) {
      setEditedMapping(mapping);
    }
  };

  const createButton = (
    <CreateButtonWithIcon
      intlId="fusion.attributesynchronizationmapping.list.create"
      key="create"
      onClick={onCreate}
    />
  );

  const entityToTableRow = (entity: AsMapping): EntityTableRow => {
    return {
      id: entity.id,
      pointsharpField: entity.internalAttribute,
      externalField: entity.externalAttribute,
      asMethods: `${entity.synchronizationMethod
        .toLocaleLowerCase()
        .replace(/^\w/, (c) =>
          c.toUpperCase()
        )} on ${entity.direction.toLocaleLowerCase()}`,
      priority: entity.priority,
      // This changes the row color to show added or updated rows
      isNew:
        entity.draftAction === DraftActions.Added ||
        entity.draftAction === DraftActions.Updated,
    };
  };

  const tableRows = asMappings.map(entityToTableRow);

  return (
    <>
      {props.editMode && props.draftData && (
        <DraftCard
          draftData={{
            updatedAt: props.draftData.updatedAt ?? Date.now().toString(),
            updatedBy: props.draftData.updatedBy ?? Date.now().toString(),
          }}
          onDiscardDraft={handleDiscardDraft}
        />
      )}
      <EntityTable
        columns={shownColumns}
        rows={tableRows}
        onSearch={onSearch}
        placeholder={intl.format('fusion.general.searchPlaceholder')}
        totalEntries={tableRows.length}
        toolbarButtons={props.editMode ? [createButton] : []}
      />
      <Popup
        open={popupOpen}
        primaryTitle={
          editedMapping
            ? intl.format(
                'fusion.attributesynchronizationmapping.popup.editTitle'
              )
            : intl.format(
                'fusion.attributesynchronizationmapping.popup.createTitle'
              )
        }
        secondaryTitle={
          editedMapping
            ? intl.format(
                'fusion.attributesynchronizationmapping.popup.editTitle'
              )
            : intl.format(
                'fusion.attributesynchronizationmapping.popup.createTitle'
              )
        }
        onClose={onClosePopup}
        hideButtons
      >
        <AttributeSyncMappingForm
          onSubmit={onSubmitMappingForm}
          asDefinition={props.asDefinition}
          editedMapping={editedMapping}
          onClose={onClosePopup}
          system={props.system}
        />
      </Popup>
      {props.editMode && (
        <FormButtons
          onSave={handleSubmit}
          onCancel={props.onCancel}
          isDraftable
          onSaveDraft={handleDraft}
        />
      )}
    </>
  );
});
