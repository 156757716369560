import {EntityInformation} from "@sivis/shared/components/entityView";
import styles from "./organizationalEntityType.module.scss";
import {OrganizationalEntityTypeInput} from "@sivis/identity/api";
import {useTranslateConfigs} from "@sivis/intl";

export interface OrganizationalEntityTypeInformationTabProps {
  isNew: boolean,
  onSave: (OrganizationalEntityTypeInput: OrganizationalEntityTypeInput) => void,
  onCancel: () => void,
  editMode: boolean,
  defaultInput: OrganizationalEntityTypeInput
}

const OrganizationalEntityTypeInformationTab = (props: OrganizationalEntityTypeInformationTabProps) => {

  const configs = [
    {
      property: 'name',
      required: true
    },
    {
      property: 'apiName',
    },
    {
      property: 'description',
    }
  ];

  const translatedConfigs = useTranslateConfigs(configs, "fusion.organizationalEntityType.propertyName");

  const entityInformation = <EntityInformation
    editMode={props.editMode}
    configs={translatedConfigs}
    value={props.defaultInput}
    onSubmit={props.onSave}
    onCancel={props.onCancel}
  />;

  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
};

export default OrganizationalEntityTypeInformationTab;

