import { useCustomIntl } from '@sivis/intl';
import {
  AFFECTED_IDENTITIES_PATHNAME,
  INFORMATION_TAB_PATHNAME
} from '../components/routes/constants';
import {
  ActivateButton,
  DeactivateButton,
  DeleteButton,
  EditButton,
  EntityView
} from '@sivis/shared/components/entityView';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';
import { Outlet } from 'react-router-dom';
import { useBusinessRuleApi } from './api/useBusinessRuleApi';
import { useState } from 'react';
import { Alert } from '@mui/material';
import { ActionPopup } from '../components/popup/actionPopup';
import { WatchValues } from './ruleUtils';
import { BusinessRule, RuleStatus } from '@sivis/identity/api';


export const RuleView = () => {
  const intl = useCustomIntl();
  const {
    businessRule,
    setBusinessRule,
    error,
    isNew,
    onSave,
    onPublish,
    onCancel,
    onDelete,
    onDeleteDraft,
    editMode,
    toggleEditMode,
    onActivate,
    onDeactivate,
    isLoading,
    draftData,
    setDraftData,
    refetch
  } = useBusinessRuleApi();
  const [statusConfirmationPopupOpen, setStatusConfirmationPopupOpen] = useState(false);
  const [deletionPopupOpen, setDeletionPopupOpen] = useState(false);
  const [watchValues, setWatchValues] = useState<WatchValues>({});

  const informationTab = {
    label: intl.format('fusion.breadcrumb.information'),
    path: INFORMATION_TAB_PATHNAME
  };
  const affectedIdentitiesTab = {
    label: intl.format('fusion.rule.affectedIdentities.list.name'),
    path: AFFECTED_IDENTITIES_PATHNAME
  };

  const tabs = isNew ? [informationTab] : [
    informationTab, affectedIdentitiesTab
  ];

  const titleExisting = intl.format('fusion.rule.information.titleExisting', { rule: businessRule?.name ?? '' });
  const title = isNew ? intl.format('fusion.rule.information.titleNew') : titleExisting;
  const buttons = isNew ? undefined : [
    <EditButton onClick={toggleEditMode} disabled={editMode} key="edit" />,
    businessRule?.status === RuleStatus.Active ?
      <DeactivateButton onClick={() => setStatusConfirmationPopupOpen(true)} key={'deactivate'} /> :
      <ActivateButton onClick={() => setStatusConfirmationPopupOpen(true)} key={'activate'}
                      disabled={!businessRule.isPublished} />,
    <DeleteButton onClick={() => setDeletionPopupOpen(true)}
                  disabled={businessRule?.status != RuleStatus.Inactive} key="delete" />
  ];

  const primaryTitleActivate = intl.format('fusion.rule.activateRule.title', { rule: businessRule?.name });
  const primaryTitleDeactivate = intl.format('fusion.rule.deactivateRule.title', { rule: businessRule?.name });
  const secondaryTitleActivate = intl.format('fusion.rule.activateRule.secondaryTitle');
  const secondaryTitleDeactivate = intl.format('fusion.rule.deactivateRule.secondaryTitle');

  const statusInformationAlert = <Alert severity="info">
    {intl.formatBold(businessRule?.status == RuleStatus.Active ? 'fusion.rule.deactivateRule.alert' :
      'fusion.rule.activateRule.alert', { num: '1000' })}
    <p>{intl.format(businessRule?.status == RuleStatus.Active ? 'fusion.rule.deactivateRule.alert2' :
      'fusion.rule.activateRule.alert2')}</p>
  </Alert>;

  const statusConfirmationPopup = businessRule ?
    <ActionPopup
      open={statusConfirmationPopupOpen}
      onConfirm={() => {
        setStatusConfirmationPopupOpen(false);
        businessRule.status == RuleStatus.Active ? onDeactivate() : onActivate();
      }}
      onClose={() => {
        setStatusConfirmationPopupOpen(false);
      }}
      primaryTitle={businessRule.status === RuleStatus.Active ? primaryTitleDeactivate : primaryTitleActivate}
      secondaryTitle={businessRule.status === RuleStatus.Active ? secondaryTitleDeactivate : secondaryTitleActivate}
      alert={statusInformationAlert}
    /> : null;

  const deletionAlert = <Alert
    severity="warning">{intl.format('fusion.rule.deleteRule.alert')}</Alert>;

  const deletionPopup = businessRule ? <ActionPopup
    open={deletionPopupOpen}
    onConfirm={() => {
      setDeletionPopupOpen(false);
      onDelete();
    }}
    onClose={() => {
      setDeletionPopupOpen(false);
    }}
    primaryTitle={intl.format('fusion.rule.deleteRule.title', { rule: businessRule.name })}
    secondaryTitle={intl.format('fusion.rule.deleteRule.secondaryTitle')}
    alert={deletionAlert}
  /> : null;

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, 'fusion.rule.error.notFound', intl.format) : undefined}>
    <Outlet context={{
      editMode,
      isLoading,
      toggleEditMode,
      isNew,
      businessRule,
      setBusinessRule,
      onSave,
      onPublish,
      onCancel,
      onDelete,
      onDeleteDraft,
      onActivate,
      watchValues,
      setWatchValues,
      draftData,
      setDraftData,
      refetch
    }} />
    {statusConfirmationPopup}
    {deletionPopup}
  </EntityView>;
};

export type ContextType = {
  editMode: boolean;
  isLoading: boolean;
  toggleEditMode: () => void;
  isNew: boolean;
  businessRule: BusinessRule;
  setBusinessRule: (rule: BusinessRule) => void;
  onSave: (value: Record<string, any>) => void;
  onPublish: (value: Record<string, any>) => void;
  onCancel: () => void;
  onDelete: () => void;
  onDeleteDraft: (value: string) => void;
  watchValues: WatchValues;
  setWatchValues: (value: WatchValues) => void;
  draftData: BusinessRule;
  setDraftData: (value: BusinessRule) => void;
  refetch: () => void;
}

