import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EntityInformation, FieldType } from '@sivis/shared/components/entityView';
import styles from './account.module.scss';
import {
  Account,
  AccountInput,
  useGetAccountTypesQuery,
  useGetAccountFieldDefinitionsQuery,
  useGetIdentitiesQuery,
  useSearchIdentitiesQuery,
  useGetSystemsQuery,
} from "@sivis/identity/api";
import { useTranslateConfigs } from "@sivis/intl";
import _ from "lodash";
import { isHasId, notEmpty } from "@sivis/shared/utils";

export interface AccountInformationTabProps {
  editMode: boolean;
  account: Account;
  isNew: boolean;
  defaultInput: AccountInput;
  onSave: (value: Record<string, any>) => void;
  onCancel: () => void;
}

interface DefaultInput {
  accountType?: string | { id: string };
  identityId?: string | { id: string } | null;
  systemId?: string | { id: string } | null;
  [key: string]: any;
}

function normalizeDefaultInput(defaultInput: DefaultInput): DefaultInput {
  let accountType = defaultInput.accountType;
  let identityId = defaultInput.identityId;
  let systemId = defaultInput.systemId;

  if (accountType && typeof accountType === "object" && isHasId(accountType)) {
    accountType = accountType.id;
  }

  if (identityId && typeof identityId === "object" && isHasId(identityId)) {
    identityId = identityId.id;
  }

  if (systemId && typeof systemId === "object" && isHasId(systemId)) {
    systemId = systemId.id;
  }

  return {
    ...defaultInput,
    accountType,
    identityId: identityId ?? undefined,
    systemId: systemId ?? undefined,
  };
}

function isAccountInput(value: any): value is AccountInput {
  return (
    typeof value.accountType === "string" &&
    typeof value.name === "string" &&
    (value.customFields === undefined || typeof value.customFields === "object") &&
    (value.externalEntityIds === undefined || typeof value.externalEntityIds === "object") &&
    (value.identityId === undefined || typeof value.identityId === "string") &&
    (value.systemId === undefined || typeof value.systemId === "string")
  );
}

export const AccountInformationTab: React.FC<AccountInformationTabProps> = (props) => {
  const {
    data: fieldDefinitionsData,
    error: fieldDefinitionsError,
    isLoading: isLoadingFieldDefinitions,
    refetch: refetchFieldDefinitions,
  } = useGetAccountFieldDefinitionsQuery();

  const { data: accountTypesData, error: accountTypesError, isLoading: isLoadingAccountTypes } =
    useGetAccountTypesQuery();

  const { data: identitiesData, error: identitiesError, isLoading: isLoadingIdentities } =
    useGetIdentitiesQuery();

  const { data: systemsData, error: systemsError, isLoading: isLoadingSystems } =
    useGetSystemsQuery();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [systemSearchTerm, setSystemSearchTerm] = useState<string>('');

  // Fetch identities using the search term
  const { data: searchResults, isFetching: loadingEntities } = useSearchIdentitiesQuery({
    first: 10,
    sort: "lastName,asc",
    filter: searchTerm
      ? {
        operator: "OR",
        logicalValue: [
          { name: "firstName", operator: "CONTAINS", stringValue: searchTerm },
          { name: "lastName", operator: "CONTAINS", stringValue: searchTerm },
          { name: "primaryEmail", operator: "CONTAINS", stringValue: searchTerm },
        ],
      }
      : undefined,
  });

  const { data: systemSearchResults, isFetching: loadingSystem } = useGetSystemsQuery({
    first: 10,
    sort: "systemName,asc",
    filter: systemSearchTerm
      ? {
        operator: "OR",
        logicalValue: [{ name: "systemName", operator: "CONTAINS", stringValue: systemSearchTerm }],
      }
      : undefined,
  });

  const handleSearch = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);

  const handleSystemSearch = useCallback((term: string) => {
    setSystemSearchTerm(term);
  }, []);

  useEffect(() => {
    if (props.account?.accountType?.id) {
      refetchFieldDefinitions();
    }
  }, [props.account?.accountType?.id, refetchFieldDefinitions]);

  const loading =
    isLoadingFieldDefinitions || isLoadingAccountTypes || isLoadingIdentities || isLoadingSystems;
  const error = fieldDefinitionsError || accountTypesError || identitiesError || systemsError;

  const handleOnSubmit = useCallback(
    (value: Record<string, any>) => {
      const formattedValue = {
        ...value,
        identityId: value.identity,
        systemId: value.system,
      };

      if (isAccountInput(formattedValue)) {
        props.onSave(formattedValue);
      }
    },
    [props.onSave]
  );

  const accountTypeReadOnly = props.account?.accountType?.name;

  const selectedIdentity = useMemo(
    () =>
      identitiesData?.identities?.edges
        ?.filter(notEmpty)
        .find((edge) => edge.node?.id === props.defaultInput.identityId),
    [identitiesData, props.defaultInput.identityId]
  );

  const identityName = selectedIdentity
    ? `${selectedIdentity.node.firstName} ${selectedIdentity.node.lastName}`
    : "";

  const selectedSystem = useMemo(
    () =>
      systemsData?.systems?.edges
        ?.filter(notEmpty)
        .find((edge) => edge.node?.id === props.defaultInput.systemId),
    [systemsData, props.defaultInput.systemId]
  );

  const systemName = selectedSystem ? selectedSystem.node.systemName : "";

  const accountTypesOptions = useMemo(
    () =>
      accountTypesData?.accountTypes?.edges
        ?.filter(notEmpty)
        .map((edge) => ({
          value: edge.node?.id ?? "",
          text: edge.node?.name ?? "",
        })) ?? [],
    [accountTypesData]
  );

  const identitiesOptions = useMemo(
    () =>
      identitiesData?.identities?.edges
        ?.filter(notEmpty)
        .map((edge) => ({
          value: edge.node?.id ?? "",
          text: `${edge.node.firstName} ${edge.node.lastName}`,
        })) ?? [],
    [identitiesData]
  );

  const systemOptions = useMemo(
    () =>
      systemsData?.systems?.edges
        ?.filter(notEmpty)
        .map((edge) => ({
          value: edge.node?.id ?? "",
          text: edge.node?.systemName ?? "",
        })) ?? [],
    [systemsData]
  );

  const normalizedDefaultInput = useMemo(
    () => normalizeDefaultInput(props.defaultInput),
    [props.defaultInput]
  );

  // Helper function to map field definitions to configs
  const mapFieldDefinitionToConfig = useCallback(
    (field: any) => {
      const { fieldName, required, readOnly } = field.fieldDefinitionFields;
      const lowerFieldName = _.lowerFirst(fieldName) as keyof AccountInput;

      const fieldOptionsMap: { [key: string]: any } = {
        AccountType: accountTypesOptions,
        IdentityId: identitiesOptions,
        SystemId: systemOptions,
      };

      const fieldRenderRowMap: { [key: string]: () => any } = {
        AccountType: () => accountTypeReadOnly,
        Identity: () => identityName,
        System: () => systemName,
      };

      const fieldTypeMap: { [key: string]: FieldType | undefined } = {
        Identity: FieldType.AUTOCOMPLETE,
        System: FieldType.AUTOCOMPLETE,
      };

      return {
        property: lowerFieldName,
        required: required,
        disabled: props.isNew && readOnly,
        fieldType: fieldTypeMap[fieldName] ?? undefined,
        options: fieldOptionsMap[fieldName],
        renderRow: !props.isNew ? fieldRenderRowMap[fieldName] : undefined,
      };
    },
    [
      props.isNew,
      accountTypesOptions,
      identitiesOptions,
      systemOptions,
      accountTypeReadOnly,
      identityName,
      systemName,
    ]
  );

  const defaultSystemId = useMemo(() => {
    if (props.isNew) {
      return systemsData?.systems?.edges?.[0]?.node?.id;
    }
    return undefined;
  }, [props.isNew, systemsData]);

  const configs = useMemo(() => {
    const fieldDefinitions = fieldDefinitionsData?.accountFieldDefinitions?.filter(notEmpty) ?? [];

    const systemId = props.isNew ? defaultSystemId : props.account?.systemId;

    const filteredFieldDefinitions = fieldDefinitions.filter((field) => {
      const { visible } = field.fieldDefinitionFields;
      return visible && field.system === systemId;
    });

    const sortedFieldDefinitions = filteredFieldDefinitions.sort(
      (a, b) => a.fieldDefinitionFields.uiPosition - b.fieldDefinitionFields.uiPosition
    );

    return sortedFieldDefinitions.map(mapFieldDefinitionToConfig);
  }, [
    fieldDefinitionsData,
    props.isNew,
    props.account?.systemId,
    defaultSystemId,
    mapFieldDefinitionToConfig,
  ]);

  const translatedConfigs = useTranslateConfigs(configs, "fusion.account.propertyName");

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading field definitions, account types, identities, or systems</div>;
  }

  const searchFunctions = {
    identity: {
      searchFunction: handleSearch,
      searchResults: searchResults,
      loadingState: loadingEntities ?? false,
      currentValue: selectedIdentity?.node, // Pass the current identity
    },
    system: {
      searchFunction: handleSystemSearch,
      searchResults: systemSearchResults,
      loadingState: loadingSystem ?? false,
      currentValue: selectedSystem?.node, // Pass the current system
    },
  };

  return (
    <div className={styles.listContainer}>
      <EntityInformation
        editMode={props.editMode}
        configs={translatedConfigs}
        value={normalizedDefaultInput}
        onSubmit={handleOnSubmit}
        onCancel={props.onCancel}
        searchFunctions={searchFunctions}
        loadingEntities={loadingEntities ?? false}
      />
    </div>
  );
};
