import {createApi} from '@reduxjs/toolkit/query/react';
import {GraphQLClient} from 'graphql-request';
import {createBaseQueryWithErrorHandling} from "@sivis/shared/api-utils";

export const attributeSyncClient: GraphQLClient = new GraphQLClient(
  '/graphql/attributesynchronization'
);

export const attributeSyncBaseApi = createApi({
  baseQuery: createBaseQueryWithErrorHandling('/graphql/attributesynchronization'),
  reducerPath: 'attributesynchronization',
  endpoints: () => ({}),
});
