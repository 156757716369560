import styles from "./ClickableText.module.scss";
import {FormattedMessage} from "react-intl";
import React from "react";

interface ClickableTextProps {
  textId: string;
  onClick: () => void;
}

/**
 * An accessibility compliant clickable text component in HTML link style.
 */
export const ClickableText = (props: ClickableTextProps) => {
  return <button
    type="button" // prevent the button to be triggered on form submit
    className={styles.clickableText}
    onClick={props.onClick}
  >
    <FormattedMessage id={props.textId}/>
  </button>;
};
