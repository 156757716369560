import {
  AFFECTED_IDENTITIES_PATHNAME,
  INFORMATION_TAB_PATHNAME
} from '../components/routes/constants';
import { Outlet, RouteObject } from 'react-router-dom';
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from '../components/breadcrumb/breadcrumb';
import { RuleView } from './ruleView';
import { EntityType } from '../components/breadcrumb/editModeContext';
import { RuleList } from './ruleList';
import { RuleInformationTab } from './ruleInformationTab';
import { RuleAffectedIdentitiesTab } from './ruleAffectedIdentitiesTab';
import { useGetBusinessRuleQuery } from '@sivis/identity/api';

const useGetRuleName = (id: string, isNew: boolean) => {
  const { data } = useGetBusinessRuleQuery({ id }, { skip: isNew });
  return data?.businessRule?.name;
};

export const ruleRoutes: RouteObject[] = [
  {
    index: true,
    element: <RuleList />
  },
  {
    path: ':id',
    element: <Outlet />,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetRuleName,
        pathSuffix: '/' + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <RuleView />
      },
      {
        element: <RuleView />,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <RuleInformationTab />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: 'fusion.breadcrumb.information',
                intlIdEdit: 'fusion.breadcrumb.information.edit',
                entity: EntityType.CUSTOM_RULE
              })
            }
          },
          {
            path: AFFECTED_IDENTITIES_PATHNAME,
            element: <RuleAffectedIdentitiesTab />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: 'fusion.rule.affectedIdentities.list.name',
                intlIdEdit: 'fusion.breadcrumb.rule.affectedIdentities.edit',
                entity: EntityType.CUSTOM_RULE
              })
            }
          }
        ]
      }
    ]
  }
];
