import {TextField} from "@mui/material";
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import {styled} from "@mui/material/styles";

interface DatePickerProps {
  label?: string;
  date: Date | null;
  onChange: (newDate: Date | null) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const dateToString = (date: Date | null) => date?.toISOString() ?? null;

export const isoStringToDate = (dateStr: string | null | undefined) => dateStr ? new Date(dateStr) : null;

/**
 * Clear time within the day and convert the date to timestamp, used to compare dates without time.
 */
export const normalizeTimeOfDateInMilliseconds = (date: Date) => date.setHours(0, 0, 0, 0);

const StyledTextField = styled(TextField)(() => ({
  '& input': {
    width: "80px"
  }
}));

/**
 * A wrapped MUI DatePicker in fusion style with ISO string as output.
 */
export const DatePicker = (props: DatePickerProps) => {
  const onChange = (newDate: Date | null) => {
    if (!newDate || (newDate && !isNaN(newDate.getDate()))) {
      props.onChange(newDate);
    }
  };

  return <MuiDatePicker
    label={props.label}
    value={props.date}
    onChange={onChange}
    disabled={props.disabled}
    minDate={props.minDate}
    maxDate={props.maxDate}
    renderInput={(params) => <StyledTextField {...params} size="small" />}
  />;
}
