import {SystemType, useGetSystemTypeQuery} from '@sivis/identity/api';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

interface SystemTypeInput {
  systemTypeName: string;
  systemTypeDescription?: string | null;
}

export const useSystemTypeApi = () => {
  const {id = 'new'} = useParams<{ id: string }>();
  const isNew = id === 'new';
  const {data, error, isLoading, refetch} = useGetSystemTypeQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [input, setInput] = useState<SystemTypeInput>({systemTypeName: ''});
  const [systemType, setSystemType] = useState<SystemType>();

  useEffect(() => {
    if (data?.systemType) {
      setInput({
        systemTypeName: data.systemType.systemTypeName,
        systemTypeDescription: data.systemType.systemTypeDescription ?? null
      });
      setSystemType(data.systemType);
    }
  }, [data?.systemType]);

  const goBack = () => navigate("/system-type");

  return {
    id,
    systemType,
    defaultInput: input,
    error,
    isNew,
    onCancel: goBack,
    isLoading,
    refetch
  };
};
