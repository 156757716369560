import { EOwnershipType, Resource, SideFilterQueryType } from "@sivis/identity/api";
import { useTranslateColumns } from "@sivis/intl";
import { ListAddView } from "@sivis/shared/components/entityView";
import {
  resourceEntitlementColumns,
  resourceEntityToTableRow
} from "../entitlement/entitlementUtils";
import { useResourcePageApi } from "../resource/useResourcePageApi";
import { useResourcesSideFilter } from "./components/useResourcesSideFilter";
import { CreateOwnershipProps } from "./createOwnership";
import styles from "./ownership.module.scss";
import { isIdentity } from "./ownershipUtils";

// TODO: filter out existing ownerships?
export const CreateOwnershipResourcesList = (props: CreateOwnershipProps) => {
  const {activeFilter, filterComponent} = useResourcesSideFilter(SideFilterQueryType.RESOURCES);
  const {
    onSearch,
    useResourcePageQuery,
    parseResourcePageResult,
    pageSize
  } = useResourcePageApi(activeFilter ?? undefined);
  const translatedColumns = useTranslateColumns(resourceEntitlementColumns, "fusion.entitlement.resourceEntitlement.propertyName");

  const onAdd = (resources: Resource[]) => {
    props.onAdd(resources.map(resource => ({
      id: (props.selectedEntity?.id ?? "") + resource.id,
      ownerId: props.selectedEntity?.id ?? "",
      targetId: resource.id,
      type: isIdentity(props.selectedEntity) ? EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP : EOwnershipType.ROLE_RESOURCE_OWNERSHIP,
      targetName: resource.name
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={resourceEntityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useResourcePageQuery}
      parseResult={parseResourcePageResult}
      sideFilter={filterComponent}
      excludedItems={[]}
      tableType={SideFilterQueryType.RESOURCES}
    />
  </div>;
};
