import {FunctionComponent} from 'react';
import {HasIntlId} from './HasIntlId';
import {TooltipProps} from '@mui/material/Tooltip/Tooltip';
import {useSelector} from 'react-redux';
import {selectMessageTextById} from '../intlSelectors';
import {Tooltip} from '@mui/material';

export const IntlTooltip: FunctionComponent<HasIntlId & Omit<TooltipProps, 'title'>> = ({
                                                                                          intlId,
                                                                                          ...props
                                                                                        }) => {
  const title = useSelector(state => selectMessageTextById(state, intlId));
  return <Tooltip title={title} {...props}>
    {props.children}
  </Tooltip>
}
