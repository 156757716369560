import styles from '../../../../../../libs/shared/components/entityView/src/lib/components/form/form.module.scss';
import {
  AsDefinition,
  AsDirections,
  AsMapping,
  AsMethods,
  AsmInput,
  DraftActions,
  TransformationInput,
} from '@sivis/attributesync/api';
import {
  FormButtons,
  FormButtonsProps,
} from '@sivis/shared/components/entityView';
import { forwardRef, useState, ForwardedRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AttributeSyncMappingConfiguration } from './attributeSyncMappingConfiguration';
import { AttributeSyncMappingSourceField } from './attributeSyncMappingSourceField';
import { AttributeSyncMappingExternalField } from './attributeSyncMappingExternalField';
import { System } from '@sivis/identity/api';

export interface AttributeSyncMappingFormProps extends FormButtonsProps {
  onSubmit: (value: AsmInput) => void;
  asDefinition: AsDefinition | undefined;
  editedMapping: AsMapping | null;
  onClose: () => void;
  system: System;
}

export interface IFormInput extends AsmInput {
  direction: AsDirections;
  synchronizationMethod: AsMethods;
  priority: number;
  internalAttribute: string;
  externalAttribute: string;
  asDefinitionId: string;
  id: string;
  fieldType: string;
  draftAction: DraftActions;
  originalMappingId: string;
  transformations: TransformationInput[];
}

export type AttributeSyncMappingFormHandle = {
  submit: () => void;
};

export const AttributeSyncMappingForm = forwardRef<
  AttributeSyncMappingFormHandle,
  AttributeSyncMappingFormProps
>(
  (
    props: AttributeSyncMappingFormProps,
    ref: ForwardedRef<AttributeSyncMappingFormHandle>
  ) => {
    const {
      handleSubmit,
      register,
      formState: { errors },
      setValue,
    } = useForm<IFormInput>();

    const [transformations, setTransformations] = useState<
      TransformationInput[]
    >(props.editedMapping?.transformations ?? []);

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
      // Fill in the missing data for the mapping, which is not implemented yet
      const id = props.editedMapping?.id ?? crypto.randomUUID();
      data.id = id;
      data.asDefinitionId = props.asDefinition?.id;
      data.fieldType = 'simple';
      data.priority =
        typeof data.priority === 'string'
          ? parseInt(data.priority)
          : data.priority;
      data.draftAction = props.editedMapping?.draftAction ?? DraftActions.None;
      data.originalMappingId = props.editedMapping?.originalMappingId ?? null;
      data.transformations = transformations.map((transformation) => ({
        ...transformation,
        id: transformation.id || crypto.randomUUID(),
        mappingId: id,
        value: undefined,
        selectId: undefined,
      }));

      props.onSubmit(data);
    };

    const handleTransformationsChange = (
      updatedTransformations: TransformationInput[]
    ) => {
      setTransformations(updatedTransformations);
    };

    return (
      <div className={styles.listContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AttributeSyncMappingConfiguration
            register={register}
            errors={errors}
            editedMapping={props.editedMapping}
          />

          <AttributeSyncMappingSourceField
            errors={errors}
            register={register}
            editedMapping={props.editedMapping}
            setValue={setValue}
            onTransformationsChange={handleTransformationsChange}
          />

          <AttributeSyncMappingExternalField
            register={register}
            errors={errors}
            system={props.system}
            editedMapping={props.editedMapping}
            setValue={setValue}
          />

          <FormButtons onCancel={props.onClose} />
        </form>
      </div>
    );
  }
);
