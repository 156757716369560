import {EntityView} from "@sivis/shared/components/entityView";
import ResourceTypeInformationTab from "./resourceTypeInformationTab";
import ResourceTypeResourcesTab from "./resourceTypeResourcesTab";
import {useResourceTypeApi} from "./useResourceTypeApi";
import {Outlet, useOutletContext} from "react-router-dom";
import {ResourceType} from "@sivis/identity/api";
import {INFORMATION_TAB_PATHNAME, RESOURCES_TAB_PATHNAME} from "../components/routes/constants";
import {useCustomIntl} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";

export const ResourceTypeView = () => {
  const intl = useCustomIntl();
  const {
    resourceTypeInput,
    resourceType,
    error
  } = useResourceTypeApi();

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };
  const resourcesTab = {
    label: intl.format("fusion.resource.list.name"),
    path: RESOURCES_TAB_PATHNAME
  };

  const tabs = [informationTab, resourcesTab];
  const title = `${intl.format("fusion.resourceType.information.title")}${resourceTypeInput.name}`;

  return <EntityView
    title={title}
    tabs={tabs}
    error={error ? getTranslatedErrorMessage(error, "fusion.resourceType.error.notFound", intl.format) : undefined}>
    <Outlet context={{resourceType}}/>
  </EntityView>;
};

type ContextType = {
  resourceType?: ResourceType;
};

export const ResourceTypeInformationTabWrapperComponent = () => {
  const {resourceType} = useOutletContext<ContextType>();
  return <ResourceTypeInformationTab resourceType={resourceType}/>;
};

export const ResourceTypeResourcesTabWrapperComponent = () => {
  const {resourceType} = useOutletContext<ContextType>();
  return <ResourceTypeResourcesTab resourceType={resourceType}/>;
};

