export interface HasId {
  id: string;
}

export const isHasId = (source: any): source is HasId => {
  return 'id' in source;
}

export const isEqualById = (left: any, right: any): boolean => {
  return isHasId(left) && isHasId(right) && left.id === right.id;
}
