import { Link } from "react-router-dom";
import { AccountType } from "@sivis/identity/api";
import { useAccountTypesPageApi } from "./useAccountTypePageApi";
import { GridColDef } from '@mui/x-data-grid';
import { CreateButton, EntityView, InfiniteScrollTableCursor } from "@sivis/shared/components/entityView";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";

const entityToTableRow = (entity: AccountType) => {
  return {
    id: entity.id,
    name: entity.name,
    description: entity.description,
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "description",
    flex: 1,
  },
];



export const AccountTypeList = () => {
  const intl = useCustomIntl();
  const { onSearch, pageSize, useAccountTypesPageQuery, parseAccountTypesPageResult } = useAccountTypesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.accountType.propertyName");

  return <EntityView
    title={intl.format("fusion.accountType.list.title")}
    actionButtons={[<CreateButton key={"create"} />]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useAccountTypesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseAccountTypesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
