import {Link, useNavigate} from "react-router-dom";
import {GridColDef} from '@mui/x-data-grid';
import {EntityView, InfiniteScrollTableCursor} from "@sivis/shared/components/entityView";
import {useCallback} from "react";
import {Address} from "@sivis/identity/api";
import {useAddressesPageApi} from "./useAddressesPageApi";
import {IntlButton, useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: Address) => {
  return {
    id: entity.id,
    name: entity.name,
    searchTerm: entity.searchTerm,
    address1: entity.address1,
    address2: entity.address2,
    address3: entity.address3,
    zipcode: entity.zipcode,
    city: entity.city,
    region: entity.region,
    state: entity.state,
    country: entity.country,
    defaultTimeZone: entity.defaultTimeZone,
    defaultLanguage: entity.defaultLanguage,
    phone: entity.phone,
    phoneExtension: entity.phoneExtension,
    mobilePhone: entity.mobilePhone,
    fax: entity.fax,
    faxExtension: entity.faxExtension,
    email: entity.email,
    standardCommunicationMethod: entity.standardCommunicationMethod,
    description: entity.description
  };
};

const columns: GridColDef[] = [
  // TODO: which fields should be shown in the address list?
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "description",
    flex: 1,
  }
];

export const AddressList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useAddressesPageQuery,
    parseAddressesPageResult,
    pageSize
  } = useAddressesPageApi();
  const navigate = useNavigate();
  const onAdd = useCallback(() => navigate("new"), [navigate]);
  const translatedColumns = useTranslateColumns(columns, "fusion.address.propertyName");

  return <EntityView
    title={intl.format("fusion.address.list.title")}
    actionButtons={[<IntlButton key="createButton" variant="outlined" onClick={onAdd}
                                intlId={"fusion.button.create"}/>]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useAddressesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseAddressesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
