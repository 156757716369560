import {useCustomIntl} from '@sivis/intl';
import {PreparedRegistration, prepareRegisterComponent} from '@sivis/shared/components/registry';
import {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';
import styles from './HeaderItems.module.scss';
import {HeaderItemsRegistryRenderer} from './HeaderItemsRegistryRenderer';
import {selectHeaderTitle} from "./headerSlice";

export const HEADER_ITEM_LEFT = 'HEADER_ITEM_LEFT';
export const HEADER_ITEM_MIDDLE = 'HEADER_ITEM_MIDDLE';
export const HEADER_ITEM_RIGHT = 'HEADER_ITEM_RIGHT';
export type headerItemType =
  typeof HEADER_ITEM_LEFT
  | typeof HEADER_ITEM_MIDDLE
  | typeof HEADER_ITEM_RIGHT;

export const DEFAULT_HEADER_TITLE_INTL_ID = 'general.dashboard';
export const HeaderItems: FunctionComponent = () => {
  return (
    <div id={styles.headerItems}>
      <div className={styles.leftSide}>
        <HeaderItemsRegistryRenderer type={HEADER_ITEM_LEFT}/>
      </div>
      <div className={styles.middle}>
        <HeaderItemsRegistryRenderer type={HEADER_ITEM_MIDDLE}/>
      </div>
      <div className={styles.rightSide}>
        <HeaderItemsRegistryRenderer type={HEADER_ITEM_RIGHT}/>
      </div>
    </div>
  );
};

const HeaderTitle = () => {
  const intl = useCustomIntl();
  const headerTitle = useSelector(selectHeaderTitle) || DEFAULT_HEADER_TITLE_INTL_ID;
  return <div className={styles.headerTitle}>{intl.formatMappedMessage(headerTitle)}</div>
};

export const headerTitleRegistration: PreparedRegistration = prepareRegisterComponent({
  id: `${HEADER_ITEM_MIDDLE}/${HeaderTitle.name}`,
  name: DEFAULT_HEADER_TITLE_INTL_ID,
  component: HeaderTitle,
  type: HEADER_ITEM_MIDDLE,
  order: 0
});
