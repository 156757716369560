import {
  GeneratedOrganizationalEntityTypes,
  OrganizationalEntity,
  searchOrganizationalEntitiesFilter,
  useOrganizationalEntitiesQuery
} from "@sivis/identity/api";
import {CursorBasedPageResult} from "@sivis/shared/components/entityView";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../../common/parsePageResult";
import {useListSearch} from "../../utils/filterUtils";


export const useOrganizationalEntitiesPageApi = () => {
  const { filter, onSearch } = useListSearch(searchOrganizationalEntitiesFilter);

  const useOrganizationalEntitiesPageQuery = (cursor: string | null) =>
    useOrganizationalEntitiesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    });

  const parseOrganizationalEntitiesPageResult = (res?: GeneratedOrganizationalEntityTypes.OrganizationalEntitiesQuery): CursorBasedPageResult<OrganizationalEntity> => {
    return parseCursorBasedPageResult('organizationalEntities', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useOrganizationalEntitiesPageQuery,
    parseOrganizationalEntitiesPageResult
  };
};
