import {GridColDef} from "@mui/x-data-grid-pro";
import {useCustomIntl} from "../useCustomIntl";

export const useTranslateColumns = (
  columns: GridColDef[],
  textIdPrefix: string,
  exemptedFields: string[] = ["action"]       // strings of fields for which headerName should be an empty string
): GridColDef[] => {
  const intl = useCustomIntl();
  return columns.map(column => ({
    ...column,
    headerName: !exemptedFields.includes(column.field) ?
      intl.format(`${textIdPrefix}.${column.field}`)
      : ""
  }))
}
