import { Outlet, RouteObject } from "react-router-dom";
import {
  INFORMATION_TAB_PATHNAME
} from "../components/routes/constants";
import { DynamicEntityViewBreadcrumb, intlSimpleBreadcrumbEditMode } from "../components/breadcrumb/breadcrumb";
import { AccountInformationTabWrapperComponent, AccountView } from "./AccountView";
import { EntityType } from "../components/breadcrumb/editModeContext";
import { AccountList } from "./AccountList";
import { useGetAccountQuery } from "@sivis/identity/api";

const useGetAccountName = (id: string, isNew: boolean) => {
  const skipQuery = isNew || !id || id === "";
  const { data } = useGetAccountQuery({ id }, { skip: skipQuery });
  return data?.account?.name;
};


export const accountRoutes: RouteObject[] = [
  {
    index: true,
    element: <AccountList />
  },
  {
    path: ":id",
    element: <Outlet />,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetAccountName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <AccountView />,
      },
      {
        element: <AccountView />,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <AccountInformationTabWrapperComponent />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.ACCOUNT
              })
            }
          },
          // Add other tabs here if needed, following the IdentityType pattern
        ]
      }
    ]
  }
];
