import {EOwnershipType, Ownership} from "@sivis/identity/api";
import {EntityDetailsProps, TabBar} from "@sivis/shared/components/entityView";
import {useCustomIntl} from "@sivis/intl";
import {OwnershipInformationTab} from "./ownershipInformationTab";
import {RelationEntityType} from "../components/popup/relationUtils";
import {useRoleSystemOwnershipApi} from "./api/useRoleSystemOwnershipApi";
import {useRoleResourceOwnershipApi} from "./api/useRoleResourceOwnershipApi";
import {useRoleResourceSetOwnershipApi} from "./api/useRoleResourceSetOwnershipApi";
import {useIdentityResourceOwnershipApi} from "./api/useIdentityResourceOwnershipApi";
import {useIdentitySystemOwnershipApi} from "./api/useIdentitySystemOwnershipApi";
import {useIdentityResourceSetOwnershipApi} from "./api/useIdentityResourceSetOwnershipApi";


export const TargetOwnershipDetails = (props: EntityDetailsProps<Ownership>) => {
  const intl = useCustomIntl();

  const roleOwnershipQuery = props.entity.type === EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP ? useRoleResourceSetOwnershipApi : props.entity.type === EOwnershipType.ROLE_SYSTEM_OWNERSHIP ? useRoleSystemOwnershipApi : useRoleResourceOwnershipApi;
  const identityOwnershipQuery = props.entity.type === EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP ? useIdentityResourceSetOwnershipApi : props.entity.type === EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP ? useIdentitySystemOwnershipApi : useIdentityResourceOwnershipApi;
  const roleTargetType = props.entity.type === EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP ? RelationEntityType.RESOURCE_SET : props.entity.type === EOwnershipType.ROLE_SYSTEM_OWNERSHIP ? RelationEntityType.SYSTEM : RelationEntityType.RESOURCE
  const identityTargetType = props.entity.type === EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP ? RelationEntityType.RESOURCE_SET : props.entity.type === EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP ? RelationEntityType.SYSTEM : RelationEntityType.RESOURCE

  const roleTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={roleOwnershipQuery}
        ownerType={RelationEntityType.ROLE}
        targetType={roleTargetType}
        isOwner
      />
    }
  ]

  const identitiesTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={identityOwnershipQuery}
        ownerType={RelationEntityType.IDENTITY}
        targetType={identityTargetType}
        isOwner
      />
    }
  ]

  let tabs = roleTabs;
  if (props.entity.type === EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP || props.entity.type === EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP || props.entity.type === EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP) {
    tabs = identitiesTabs;
  }

  return <TabBar tabs={tabs}/>;
}
