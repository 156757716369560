import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { AttributeConfig } from '../form/attributeRowConfig';
import styles from '../form/form.module.scss';

interface FormSelectProps {
  fieldProps: ControllerRenderProps<Record<string, any>, string>;
  config: AttributeConfig;
  className?: string;
}

export const FormSelect: React.FC<FormSelectProps> = ({
                                                        fieldProps,
                                                        config,
                                                        className,
                                                      }) => {
  return (
    <Select
      disabled={config.disabled}
      variant="outlined"
      size="small"
      {...fieldProps}
      id={config.name}
      displayEmpty={true}
      renderValue={(value) =>
        value?.length || typeof value === 'boolean'
          ? config.options?.find((option) => option.value === value)?.text
          : config.placeholder ?? ''
      }
      className={className}
    >
      {/* Workaround for MUI ListItem not allowing boolean as value */}
      {config.options?.map((option) => (
        <MenuItem key={String(option.value)} value={option.value as any}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
