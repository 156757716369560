import { ExtendedRoleEntitlement, ExtendedSystemEntitlement, System, useGetSystemQuery } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import {
  EntityDetailsProps,
  EntityTable,
  EntityTableRow,
  TabBar
} from "@sivis/shared/components/entityView";
import { RelationEntityType } from "../components/popup/relationUtils";
import styles from "../system/system.module.scss";
import { useSystemRoleEntitlementApi } from "./api/useSystemRoleEntitlementApi";
import { EntitlementInformationTab } from "./entitlementInformationTab";
import { getEntitlementFlags, systemEntitlementColumns } from "./entitlementUtils";

// TODO: refactor RoleSystemEntitlementDetails
export const RoleSystemEntitlementDetails = (props: EntityDetailsProps<ExtendedRoleEntitlement>) => {
  const intl = useCustomIntl();
  const {data} = useGetSystemQuery({id: props.entity.targetId});
  const system = data?.system;

  const systemEntitlementToTableRow = (entity: ExtendedSystemEntitlement, system: System | undefined | null): EntityTableRow => {
    return {
      id: entity.entitledId + entity.targetId,
      name: system ? system.systemName : "",
      flags: getEntitlementFlags({validity: entity.validity}, intl.format),
    }
  }
  const translatedSystemEntitlementColumns = useTranslateColumns(systemEntitlementColumns, "fusion.entitlement.systemEntitlementDetails.propertyName")
  const shownColumns = translatedSystemEntitlementColumns.filter(column => column.field !== "action");
  const systemRow = new Array(systemEntitlementToTableRow(props.entity, system));

  const tabs = [
    {
      key: "entitlementInformation",
      label: intl.format("fusion.breadcrumb.information"),
      content: <EntitlementInformationTab
        {...props}
        useEntitlementQuery={useSystemRoleEntitlementApi}
        entitledType={RelationEntityType.ROLE}
        targetType={RelationEntityType.SYSTEM}
      />
    },
    {
      key: "system",
      label: intl.format("fusion.system.propertyName.systemName"),
      content: <div className={styles.listContainer}>
        <EntityTable
          columns={shownColumns}
          rows={systemRow}
          placeholder={intl.format("fusion.general.searchPlaceholder")}
          onSearch={() => {
          }}
          totalEntries={1}
        />
      </div>
    }
  ];

  return <TabBar tabs={tabs}/>;
}
