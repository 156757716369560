import { GridColDef } from "@mui/x-data-grid-pro";

export const attributeSynchronizationDefinitionColumns: GridColDef[] = [
    {
        field: "name",
        flex: 0.5
    },
    {
        field: "priority",
        width: 150,
    },
    {
        field: "defined",
        width: 150,
        align: "left",
        sortable: false
    },
    {
        field: "description",
        flex: 1,
        sortable: false,
        renderCell: params => params.row.flags
    }
];

export enum EAttributeSynchronizationDefinitionCacheType 
{
    SYSTEM = "SYSTEM"
}