import {defaults, omit} from "lodash";
import {Address, AddressInput} from "../identityApi.types";

export const emptyAddressInput = (): AddressInput => ({
  name: '',
  searchTerm: '',
  address1: '',
  address2: '',
  address3: '',
  zipcode: '',
  city: '',
  region: '',
  state: '',
  country: '',
  defaultTimeZone: '',
  defaultLanguage: '',
  phone: '',
  phoneExtension: '',
  mobilePhone: '',
  fax: '',
  faxExtension: '',
  email: '',
  standardCommunicationMethod: '',
  description: '',
});

export const addressInputFactory = (address?: Address | AddressInput | null): AddressInput => {

  if (!address) {
    return emptyAddressInput();
  }

  return defaults({}, omit(address, ['id']), emptyAddressInput());
}
