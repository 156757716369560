import {
  Account,
  FilterExpression,
  GeneratedAccountTypes,
  searchAccountsFilter,
  useGetAccountsQuery,
} from "@sivis/identity/api";
import { CursorBasedPageResult } from "@sivis/shared/components/entityView";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../common/parsePageResult";
import { useListSearch } from "../utils/filterUtils";

export const parseAccountsPageResult = (res?: GeneratedAccountTypes.GetAccountsQuery): CursorBasedPageResult<Account> => {
  return parseCursorBasedPageResult('accounts', res);
};

export const useAccountsPageApi = () => {
  // Initialize search filter and search handler
  const { filter: searchFilter, onSearch } = useListSearch(searchAccountsFilter);

  // Combine search filter and identity filter using the provided logic
  let accountsFilter: FilterExpression | undefined;
  if (searchFilter) {
    accountsFilter = searchFilter;
  } else {
    accountsFilter = undefined;
  }

  // Function to query accounts with pagination and combined filter
  const useAccountsPageQuery = (cursor: string | null) => {
    const queryResult = useGetAccountsQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: accountsFilter
    });

    return {
      ...queryResult,
      refetch: queryResult.refetch,
    };
  };

  return {
    onSearch,
    pageSize: DEFAULT_PAGE_SIZE,
    useAccountsPageQuery,
    parseAccountsPageResult
  };
};


export const useAccountsByIdentityPageApi = ({ identityId }: { identityId: string }) => {
  const IDENTITY_ID_FILTER = "identity.id";

  // Initialize search filter and search handler
  const { filter: searchFilter, onSearch: baseOnSearch } = useListSearch(searchAccountsFilter);

  // Custom onSearch handler that combines identity filter with search
  const onSearch = (searchTerm: string) => {
    // Create identity filter
    const identityFilter: FilterExpression = {
      name: IDENTITY_ID_FILTER,
      operator: "EQ",
      uuidValue: identityId
    };

    // If there's a search term, combine with the identity filter using logical AND
    if (searchTerm) {
      const searchFilter = baseOnSearch(searchTerm);
      return {
        name: "AND",
        operator: "LOGICAL",
        logicalValue: [identityFilter, searchFilter]
      } as FilterExpression;
    }

    return identityFilter;
  };

  // Combine search filter and identity filter
  const baseFilter: FilterExpression = {
    name: IDENTITY_ID_FILTER,
    operator: "EQ",
    uuidValue: identityId
  };

  const accountsFilter: FilterExpression = searchFilter ?
    {
      name: "AND",
      operator: "LOGICAL",
      logicalValue: [baseFilter, searchFilter]
    } :
    baseFilter;

  // Function to query accounts with pagination and combined filter
  const useAccountsByIdentityQuery = (cursor: string | null) => {
    const queryResult = useGetAccountsQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: accountsFilter
    }, {
      skip: !identityId
    });

    return {
      ...queryResult,
      refetch: queryResult.refetch,
    };
  };

  return {
    onSearch,
    pageSize: DEFAULT_PAGE_SIZE,
    useAccountsByIdentityQuery,
    parseAccountsPageResult
  };
};
