import { forceRefetchPaging, mergePaging, serializeQueryArgsPaging } from '@sivis/shared/api-utils';
import { api as generatedResourceTypeApi } from './resourceType.generated';

export const resourceTypeApi = generatedResourceTypeApi.enhanceEndpoints({
  endpoints: {
    GetResourceTypes: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("resourceTypes"),
      forceRefetch: forceRefetchPaging
    }
  }
})

export const {
  useGetResourceTypeQuery,
  useLazyGetResourceTypeQuery,
  useGetResourceTypesQuery,
  useLazyGetResourceTypesQuery
} = resourceTypeApi;
