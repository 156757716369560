import {Ownership, useGetOwnershipQuery, useUpdateOwnershipMutation} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {parseValidityInput} from "../../utils/validityUtils";

export const useOwnershipApi = (
  id: string
) => {
  const {data} = useGetOwnershipQuery({id}, {refetchOnMountOrArgChange: true});
  const [updateOwnership] = useUpdateOwnershipMutation();
  const [ownership, setOwnership] = useState<Ownership>();

  useEffect(() => {
    setOwnership(data?.ownership ?? undefined);
  }, [data?.ownership]);

  const onSave = (value: Ownership) => {
    return updateOwnership({
      id,
      input: {
        validity: parseValidityInput(value?.validity ?? undefined)
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).then(() => {
    });
  };

  return {
    ownership,
    onSave
  }
}
