import {GridColDef} from "@mui/x-data-grid-pro";
import {EntityTable, EntityTableRow} from "@sivis/shared/components/entityView";
import {OrganizationalEntity, OrganizationalEntityType} from "@sivis/identity/api";
import {
  useOrganizationalEntityPageForOrganizationalEntityTypeApi
} from "./api/useOrganizationalEntityPageForOrganizationalEntityTypeApi";
import {useEffect, useState} from "react";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

export interface OrganizationalEntityTypeOrganizationalEntitiesTabProps {
  organizationalEntityType?: OrganizationalEntityType,
}

const entityToTableRow = (entity: OrganizationalEntity): EntityTableRow => {
  return {
    id: entity.id,
    organizationalEntityName: entity.name,
    alternativeName: entity.alternativeName,
    customID: entity.customID,
  };
};

const columns: GridColDef[] = [
  {
    field: "organizationalEntityName",
    flex: 1,
  },
  {
    field: "alternativeName",
    flex: 1,
  },
  {
    field: "customID",
    flex: 1,
  }
];



const OrganizationalEntityTypeOrganizationalEntitiesTab = (props: OrganizationalEntityTypeOrganizationalEntitiesTabProps) => {
  const intl = useCustomIntl();
  const {
    onSearch,
    organizationalEntities
  } = useOrganizationalEntityPageForOrganizationalEntityTypeApi({
    organizationalEntityType: props.organizationalEntityType
  });
  const [rows, setRows] = useState<EntityTableRow[]>([]);

  const translatedColumns = useTranslateColumns(columns, "fusion.organizationalEntity.propertyName");

  useEffect(() => {
    setRows(organizationalEntities.map(entityToTableRow));
  }, [organizationalEntities]);

  return <EntityTable
    columns={translatedColumns}
    rows={rows}
    onSearch={onSearch}
    placeholder={intl.format("fusion.general.searchPlaceholder")}
    totalEntries={rows.length}
  />;
}

export default OrganizationalEntityTypeOrganizationalEntitiesTab;

