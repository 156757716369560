import { ThemeOptions } from '@mui/material/styles';
import { Theme } from '@mui/material';

import './globalStyles.scss';

const PRIMARY_TEXT_COLOR = '--primaryTextColor';
const SELECTED_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.08)';

// Extend the existing theme types to add custom colors
declare module '@mui/material/styles' {
  interface CommonColors {
    almostBlack: string;
    almostWhite: string;
  }
}

export const initialPointsharpThemeOptions = (): ThemeOptions => ({
  palette: {
    primary: {
      main:
        getComputedStyle(document.documentElement).getPropertyValue('--primaryColor').replace(/\s/g, '') || '#0047BB'
    },
    secondary: {
      main:
        getComputedStyle(document.documentElement).getPropertyValue('--secondaryColor').replace(/\s/g, '') ||
        '#7F56C5'
    },
    error: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--errorColor').replace(/\s/g, '') ||
        '#DA1E28'
    },
    common: {
      almostBlack: '#1d1f29',
      almostWhite: '#f4f7f8'
    }
  },
  typography: {
    fontFamily:
      getComputedStyle(document.documentElement).getPropertyValue('--fontFamily').trim() ||
      'Source Sans Pro, sans-serif',
    allVariants: {
      color:
        getComputedStyle(document.documentElement)
        .getPropertyValue(PRIMARY_TEXT_COLOR)
        .replace(/\s/, '') || '#1D1F29'
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth:
            getComputedStyle(document.documentElement).getPropertyValue('--buttonWidth').replace(/\s/, '') ||
            '120px',
          whiteSpace: 'nowrap',
          fontSize: '14px',
          variants: [
            {
              props: { variant: 'contained' },
              style: {
                color: getComputedStyle(document.documentElement)
                .getPropertyValue('--secondaryTextColor')
                .replace(/\s/, '') || '#FFFFFF'
              }
            }
          ]
        },
        outlinedPrimary: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#1D1F29'
        },
        outlinedSecondary: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#1D1F29'
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '7px 14px',
          fontSize: '13px',
          minWidth: '16px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputSizeSmall: {
          paddingTop: 0,
          paddingBottom: 0,
          height: '30px',
          fontSize: '14px',
          paddingLeft: '10px',
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#1D1F29'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#1D1F29',
          lineHeight: 'normal'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorInherit: {
          opacity: 1
        },
        root: {
          minWidth: '160px'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#1D1F29'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'unset'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: '1100'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: SELECTED_BACKGROUND_COLOR,
            '&:hover': {
              backgroundColor: SELECTED_BACKGROUND_COLOR
            }
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: SELECTED_BACKGROUND_COLOR,
            '&:hover': {
              backgroundColor: SELECTED_BACKGROUND_COLOR
            }
          }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            color: getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#1D1F29'
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: '#FFFFFF'
        }
      }
    }
  }
});

export type PointsharpTheme = Theme;
