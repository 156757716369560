import { EntitlementType, Identity, RelationQueryType } from '@sivis/identity/api';
import { useResourceEntitlementsApi } from './useResourceEntitlementsApi';
import { combineFilter, FilterExpression, FilterOperator } from '@sivis/shared/api-utils';

export const useIdentityResourceEntitlementsApi = (identity?: Identity, extraFilter?: FilterExpression) => {
  const identityId = identity?.id;
  // TODO: search entitlements by resource name / resource type
  const identityEntitlementsFilter = combineFilter(FilterOperator.AND, {
    name: "type",
    operator: FilterOperator.EQ,
    stringValue: EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT
  }, {
    name: "entitledId",
    operator: FilterOperator.EQ,
    uuidValue: identityId
  });

  const filter = extraFilter ? combineFilter(FilterOperator.AND, extraFilter, identityEntitlementsFilter) : identityEntitlementsFilter;

  return useResourceEntitlementsApi(
    EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT,
    filter,
    RelationQueryType.IDENTITY_RESOURCE_ENTITLEMENTS,
    identityId
  );
};
