import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";
import {
  EntityDetailsProps,
  EntityTableRow,
  ListModifyView,
  TabBar
} from "@sivis/shared/components/entityView";
import {isResourceEqual, Resource, ResourceSet} from "@sivis/identity/api";
import styles from "./resourceSet.module.scss";
import {useCustomIntl, useTranslateColumns, useTranslateConfigs} from "@sivis/intl";
import {ConnectionInformationTab} from "../components/popup/connectionInformationTab";
import {RelationEntityType} from "../components/popup/relationUtils";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useResourcesForResourceSetApi} from "./useResourcesForResourceSetApi";
import {configs} from "../resource/resourceInformationTab";

interface ResourcesSetResourcesTabProps {
  resourceSet: ResourceSet;
  editMode: boolean;
}

const EntityDetails = ({entity}: EntityDetailsProps<Resource>) => {
  const intl = useCustomIntl();
  const translatedConfigs = useTranslateConfigs(configs, "fusion.resource.propertyName");
  const tabs = [
    {
      label: intl.format("fusion.resource.propertyName.name"),
      content: <ConnectionInformationTab
        configs={translatedConfigs}
        entity={entity}
        entityType={RelationEntityType.RESOURCE}
      />
    }
  ];
  return <TabBar tabs={tabs}/>;
};

const renderResource = (resource?: Resource) => {
  return resource ? `${resource.name}` : "";
};

const optionToResource = (option: Resource) => ({...option});

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "resourceId",
    flex: 1
  },
  {
    field: "resourceType",
    flex: 1
  },
  {
    field: "systemName",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

const ResourceSetResourcesTab = ({resourceSet, editMode}: ResourcesSetResourcesTabProps) => {
  const intl = useCustomIntl();
  const {
    currentlyContainedResources,
    onSave,
    onSearchForAvailableResources,
    availableResources,
    onSearch
  } = useResourcesForResourceSetApi(resourceSet);

  const translatedColumns = useTranslateColumns(columns, "fusion.resource.propertyName")

  const entityToTableRow = (entity: Resource, isNew: boolean,
                            onEdit?: (entity: Resource) => void, onDelete?: (entity: Resource) => void): EntityTableRow => {
    return {
      id: entity.id,
      name: entity.name,
      resourceId: entity.resourceId,
      resourceType: entity.resourceType?.name,
      systemName: entity.system?.systemName,
      isNew: isNew,
      action: <div className={styles.deleteButtonContainer}>
        <IconButton
          onClick={onDelete ? () => onDelete(entity) : undefined}
          aria-label={intl.format("general.delete")}>
          <ClearIcon/>
        </IconButton>
      </div>
    };
  };

  const getResourcePopupTitle = (resource: Resource) => ({
    primaryTitle: `${resource.name}`,
    secondaryTitle: intl.format("fusion.resourceSet.resource.popup.secondaryTitle", {
      resource: resource.name,
      resourceSet: resourceSet.name
    })
  });

  const shownColumns = editMode ? translatedColumns : translatedColumns.filter(column => column.field !== "action");

  const mapTableRowToEntity = (rowId: string, entity: Resource) => {
    return entity.id === rowId;
  };

  return <div className={styles.listContainer}>
    <ListModifyView
      columns={shownColumns}
      entities={currentlyContainedResources}
      options={availableResources}
      onSearchForOptions={onSearchForAvailableResources}
      renderOption={renderResource}
      onSave={onSave}
      entityToTableRow={entityToTableRow}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getResourcePopupTitle}
      optionToEntity={optionToResource}
      isEqual={isResourceEqual}
      entityDetails={EntityDetails}
      mapTableRowToEntity={mapTableRowToEntity}
    />
  </div>;
};

export default ResourceSetResourcesTab;
