import {
  EOwnershipEntity,
  EOwnershipType,
  ExtendedOwnership,
  getOwnershipOwnerType,
  isEOwnershipEntity,
  OwnershipQueryType, Resource
} from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import {
  DropdownOption,
  ListModifyViewUpdated
} from "@sivis/shared/components/entityView";
import { isEqualById } from "@sivis/shared/utils";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { EntityType, useSingleEditMode } from "../components/breadcrumb/editModeContext";
import { ConfirmationPopup } from "../components/popup/confirmationPopup";
import styles from "../identity/identity.module.scss";
import { TargetOwnershipDetails } from "../ownership/TargetOwnershipDetails";
import { useOwnershipApiForTarget } from "../ownership/api/useOwnershipApiForTarget";
import { useOwnershipsSideFilter } from "../ownership/components/useOwnershipsSideFilter";
import { createOwnershipPopup } from "../ownership/createOwnershipPopup";
import {
  mapTableRowToOwnership,
  onSearch,
  ownershipColumns,
  ownershipsToTableRow,
  useOwnershipMessages
} from "../ownership/ownershipUtils";
import { parseValidityDateString } from "../utils/validityUtils";

type ContextType = {
  resource: Resource;
  editMode: boolean;
};

export const ResourceOwnershipTab = () => {
  const {resource, editMode} = useOutletContext<ContextType>();
  const intl = useCustomIntl();
  const {deleteOwnershipMessage} = useOwnershipMessages();

  const getResourceOwnershipTypeFilterText = (type: string) => {
    if (type === EOwnershipType.ROLE_RESOURCE_OWNERSHIP.valueOf()) {
      return intl.format("fusion.role.propertyName.name");
    }
    return intl.format("fusion.identity.propertyName.name");
  }
  const {
    activeFilter,
    filterComponent
  } = useOwnershipsSideFilter(OwnershipQueryType.RESOURCE_OWNERSHIPS, getResourceOwnershipTypeFilterText, resource?.id, resource?.id);
  const {
    onSave,
    submitted,
    setSubmitted,
    pageSize,
    useOwnershipsPageQuery,
    parseOwnershipsPageResult
  } = useOwnershipApiForTarget(resource, activeFilter ?? undefined);
  const [ownershipTargetType, setOwnershipTargetType] = useState<EOwnershipEntity>();

  const translatedColumns = useTranslateColumns(ownershipColumns, "fusion.ownership.propertyName");
  const shownColumns = editMode ? translatedColumns : translatedColumns.filter(column => column.field !== "action");
  const {toggleEditMode} = useSingleEditMode(EntityType.RESOURCE);

  const getOwnershipPopupTitle = (ownership: ExtendedOwnership) => ({
    primaryTitle: intl.format("fusion.ownership.ownershipPopup.title.edit", {
      owner: ownership.targetName ?? "",
      target: resource.name ?? "",
    }),
    secondaryTitle: intl.format("fusion.ownership.ownershipPopup.secondaryTitle.targetOwned", {
      owner: ownership.targetName ?? "",
      target: resource.name ?? "",
      validFrom: parseValidityDateString(ownership.validity?.validFrom),
      validTo: parseValidityDateString(ownership.validity?.validTo)
    })
  });

  const confirmationPopup = resource ? <ConfirmationPopup
    open={submitted}
    onClose={() => {
      setSubmitted(false);
      toggleEditMode();
    }}
    primaryTitle={`${intl.format("fusion.ownership.ownershipPopup.title")}${resource.name ?? ""}`}
    message={intl.format("fusion.message.submitted", {entity: resource.name})}
  /> : null;

  const resourceOwnershipDropdownMenuOptions: DropdownOption[] = [
    {
      entityToAdd: EOwnershipEntity.IDENTITY,
      label: intl.format("fusion.identity.propertyName.name")
    },
    {
      entityToAdd: EOwnershipEntity.ROLE,
      label: intl.format("fusion.role.propertyName.name")
    }
  ];

  const handleSelectDropdownOption = (option: DropdownOption | undefined) => {
    if (option && isEOwnershipEntity(option)) {
      setOwnershipTargetType(option);
    }
  };

  const createPopup = createOwnershipPopup({
    ownershipFor: EOwnershipEntity.RESOURCE,
    ownershipTargetType: ownershipTargetType,
    primaryTitle: intl.format("fusion.resourceOwnership.add"),
    secondaryTitle: intl.formatBold(ownershipTargetType === EOwnershipEntity.IDENTITY ? "fusion.ownership.targetIdentityOwnership.createPopup.description" : "fusion.ownership.targetRoleOwnership.createPopup.description",
      {target: resource ? resource.name : ""}),
    target: resource
  })

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
      columns={shownColumns}
      onSave={onSave}
      entityToTableRow={ownershipsToTableRow(getOwnershipOwnerType, intl.format)}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getOwnershipPopupTitle}
      isEqual={isEqualById}
      entityDetails={TargetOwnershipDetails}
      deleteMessage={deleteOwnershipMessage}
      mapTableRowToEntity={mapTableRowToOwnership}
      pageSize={pageSize}
      usePageQuery={useOwnershipsPageQuery}
      parseResult={parseOwnershipsPageResult}
      createButtonText="fusion.ownership.add"
      createPopup={createPopup}
      tableType={OwnershipQueryType.RESOURCE_OWNERSHIPS}
      useToolbarButtonsWithOptions={true}
      dropdownMenuItems={resourceOwnershipDropdownMenuOptions}
      setSelectedDropdownOption={handleSelectDropdownOption}
      sideFilter={filterComponent}
    />
  </div>
}
