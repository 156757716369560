import {Validity, ValidityInput} from "@sivis/identity/api";
import {CustomIntlType} from "@sivis/intl";
import {FieldType} from "@sivis/shared/components/entityView";
import {parseDateString} from "../entitlement/entitlementUtils";

const stringToDate = (input?: string) => input ? new Date(input) : undefined;

export const validateDateRangeInput = (validFrom?: string, validTo?: string) => {
  const validFromDate = stringToDate(validFrom);
  const validToDate = stringToDate(validTo);
  return !validFromDate || !validToDate || validFromDate <= validToDate;
}

/**
 * Checks if the dates are valid and remove timezone from string
 */
export const parseValidityInput = (input?: ValidityInput): ValidityInput => {
  const validFrom = stringToDate(input?.validFrom ?? undefined);
  const validTo = stringToDate(input?.validTo ?? undefined);
  if (validFrom && validTo && validFrom > validTo) {
    throw new Error("Invalid input: validTo cannot be before validFrom.");
  }

  return {
    validFrom: validFrom ? validFrom.toISOString().replace('Z', '') : undefined,
    validTo: validTo ? validTo.toISOString().replace('Z', '') : undefined
  };
};

export const parseValidityDateString = (date?: string | null) => date ? new Date(date).toLocaleDateString() : "-";

export const showValidityFlag = (validity?: Validity | null) => {
  return validity?.validTo && new Date(validity.validTo).getTime() > new Date().getTime();
};

export const dateDiffInDays = (dateEarlier: Date, dateLater: Date) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information
  const utc1 = Date.UTC(dateEarlier.getFullYear(), dateEarlier.getMonth(), dateEarlier.getDate());
  const utc2 = Date.UTC(dateLater.getFullYear(), dateLater.getMonth(), dateLater.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export const getValidityFieldConfigs = (translate: CustomIntlType["format"]) => {
  const validFromText = translate("fusion.general.validity.validFrom");
  const validToText = translate("fusion.general.validity.validTo");
  return {
    property: [
      {
        property: 'validity.validFrom',
        name: validFromText,
        fieldType: FieldType.DATE,
      },
      {
        property: 'validity.validTo',
        name: validToText,
        fieldType: FieldType.DATE,
        validate: (validTo: string, allValues: Record<string, any>) => {
          return validateDateRangeInput(allValues.validity?.validFrom, validTo) ||
              translate("fusion.general.validity.dateRangeError", {
                validFrom: validFromText,
                validTo: validToText
              });
        }
      }
    ],
    name: translate("fusion.general.validity"),
    renderRow: (value: string) => parseDateString(value, "-")
  }
};
