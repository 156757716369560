import {useRolesPageApi} from "../role/useRolesPageApi";
import {useTranslateColumns} from "@sivis/intl";
import {
  isIdentity,
  isResource,
  isResourceSet,
  isSystem,
  roleOwnershipColumns
} from "./ownershipUtils";
import {EOwnershipType, Role} from "@sivis/identity/api";
import {addButton, removeButton} from "../membership/createMembershipForIdentity";
import styles from "./ownership.module.scss";
import {ListAddView} from "@sivis/shared/components/entityView";
import {CreateOwnershipProps} from "./createOwnership";

export const CreateOwnershipRolesList = (props: CreateOwnershipProps) => {
  const {
    onSearch,
    useRolesPageQuery,
    parseRolesPageResult,
    pageSize
  } = useRolesPageApi();
  const translatedColumns = useTranslateColumns(roleOwnershipColumns, "fusion.role.propertyName");

  const entityToTableRow = (entity: Role, isSelected: boolean) => {
    return {
      id: entity.id,
      name: entity.name,
      description: entity.description,
      identitiesCount: entity.identitiesCount,
      dataObject: entity,
      isNew: isSelected,
      action: isSelected ? removeButton : addButton
    };
  };

  let type: EOwnershipType;
  if (isResource(props.selectedEntity)) {
    type = EOwnershipType.ROLE_RESOURCE_OWNERSHIP;
  } else if (isResourceSet(props.selectedEntity)) {
    type = EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP;
  } else if (isSystem(props.selectedEntity)) {
    type = EOwnershipType.ROLE_SYSTEM_OWNERSHIP;
  } else if (isIdentity(props.selectedEntity)) {
    type = EOwnershipType.IDENTITY_ROLE_OWNERSHIP;
  } else {
    throw new Error("Unknown entity type");
  }

  const onAdd = (roles: Role[]) => {
    if (isIdentity(props.selectedEntity)) {
      props.onAdd(roles.map(role => ({
        id: props.selectedEntity.id + role.id,
        ownerId: props.selectedEntity.id,
        targetId: role.id,
        type: EOwnershipType.IDENTITY_ROLE_OWNERSHIP,
        targetName: role.name
      })));
    } else {
      props.onAdd(roles.map(role => ({
        id: role.id + props.selectedEntity.id,
        ownerId: role.id,
        targetId: props.selectedEntity.id,
        type: type,
        targetName: role.name
      })));
    }
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={entityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useRolesPageQuery}
      parseResult={parseRolesPageResult}
      excludedItems={[]}
    />
  </div>;
};
