import {BackendSystem} from './BackendSystem';

export interface BackendState {
  defaultSystem: string
  systems: {
    [id: string]: BackendSystem
  },
  errors: {
    [id: string]: any
  }
}

export const BACKEND_SLICE_NAME = 'backend';

export interface BackendSliceState {
  [BACKEND_SLICE_NAME]: BackendState
}
