import {defaults, omit} from "lodash";
import {FilterExpression, Role, RoleInput} from "../identityApi.types";
import {FilterOperator} from "@sivis/shared/api-utils";

const emptyRoleInput = (): RoleInput => ({
  name: '',
  description: ''
});

export const roleInputFactory = (role?: Role | RoleInput | null): RoleInput => {
  if (!role) {
    return emptyRoleInput();
  }

  return defaults({}, omit(role, ['id', 'identities']), emptyRoleInput());
}

export const searchRolesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "description", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});
