import {
    AsDefinition,
    useGetAttributeSyncDefinitionsForSystemQuery,
    GeneratedAttributeSyncDefinitionTypes,
    searchAsdFilter
} from "@sivis/attributesync/api";
import { CursorBasedPageResult } from "@sivis/shared/components/entityView";
import {
    DEFAULT_PAGE_SIZE,
    parseCursorBasedPageResult
} from "../../common/parsePageResult";
import { useListSearch } from "../../utils/filterUtils";

export interface AttributeSyncDefinitionPageApiProps {
    systemId: string | undefined;
}

export const useAttributeSyncDefinitionPageApi = (props: AttributeSyncDefinitionPageApiProps) => {
    const {onSearch } = useListSearch(searchAsdFilter);

    const useAttributeSyncDefinitionsForSystemPageQuery = (cursor: string | null) => 
        useGetAttributeSyncDefinitionsForSystemQuery({ first: DEFAULT_PAGE_SIZE, after: cursor, systemId: props.systemId });

    const parseAttributeSyncDefinitionsPageResult = (res?: GeneratedAttributeSyncDefinitionTypes.GetAttributeSyncDefinitionsQuery): CursorBasedPageResult<AsDefinition> => {
        return parseCursorBasedPageResult('asDefinitions', res);
    };

    return {
        pageSize: DEFAULT_PAGE_SIZE,
        onSearch,
        useAttributeSyncDefinitionsForSystemPageQuery,
        parseAttributeSyncDefinitionsPageResult
    };
};