import {Link} from "react-router-dom";
import {OrganizationalEntity} from "@sivis/identity/api";
import {useOrganizationalEntitiesPageApi} from "./api/useOrganizationalEntitiesPageApi";
import {GridColDef} from '@mui/x-data-grid';
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: OrganizationalEntity) => {
  return {
    id: entity.id,
    name: entity.name,
    organizationalEntityType: entity.organizationalEntityType?.name,
    address: entity.address?.name,
    alternativeName: entity.alternativeName,
    customID: entity.customID,
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "organizationalEntityType",
    flex: 1,
  },
  {
    field: "alternativeName",
    flex: 1,
  },
  {
    field: "customID",
    flex: 1,
  },
  {
    field: "address",
    flex: 1,
  },
];

export const OrganizationalEntityList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useOrganizationalEntitiesPageQuery,
    parseOrganizationalEntitiesPageResult,
    pageSize
  } = useOrganizationalEntitiesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.organizationalEntity.propertyName");

  return <EntityView
    title={intl.format("fusion.organizationalEntity.list.title")}
    actionButtons={[<CreateButton key={"create"}/>]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useOrganizationalEntitiesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseOrganizationalEntitiesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
