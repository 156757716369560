import { CursorBasedPageResult } from "@sivis/shared/components/entityView";
import {
  AccountType,
  GeneratedAccountTypes,
  searchAccountTypesFilter,
  useGetAccountTypesQuery,
} from "@sivis/identity/api";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../common/parsePageResult";
import { useListSearch } from "../utils/filterUtils";

export const useAccountTypesPageApi = () => {
  const { filter, onSearch } = useListSearch(searchAccountTypesFilter);

  const useAccountTypesPageQuery = (cursor: string | null) => {
    const queryResult = useGetAccountTypesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    });

    return {
      ...queryResult,
      refetch: queryResult.refetch,
    };
  };

  const parseAccountTypesPageResult = (res?: GeneratedAccountTypes.GetAccountTypesQuery): CursorBasedPageResult<AccountType> => {
    return parseCursorBasedPageResult('accountTypes', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useAccountTypesPageQuery,
    parseAccountTypesPageResult
  };
};
