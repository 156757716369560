import {IdentityRoleMembership} from "@sivis/identity/api";
import {FormatMessageFunction} from "@sivis/intl";
import {
  DatePicker,
  isoStringToDate,
  normalizeTimeOfDateInMilliseconds
} from "@sivis/shared/components/date-picker";
import {parseDateString} from "../entitlement/entitlementUtils";
import React from "react";
import {PrimitiveType} from "intl-messageformat";
import {Flags, validityIcon} from "../components/flag/flags";
import {dateDiffInDays, showValidityFlag} from "../utils/validityUtils";

export const isMembership = (value: Record<string, any>): value is IdentityRoleMembership => {
  return value['identity']?.id && value['role']?.id;
};

export const deleteMembershipMessage = "fusion.membership.message.deleteMembership";

export const mapTableRowToMembership = (rowId: string, membership: IdentityRoleMembership) => rowId === membership.id;


export const getAccountsText = (allAccounts: boolean | undefined, format: FormatMessageFunction) =>
  allAccounts === undefined ? "" : format("fusion.membership.allAccounts." + allAccounts);

// TODO: template text
export const getTemplateText = (isTemplate: boolean) => isTemplate ? "Template" : "No template";

interface RenderDateParams {
  date: string | null;
  onChange: (newDate: Date | null) => void;
  editMode: boolean;
  minDate?: string;
  maxDate?: string;
}

export const renderDate = ({date, onChange, editMode, minDate, maxDate}: RenderDateParams) => {
  if (editMode) {
    return <DatePicker
      date={isoStringToDate(date)}
      onChange={onChange}
      minDate={isoStringToDate(minDate) ?? undefined}
      maxDate={isoStringToDate(maxDate) ?? undefined}
    />;
  }
  return <span>{parseDateString(date ?? null)}</span>;
};

interface ValidateDateRangeResult {
  validated: boolean;
  errorMsg: null | string;
}

// TODO: translate "no validity"
const formatDate = (date: Date | null) => date ? date.toDateString() : "no validity";

export const validateDateRange = (from: Date | null, to: Date | null, parent: IdentityRoleMembership,
                                  format: (id: string, values?: Record<string, PrimitiveType>) => string): ValidateDateRangeResult => {
  if (from && to && normalizeTimeOfDateInMilliseconds(from) > normalizeTimeOfDateInMilliseconds(to)) {
    return {
      validated: false,
      errorMsg: null
    };
  }

  let errorMsg: null | string = null;
  const parentValidFrom = isoStringToDate(parent.validity?.validFrom);
  const parentValidTo = isoStringToDate(parent.validity?.validTo);
  const startDateValid = !parentValidFrom ||
    (from && normalizeTimeOfDateInMilliseconds(from) >= normalizeTimeOfDateInMilliseconds(parentValidFrom));
  const endDateValid = !parentValidTo ||
    (to && normalizeTimeOfDateInMilliseconds(to) <= normalizeTimeOfDateInMilliseconds(parentValidTo));

  if (!startDateValid) {
    errorMsg = format(
      "fusion.membership.validityRange.error",
      {accountDate: formatDate(from), identityDate: formatDate(parentValidFrom)}
    );
  } else if (!endDateValid) {
    errorMsg = format(
      "fusion.membership.validityRange.error",
      {accountDate: formatDate(to), identityDate: formatDate(parentValidTo)}
    );
  }
  return {
    validated: !!startDateValid && !!endDateValid,
    errorMsg
  };
};

export const isValidityShortened = (membership: IdentityRoleMembership, updatedMembership: IdentityRoleMembership) => {
  const validFrom = isoStringToDate(membership.validity?.validFrom);
  const validTo = isoStringToDate(membership.validity?.validTo);
  const updatedValidFrom = isoStringToDate(updatedMembership.validity?.validFrom);
  const updatedValidTo = isoStringToDate(updatedMembership.validity?.validTo);

  // check if start date is shortened:
  // 1. if only updated validFrom is set -> shortened
  // 2. both validFrom dates are set, but updated one is later -> shortened
  const startDateShortened = (!validFrom && !!updatedValidFrom) ||
    (!!validFrom && !!updatedValidFrom && normalizeTimeOfDateInMilliseconds(validFrom) <= normalizeTimeOfDateInMilliseconds(updatedValidFrom));

  // check if end date is shortened:
  // 1. if only updated validTo is set -> shortened
  // 2. both validTo dates are set, but updated one is earlier -> shortened
  const endDateShortened = (!validTo && !!updatedValidTo) ||
    (!!validTo && !!updatedValidTo && normalizeTimeOfDateInMilliseconds(validTo) >= normalizeTimeOfDateInMilliseconds(updatedValidTo));
  return startDateShortened || endDateShortened;
};

export const getMembershipFlags = (relation: IdentityRoleMembership, format: FormatMessageFunction) => {
  if (showValidityFlag(relation.validity)) {
    return <Flags flags={[
      {
        text: format("fusion.membership.expiresIn", {expiresIn: dateDiffInDays(new Date(), new Date(relation.validity?.validTo ?? ""))}),
        icon: validityIcon
      }
    ]}/>;
  }
  return null;
};
