import { GridColDef } from "@mui/x-data-grid-pro";
import { Account, identityName, System, useGetAccountQuery, useGetIdentityQuery } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns, useTranslateConfigs } from "@sivis/intl";
import {
  EntityDetailsProps,
  EntityTableRow,
  InfiniteScrollTableCursor,
  Popup,
  TabBar
} from "@sivis/shared/components/entityView";
import { useEffect, useState } from "react";
import { ConnectionInformationTab } from "../components/popup/connectionInformationTab";
import { RelationEntityType } from "../components/popup/relationUtils";
import { useAccountsPageForSystemApi } from "./useAccountsPageforSystemApi";

export interface SystemAccountsTabProps {
  system?: System;
}

export interface SelectedAccountDetails {
  id: string,
  name: string,
  identityId: string,
  identityName: string,
  systemName: string,
  accountTypeName: string,
  accountTypeDescription: string | undefined,
  email: string,
  accountStatus: string,
  validity: string,
  createdAt: string,
}

// TODO: define account status and account validity
const entityToTableRow = (entity: Account): EntityTableRow => {
  return {
    id: entity.id,
    accountId: entity.name,
    accountType: entity.accountType.name,
    accountStatus: "",
  };
}

const columns: GridColDef[] = [
  {field: "accountId", flex: 1},
  {field: "accountType", flex: 1},
  {field: "accountStatus", flex: 1},
  {
    field: "flags",
    sortable: false,
    flex: 1,
    renderCell: params => params.row.flags
  },
];

const EntityDetails = ({entity}: EntityDetailsProps<SelectedAccountDetails>) => {
  const intl = useCustomIntl();
  const configs = [
    {property: "identityName"},
    {property: "systemName"},
    {property: "accountTypeName"},
    {property: "email"},
    {property: "accountStatus"},
    {property: "validity"},
    {property: "createdAt"}
  ];
  const translatedConfigs = useTranslateConfigs(configs, "fusion.account.propertyName");
  const tabs = [
    {
      label: intl.format("fusion.account.title"),
      content: <ConnectionInformationTab
        configs={translatedConfigs}
        entity={entity}
        entityType={RelationEntityType.ACCOUNT}
      />
    }
  ];
  return entity ? <TabBar tabs={tabs}/> : null;
}

const SystemAccountsTab = (props: SystemAccountsTabProps) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [accountId, setAccountId] = useState<string>();
  const {data: dataAccount} = useGetAccountQuery({id: accountId ?? ""}, {skip: !accountId});
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const {data: dataIdentity} = useGetIdentityQuery({id: selectedAccount?.identityId ?? ""}, {skip: !selectedAccount});
  const intl = useCustomIntl();
  const translatedColumns = useTranslateColumns(columns, "fusion.account.propertyName");
  const [accountIdentityName, setAccountIdentityName] = useState<string>("");

  const {
    onSearch,
    pageSize,
    useAccountsPageForSystemPageQuery,
    parseAccountsPageForSystemPageResult
  } = useAccountsPageForSystemApi(props.system?.id);

  useEffect(() => {
    if (dataAccount?.account) {
      setSelectedAccount(dataAccount.account);
    }
  }, [dataAccount?.account]);

  useEffect(() => {
    if (dataIdentity?.identity) {
      setAccountIdentityName(
        dataIdentity?.identity.firstName && dataIdentity?.identity.lastName ?
          identityName(dataIdentity.identity) : dataIdentity.identity.lastName
      );
    }
  }, [dataIdentity?.identity]);

  const buildModifiedAccount = (): SelectedAccountDetails => {
    return {
      id: selectedAccount?.id ?? "",
      name: selectedAccount?.name ?? "",
      accountTypeName: selectedAccount?.accountType.name ?? "",
      accountTypeDescription: selectedAccount?.accountType.description ?? "",
      identityId: selectedAccount?.identityId ?? "",
      identityName: accountIdentityName,
      systemName: props.system?.systemName ?? "",
      email: "placeholder@email.com",
      accountStatus: "active",
      validity: "todo",
      createdAt: selectedAccount?.meta?.createdAt ? new Date(selectedAccount.meta.createdAt).toLocaleDateString() : "",
    }
  };

  const onRowClick = (id: string) => {
    if (id !== accountId) {
      setSelectedAccount(null);
    }
    setAccountId(id);
    setPopupOpen(true);
  }

  const onClosePopup = () => {
    setPopupOpen(false);
  }

  return (
    <>
      <InfiniteScrollTableCursor
        columns={translatedColumns}
        pageSize={pageSize}
        usePageQuery={useAccountsPageForSystemPageQuery}
        renderRow={entityToTableRow}
        parseResult={parseAccountsPageForSystemPageResult}
        onSearch={onSearch}
        placeholder={intl.format("fusion.general.searchPlaceholder")}
        onRowClick={onRowClick}
      />
      <Popup
        open={popupOpen}
        primaryTitle={selectedAccount?.name ?? ""}
        secondaryTitle={intl.format("fusion.system.account.popup.secondaryTitle", {
          identityName: accountIdentityName
        })}
        onClose={onClosePopup}
        hideButtons
        styleType="edit"
      >
        {selectedAccount ? <EntityDetails
          entity={buildModifiedAccount()}
          editMode={false}
        /> : null}
      </Popup>
    </>
  );
}

export default SystemAccountsTab;
