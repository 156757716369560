import {
  FilterExpression,
  GeneratedRoleTypes,
  Role,
  searchRolesFilter,
  useGetRolesQuery,
  useGetRolesWithIdentitiesCountQuery
} from '@sivis/identity/api';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../common/parsePageResult';
import { getExistingOrCombinedFilter, useListSearch } from '../utils/filterUtils';

export const useRolesPageApi = (extraFilter?: FilterExpression) => {
  const { filter, onSearch } = useListSearch(searchRolesFilter);
  let rolesFilter = getExistingOrCombinedFilter(filter, extraFilter);

  const useRolesPageQuery = (cursor: string | null) =>
    useGetRolesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: rolesFilter
    });

  const useRolesWithIdentitiesCountPageQuery = (cursor: string | null) =>
    useGetRolesWithIdentitiesCountQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: rolesFilter
    });

  const parseRolesPageResult = (res?: GeneratedRoleTypes.GetRolesQuery): CursorBasedPageResult<Role> => {
    return parseCursorBasedPageResult('roles', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useRolesPageQuery,
    useRolesWithIdentitiesCountPageQuery,
    parseRolesPageResult
  };
};
