import { EntitlementType, System } from "@sivis/identity/api";
import { combineFilter, FilterOperator } from "@sivis/shared/api-utils";
import { useRoleEntitlementsApi } from "./useRoleEntitlementsApi";

export const useSystemRoleEntitlementsApi = (system?: System) => {
  const systemId = system?.id;
  // TODO: search entitlements
  const systemEntitlementsFilter = combineFilter(FilterOperator.AND, {
    name: "type",
    operator: FilterOperator.EQ,
    stringValue: EntitlementType.ROLE_SYSTEM_ENTITLEMENT
  }, {
    name: "targetId",
    operator: FilterOperator.EQ,
    uuidValue: systemId
  });

  return useRoleEntitlementsApi(EntitlementType.ROLE_SYSTEM_ENTITLEMENT, systemEntitlementsFilter, systemId);
};
