import {Ownership} from "@sivis/identity/api";
import {EntityDetailsProps, TabBar} from "@sivis/shared/components/entityView";
import {useCustomIntl} from "@sivis/intl";
import {OwnershipInformationTab} from "./ownershipInformationTab";
import {useIdentityResourceOwnershipApi} from "./api/useIdentityResourceOwnershipApi";
import {RelationEntityType} from "../components/popup/relationUtils";
import {useIdentityResourceSetOwnershipApi} from "./api/useIdentityResourceSetOwnershipApi";
import {useIdentitySystemOwnershipApi} from "./api/useIdentitySystemOwnershipApi";
import {useIdentityRoleOwnershipApi} from "./api/useIdentityRoleOwnershipApi";

export const IdentityOwnershipDetails = (props: EntityDetailsProps<Ownership>) => {
  const intl = useCustomIntl();
  const resourceTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useIdentityResourceOwnershipApi}
        ownerType={RelationEntityType.IDENTITY}
        targetType={RelationEntityType.RESOURCE}
      />
    }
  ]

  const resourceSetTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useIdentityResourceSetOwnershipApi}
        ownerType={RelationEntityType.IDENTITY}
        targetType={RelationEntityType.RESOURCE_SET}
      />
    }
  ]

  const systemTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useIdentitySystemOwnershipApi}
        ownerType={RelationEntityType.IDENTITY}
        targetType={RelationEntityType.SYSTEM}
      />
    }
  ]

  const roleTabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <OwnershipInformationTab
        {...props}
        useOwnershipQuery={useIdentityRoleOwnershipApi}
        ownerType={RelationEntityType.IDENTITY}
        targetType={RelationEntityType.ROLE}
      />
    }
  ]

  let tabs;
  if (props.entity.type === "IDENTITY_RESOURCE_OWNERSHIP") {
    tabs = resourceTabs;
  } else if (props.entity.type === "IDENTITY_RESOURCE_SET_OWNERSHIP") {
    tabs = resourceSetTabs;
  } else if (props.entity.type === "IDENTITY_SYSTEM_OWNERSHIP") {
    tabs = systemTabs;
  } else {
    tabs = roleTabs;
  }

  return <TabBar tabs={tabs}/>;
}
