import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import { api as generatedSystemApi } from './system.generated';

export const systemApi = generatedSystemApi.enhanceEndpoints({
  addTagTypes: ['system'],
  endpoints: {
    GetSystems: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("systems"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ['system']
    },
    CreateSystem: { invalidatesTags: ["system"] },
    UpdateSystem: { invalidatesTags: ["system"] },
    DeleteSystem: { invalidatesTags: ["system"] },
  }
})

export const {
  useGetSystemQuery,
  useLazyGetSystemQuery,
  useGetSystemsQuery,
  useLazyGetSystemsQuery,
  useGetSystemTypeFilterEntriesQuery,
  useCreateSystemMutation,
  useUpdateSystemMutation,
  useDeleteSystemMutation
} = systemApi;
