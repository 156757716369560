import {ThemeOptions} from "@mui/material/styles";

export interface IStyles {
  primaryColor: string;
  secondaryColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  primaryBackground: string;
  topnavBackground: string;
  sidenavBackground: string;
  tabbarColor: string;
  primaryIconColor: string;
  secondaryIconColor: string;
  shadowColor: string;
}

export interface IStylesState {
  themeType: ThemeType;
  themeOptions: ThemeOptions;
  styles: IStyles;
  loading: boolean;
  error: any;
  isHelpButtonVisible: boolean;
}

export interface IStyleStateSlice {
  theme: IStyles
}

export type ThemeType = "light" | "dark";


export enum EStyleProperties {
  primaryColor = "--primaryColor",
  secondaryColor = "--secondaryColor",
  topnavBackground = "--topnavBackground",
  sidenavBackground = "--sidenavBackground",
  tabbarColor = "--tabbarColor",
  primaryBackground = "--primaryBackground",
  primaryTextColor = "--primaryTextColor",
  secondaryTextColor = "--secondaryTextColor",
  primaryIconColor = "--primaryIconColor",
  secondaryIconColor = "--secondaryIconColor",
  shadowColor = "--shadowColor",
}
