import React from "react";
import styles from "./SideBarToggleButton.module.scss";
import {useIntl} from "react-intl";
import {Tooltip} from "@mui/material";

interface ThisProps {
  clicked: () => void;
  keyDown: (e: React.KeyboardEvent) => void;
  isClosed: boolean;
}

const SideBarToggleButton = (props: ThisProps) => {
  const intl = useIntl();

  let closed = "";
  if (props.isClosed) {
    closed = styles.closed;
  }
  return (
    <Tooltip
      title={props.isClosed ? intl.formatMessage({id: "general.expand"}) : intl.formatMessage({id: "general.hide"})}>
      <div onClick={props.clicked} className={[styles.toggleBtn, closed].join(" ")}
           onKeyDown={e => props.keyDown(e)}
           role="button" tabIndex={0} aria-label={intl.formatMessage({id: "general.toggleSidebar"})}
           aria-expanded="true">
        <span className={styles.bar1}></span>
        <span className={styles.bar2}></span>
        <span className={styles.bar3}></span>
      </div>
    </Tooltip>
  );
};
export default SideBarToggleButton;
