import {
  Address,
  OrganizationalEntity,
  searchOrganizationalEntitiesFilter,
  useOrganizationalEntitiesQuery
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {DEFAULT_PAGE_SIZE} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";
import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";


interface UseOrganizationalEntityPageForAddressApiProps {
  address?: Address;
}

export const useOrganizationalEntityPageForAddressApi = (props: UseOrganizationalEntityPageForAddressApiProps) => {
  const {filter: searchFilter, onSearch} = useListSearch(searchOrganizationalEntitiesFilter);
  const addressFilter = {
    operator: FilterOperator.EQ,
    name: "address.id",
    uuidValue: props.address?.id
  };
  const filter = searchFilter ? combineFilter(FilterOperator.AND, searchFilter, addressFilter)
    : addressFilter;
  const {data} = useOrganizationalEntitiesQuery({filter}, {skip: !props.address?.id});
  const [organizationalEntities, setOrganizationalEntities] = useState<OrganizationalEntity[]>([]);

  useEffect(() => {
    setOrganizationalEntities(
      (data?.organizationalEntities?.edges ?? [])
      .filter(edge => edge?.node)
      .map(edge => edge!.node)
    );
  }, [data?.organizationalEntities]);

  return {
    organizationalEntities,
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch
  };
};

