import {
  FilterExpression,
  GeneratedIdentityTypes,
  Identity,
  searchIdentitiesFilter,
  useGetIdentitiesOnResourceEntitlementCreationQuery
} from '@sivis/identity/api';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../../common/parsePageResult';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import { getExistingOrCombinedFilter, useListSearch } from '../../utils/filterUtils';

export const useIdentitiesForCreateEntitlementApi = (resourceId: string, extraFilter?: FilterExpression | undefined) => {
  const {filter, onSearch} = useListSearch(searchIdentitiesFilter);
  let identitiesFilter = getExistingOrCombinedFilter(filter, extraFilter);

  const useIdentitiesPageQuery = (cursor: string | null) =>
    useGetIdentitiesOnResourceEntitlementCreationQuery({
      resourceId,
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: identitiesFilter,
      extraKey: resourceId
    });

  const parseIdentitiesPageResult = (res?: GeneratedIdentityTypes.GetIdentitiesQuery): CursorBasedPageResult<Identity> => {
    return parseCursorBasedPageResult('identitiesCreatableResourceEntitlements', res);
  };

  return {
    onSearch,
    pageSize: DEFAULT_PAGE_SIZE,
    useIdentitiesPageQuery,
    parseIdentitiesPageResult
  };
};
