import { EOwnershipEntity, Identity, Ownership, Resource, ResourceSet, Role, System } from "@sivis/identity/api";
import { useCustomIntl } from "@sivis/intl";
import { TabBar } from "@sivis/shared/components/entityView";
import { CreateOwnershipIdentitiesList } from "./CreateOwnershipIdentitiesList";
import { CreateOwnershipResourceSetsList } from "./CreateOwnershipResourceSetsList";
import { CreateOwnershipResourcesList } from "./CreateOwnershipResourcesList";
import { CreateOwnershipRolesList } from "./CreateOwnershipRolesList";
import { CreateOwnershipSystemsList } from "./CreateOwnershipSystemsList";

export interface CreateOwnershipProps {
  selectedEntity: Identity | Role | Resource | ResourceSet | System;
  onAdd: (added: Ownership[]) => void;
  ownershipTargetType?: EOwnershipEntity;
}

export const CreateOwnership = (props: CreateOwnershipProps) => {
  const intl = useCustomIntl();

  const tabMappings = {
    [EOwnershipEntity.RESOURCE]: [{
      label: intl.format("fusion.resource.list.name"),
      content: <CreateOwnershipResourcesList {...props} />
    }],
    // currently resource Set is separate from resources (to show them in one view, we would need to fetch from two repositories)
    [EOwnershipEntity.RESOURCE_SET]: [{
      label: intl.format("fusion.resourceSet.list.name"),
      content: <CreateOwnershipResourceSetsList {...props} />
    }],
    [EOwnershipEntity.SYSTEM]: [{
      label: intl.format("fusion.system.list.name"),
      content: <CreateOwnershipSystemsList {...props} />
    }],
    [EOwnershipEntity.ROLE]: [{
      label: intl.format("fusion.role.list.name"),
      content: <CreateOwnershipRolesList {...props} />
    }],
    [EOwnershipEntity.IDENTITY]: [{
      label: intl.format("fusion.identity.list.name"),
      content: <CreateOwnershipIdentitiesList {...props} />
    }],
  };

  const tabs = props.ownershipTargetType ? tabMappings[props.ownershipTargetType] : [];
  return <TabBar tabs={tabs}/>;
};
