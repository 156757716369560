import {combineFilter, FilterExpression, FilterOperator} from "@sivis/shared/api-utils";

export const searchMembershipAccountsFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    { name: "account.name", operator: FilterOperator.CONTAINS, stringValue: searchStr },
  ]
});

export const getIdentityAndRoleFilter = (identityId: string, roleId: string) => combineFilter(
  FilterOperator.AND,
  {name: "account.identity.id", operator: FilterOperator.EQ, uuidValue: identityId},
  {name: "role.id", operator: FilterOperator.EQ, uuidValue: roleId}
);
