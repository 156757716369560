import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import styles from "./popup.module.scss";
import React from "react";
import {OrganizationalEntityType} from "@sivis/identity/api";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import {EntityCard} from "./relationInfoPanel";

interface RelationInfoPanelProps {
  upperCard: JSX.Element;
  lowerCard: JSX.Element;
  arrowText: string;
  arrowDown: boolean
}

export const VerticalRelationInfoPanel = (props: RelationInfoPanelProps) => {
  return <div className={styles.verticalInfoPanelContainer}>
    {props.upperCard}
    <VerticalRelationShipArrow text={props.arrowText} arrowDown={props.arrowDown}/>
    {props.lowerCard}
  </div>;
};

interface VerticalRelationShipArrowProps {
  text: string;
  arrowDown: boolean;
}

const VerticalRelationShipArrow = (props: VerticalRelationShipArrowProps) => {
  return (props.arrowDown ?
      (
        <div className={styles.verticalTextArrowContainer}>
          <ArrowDownward/>
          <span>{props.text}</span>
        </div>
      )
      :
      (
        <div className={styles.verticalTextArrowContainer}>
          <span>{props.text}</span>
          <ArrowUpward/>
        </div>
      )
  )

};

interface OrganizationalEntityTypeCardProps {
  entity: OrganizationalEntityType
}

export const OrganizationalEntityTypeCard = (props: OrganizationalEntityTypeCardProps) => {
  return <EntityCard
    icon={<BusinessOutlinedIcon/>}
    title={"Organizational Entity Type"}
    fields={[
      {name: props.entity.name, description: props.entity.description ?? ""}
    ]}
  />;
}
