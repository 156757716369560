import { useCallback, useState } from 'react';
import { FilterExpression } from '@sivis/identity/api';
import { combineFilter, FilterOperator } from '@sivis/shared/api-utils';

export const useListSearch = (buildFilter: (searchStr: string) => FilterExpression) => {
  const [filter, setFilter] = useState<FilterExpression>();

  const onSearch = useCallback((searchStr: string) => {
    if (!searchStr.length) {
      setFilter(undefined);
    } else {
      setFilter(buildFilter(searchStr));
    }
  }, [buildFilter]);

  return {
    onSearch,
    filter
  }
};

export const placeholderFilter = {operator: FilterOperator.EQ};

export const getExistingOrCombinedFilter = (
  filter?: FilterExpression,
  extraFilter?: FilterExpression
): FilterExpression | undefined => {
  if (filter && extraFilter) {
    return combineFilter(FilterOperator.AND, filter, extraFilter);
  } else if (filter && !extraFilter) {
    return filter;
  } else if (!filter && extraFilter) {
    return extraFilter;
  } else {
    return undefined;
  }
};
