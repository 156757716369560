import {EditButton, EntityView} from "@sivis/shared/components/entityView";
import {useResourceApi} from "./useResourceApi";
import {
  IDENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  ROLES_TAB_PATHNAME
} from "../components/routes/constants";
import {Resource, ResourceInput} from "@sivis/identity/api";
import {Outlet, useOutletContext} from "react-router-dom";
import ResourceInformationTab from "./resourceInformationTab";
import {useCustomIntl} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";

export const ResourceView = () => {
  const intl = useCustomIntl();
  const {resourceInput, resource, error} = useResourceApi();
  const {editMode, toggleEditMode} = useSingleEditMode(EntityType.RESOURCE);

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };
  const identitiesTab = {
    label: intl.format("fusion.identity.list.name"),
    path: IDENTITIES_TAB_PATHNAME
  };
  const rolesTab = {label: intl.format("fusion.role.list.name"), path: ROLES_TAB_PATHNAME};
  const ownershipTab = {
    label: intl.format("fusion.ownership.list.name"),
    path: OWNERSHIP_TAB_PATHNAME
  };

  const tabs = [informationTab, identitiesTab, rolesTab, ownershipTab];
  const title = `${intl.format("fusion.resource.information.title")}${resourceInput.name}`;
  const buttons = [
    <EditButton onClick={toggleEditMode} disabled={editMode} key="edit"/>
  ];

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.resource.error.notFound", intl.format) : undefined}>
    <Outlet context={{resourceInput, resource, editMode}}/>
  </EntityView>;
};

export type ContextType = {
  resourceInput: ResourceInput;
  resource: Resource;
  editMode: boolean;
};

export const ResourceInformationTabWrapperComponent = () => {
  const {resourceInput} = useOutletContext<ContextType>();
  return <ResourceInformationTab
    resourceInput={resourceInput}
  />;
};

