import { FilterOperator } from "@sivis/shared/api-utils";
import { defaults, omit } from "lodash";
import { FilterExpression, ResourceType, ResourceTypeInput } from "../identityApi.types";

export const emptyResourceTypeInput = (): ResourceTypeInput => ({
  name: '',
  description: '',
  systemType: '',
});

export const resourceTypeInputFactory = (resourceType?: ResourceType | ResourceTypeInput | null): ResourceTypeInput => {

  if (!resourceType) {
    return emptyResourceTypeInput();
  }

  return defaults({}, omit(resourceType, ['id']), emptyResourceTypeInput());
}

export const searchResourceTypesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "description", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "systemType.systemTypeName", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});

