import {
  executeAfterQueryFulfilled,
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from "@sivis/shared/api-utils";
import {
  api as generatedEntitlementApi,
  GetEntitlementsDocument,
  GetEntitlementsQuery,
  GetEntitlementsQueryVariables,
  GetEntitlementsResourceTypesDocument,
  GetEntitlementsResourceTypesQuery,
  GetEntitlementsResourceTypesQueryVariables,
  GetEntitlementsSystemsDocument,
  GetEntitlementsSystemsQuery,
  GetEntitlementsSystemsQueryVariables
} from "./entitlement.generated";
import {
  extendIdentityEntitlements,
  extendResourceEntitlements,
  extendResourceTypesFilter,
  extendRoleEntitlements,
  extendSystemsFilter,
  resetGetIdentityEntitlementsActionCreator,
  resetGetResourceEntitlementsActionCreator,
  resetGetRoleEntitlementsActionCreator,
  updateGetIdentityEntitlementsActionCreator,
  updateGetResourceEntitlementsActionCreator,
  updateGetRoleEntitlementsActionCreator
} from "./entitlementUtils";

export const injectedEntitlementApi = generatedEntitlementApi.injectEndpoints({
  endpoints: (build) => ({
    GetResourceEntitlements: build.query<GetEntitlementsQuery, GetEntitlementsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetEntitlementsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("entitlements"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      onQueryStarted: executeAfterQueryFulfilled([extendResourceEntitlements])
    }),
    GetIdentityEntitlements: build.query<GetEntitlementsQuery, GetEntitlementsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetEntitlementsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("entitlements"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      onQueryStarted: executeAfterQueryFulfilled([extendIdentityEntitlements])
    }),
    GetRoleEntitlements: build.query<GetEntitlementsQuery, GetEntitlementsQueryVariables & {
      cacheKey: string
    } | void>({
      query: (variables) => ({document: GetEntitlementsDocument, variables}),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("entitlements"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      onQueryStarted: executeAfterQueryFulfilled([extendRoleEntitlements])
    }),
    GetEntitlementsResourceTypesEnhanced: build.query<GetEntitlementsResourceTypesQuery, GetEntitlementsResourceTypesQueryVariables>({
      query: (variables) => ({
        document: GetEntitlementsResourceTypesDocument,
        variables
      }),
      onQueryStarted: executeAfterQueryFulfilled([extendResourceTypesFilter]),
      providesTags: ["entitlement"]
    }),
    GetEntitlementsSystemsEnhanced: build.query<GetEntitlementsSystemsQuery, GetEntitlementsSystemsQueryVariables>({
      query: (variables) => ({
        document: GetEntitlementsSystemsDocument,
        variables
      }),
      onQueryStarted: executeAfterQueryFulfilled([extendSystemsFilter]),
      providesTags: ["entitlement"]
    })
  })
});

export const entitlementApi = injectedEntitlementApi.enhanceEndpoints({
  endpoints: {
    GetEntitlements: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("entitlements"),
      forceRefetch: forceRefetchPaging,
      providesTags: ["entitlement"]
    },
    GetEntitlement: { providesTags: ["entitlement"] },
    GetEntitlementsValidity: {providesTags: ["entitlement"]},
    CreateEntitlement: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetResourceEntitlementsActionCreator,
        resetGetIdentityEntitlementsActionCreator,
        resetGetRoleEntitlementsActionCreator
      ]),
      invalidatesTags: ["entitlement"]
    },
    UpdateEntitlement: {
      onQueryStarted: executeAfterQueryFulfilled([
        updateGetResourceEntitlementsActionCreator,
        updateGetIdentityEntitlementsActionCreator,
        updateGetRoleEntitlementsActionCreator
      ]),
      invalidatesTags: ["entitlement"]
    },
    DeleteEntitlement: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetResourceEntitlementsActionCreator,
        resetGetIdentityEntitlementsActionCreator,
        resetGetRoleEntitlementsActionCreator
      ]),
      invalidatesTags: ["entitlement"]
    }
  },
});

export const {
  useGetEntitlementsQuery,
  useGetResourceEntitlementsQuery,
  useGetIdentityEntitlementsQuery,
  useGetRoleEntitlementsQuery,
  useLazyGetEntitlementsQuery,
  useGetEntitlementQuery,
  useLazyGetEntitlementQuery,
  useCreateEntitlementMutation,
  useUpdateEntitlementMutation,
  useDeleteEntitlementMutation,
  useGetEntitlementsResourceTypesEnhancedQuery,
  useGetEntitlementsSystemsEnhancedQuery,
  useGetEntitlementsValidityQuery
} = entitlementApi;
