import {PropsWithChildren} from "react";
import {List, ListItem, ListItemButton} from "@mui/material";
import styles from "./table.module.scss";

interface SideFilterProps {
  title: string;
}

export const SideFilter = (props: PropsWithChildren<SideFilterProps>) => {
  return <div className={styles.filterContainer}>
    <div className={styles.filterTitle}>{props.title}</div>
    <List>
      {props.children}
    </List>
  </div>;
};

interface FilterGroupProps {
  active: boolean;
  text: string;
  amountEntries: number;
  onClick: () => void;
}

const FILTER_TEXT_COLOR_ACTIVE = "#3171E6";
const FILTER_TEXT_COLOR = "#656b6d";
const FILTER_BG_COLOR = "#F0F5FF";

export const FilterGroup = (props: FilterGroupProps) => {
  const onClick = () => {
    if (!props.active) {
      props.onClick();
    }
  };

  return <ListItem disablePadding>
    <ListItemButton onClick={onClick} sx={{
      backgroundColor: props.active ? FILTER_BG_COLOR : undefined,
      color: props.active ? FILTER_TEXT_COLOR_ACTIVE : FILTER_TEXT_COLOR
    }}>
      <div className={styles.filterText}>
        <div>{props.text}</div>
        <div>{props.amountEntries}</div>
      </div>
    </ListItemButton>
  </ListItem>;
};
