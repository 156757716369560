import {RouteObject} from "react-router-dom";
import {PermissionList} from "./permissionList";
import {PermissionView} from "./permissionView";
import {DynamicEntityViewBreadcrumb} from "../components/breadcrumb/breadcrumb";
import {useGetPermissionQuery} from "@sivis/security/api";

const useGetPermissionName = (id: string, isNew: boolean) => {
  const {data} = useGetPermissionQuery({id}, {skip: isNew});
  return data?.permission?.name;
};

export const permissionRoutes: RouteObject[] = [
  {
    index: true,
    element: <PermissionList/>
  },
  {
    path: ":id",
    element: <PermissionView/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({useGetEntityName: useGetPermissionName})
    }
  }
];
