export interface HasMessage {
  message: string;
}

export const isHasMessage = (source: unknown): source is HasMessage => {
  const hasMessage: HasMessage = (source as HasMessage);
  return hasMessage?.message !== null && hasMessage?.message !== undefined;
}

export const getMessageOrSelf = (source: unknown): string | unknown => {
  if (isHasMessage(source)) {
    return source.message;
  }
  return source;
}
