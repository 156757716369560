import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {BACKEND_SLICE_NAME, BackendState} from './BackendState';
import {backendCheckHealth} from './backendActions';
import {HealthCheckRequestType, HealthCheckResult} from './HealthCheck';
import {HealthState} from './HealthState';


const initialState: BackendState = {
  defaultSystem: '',
  systems: {},
  errors: {}
};

const resolveSystemId = (state: BackendState, request?: HealthCheckRequestType): string => {
  if (!request) {
    return state.defaultSystem
  }

  if (typeof request === 'string') {
    return request
  }

  return request.id;
};


const backendSlice = createSlice({
    name: BACKEND_SLICE_NAME,
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(
        backendCheckHealth.pending,
        (state: Draft<BackendState>, action: PayloadAction<HealthCheckRequestType | undefined>) => {
          const id = resolveSystemId(state, action.payload);
          state.systems[id].healthState = HealthState.checking;
          state.errors[id] = null;
        })
      .addCase(
        backendCheckHealth.fulfilled, (
          state: Draft<BackendState>, action: PayloadAction<HealthCheckResult>) => {
          const {id, healthState, error} = action.payload;
          state.systems[id].healthState = healthState;
          state.errors[id] = error;

        })
      .addCase(
        backendCheckHealth.rejected, (
          state: Draft<BackendState>, action: PayloadAction<any>) => {
          const {id, error} = action.payload;
          state.systems[id].healthState = HealthState.down;
          state.errors[id] = error;

        })
    }
  }
);

export const backendReducer = backendSlice.reducer;

export default {[BACKEND_SLICE_NAME]: backendReducer};
