import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import {
  api as generatedIdentityApi,
  GetIdentitiesCreatableResourceEntitlementsDocument,
  GetIdentitiesCreatableResourceEntitlementsQuery,
  GetIdentitiesCreatableResourceEntitlementsQueryVariables
} from './identity.generated';

export const injectedIdentityApi = generatedIdentityApi.injectEndpoints({
  endpoints: (build) => ({
    GetIdentitiesOnResourceEntitlementCreation: build.query<GetIdentitiesCreatableResourceEntitlementsQuery, GetIdentitiesCreatableResourceEntitlementsQueryVariables & {
      extraKey?: string
    } | void>({
      query: (variables) => ({
        document: GetIdentitiesCreatableResourceEntitlementsDocument,
        variables
      }),
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("identitiesCreatableResourceEntitlements"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs
    })
  })
});

export const identityApi = injectedIdentityApi.enhanceEndpoints({
  endpoints: {
    GetIdentities: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("identities"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ["identity"]
    },
    GetIdentitiesById: { providesTags: ["identity"] },
    GetIdentityCreationDates: { providesTags: ["identity"] },
    GetIdentityUpdateDates: { providesTags: ["identity"] },
    CreateIdentity: { invalidatesTags: ["identity"] },
    UpdateIdentity: { invalidatesTags: ["identity"] },
    DeleteIdentity: { invalidatesTags: ["identity"] },

    GetIdentityTypes: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("identityTypes"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ['identityType']
    },
    GetIdentityType: { providesTags: ["identityType"] },
    CreateIdentityType: { invalidatesTags: ["identityType"] },
    UpdateIdentityType: { invalidatesTags: ["identityType"] },
    DeleteIdentityType: { invalidatesTags: ["identityType"] },
  }
});

export const {
  useGetIdentityQuery,
  useLazyGetIdentityQuery,
  useGetIdentitiesQuery,
  useLazyGetIdentitiesQuery,
  useGetIdentitiesByIdQuery,
  useLazyGetIdentitiesByIdQuery,
  useGetIdentityFieldDefinitionsQuery,
  useLazyGetIdentityFieldDefinitionsQuery,
  useGetIdentityCreationDatesQuery,
  useGetIdentityUpdateDatesQuery,
  useGetIdentityTypeQuery,
  useLazyGetIdentityTypeQuery,
  useGetIdentityTypesQuery,
  useLazyGetIdentityTypesQuery,
  useCreateIdentityMutation,
  useUpdateIdentityMutation,
  useDeleteIdentityMutation,
  useCreateFieldDefinitionMutation,
  useUpdateIdentityFieldDefinitionsMutation,
  useUpdateFieldDefinitionMutation,
  useCreateIdentityTypeMutation,
  useUpdateIdentityTypeMutation,
  useDeleteIdentityTypeMutation,
  useGetIdentitiesOnResourceEntitlementCreationQuery, useSearchIdentitiesQuery
} = identityApi;
