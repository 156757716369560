import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import { api as generatedOrganizationalEntityApi } from './organizationalEntity.generated';

export const organizationalEntityApi = generatedOrganizationalEntityApi.enhanceEndpoints({
  addTagTypes: ['organizationalEntity', 'organizationalEntityType'],
  endpoints: {
    OrganizationalEntities: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("organizationalEntities"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ['organizationalEntity']
    },
    CreateOrganizationalEntity: { invalidatesTags: ["organizationalEntity"] },
    UpdateOrganizationalEntity: { invalidatesTags: ["organizationalEntity"] },
    DeleteOrganizationalEntity: { invalidatesTags: ["organizationalEntity"] },

    GetOrganizationalEntityTypes: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("organizationalEntityTypes"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ['organizationalEntityType']
    },
    CreateOrganizationalEntityType: { invalidatesTags: ["organizationalEntityType"] },
    UpdateOrganizationalEntityType: { invalidatesTags: ["organizationalEntityType"] },
    DeleteOrganizationalEntityType: { invalidatesTags: ["organizationalEntityType"] },
  },
})

export const {
  useGetOrganizationalEntityTypeQuery,
  useLazyGetOrganizationalEntityTypeQuery,
  useGetOrganizationalEntityTypesQuery,
  useLazyGetOrganizationalEntityTypesQuery,
  useCreateOrganizationalEntityTypeMutation,
  useUpdateOrganizationalEntityTypeMutation,
  useDeleteOrganizationalEntityTypeMutation,
  useOrganizationalEntityQuery,
  useLazyOrganizationalEntityQuery,
  useOrganizationalEntitiesQuery,
  useLazyOrganizationalEntitiesQuery,
  useCreateOrganizationalEntityMutation,
  useUpdateOrganizationalEntityMutation,
  useDeleteOrganizationalEntityMutation
} = organizationalEntityApi;
