import {
  EntitlementType,
  ExtendedResourceTypeFilterEntry,
  ExtendedSystemFilterEntry,
  FilterEntry,
  RelationQueryType,
  useGetEntitlementsResourceTypesEnhancedQuery,
  useGetEntitlementsSystemsEnhancedQuery,
  useGetEntitlementsValidityQuery
} from '@sivis/identity/api';
import React, { useEffect, useState } from 'react';
import { FilterExpression, FilterOperator } from '@sivis/shared/api-utils';
import { resetTable, SideFilterNew } from '@sivis/shared/components/entityView';
import { useCustomIntl } from '@sivis/intl';
import { placeholderFilter } from '../../utils/filterUtils';
import { useDispatch } from 'react-redux';

const buildResourceTypeFilterGroup = (filters: ExtendedResourceTypeFilterEntry[], title: string) => ({
  id: "resourceType",
  title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: entry.resourceType?.name ?? "",
    count: entry.count,
    filter: {
      name: "resourceTypeId",
      operator: FilterOperator.EQ,
      uuidValue: entry.id
    }
  }))
});

const buildValidityFilterGroup = (filters: FilterEntry[], title: string, translate: (id: string) => string) => ({
  id: "validity",
  title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: translate("fusion.general." + entry.id),
    count: entry.count,
    filter: entry.filter ?? placeholderFilter
  }))
});

const buildSystemFilterGroup = (filters: ExtendedSystemFilterEntry[], title: string) => ({
  id: "system",
  title: title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: entry.system?.systemName ?? "",
    count: entry.count,
    filter: {
      name: "systemId",
      operator: FilterOperator.EQ,
      uuidValue: entry.id
    }
  }))
});

const compareFilterEntries = (a: FilterEntry, b: FilterEntry) => b.count - a.count;

export const useResourceEntitlementFilter = (type: EntitlementType, queryType: RelationQueryType, entitledId?: string) => {
  const [filter, setFilter] = useState<FilterExpression | null>(null);
  const [resourceTypeFilters, setResourceTypeFilters] = useState<ExtendedResourceTypeFilterEntry[]>([]);
  const [systemFilters, setSystemFilters] = useState<ExtendedSystemFilterEntry[]>([]);
  const [validityFilters, setValidityFilters] = useState<FilterEntry[]>([]);
  const intl = useCustomIntl();

  const {data: dataResourceTypes} = useGetEntitlementsResourceTypesEnhancedQuery({
    entitledId: entitledId ?? "",
    type
  }, {skip: !entitledId});

  const {data: dataSystems} = useGetEntitlementsSystemsEnhancedQuery({
    entitledId: entitledId ?? "",
    type
  }, {skip: !entitledId});

  const {data: dataValidity} = useGetEntitlementsValidityQuery({
    entitledId: entitledId ?? "",
    type
  }, {skip: !entitledId});

  useEffect(() => {
    const newResourceTypesFilter = (dataResourceTypes?.entitlementsResourceTypes
    ?.filter(entry => entry !== null) ?? []) as ExtendedResourceTypeFilterEntry[];
    newResourceTypesFilter.sort(compareFilterEntries);
    setResourceTypeFilters(newResourceTypesFilter);

    const newSystemsFilter = (dataSystems?.entitlementsSystems
    ?.filter(entry => entry !== null) ?? []) as ExtendedSystemFilterEntry[];
    newSystemsFilter.sort(compareFilterEntries);
    setSystemFilters(newSystemsFilter);

    const newValidityFilter = (dataValidity?.entitlementsValidity
    ?.filter(entry => entry !== null) ?? []) as FilterEntry[];
    setValidityFilters(newValidityFilter);
  }, [dataResourceTypes?.entitlementsResourceTypes, dataSystems?.entitlementsSystems, dataValidity?.entitlementsValidity]);


  const dispatch = useDispatch();
  const onSetFilter = (newFilter: FilterExpression | null) => {
    dispatch(resetTable(queryType)());
    setFilter(newFilter);
  };

  const resourcesFilter = <SideFilterNew
    title={(intl.format("fusion.general.filter")).toUpperCase()}
    onFilterChange={onSetFilter}
    filterGroups={[
      buildResourceTypeFilterGroup(
        resourceTypeFilters,
        intl.format("fusion.resource.propertyName.resourceType")
      ),
      buildValidityFilterGroup(
        validityFilters,
        intl.format("fusion.entitlement.filter.expiration"),
        intl.format
      ),
      buildSystemFilterGroup(
        systemFilters,
        intl.format("fusion.resource.propertyName.systemName")
      )
    ]}
  />;

  return {
    activeFilter: filter,
    filterComponent: resourcesFilter
  };
};
