import { UseFormRegister, FieldErrors } from "react-hook-form";
import { Box, MenuItem, TextField, Typography, Divider } from "@mui/material";
import { useCustomIntl } from "@sivis/intl";
import { IFormInput } from "./attributeSyncMappingForm";
import { AsDirections, AsMapping, AsMethods } from "@sivis/attributesync/api";
import { useState } from "react";
import styles from "./attributeSyncMappingForm.module.scss";

export interface AttributeSyncMappingConfigurationProps {
    register: UseFormRegister<IFormInput>;
    errors: FieldErrors<IFormInput>;
    editedMapping: AsMapping | null;
};

export const AttributeSyncMappingConfiguration = (props: AttributeSyncMappingConfigurationProps) => {
    const intl = useCustomIntl();

    const [selectedDirection, setSelectedDirection] = useState<string>(props.editedMapping?.direction ?? "");
    const [selectedMethod, setSelectedMethod] = useState<string>(props.editedMapping?.synchronizationMethod ?? "");
    const [selectedPriority, setSelectedPriority] = useState<number>(props.editedMapping?.priority ?? 0);

    const handleChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDirection(event.target.value);
    };
    const handleChangeMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedMethod(event.target.value);
    };
    const handleChangePriority = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPriority(parseInt(event.target.value));
    };

    // Utility functions to transform the keys of the AsMethods and AsDirections enums to 
    // upper snake case and return them as an array of objects for the select components
    const toUpperSnakeCase = (str: string) => {
        return str
            .replace(/([a-z])([A-Z])/g, '$1_$2')
            .toUpperCase();
    };

    const asMethods = () => {
        let tempArray = [];
        for (let method in AsMethods) {
            let transformedKey = toUpperSnakeCase(method);
            tempArray.push({ value: transformedKey, text: method });
        }
        return tempArray;
    };

    const asDirections = () => {
        let tempArray = [];
        for (let direction in AsDirections) {
            let transformedKey = toUpperSnakeCase(direction);
            tempArray.push({ value: transformedKey, text: direction });
        }
        return tempArray;
    };

    return (
        <Box component="form" noValidate autoComplete="off">
            <Divider />
            <Typography variant="h6" gutterBottom color={'black'} className={styles.header}>
                {intl.format("fusion.attributesynchronizationmapping.popup.configure")}
            </Typography>
            <Box className={styles.directionBox}>
            <TextField
                select
                required
                label={intl.format("fusion.attributesynchronizationmapping.popup.direction")}
                {...props.register("direction", { required: intl.format("fusion.attributesynchronizationmapping.popup.directionError") })}
                className={styles.textField}
                margin="normal"
                error={!!props.errors.direction}
                helperText={props.errors.direction?.message}
                value={selectedDirection}
                onChange={handleChangeDirection}
            >
                {asDirections().map(option => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)}
            </TextField>
            </Box>
            <Box className={styles.directionBox}>
            <TextField
                select
                required
                label={intl.format("fusion.attributesynchronizationmapping.popup.method")}
                {...props.register("synchronizationMethod", { required: intl.format("fusion.attributesynchronizationmapping.popup.methodError") })}
                className={styles.textField}
                margin="normal"
                error={!!props.errors.synchronizationMethod}
                helperText={props.errors.synchronizationMethod?.message}
                value={selectedMethod}
                onChange={handleChangeMethod}
            >
                {asMethods().map(option => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)}
            </TextField>
            </Box>
            <Box className={styles.directionBox}>
            <TextField
                type="number"
                label={intl.format("fusion.attributesynchronizationmapping.popup.priority")}
                {...props.register("priority")}
                className={styles.textField}
                margin="normal"
                error={!!props.errors.priority}
                helperText={props.errors.priority?.message}
                value={selectedPriority}
                onChange={handleChangePriority}
            />
            </Box>
            <Divider className={styles.dividerMargin}/>
        </Box>
    );
};