import {RelationInformationTab} from "../components/popup/relationInformationTab";
import {EntityDetailsProps} from "@sivis/shared/components/entityView";
import {IdentityRoleMembership} from "@sivis/identity/api";
import {useIdentityRoleMembershipApi} from "./api/useIdentityRoleMembershipApi";
import {getTemplateText, isMembership, isValidityShortened} from "./membershipUtils";
import {RelationEntityType} from "../components/popup/relationUtils";
import {useCustomIntl} from "@sivis/intl";
import {InformationCard} from "../components/popup/relationInfoPanel";
import styles from "./membership.module.scss";
import {getValidityFieldConfigs} from "../utils/validityUtils";

export const MembershipInformationTab = (props: EntityDetailsProps<IdentityRoleMembership>) => {
  const intl = useCustomIntl();
  const configs = [getValidityFieldConfigs(intl.format)];

  const membership = props.entity;
  const {onSave: onSaveIdentityRoleMembership} = useIdentityRoleMembershipApi(membership.id);

  const onSave = (value: IdentityRoleMembership) => {
    // TODO: require confirmation if validity is shortened
    const validityShortened = isValidityShortened(membership, value);
    return onSaveIdentityRoleMembership(value);
  };

  const relationText = intl.format("fusion.membership.ownership.member");
  const templateText = getTemplateText(membership.template);

  return <div className={styles.tabContainer}>
    <div className={styles.informationCardContainer}>
      <InformationCard text={templateText}/>
    </div>
    <RelationInformationTab
        {...props}
        configs={configs}
        onSave={onSave}
        leftEntityType={RelationEntityType.IDENTITY}
        leftEntity={membership.identity}
        rightEntityType={RelationEntityType.ROLE}
        rightEntity={membership.role}
        relationText={relationText}
        entity={membership}
        checkValueType={isMembership}
    />
  </div>;
};
