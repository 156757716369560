import {
    AsMapping,
    useGetAttributeSyncMappingsQuery,
    GeneratedAttributeSyncMappingTypes,
    searchAsdFilter
} from "@sivis/attributesync/api";
import { CursorBasedPageResult } from "@sivis/shared/components/entityView";
import {
    DEFAULT_PAGE_SIZE,
    parseCursorBasedPageResult
} from "../../common/parsePageResult";
import { useListSearch } from "../../utils/filterUtils";

export const useAttributeSyncMappingPageApi = () => {
    const { onSearch } = useListSearch(searchAsdFilter);

    const useAttributeSyncMappingsPageQuery = (cursor: string | null) =>
        useGetAttributeSyncMappingsQuery({ first: DEFAULT_PAGE_SIZE, after: cursor});

    const parseAttributeSyncMappingsPageResult = (res?: GeneratedAttributeSyncMappingTypes.GetAttributeSyncMappingsQuery): CursorBasedPageResult<AsMapping> => {
        return parseCursorBasedPageResult('asMappings', res);
    };

    return {
        pageSize: DEFAULT_PAGE_SIZE,
        onSearch,
        useAttributeSyncMappingsPageQuery,
        parseAttributeSyncMappingsPageResult
    };
};