import {
    EntitlementType,
    RelationQueryType,
    Resource,
    useGetRoleEntitlementsQuery
} from '@sivis/identity/api';
import { combineFilter, FilterOperator } from '@sivis/shared/api-utils';
import { DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE } from '../../common/parsePageResult';
import { useEntitlementsApi } from './useEntitlementsApi';

export const useResourceRoleEntitlementsApi = (resource?: Resource) => {
  const resourceId = resource?.id;
  // TODO: search entitlements
  const resourceEntitlementsFilter = combineFilter(FilterOperator.AND, {
    name: "type",
    operator: FilterOperator.EQ,
    stringValue: EntitlementType.ROLE_RESOURCE_ENTITLEMENT
  }, {
    name: "targetId",
    operator: FilterOperator.EQ,
    uuidValue: resourceId
  });

  const useEntitlementsPageQuery = (cursor: string | null) =>
      useGetRoleEntitlementsQuery(
          {
            first: DEFAULT_PAGE_SIZE,
            after: cursor,
            sort: DEFAULT_ENTITY_SORT,
            filter: resourceEntitlementsFilter,
            cacheKey: EntitlementType.ROLE_RESOURCE_ENTITLEMENT
          },
          {skip: !resourceId}
      );

  const {onSave, pageSize, parseEntitlementsPageResult, submitted, setSubmitted} = useEntitlementsApi(
      !resourceId,
      EntitlementType.ROLE_RESOURCE_ENTITLEMENT,
    RelationQueryType.ROLE_RESOURCE_ENTITLEMENTS,
      resource
  );

  return {
    onSave,
    pageSize,
    useEntitlementsPageQuery,
    parseEntitlementsPageResult,
    submitted,
    setSubmitted
  };
};
