import {EntityInformation} from "@sivis/shared/components/entityView";
import styles from "./address.module.scss";
import {AddressInput} from "@sivis/identity/api";
import {useTranslateConfigs} from "@sivis/intl";

export interface AddressInformationTabProps {
  isNew: boolean,
  onSave: (AddressInput: AddressInput) => void,
  onDelete: (() => void) | undefined,
  onCancel: () => void,
  editMode: boolean,
  defaultInput: AddressInput
}

export const AddressInformationTab = (props: AddressInformationTabProps) => {
  const configs = [
    {
      property: 'name',
      required: true
    },
    {
      property: 'description',
      name: 'Description',
    },
    {
      property: 'address1',
    }, {
      property: 'address2',
    }, {
      property: 'address3',
    }, {
      property: 'zipcode',
    }, {
      property: 'city',
    }, {
      property: 'region',
    }, {
      property: 'state',
    }, {
      property: 'country',
    }, {
      property: 'defaultTimeZone',
      // TODO: make Default Time Zone an Enum / use Select
    }, {
      property: 'defaultLanguage',
      // TODO: make Default Language an Enum / use Select
    }, {
      property: 'phone',
    }, {
      property: 'phoneExtension',
    }, {
      property: 'mobilePhone',
    }, {
      property: 'fax',
    }, {
      property: 'faxExtension',
    }, {
      property: 'email',
    }, {
      property: 'standardCommunicationMethod',
    }
  ];

  const translatedConfigs = useTranslateConfigs(configs, "fusion.address.propertyName");

  const entityInformation = <EntityInformation
    editMode={props.editMode}
    configs={translatedConfigs}
    value={props.defaultInput}
    onSubmit={props.onSave}
    onCancel={props.onCancel}
  />;

  // TODO: group address items
  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
};
