export type Either<L, R> = Left<L> | Right<R>;
export type Left<L> = {
  readonly isLeft: true,
  value: L,
};
export type Right<R> = {
  readonly isLeft: false,
  value: R,
}
const left = <L, R>(value: L): Either<L, R> => ({
  isLeft: true,
  value
});
const right = <L, R>(value: R): Either<L, R> => ({
  isLeft: false,
  value
});
export const either = {
  left,
  right
};
