import { Entitlement, Identity, Resource, useGetIdentityQuery, useGetResourceQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";
import { useEntitlementApi } from "./useEntitlementApi";

export const useIdentityResourceEntitlementApi = ({id, entitledId, targetId}: Entitlement) => {
  const {data: dataRecipient} = useGetIdentityQuery({id: entitledId}, {refetchOnMountOrArgChange: true});
  const {data: dataResource} = useGetResourceQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [entitled, setEntitled] = useState<Identity>();
  const [target, setTarget] = useState<Resource>();

  useEffect(() => {
    setEntitled(dataRecipient?.identity ?? undefined);
    setTarget(dataResource?.resource ?? undefined);
  }, [dataRecipient?.identity, dataResource?.resource]);

  const {entitlement, onSave} = useEntitlementApi(id);

  return {
    entitlement,
    entitled,
    target,
    onSave
  }
};
