import { FilterExpression } from '@sivis/identity/api';
import { FilterOperator } from '@sivis/shared/api-utils';

export const searchBusinessRulesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    { name: 'name', operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: 'targetValue', operator: FilterOperator.CONTAINS, stringValue: searchStr }
  ]
});
