import {
  emptyResourceInput,
  Resource,
  ResourceInput,
  resourceInputFactory,
  useGetResourceQuery
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {newId} from "../common/newId";

export const useResourceApi = () => {

  const {id = newId} = useParams<{ id: string }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useGetResourceQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [resourceInput, setResourceInput] = useState<ResourceInput>(emptyResourceInput());
  const [resource, setResource] = useState<Resource>();

  useEffect(() => {
    const resourceInput = resourceInputFactory(data?.resource);
    setResourceInput(resourceInput);
    setResource(data?.resource ?? undefined);
  }, [data?.resource]);

  const goBack = () => navigate("/resource");

  return {
    id,
    resourceInput,
    resource,
    error,
    isNew,
    onCancel: goBack,
    isLoading,
    refetch
  };
};

