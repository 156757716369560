import {IconButton} from "@mui/material";
import {FunctionComponent, PropsWithChildren} from "react";
import styles from "./sidebar.module.scss";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {HasIntlId, selectMessageTextById} from "@sivis/intl";
import {useSelector} from "react-redux";

interface SidebarCategoryProps {
  name: string;
  open: boolean;
  toggleOpen: () => void;
}

export const SidebarCategory = ({
                                  name,
                                  open,
                                  toggleOpen,
                                  children
                                }: PropsWithChildren<SidebarCategoryProps>) => {
  const childrenStyleClass = open ? "" : styles.hide;
  return <>
    <div className={styles.category} onClick={toggleOpen}>
      {name}
      <IconButton aria-label="toggle" size="small">
        {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
      </IconButton>
    </div>
    <div className={childrenStyleClass}>{children}</div>
  </>;
};

export const IntlSidebarCategory: FunctionComponent<HasIntlId & Omit<SidebarCategoryProps, "name">> = ({
                                                                                                         intlId,
                                                                                                         ...props
                                                                                                       }) => {
  const name = useSelector(state => selectMessageTextById(state, intlId));
  return <SidebarCategory {...props} name={name}/>;
};
