import {Identity, Ownership, Role, useGetIdentityQuery, useGetRoleQuery} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {useOwnershipApi} from "./useOwnershipApi";

export const useIdentityRoleOwnershipApi = ({id, ownerId, targetId}: Ownership) => {
  const {data: dataOwner} = useGetIdentityQuery({id: ownerId}, {refetchOnMountOrArgChange: true});
  const {data: dataTarget} = useGetRoleQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [owner, setOwner] = useState<Identity>();
  const [target, setTarget] = useState<Role>();

  useEffect(() => {
    setOwner(dataOwner?.identity ?? undefined);
    setTarget(dataTarget?.role ?? undefined);
  }, [dataOwner?.identity, dataTarget?.role]);

  const {ownership, onSave} = useOwnershipApi(id);

  return {ownership, owner, target, onSave}
}
