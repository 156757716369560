import {JSONToText} from '../utils';
import {IText} from '../types';
import {ELocales} from '../ELocales';
import {general_messages_de, general_messages_en} from "@sivis/translation";

type MessageIdEnType = keyof typeof general_messages_en;
type MessageIdDeType = keyof typeof general_messages_de;
/**
 * Keys that are exclusively defined in general_en.json, but not in general_de.json.
 * If general_en.json does not have extra keys, then it resolves to "".
 */
type KeysOnlyDefinedInEnglishJSON = Exclude<MessageIdEnType, MessageIdDeType> extends never ? "" : Exclude<MessageIdEnType, MessageIdDeType>;
/**
 * Keys that are exclusively defined in general_de.json, but not in general_en.json.
 * If general_de.json does not have extra keys, then it resolves to "".
 */
type KeysOnlyDefinedInGermanJSON = Exclude<MessageIdDeType, MessageIdEnType> extends never ? "" : Exclude<MessageIdDeType, MessageIdEnType>;

export type GeneralMessageId = MessageIdEnType | MessageIdDeType;

export const initialGeneralMessages = (): IText[] => {
  return [...JSONToText(general_messages_de, ELocales.DE), ...JSONToText(general_messages_en, ELocales.EN)];
}
