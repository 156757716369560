import {IdentityRoleMembership} from "@sivis/identity/api";
import {EntityDetailsProps, TabBar} from "@sivis/shared/components/entityView";
import {MembershipInformationTab} from "./membershipInformationTab";
import {useCustomIntl} from "@sivis/intl";
import {MembershipAccountTab} from "./membershipAccountTab";

export const IdentityRoleMembershipDetails = (props: EntityDetailsProps<IdentityRoleMembership>) => {
  const intl = useCustomIntl();
  const tabs = [
    {
      label: intl.format("fusion.breadcrumb.information"),
      content: <MembershipInformationTab {...props} />
    },
    {
      label: intl.format("fusion.account.list.name"),
      content: <MembershipAccountTab {...props} />
    }
  ]

  return <TabBar tabs={tabs}/>;
}
