import {
  createAsyncThunk,
  createSelector,
  createSlice,
  Draft,
  PayloadAction
} from '@reduxjs/toolkit';
import { adminLogin } from '../components/login/sivisWebApi';
import {
  decodeToken,
  deleteAllCookies,
  getTokenFromCookie,
  setCookieFromToken
} from '@sivis/shared/utils';
import { EAuthProvider } from '@sivis/shared/types';
import { setReady } from '@sivis/app-shell';
import { destroySession } from '@sivis/redux';

export const ACCESS_TOKEN = 'SivisJwtToken';

interface AuthState {
  authenticated: boolean;
  loading: boolean;
  error: string;
}

export interface AuthSliceState {
  auth: AuthState;
}

export const getInitialAuthState = (): AuthState => ({
  authenticated: false,
  loading: false,
  error: ''
});

const initialState = getInitialAuthState();

export const AUTH_SLICE_NAME = 'auth';

export const authenticateAdmin = createAsyncThunk(
  `${AUTH_SLICE_NAME}/authenticateAdmin`,
  async (params: { username: string; password: string; }, { rejectWithValue }) => {
    try {
      const res = await adminLogin(params.username, params.password);
      setCookieFromToken(res.token, ACCESS_TOKEN);
      return;
    } catch (error: any) {
      return rejectWithValue(error?.message ?? 'Login failed.');
    }
  }
);

interface SivisWebTokenObj {
  sub: string;
  authenticationProvider: EAuthProvider;
}

const isSivisWebTokenObj = (obj: SivisWebTokenObj): obj is SivisWebTokenObj => {
  return obj?.sub !== undefined && obj?.authenticationProvider !== undefined;
};

const authenticateFromCookie = (): boolean => {
  const token = getTokenFromCookie(ACCESS_TOKEN);
  if (!token?.length) {
    return false;
  }

  const tokenObj: any = decodeToken(token);
  return isSivisWebTokenObj(tokenObj) && tokenObj.authenticationProvider === EAuthProvider.ADMIN;
};

export const checkAuthState = createAsyncThunk<void, void, {
  state: AuthSliceState
}>(
  `${AUTH_SLICE_NAME}/checkAuthState`,
  async (_, { dispatch, getState }) => {
    const shouldAuthenticate = authenticateFromCookie();
    if (shouldAuthenticate) {
      dispatch(authSuccess());
    } else {
      dispatch(authFail());
    }
    dispatch(setReady());
  }
);

export const logout = createAsyncThunk<void, void, {
  state: AuthSliceState
}>(
  `${AUTH_SLICE_NAME}/logout`,
  async (_, { dispatch }) => {
    deleteAllCookies();
    dispatch(destroySession());
    dispatch(setReady());
  }
);

const setStateOnAuthSuccess = (state: Draft<AuthState>) => {
  state.loading = false;
  state.authenticated = true;
  state.error = '';
};

const setStateOnAuthFail = (state: Draft<AuthState>, error?: string) => {
  state.loading = false;
  state.authenticated = false;
  state.error = error ?? '';
};

const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    authSuccess: (state: Draft<AuthState>) => {
      setStateOnAuthSuccess(state);
    },
    authFail: (state: Draft<AuthState>, action: PayloadAction<string | undefined>) => {
      setStateOnAuthFail(state, action.payload ?? '');
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authenticateAdmin.pending, (state: Draft<AuthState>) => {
      state.loading = true;
    })
    .addCase(authenticateAdmin.fulfilled, (state: Draft<AuthState>) => {
      setStateOnAuthSuccess(state);
    })
    .addCase(authenticateAdmin.rejected, (state: Draft<AuthState>, action) => {
      setStateOnAuthFail(state, action.payload as string);
    });
  }
});

const selectAuthState = createSelector(
  (state: AuthSliceState) => state,
  state => state.auth
);

export const selectAuthAuthenticated = createSelector(
  selectAuthState,
  state => state.authenticated
);

export const selectAuthLoading = createSelector(
  selectAuthState,
  state => state.loading
);

export const selectAuthError = createSelector(
  selectAuthState,
  state => state.error
);

export const { authSuccess, authFail } = authSlice.actions;
export const authReducer = authSlice.reducer;
