export enum EOetRelationType {
  parent_child = "PARENT_CHILD",
  default_value = "DEFAULT_VALUE",
}

export enum EOetRelationTypeSelectOption {
  default_value = "DEFAULT_VALUE",
  parent = "PARENT",
  child = "CHILD",
}
