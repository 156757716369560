import {
  DeleteButton,
  EditButton,
  EntityView,
} from '@sivis/shared/components/entityView';
import { useSystemApi } from './useSystemApi';
import SystemInformationTab, {
  SystemInformationTabProps,
} from './systemInformationTab';
import SystemResourcesTab, {
  SystemResourcesTabProps,
} from './systemResourcesTab';
import { Outlet, useOutletContext } from 'react-router-dom';
import {
  ACCOUNTS_TAB_PATHNAME,
  ATTRIBUTE_SYNCHRONIZATION_DEFINITION_TAB_PATHNAME, FIELDDEFINITION_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCES_TAB_PATHNAME,
  ROLES_TAB_PATHNAME,
} from '../components/routes/constants';
import { useCustomIntl } from '@sivis/intl';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';
import { System, SystemInput } from '@sivis/identity/api';
import SystemRolesTab from './systemRolesTab';
import { SystemOwnershipTab } from './systemOwnershipTab';
import SystemAccountsTab, { SystemAccountsTabProps } from './systemAccountsTab';
import { SystemAttributeSynchronizationDefinitionTab } from './systemAttributeSynchronizationDefinitionTab';
import SystemFielddefinitionTab from './systemFielddefinitionTab';

export const SystemView = () => {
  const intl = useCustomIntl();
  const {
    defaultInput,
    system,
    systemTypes,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
  } = useSystemApi();

  const informationTab = {
    label: intl.format('fusion.breadcrumb.information'),
    path: INFORMATION_TAB_PATHNAME,
  };
  const accountsTab = {
    label: intl.format('fusion.account.list.name'),
    path: ACCOUNTS_TAB_PATHNAME,
  };
  const rolesTab = {
    label: intl.format('fusion.role.list.name'),
    path: ROLES_TAB_PATHNAME,
  };
  const resourcesTab = {
    label: intl.format('fusion.resource.list.name'),
    path: RESOURCES_TAB_PATHNAME,
  };
  const ownershipTab = {
    label: intl.format('fusion.ownership.list.name'),
    path: OWNERSHIP_TAB_PATHNAME,
  };
  const attributeSynchronizationDefinitionTab = {
    label: intl.format('fusion.attributesynchronizationdefinition.list.name'),
    path: ATTRIBUTE_SYNCHRONIZATION_DEFINITION_TAB_PATHNAME,
  };

  const fieldDefinitionTab = {
    label: intl.format("fusion.breadcrumb.fieldDefinitions"),
    path: FIELDDEFINITION_TAB_PATHNAME
  };

  const tabs = isNew ? [informationTab] : [informationTab, fieldDefinitionTab, accountsTab,
        rolesTab,
        resourcesTab,
        ownershipTab,
        attributeSynchronizationDefinitionTab,
      ];

  const titleExisting = `${intl.format(
    'fusion.system.information.titleExisting'
  )}${system?.systemName}`;
  const title = isNew
    ? intl.format('fusion.system.information.titleNew')
    : titleExisting;
  const buttons = isNew
    ? undefined
    : [
        <EditButton disabled={editMode} onClick={toggleEditMode} key="edit" />,
        <DeleteButton onClick={onDelete} key="delete" />,
      ];

  return (
    <EntityView
      title={title}
      tabs={tabs}
      actionButtons={buttons}
      error={
        error
          ? getTranslatedErrorMessage(
              error,
              'fusion.system.error.notFound',
              intl.format
            )
          : undefined
      }
    >
      <Outlet
        context={{
          editMode,
          system,
          systemTypes,
          isNew,
          onSave,
          onCancel,
          onDelete,
          defaultInput,
        }}
      />
    </EntityView>
  );
};

export type ContextType = {
  systemInput: SystemInput;
  system: System;
  editMode: boolean;
  onCancel: () => void;
};

export const SystemInformationTabWrapperComponent = () => {
  const props = useOutletContext<SystemInformationTabProps>();
  return <SystemInformationTab {...props} />;
};

export const SystemAccountsTabWrapperComponent = () => {
  const { system } = useOutletContext<SystemAccountsTabProps>();
  return <SystemAccountsTab system={system} />;
};

export const SystemResourcesTabWrapperComponent = () => {
  const { system } = useOutletContext<SystemResourcesTabProps>();
  return <SystemResourcesTab system={system} />;
};

export const SystemRolesTabWrapperComponent = () => {
  const { editMode, system } = useOutletContext<ContextType>();
  return <SystemRolesTab system={system} editMode={editMode} />;
};

export const SystemOwnershipTabWrapperComponent = () => {
  const { editMode, system } = useOutletContext<ContextType>();
  return <SystemOwnershipTab system={system} editMode={editMode} />;
};

export const SystemAttributeSynchronizationDefinitionTabWrapperComponent =
  () => {
    const { editMode, system, onCancel } = useOutletContext<ContextType>();
    return (
      <SystemAttributeSynchronizationDefinitionTab
        system={system}
        editMode={editMode}
        onCancel={onCancel}
      />
    );
  };

export const AccountTypeFieldDefinitionTabWrapperComponent = () => {
  const props = useOutletContext<SystemInformationTabProps>();
  return <SystemFielddefinitionTab {...props} />;
};
