import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  Role,
  RoleInput,
  roleInputFactory,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation
} from "@sivis/identity/api";
import {newId} from "../common/newId";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";

export const useRoleApi = () => {
  const {id = newId} = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useGetRoleQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [deleteRole] = useDeleteRoleMutation()
  const [updateRole] = useUpdateRoleMutation();
  const [createRole] = useCreateRoleMutation();
  const [input, setInput] = useState<RoleInput>(roleInputFactory());
  const [role, setRole] = useState<Role>();
  const {editMode, toggleEditMode} = useSingleEditMode(EntityType.ROLE, isNew);

  useEffect(() => {
    setInput(roleInputFactory(data?.role));
    setRole(data?.role ?? undefined);
  }, [data?.role]);
  const goBack = () => navigate("/role");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {
    onSave = (value: RoleInput | Role) => {
      createRole({input: roleInputFactory(value)}).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {
    onSave = (value: RoleInput | Role) => {
      updateRole({id, input: roleInputFactory(value)})
      .then(() => {
        toggleEditMode();
        refetch();
      });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deleteRole({id}).then(goBack);
    };
  }

  return {
    id,
    role,
    defaultInput: input,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
}
