import {DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";
import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";
import {searchAccountsFilter, useGetAccountsQuery} from "@sivis/identity/api";
import {parseAccountsPageResult} from "../account/useAccountsPageApi";

export const useAccountsPageForSystemApi = (systemId?: string) => {
  const {filter: searchFilter, onSearch} = useListSearch(searchAccountsFilter);
  const systemFilter = {
    operator: FilterOperator.EQ,
    name: "systemId",
    uuidValue: systemId
  };
  const filter = searchFilter ? combineFilter(FilterOperator.AND, searchFilter, systemFilter)
    : systemFilter;
  const useAccountsPageForSystemPageQuery = (cursor: string | null) =>
    useGetAccountsQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    }, {skip: !systemId});

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useAccountsPageForSystemPageQuery,
    parseAccountsPageForSystemPageResult: parseAccountsPageResult
  };
};
