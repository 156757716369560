import styles from "./entityView.module.scss";
import {Tab, TabBar} from "./components/tabBar/tabBar";
import React, {PropsWithChildren} from "react";

interface EntityViewProps {
  title: string;
  error?: string;
  actionButtons?: JSX.Element[];
  tabs?: Tab[];
}

export const EntityView = (props: PropsWithChildren<EntityViewProps>) => {
  const content = props.error ? <div className={styles.childrenContainer}>
    {props.error}
  </div> : <>
    {props.actionButtons ? <div className={styles.actionButtonContainer}>
      {props.actionButtons.map((button, index) => React.cloneElement(button, {key: index}))}
    </div> : null}
    {props.tabs ? <div className={styles.tabBarContainer}>
      <TabBar tabs={props.tabs} withNavigation/>
    </div> : null}
    <div className={styles.childrenContainer}>
      {props.children}
    </div>
  </>;

  return <div className={styles.container}>
    <h1>{props.title}</h1>
    {content}
  </div>;
};

