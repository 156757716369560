import {
  Address,
  OrganizationalEntity,
  OrganizationalEntityInput,
  OrganizationalEntityType
} from "@sivis/identity/api";
import styles from "./organizationalEntity.module.scss";
import {EntityInformation} from "@sivis/shared/components/entityView";
import {useTranslateConfigs} from "@sivis/intl";

export interface OrganizationalEntityInformationTabProps {
  organizationalEntity: OrganizationalEntity | undefined,
  organizationalEntityTypes: OrganizationalEntityType[],
  addresses: Address[],
  isNew: boolean,
  onSave: (value: Record<string, any>) => void,
  onCancel: () => void,
  editMode: boolean,
  defaultInput: OrganizationalEntityInput
}


const OrganizationalEntityInformationTab = (props: OrganizationalEntityInformationTabProps) => {

  const flattenedOrganizationalEntity = props.organizationalEntity ? {
    ...props.organizationalEntity,
    organizationalEntityType: props.organizationalEntity.organizationalEntityType ? props.organizationalEntity.organizationalEntityType.id : null,
    address: props.organizationalEntity.address ? props.organizationalEntity.address.id : ''
  } : {};

  const organizationalEntityTypeReadOnly = props.organizationalEntity?.organizationalEntityType ?
    props.organizationalEntity.organizationalEntityType.name : '';

  const addressReadOnly = props.organizationalEntity?.address ?
    props.organizationalEntity.address.name : '';

  const configs = [
    {
      property: 'name',
      required: true
    },
    {
      property: 'alternativeName',
    },
    {
      property: 'organizationalEntityType',
      disabled: !props.isNew,
      required: true,
      options: props.organizationalEntityTypes.map(organizationalEntityType => ({
        value: organizationalEntityType.id,
        text: organizationalEntityType.name
      })),
      renderRow: (_: string) => (organizationalEntityTypeReadOnly)
    },
    {
      property: 'customID',
    },
    {
      property: 'address',
      options: props.addresses.map(address => ({
        value: address.id,
        text: address.name
      }))
      ,
      renderRow: (_: string) => (addressReadOnly)
    },
  ];

  const translatedConfigs = useTranslateConfigs(configs, "fusion.organizationalEntity.propertyName");

  const entityInformation = <EntityInformation
    editMode={props.editMode}
    configs={translatedConfigs}
    value={props.isNew ? props.defaultInput : flattenedOrganizationalEntity}
    onSubmit={props.onSave}
    onCancel={props.onCancel}
  />;

  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
  // TODO: address items should be grouped
};

export default OrganizationalEntityInformationTab;
