import React, { Fragment } from 'react';
import _ from 'lodash';
import styles from './form.module.scss';
import { AttributeRowConfig, AttributeConfig } from './attributeRowConfig';
import { FieldErrors } from 'react-hook-form';
import { getSingleProperty } from './utils';

interface RenderRowProps {
  config: AttributeRowConfig;
  errors: FieldErrors<Record<string, any>>;
  renderController: (config: AttributeConfig) => JSX.Element | null;
  watchAllFields: Record<string, any>;
}




const RenderRowComponent: React.FC<RenderRowProps> = ({
                                                        config,
                                                        errors,
                                                        renderController,
                                                        watchAllFields,
                                                      }) => {
  const renderError = (config: AttributeConfig) => {
    const propertyError = _.get(errors, config.property);
    return propertyError?.type === 'required' ||
    propertyError?.type === 'validate' ? (
      <div className={styles.errorRow}>
        {propertyError?.message as string}
      </div>
    ) : null;
  };

  const propertyRenderer = (
    config: AttributeRowConfig,
    renderer: (config: AttributeConfig) => JSX.Element | null
  ) =>
    Array.isArray(config.property)
      ? config.property.map((singleProperty) => renderer(singleProperty))
      : renderer(config as AttributeConfig);

  const hidden =
    config.renderCondition && !config.renderCondition(watchAllFields);
  if (hidden) {
    return null;
  }

  return (
    <Fragment key={getSingleProperty(config.property)}>
      <div className={styles.row} role="listitem">
        <div>
          <label className={styles.rowName} htmlFor={config.name}>
            {config.name} {config.required ? '*' : ''}
          </label>
        </div>
        <div>{propertyRenderer(config, renderController)}</div>
        {propertyRenderer(config, renderError)}
      </div>
    </Fragment>
  );
};

export default RenderRowComponent;
