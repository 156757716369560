import { DialogActions } from "@mui/material";
import { EOwnershipEntity, Identity, Ownership, Resource, ResourceSet, Role, System } from "@sivis/identity/api";
import { CreatePopupProps, FormButtons, Popup } from "@sivis/shared/components/entityView";
import { ReactNode, useState } from "react";
import { CreateOwnership } from "./createOwnership";

interface CreateOwnershipPopupProps {
  ownershipFor: EOwnershipEntity;
  ownershipTargetType?: EOwnershipEntity;
  primaryTitle: string;
  secondaryTitle: ReactNode;
  owner?: Identity | Role;
  target?: Resource | ResourceSet | System | Role;
}

export const createOwnershipPopup = (props: CreateOwnershipPopupProps) =>
  function Comp({open, onClose, onSave: onSaveFromList}: CreatePopupProps<Ownership>) {
    const [added, setAdded] = useState<Ownership[]>([]);

    const onSave = () => {
      if (onSaveFromList) {
        onSaveFromList(added);
      }
    };

    let content = <></>;
    if (props.owner) {
      content = <CreateOwnership selectedEntity={props.owner} onAdd={setAdded}
                                 ownershipTargetType={props.ownershipTargetType}/>;
    } else if (props.target) {
      content = <CreateOwnership selectedEntity={props.target} onAdd={setAdded}
                                 ownershipTargetType={props.ownershipTargetType}/>;
    }

    return <Popup
      open={open}
      onClose={onClose}
      primaryTitle={props.primaryTitle}
      secondaryTitle={props.secondaryTitle}
      hideButtons
      styleType="edit"
    >
      {content}
      <DialogActions>
        <FormButtons onCancel={onClose} onSave={onSave} saveInsteadOfSubmit={true}/>
      </DialogActions>
    </Popup>;
  };
