import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {IntlButton} from "@sivis/intl";
import AddIcon from '@mui/icons-material/Add';

export const CreateButton = ({disabled}: { disabled?: boolean }) => {
  const navigate = useNavigate();
  const onCreate = useCallback(() => navigate("new"), [navigate]);
  return <IntlButton variant="outlined" onClick={onCreate} disabled={disabled}
                     intlId="fusion.button.create"/>;
};

export const EditButton = ({onClick, disabled}: { onClick?: () => void, disabled?: boolean }) => {
  return <IntlButton variant="outlined" onClick={onClick} disabled={disabled}
                     intlId="general.edit"/>;
};

export const DeleteButton = ({onClick, disabled}: { onClick?: () => void, disabled?: boolean }) => {
  return <IntlButton variant="outlined" onClick={onClick} disabled={disabled}
                     intlId="general.delete"/>;
};

export const DeactivateButton = ({onClick, disabled}: {
  onClick?: () => void,
  disabled?: boolean
}) => {
  return <IntlButton variant="outlined" onClick={onClick} disabled={disabled}
                     intlId="general.deactivate"/>;
};

export const ActivateButton = ({onClick, disabled}: {
  onClick?: () => void,
  disabled?: boolean
}) => {
  return <IntlButton variant="outlined" onClick={onClick} disabled={disabled}
                     intlId="general.activate"/>;
};

interface CreateButtonWithIconProps {
  intlId: string;
  disabled?: boolean;
  onClick: () => void;
}

export const CreateButtonWithIcon = ({disabled, intlId, onClick}: CreateButtonWithIconProps) => {
  return <IntlButton variant="outlined" onClick={onClick} disabled={disabled} size="small"
                     style={{textTransform: "uppercase"}} intlId={intlId} icon={<AddIcon/>}/>;
};
