/**
 * Check if object type contains a specified property and narrow it down to Record.
 * Necessary for refining object properties without using `any`
 * and instead only `unknown`, enforcing further type checking.
 * * TypeScript's `in` operator does not narrow the `object` type.
 * * Lodash's `has` function does not narrow the `object` type.
 *
 * @param data Object to narrow down
 * @param property Property name to check for
 * @returns true if `data` contains given property.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const hasProperty = <K extends PropertyKey>(data: object, property: K): data is Record<K, unknown> => {
  return property in data;
};


type MaybeKey = string | number | symbol;

/**
 * Check whether the key-like variable is actually a key of specified enum or map
 * @param map enum to check against
 * @returns type guard whether key-like variable is actually a key of specified enum or map
 */
export const isMapKey = <E extends object>(map: E) => (key: MaybeKey): key is keyof typeof map => {
  return key in map;
};

export const isEnumKey = isMapKey;

/**
 * Check whether a variable with a nullable union type is not empty
 * @param value variable to check against
 * @returns true if the variable is not null or undefined
 */
export const notEmpty = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

/**
 * Get the enum value from string. This is useful for string enums where the property name doesn't match the
 * value e.g.: enum { GREEN = 'green', RED = 'red' }
 * @param value
 * @param _enum
 */
export const enumFromValue = <T extends Record<string, string>>(value: string, _enum: T) => {
  const enumName = (Object.keys(_enum) as Array<keyof T>).find(k => _enum[k] === value)
  if (!enumName) {
    return null;
  }
  return _enum[enumName]
}
