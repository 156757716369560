import {createAsyncThunk} from '@reduxjs/toolkit';
import {IntlSliceState, IText} from './types';
import {fileDownload, getMessageOrSelf, isEnumKey, LoggingService} from '@sivis/shared/utils';
import {httpGet, httpPost} from '@sivis/http-client';
import {showError, showSuccess} from '@sivis/redux';
import Axios, {AxiosError, AxiosResponse} from 'axios';
import {evaluateText} from './utils';
import {ELocales} from './ELocales';
import {selectIsBackendUp} from "@sivis/shared/backend";

export const INTL_SLICE_NAME = 'intl';
const INTL_LOGGING_SOURCE = 'IntlAction'

export const fetchTexts = createAsyncThunk<IText[] | undefined, void, { state: IntlSliceState }>(
  `${INTL_SLICE_NAME}/fetchMessages`,
  async (_, {getState, rejectWithValue}) => {
    LoggingService.info('Fetching messages for all languages', INTL_LOGGING_SOURCE);
    if (!selectIsBackendUp(getState())) {
      LoggingService.info('No Spring backend. Fetching standard messages', INTL_LOGGING_SOURCE);
      return;
    }

    try {
      const customText = await httpGet<IText[]>('/customization/text/getText');
      if (!evaluateText(customText)) {
        return rejectWithValue('No custom text found');
      }
      return customText;

    } catch (e) {

      return rejectWithValue(getMessageOrSelf(e));

    }

  }
);

export const saveText = createAsyncThunk<IText[] | undefined, IText[], { state: IntlSliceState }>(
  `${INTL_SLICE_NAME}/saveText`,
  async (newTexts: IText[], {dispatch, rejectWithValue}) => {
    LoggingService.info('Save custom text', 'IntlAction');
    try {
      await httpPost<string, IText[]>('/customization/text/saveText', newTexts)
      dispatch(showSuccess('customize.text.success'));
      return newTexts
    } catch (e) {
      LoggingService.error(e, INTL_LOGGING_SOURCE);
      dispatch(showError('customize.text.error'));
      return rejectWithValue(getMessageOrSelf(e));
    }
  });

export const uploadText = createAsyncThunk<IText[] | undefined, any, { state: IntlSliceState }>(
  `${INTL_SLICE_NAME}/uploadText`,
  async (file, {dispatch, rejectWithValue}) => {
    LoggingService.info('Upload custom text', INTL_LOGGING_SOURCE);
    const fd = new FormData();
    fd.append('file', file);
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    try {
      const newTexts = await httpPost<IText[], FormData>('/customization/text/uploadTextFile', fd, {options});
      dispatch(showSuccess('customize.text.success'));
      return newTexts;
    } catch (e) {
      LoggingService.error(e, INTL_LOGGING_SOURCE);
      dispatch(showError('customize.text.error'));
      return rejectWithValue(getMessageOrSelf(e));
    }
  }
);

export const downloadTexts = createAsyncThunk<void, IText[]>(
  `${INTL_SLICE_NAME}/downloadTexts`,
  async (texts, {dispatch}) => {
    LoggingService.info('Download custom text', INTL_LOGGING_SOURCE);
    await Axios.post('/customization/text/getTextFile', texts, {
      responseType: 'arraybuffer'
    })
    .then((response: AxiosResponse) => {
      const fileName = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '') || 'download.xlsx';
      fileDownload({data: response.data, fileName: fileName});
    })
    .catch((error: AxiosError<unknown>) => {
      let errorMsg = 'Error';
      if (error.response) {
        const axiosMsg = error.response.statusText;
        if (axiosMsg !== undefined && typeof axiosMsg === 'string'
          && axiosMsg !== null && axiosMsg.length > 0) {
          errorMsg = axiosMsg;
        }
      }
      dispatch(showError(errorMsg));
    });
  }
);

export const activateLanguage = createAsyncThunk(
  `${INTL_SLICE_NAME}/activateLanguage`,
  async (locale: ELocales, {rejectWithValue}) => {
    try {
      await httpGet('/customization/locale/activate/' + locale);
      return locale;
    } catch (e) {
      LoggingService.error('Error activating language:', 'LanguageAction');
      return rejectWithValue(getMessageOrSelf(e))
    }
  }
);

export const deactivateLanguage = createAsyncThunk(
  `${INTL_SLICE_NAME}/deactivateLanguage`,
  async (locale: ELocales, {rejectWithValue}) => {
    try {
      await httpGet('/customization/locale/deactivate/' + locale);
      return locale;
    } catch (e) {
      LoggingService.error('Error deactivating language:', INTL_LOGGING_SOURCE);
      return rejectWithValue(getMessageOrSelf(e));
    }
  }
);

interface ILocaleDTO {
  locale: string;
  active: boolean;
}

export const fetchLanguagesIfNeeded = createAsyncThunk(
  `${INTL_SLICE_NAME}/fetchLanguagesIfNeeded`,
  async () => {
    LoggingService.info('Fetch language settings', INTL_SLICE_NAME);
    const localeDtos: ILocaleDTO[] = await httpGet<ILocaleDTO[]>('/customization/locale/getActive');
    const backendLocales: ELocales[] = [];
    localeDtos.forEach(l => {
      const langUpper = l.locale.toUpperCase();
      if (isEnumKey(ELocales)(langUpper)) {
        backendLocales.push(ELocales[langUpper]);
      }
    });
    return backendLocales;
  }
);
type SaveUserLanguage = { username: string, locale: ELocales };

export const trySaveUserLanguage = createAsyncThunk<void, SaveUserLanguage, {
  state: IntlSliceState
}>(
  `${INTL_SLICE_NAME}/trySaveUserLanguage`,
  async ({username, locale}, {dispatch, getState}) => {
    const {intl: {language, messages, showWarningOnSwitch}} = getState();

    if (locale === language) {
      return;
    }

    if (!showWarningOnSwitch) {
      dispatch(saveUserLanguage({locale, username}));
      return;
    }

    const warning = messages.find((msg: IText) => msg.id === 'customize.text.restoreStandardConfirm' && msg.locale === locale)?.message;
    if (window.confirm(warning)) {
      dispatch(saveUserLanguage({locale, username}));
    }
  }
);

export const saveUserLanguage = createAsyncThunk<ELocales, SaveUserLanguage, {
  state: IntlSliceState
}>(
  `${INTL_SLICE_NAME}/saveUserLanguage`,
  async ({username, locale}, {dispatch, rejectWithValue}) => {
    LoggingService.info('Set custom language', 'LanguageAction');
    try {

      const fd = new FormData();
      fd.append('locale', locale.toLocaleLowerCase());
      fd.append('username', username);

      await httpPost('/appUser/saveLocale', fd);
      dispatch(showSuccess('language.saveSuccess'));

      return locale;

    } catch (e) {
      LoggingService.error(e, INTL_LOGGING_SOURCE)
      dispatch(showError('language.saveError'));
      return rejectWithValue(getMessageOrSelf(e))
    }

  }
);
