import {Category, Sidebar} from "@sivis/shared/components/sidebar";

export const FusionSidebar = () => {
  const categories: Category[] = [
    {
      name: "fusion.sidebar.category.main",
      items: [
        { name: "fusion.identity.list.name", url: "/identity" },
        { name: "fusion.account.list.name", url: "/account" },
        { name: "fusion.role.list.name", url: "/role" },
        { name: "fusion.resource.list.name", url: "/resource" },
        { name: "fusion.resourceSet.list.name", url: "/resource-set" },
      ],
    },
    {
      name: "fusion.sidebar.category.organization",
      items: [
        { name: "fusion.organizationalEntity.list.name", url: "/organizational-entity" },
        { name: "fusion.organizationalEntityType.list.name", url: "/organizational-entity-type" },
        { name: "fusion.address.list.name", url: "/address" },
      ],
    },
    {
      name: "fusion.sidebar.category.configure",
      items: [
        { name: "fusion.system.list.name", url: "/system" },
        { name: "fusion.permission.list.name", url: "/permission" },
        { name: "fusion.identityType.list.name", url: "/identity-type" },
        { name: "fusion.accountType.list.name", url: "/account-type" },
        { name: "fusion.resourceType.list.name", url: "/resource-type" },
        { name: "fusion.systemType.list.name", url: "/system-type" },
        {name: "fusion.rule.list.name", url: "/rule"}
      ],
    }
  ];

  return <Sidebar categories={categories} />
}

