import jwtDecode from 'jwt-decode';

export const getTokenFromCookie = (tokenKey: string) => {
  const [, token] = document.cookie.split(';')
  .map(value => value.trim())
  .map(value => value.split('='))
  .find(([key]) => key === tokenKey) || [];
  return token;
};

export const setCookieFromToken = (token: string, key: string) => {
  const expirationTime = getExpirationTime(token);
  if (expirationTime) {
    document.cookie = `${key}=${token};expires=` + expirationTime + ';path=/';
  }
};

const getExpirationTime = (token: string) => {
  const tokenObj: any = decodeToken(token);
  if (tokenObj?.iat && tokenObj?.exp) {
    const tokenAgeInSeconds = tokenObj.exp - tokenObj.iat;
    const time = new Date();
    time.setSeconds(time.getSeconds() + tokenAgeInSeconds);
    return time.toUTCString();
  }
  return undefined;
};

export const decodeToken = (token: string) => {
  return jwtDecode(token);
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};
