import {ELocales} from './ELocales';

// assure that the Objects are readonly to achieve compile time immutability
export interface ILanguageState {
  readonly language: ELocales;
  readonly availableLanguages: ELocales[];
  readonly loading: boolean;
  readonly showWarningOnSwitch: boolean;
}

export interface IText {
  id: string;
  locale: ELocales;
  message: string;
}

export const isTextEqual = (left: IText, right: IText): boolean => {
  const isIdEqual = left?.id === right?.id;

  if (!isIdEqual) {
    return false;
  }

  return left?.locale === right?.locale
}

export interface ITextState {
  messages: IText[];
  loading: boolean;
  error: any;
}

export type IntlState = ITextState & ILanguageState;

export interface IntlSliceState {
  intl: IntlState;
}

export type UpperCaseLanguage = Uppercase<IntlSliceState['intl']['language']>
