import { useState } from 'react';
import {
  IdentityRoleMembership,
  Role,
  searchMembershipsInRoleFilter,
  useCreateIdentityRoleMembershipMutation,
  useDeleteIdentityRoleMembershipMutation,
  useGetIdentityRoleMembershipsQuery
} from '@sivis/identity/api';
import { useListSearch } from '../../utils/filterUtils';
import { DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE } from '../../common/parsePageResult';
import { parseMembershipsPageResult } from './useRoleIdentityMembershipsApi';
import { combineFilter, FilterOperator } from '@sivis/shared/api-utils';

export const useIdentityRoleMembershipsApi = (role?: Role) => {
  const roleId = role?.id;
  const {filter: searchMembershipsFilter, onSearch} = useListSearch(searchMembershipsInRoleFilter);
  const roleFilter = {name: "role.id", operator: FilterOperator.EQ, uuidValue: roleId};
  const membershipsFilter = searchMembershipsFilter ? combineFilter(FilterOperator.AND, searchMembershipsFilter, roleFilter)
    : roleFilter;
  const [deleteMembership] = useDeleteIdentityRoleMembershipMutation();
  const [createMembership] = useCreateIdentityRoleMembershipMutation();
  const [submitted, setSubmitted] = useState(false);

  const useMembershipsPageQuery = (cursor: string | null) =>
    useGetIdentityRoleMembershipsQuery(
      {
        first: DEFAULT_PAGE_SIZE,
        after: cursor,
        sort: DEFAULT_ENTITY_SORT,
        filter: membershipsFilter
      },
      {skip: !roleId}
    );

  const onSave = (added: IdentityRoleMembership[], deleted: IdentityRoleMembership[]) => {
    if (!roleId) {
      return Promise.resolve();
    }
    return Promise.all([
      ...added.map(membership => createMembership({
        identityId: membership.identity.id, roleId,
        input: {}
      })),
      ...deleted.map(membership => deleteMembership({id: membership.id}))
    ]).then(() => {
      setSubmitted(true);
    });
  };

  return {
    onSave,
    onSearch,
    submitted,
    setSubmitted,
    pageSize: DEFAULT_PAGE_SIZE,
    useMembershipsPageQuery,
    parseMembershipsPageResult
  };
}
