import debug from 'debug';
import {LoggingLevel} from './LoggingLevel';
import {isHasMessage} from './HasMessage';

const BASE = 'sivis-web-manager';

export class LoggingService {
  public static generateMessage(level: LoggingLevel, message: unknown, source: string) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    const logmessage = isHasMessage(message) ? message.message : message;

    if (source) {
      createDebug(source, logmessage);
    } else {
      createDebug(logmessage);
    }
  }

  public static trace(message: any, source: string) {
    return this.generateMessage(LoggingLevel.trace, message, source);
  }

  public static info(message: any, source: string) {
    return this.generateMessage(LoggingLevel.info, message, source);
  }

  public static warn(message: any, source: string) {
    return this.generateMessage(LoggingLevel.warn, message, source);
  }

  public static error(message: any, source: string) {
    return this.generateMessage(LoggingLevel.error, message, source);
  }
}
