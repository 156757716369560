import {GridColDef} from "@mui/x-data-grid-pro";
import {EntityTableRow, InfiniteScrollTableCursor} from "@sivis/shared/components/entityView";
import {Resource, System} from "@sivis/identity/api";
import {useResourcePageForSystemApi} from "./useResourcePageforSystemApi";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

export interface SystemResourcesTabProps {
  system?: System,
}

const entityToTableRow = (entity: Resource): EntityTableRow => {
  return {
    id: entity.id,
    name: entity.name,
    resourceId: entity.resourceId,
    resourceType: entity.resourceType?.name,
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
  },
  {
    field: "resourceId",
    flex: 1,
  },
  {
    field: "resourceType",
    flex: 1,
  },
];

const SystemResourcesTab = (props: SystemResourcesTabProps) => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useResourcePageForSystemPageQuery,
    parseResourcePageForSystemPageResult,
    pageSize
  } = useResourcePageForSystemApi(props.system?.id);

  const translatedColumns = useTranslateColumns(columns, "fusion.resource.propertyName");

  return <InfiniteScrollTableCursor
    columns={translatedColumns}
    pageSize={pageSize}
    usePageQuery={useResourcePageForSystemPageQuery}
    renderRow={entityToTableRow}
    parseResult={parseResourcePageForSystemPageResult}
    onSearch={onSearch}
    placeholder={intl.format("fusion.general.searchPlaceholder")}
  />;
}

export default SystemResourcesTab;
