import { useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import { Stack } from '@mui/system';
import { EntityForm, RowGroup } from '@sivis/shared/components/entityView';
import styles from './rule.module.scss';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { updateWatchValues, WatchValues } from './ruleUtils';
import { Criterion } from '@sivis/identity/api';

const configsSingleCriterion = [
  {
    property: 'entityField'
  },
  {
    // for now only equals is supported
    property: 'operation',
    disabled: true
  },
  {
    property: 'value'
  }
];

interface CriterionFormProps {
  criterion: Criterion;
  groupId: string;
  onDeleteCriterion: (groupId: string, criterionId: string) => void;
  onSave: (value: Record<string, any>) => void;
  setWatchValues: (value: (((prevState: WatchValues) => WatchValues) | WatchValues)) => void;
}

export const CriterionForm = ({
                                criterion,
                                groupId,
                                onDeleteCriterion,
                                onSave,
                                setWatchValues
                              }: CriterionFormProps) => {
  const intl = useCustomIntl();
  const translatedConfigs = useTranslateConfigs(configsSingleCriterion, 'fusion.rule.singleCriterion.propertyName');


  return (
    <div key={criterion.id}>
      <Stack direction="row" justify-content="space-between" sx={{ width: '100%' }}>
        <RowGroup differentStyles={styles.rowGroupThreeColumns}>
          <EntityForm
            configs={translatedConfigs}
            value={{ ...criterion }}
            onSubmit={onSave}
            hideButtons
            displayInThreeColumns
            onWatch={(watchValues) => updateWatchValues(`criterion-${criterion.id}`, watchValues, setWatchValues)}
          />
        </RowGroup>
        <IconButton
          onClick={() => onDeleteCriterion(groupId, criterion.id)}
          aria-label={intl.format('general.delete')}>
          <ClearIcon />
        </IconButton>
      </Stack>
    </div>
  );
};
