import {searchResourcesFilter, useGetResourcesQuery} from "@sivis/identity/api";
import {DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";
import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";
import {parseResourcePageResult} from "../resource/useResourcePageApi";

export const useResourcePageForSystemApi = (systemId?: string) => {
  const { filter: searchFilter, onSearch } = useListSearch(searchResourcesFilter);
  const systemFilter = {
    operator: FilterOperator.EQ,
    name: "system.id",
    uuidValue: systemId
  };
  const filter = searchFilter ? combineFilter(FilterOperator.AND, searchFilter, systemFilter)
    : systemFilter;
  const useResourcePageForSystemPageQuery = (cursor: string | null) =>
    useGetResourcesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    }, {skip: !systemId});

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useResourcePageForSystemPageQuery,
    parseResourcePageForSystemPageResult: parseResourcePageResult
  };
};
