import {
  GeneratedOrganizationalEntityTypes,
  OrganizationalEntityType,
  searchOrganizationalEntityTypesFilter,
  useGetOrganizationalEntityTypesQuery
} from "@sivis/identity/api";
import {CursorBasedPageResult} from "@sivis/shared/components/entityView";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../../common/parsePageResult";
import {useListSearch} from "../../utils/filterUtils";


export const useOrganizationalEntityTypesPageApi = () => {
  const { filter, onSearch } = useListSearch(searchOrganizationalEntityTypesFilter);

  const useOrganizationalEntityTypesPageQuery = (cursor: string | null) =>
    useGetOrganizationalEntityTypesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    });

  const parseOrganizationalEntityTypesPageResult = (res?: GeneratedOrganizationalEntityTypes.GetOrganizationalEntityTypesQuery): CursorBasedPageResult<OrganizationalEntityType> => {
    return parseCursorBasedPageResult('organizationalEntityTypes', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useOrganizationalEntityTypesPageQuery,
    parseOrganizationalEntityTypesPageResult
  };
};
