import { Identity, Ownership, ResourceSet, useGetIdentityQuery, useGetResourceSetQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";
import { useOwnershipApi } from "./useOwnershipApi";

export const useIdentityResourceSetOwnershipApi = ({id, ownerId, targetId}: Ownership) => {
  const {data: dataOwner} = useGetIdentityQuery({id: ownerId}, {refetchOnMountOrArgChange: true});
  const {data: dataTarget} = useGetResourceSetQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [owner, setOwner] = useState<Identity>();
  const [target, setTarget] = useState<ResourceSet>();

  useEffect(() => {
    setOwner(dataOwner?.identity ?? undefined);
    setTarget(dataTarget?.resourceSet ?? undefined);
  }, [dataOwner?.identity, dataTarget?.resourceSet]);

  const {ownership, onSave} = useOwnershipApi(id);

  return {ownership, owner, target, onSave}
}
