import { forceRefetchPaging, mergePaging, serializeQueryArgsPaging } from "@sivis/shared/api-utils";
import { api as generatedSystemTypeApi } from './systemType.generated';

export const systemTypeApi = generatedSystemTypeApi.enhanceEndpoints({
  endpoints: {
    GetSystemTypes: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("systemTypes"),
      forceRefetch: forceRefetchPaging
    }
  }
})

export const {
  useGetSystemTypeQuery,
  useLazyGetSystemTypeQuery,
  useGetSystemTypesQuery,
  useLazyGetSystemTypesQuery
} = systemTypeApi;
