import {
  GeneratedResourceTypes,
  Resource,
  searchResourcesFilter,
  useGetResourcesQuery
} from '@sivis/identity/api';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../common/parsePageResult';
import { getExistingOrCombinedFilter, useListSearch } from '../utils/filterUtils';
import { FilterExpression } from '@sivis/shared/api-utils';


export const parseResourcePageResult = (res?: GeneratedResourceTypes.GetResourcesQuery): CursorBasedPageResult<Resource> => {
  return parseCursorBasedPageResult('resources', res);
};

export const useResourcePageApi = (extraFilter?: FilterExpression) => {
  const { filter, onSearch } = useListSearch(searchResourcesFilter);
  let resourcesFilter = getExistingOrCombinedFilter(filter, extraFilter);

  const useResourcePageQuery = (cursor: string | null) =>
    useGetResourcesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: resourcesFilter
    });

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useResourcePageQuery,
    parseResourcePageResult
  };
};
