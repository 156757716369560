import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../../common/parsePageResult';
import { CursorBasedPageResult, refetchTable } from '@sivis/shared/components/entityView';
import {
  EOwnershipEntity,
  ExtendedOwnership,
  FilterExpression,
  GeneratedOwnershipTypes,
  Ownership,
  OwnershipQueryType,
  useCreateOwnershipMutation,
  useDeleteOwnershipMutation,
  useGetResourceOwnershipsQuery,
  useGetResourceSetOwnershipsQuery,
  useGetSystemOwnershipsQuery
} from '@sivis/identity/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { parseValidityInput } from '../../utils/validityUtils';

export const parseOwnershipsPageResult = (res?: GeneratedOwnershipTypes.GetOwnershipsQuery): CursorBasedPageResult<Ownership> => {
  return parseCursorBasedPageResult('ownerships', res);
};

export const useTargetOwnershipsApi = (
  cacheKey: EOwnershipEntity,
  targetsFilter: FilterExpression,
  queryType: OwnershipQueryType,
  targetId?: string
) => {
  const [deleteOwnership] = useDeleteOwnershipMutation();
  const [createOwnership] = useCreateOwnershipMutation();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  let useQuery: any;
  if (cacheKey === EOwnershipEntity.RESOURCE_SET) {
    useQuery = useGetResourceSetOwnershipsQuery;
  } else if (cacheKey === EOwnershipEntity.SYSTEM) {
    useQuery = useGetSystemOwnershipsQuery;
  } else {
    useQuery = useGetResourceOwnershipsQuery;
  }

  const useOwnershipsPageQuery = (cursor: string | null) =>
    useQuery(
      {
        first: DEFAULT_PAGE_SIZE,
        after: cursor,
        sort: DEFAULT_ENTITY_SORT,
        filter: targetsFilter,
        cacheKey: cacheKey
      },
      {skip: !targetId}
    );

  const onSave = (added: ExtendedOwnership[], deleted: ExtendedOwnership[]) => {
    if (!targetId) {
      return Promise.resolve();
    }
    return Promise.all([
      ...added.map(ownership => createOwnership({
        ownerId: ownership.ownerId,
        targetId: targetId,
        type: ownership.type,
        input: {
          validity: parseValidityInput(ownership.validity ?? undefined)
        }
      })),
      ...deleted.map(ownership => deleteOwnership({
        id: ownership.id
      }))
    ]).then(() => {
      setSubmitted(true);
      dispatch(refetchTable(queryType)());
    });
  };

  return {
    onSave,
    pageSize: DEFAULT_PAGE_SIZE,
    useOwnershipsPageQuery,
    parseOwnershipsPageResult,
    submitted,
    setSubmitted
  };
};
