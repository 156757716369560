import {Alert} from "@mui/material";
import {Popup} from "@sivis/shared/components/entityView";

interface ErrorPopupProps {
  open: boolean;
  onClose: () => void;
  primaryTitle: string;
  secondaryTitle?: string;
  message: string;
}

export const ErrorPopup = (props: ErrorPopupProps) => {
  return <Popup
    {...props}
    styleType="message"
    hideButtons
  >
    <Alert severity="error">{props.message}</Alert>
  </Popup>;
}
