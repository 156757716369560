import {
  executeAfterQueryFulfilled,
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import { api as generatedOetRelationApi } from './oetRelation.generated';
import { refetchTable } from '@sivis/shared/components/entityView';
import { RelationQueryType } from '../action';
import { resetGetOetRelationsActionCreator } from './oetRelationUtils';

export const oetRelationApi = generatedOetRelationApi.enhanceEndpoints({
  endpoints: {
    GetOetRelations: {
      serializeQueryArgs: serializeQueryArgsPaging,
      forceRefetch: forceRefetchPaging,
      merge: mergePaging("oetRelations"),
      transformResponse: transformResponseWithArgs
    },
    GetOetRelation: {providesTags: ["oetRelation"]},
    CreateOetRelation: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetOetRelationsActionCreator,
        refetchTable(RelationQueryType.OET_RELATIONS)
      ])
    },
    UpdateOetRelation: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetOetRelationsActionCreator,
        refetchTable(RelationQueryType.OET_RELATIONS)
      ])
    },
    DeleteOetRelation: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetOetRelationsActionCreator,
        refetchTable(RelationQueryType.OET_RELATIONS)
      ])
    }
  }
});

export const {
  useGetOetRelationQuery,
  useLazyGetOetRelationQuery,
  useGetOetRelationsQuery,
  useLazyGetOetRelationsQuery,
  useCreateOetRelationMutation,
  useUpdateOetRelationMutation,
  useDeleteOetRelationMutation
} = oetRelationApi;
