import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthAuthenticated } from '../../redux/authSlice';

export const AuthRedirect = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useSelector(selectAuthAuthenticated);
  if (!isAuthenticated) {
    return children;
  } else {
    return <Navigate to={'/'} />;
  }
};
