import {
  CursorBasedPageResult,
  EntityTableRow,
  InfiniteScrollTableCursor,
  TableType
} from "./components/table";
import {useEffect, useState} from "react";
import {GridColDef} from "@mui/x-data-grid-pro";
import {containsListItem} from "./components/utils";
import {useCustomIntl} from "@sivis/intl";
import {TypedUseQueryHookResult} from "@reduxjs/toolkit/dist/query/react/buildHooks";

interface ListAddViewProps<ListItem extends { id: string }, QueryResult> {
  columns: GridColDef[];
  entityToTableRow: (entity: ListItem, isNew: boolean) => EntityTableRow & { dataObject: ListItem };
  onChange: (toAdd: ListItem[]) => void;
  onSearch: (searchStr: string) => void;
  sideFilter?: JSX.Element;

  pageSize: number;
  usePageQuery: (cursor: string | null) => TypedUseQueryHookResult<QueryResult, any, any>;
  parseResult: (res?: QueryResult) => CursorBasedPageResult<ListItem>;
  // TODO: items to exclude should be filtered out in backend
  excludedItems: ListItem[];
  tableType?: TableType;
}

/**
 * A list with pagination. Clicking on each item in the list will select it and all selected items are displayed
 * at the top of the list. Item must have a unique id.
 */
export const ListAddView = <ListItem extends {
  id: string
}, QueryResult>(props: ListAddViewProps<ListItem, QueryResult>) => {
  const intl = useCustomIntl();
  const [addedEntities, setAddedEntities] = useState<ListItem[]>([]);

  useEffect(() => {
    props.onChange(addedEntities);
  }, [addedEntities]);

  // Display added items at the top, also apply filter to added items.
  const renderRows = (entities: ListItem[]) => {
    return [
      ...addedEntities.filter(entity => containsListItem(entities, equals)(entity))
      .map(entity => props.entityToTableRow(entity, true)),
      ...entities.filter(entity => !isAdded(entity) && !isExcluded(entity))
      .map(entity => props.entityToTableRow(entity, false))
    ];
  };

  const equals = (a: ListItem, b: ListItem) => a.id === b.id;
  const isExcluded = containsListItem(props.excludedItems, equals);
  const isAdded = containsListItem(addedEntities, equals);

  const onAdd = (toAdd: ListItem) => {
    if (!isExcluded(toAdd)) {
      setAddedEntities([...addedEntities, toAdd]);
    }
  };

  const onDeselect = (toDeselect: ListItem) => {
    setAddedEntities(addedEntities.filter(added => added.id !== toDeselect.id));
  };

  const onRowClick = (_: string, item?: ListItem) => {
    if (item && isAdded(item)) {
      onDeselect(item);
    } else if (item) {
      onAdd(item);
    }
  };

  return <InfiniteScrollTableCursor
    columns={props.columns}
    pageSize={props.pageSize}
    usePageQuery={props.usePageQuery}
    renderRows={renderRows}
    parseResult={props.parseResult}
    onSearch={props.onSearch}
    placeholder={intl.format("fusion.general.searchPlaceholder")}
    onRowClick={onRowClick}
    sideFilter={props.sideFilter}
    tableType={props.tableType}
  />;
};
