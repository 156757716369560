import React, { useState } from "react";
import { Popup } from "@sivis/shared/components/entityView";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid-pro";
import { Delete as GridDeleteIcon } from "@mui/icons-material";
import { AsMapping } from "@sivis/attributesync/api";

interface DeleteMappingButtonProps {
    id: GridRowId;
    intl: any;
    asMappings: AsMapping[];
    onRemoveMapping: (mapping: AsMapping) => void;
}

const DeleteMappingButton: React.FC<DeleteMappingButtonProps> = ({ id, intl, asMappings, onRemoveMapping }) => {
    const [messagePopupOpen, setMessagePopupOpen] = useState(false);
    const [actionMappingId, setActionMappingId] = useState<GridRowId>("");

    const onDelete = (id: GridRowId) => () => {
        setMessagePopupOpen(true);
        setActionMappingId(id);
    };

    const deleteMapping = () => {
        setMessagePopupOpen(false);
        const mappingToDelete = asMappings.find(mapping => mapping.id === actionMappingId);
        if (mappingToDelete) {
            onRemoveMapping(mappingToDelete);
        }
        setActionMappingId("");
    };

    const onCloseMessagePopup = () => setMessagePopupOpen(false);

    return (
        <>
            <GridActionsCellItem
                icon={<GridDeleteIcon />}
                label="Delete"
                key={id}
                onClick={onDelete(id)}
            />
            <Popup
                primaryTitle={intl.format("fusion.attributesynchronizationmapping.popup.deleteTitle")}
                styleType="message"
                secondaryTitle={intl.format("fusion.attributesynchronizationmapping.popup.deleteSecondaryTitle")}
                open={messagePopupOpen}
                onClose={onCloseMessagePopup}
                onConfirm={deleteMapping}
            />
        </>
    );
};

export default DeleteMappingButton;