import {Link, matchPath, useLocation} from "react-router-dom";
import styles from "./sidebar.module.scss";
import {FunctionComponent, useEffect, useState} from "react";
import {HasIntlId, selectMessageTextById} from "@sivis/intl";
import {useSelector} from "react-redux";

interface SidebarItemProps {
  name: string;
  url: string;
  onSelect: () => void;
}

export const SidebarItem = ({name, url, onSelect}: SidebarItemProps) => {
  const location = useLocation();
  const [selected, setSelected] = useState(false);
  const linkClass = selected ? styles.selected : "";

  useEffect(() => {
    setSelected(!!matchPath({path: url, end: false}, location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (selected) {
      onSelect();
    }
  }, [selected]);

  return <Link to={url} className={linkClass}>{name}</Link>;
};

export const IntlSidebarItem: FunctionComponent<HasIntlId & Omit<SidebarItemProps, "name">> = ({
                                                                                                 intlId,
                                                                                                 ...props
                                                                                               }) => {
  const name = useSelector(state => selectMessageTextById(state, intlId));
  return <SidebarItem {...props} name={name}/>;
};
