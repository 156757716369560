import {createAction} from '@reduxjs/toolkit';

export enum ShowMessageType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export const isShowMessageType = (source: string): source is ShowMessageType => {
  if (!source) {
    return false;
  }

  return source in ShowMessageType;
}

export interface ShowMessage<MessageId = string> {
  messageId: MessageId;
  messageType?: ShowMessageType;
  language?: string;
  append?: string
}

//add '?:' to REGEX - non-capturing group to improve performance and reduce risk of backtracking
const MESSAGE_ID_REGEX = /(?:\w+\.)+\w+/;
export const isStringMessageId = (messageId: string): boolean => {
  if (!messageId) {
    return false;
  }

  return MESSAGE_ID_REGEX.test(messageId);
}

export const isShowMessage = (source: unknown): source is ShowMessage => {
  const messageId = (source as ShowMessage)?.messageId;
  return messageId !== null && messageId !== undefined;
}

export const messageTypeFromActionType = (actionType: string): ShowMessageType | null => {
  if (!actionType) {
    return null;
  }

  const messageType = actionType.replace('show/', '');

  if (!isShowMessageType(messageType)) {
    return null;
  }

  return messageType;

}
//TODO: remove undefined
export type ShowMessagePayload = ShowMessage | string;

const createShowAction = (messageType: ShowMessageType) => createAction<ShowMessagePayload>(`show/${messageType}`);
export const showSuccess = createShowAction(ShowMessageType.success);
export const showInfo = createShowAction(ShowMessageType.info);
export const showWarning = createShowAction(ShowMessageType.warning);
export const showError = createShowAction(ShowMessageType.error);
