import * as Types from '../attributeSyncApi.types';

import { SystemFieldFragmentFragmentDoc } from './systemFieldFragments.generated';
import { attributeSyncBaseApi } from '../attributeSyncBaseApi';

            /* eslint-disable */
              /**
               *
               * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
               *
               * instead, edit one of the `.graphql` files in this project and run
               *
               * npm run graphql-codegen
               *
               * for this file to be re-created
               */
               
export type GetSystemFieldsForSystemQueryVariables = Types.Exact<{
  systemId: Types.Scalars['UUID'];
}>;


export type GetSystemFieldsForSystemQuery = { __typename?: 'Query', systemFieldsForSystem: Array<{ __typename?: 'SystemField', field?: string | null, id: any, systemId: any }> };

export type CreateSystemFieldMutationVariables = Types.Exact<{
  input: Types.SystemFieldInput;
}>;


export type CreateSystemFieldMutation = { __typename?: 'Mutation', addSystemField: { __typename?: 'SystemFieldPayload', systemField: { __typename?: 'SystemField', field?: string | null, id: any, systemId: any } } };

export type UpdateSystemFieldMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.SystemFieldInput;
}>;


export type UpdateSystemFieldMutation = { __typename?: 'Mutation', updateSystemField: { __typename?: 'SystemFieldPayload', systemField: { __typename?: 'SystemField', field?: string | null, id: any, systemId: any } } };

export type DeleteSystemFieldMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteSystemFieldMutation = { __typename?: 'Mutation', deleteSystemField: any };


export const GetSystemFieldsForSystemDocument = `
    query GetSystemFieldsForSystem($systemId: UUID!) {
  systemFieldsForSystem(systemId: $systemId) {
    ...SystemFieldFragment
  }
}
    ${SystemFieldFragmentFragmentDoc}`;
export const CreateSystemFieldDocument = `
    mutation CreateSystemField($input: SystemFieldInput!) {
  addSystemField(input: $input) {
    systemField {
      ...SystemFieldFragment
    }
  }
}
    ${SystemFieldFragmentFragmentDoc}`;
export const UpdateSystemFieldDocument = `
    mutation UpdateSystemField($id: UUID!, $input: SystemFieldInput!) {
  updateSystemField(id: $id, input: $input) {
    systemField {
      ...SystemFieldFragment
    }
  }
}
    ${SystemFieldFragmentFragmentDoc}`;
export const DeleteSystemFieldDocument = `
    mutation DeleteSystemField($id: UUID!) {
  deleteSystemField(id: $id)
}
    `;

const injectedRtkApi = attributeSyncBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetSystemFieldsForSystem: build.query<GetSystemFieldsForSystemQuery, GetSystemFieldsForSystemQueryVariables>({
      query: (variables) => ({ document: GetSystemFieldsForSystemDocument, variables })
    }),
    CreateSystemField: build.mutation<CreateSystemFieldMutation, CreateSystemFieldMutationVariables>({
      query: (variables) => ({ document: CreateSystemFieldDocument, variables })
    }),
    UpdateSystemField: build.mutation<UpdateSystemFieldMutation, UpdateSystemFieldMutationVariables>({
      query: (variables) => ({ document: UpdateSystemFieldDocument, variables })
    }),
    DeleteSystemField: build.mutation<DeleteSystemFieldMutation, DeleteSystemFieldMutationVariables>({
      query: (variables) => ({ document: DeleteSystemFieldDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSystemFieldsForSystemQuery, useLazyGetSystemFieldsForSystemQuery, useCreateSystemFieldMutation, useUpdateSystemFieldMutation, useDeleteSystemFieldMutation } = injectedRtkApi;

