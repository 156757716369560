import styles from './draft.module.scss';
import { Card, CardContent, Typography } from '@mui/material';
import { format } from 'date-fns';
import { IntlButton, useCustomIntl } from '@sivis/intl';

interface DraftCardProps {
  draftData: {
    updatedAt: string;
    updatedBy: string;
  };
  onDiscardDraft: () => void;
}

const DraftCard = (props: DraftCardProps) => {
  const intl = useCustomIntl();

  return (
    <Card className={styles.draftCard}>
      <CardContent className={styles.draftCardContent}>
        <Typography variant="body1" component="div">
          {intl.format(
            'fusion.draft.draftCard.title',
            {
              user: props.draftData?.updatedBy ?? intl.format('fusion.draft.draftCard.updatedBy'),
              date: format(
                new Date(props.draftData?.updatedAt),
                'dd.MM.yyyy'
              ),
              time: format(
                new Date(props.draftData?.updatedAt),
                'HH:mm'
              )
            }
          )}
        </Typography>
        <IntlButton
          intlId="fusion.draft.draftCard.button"
          color="primary"
          variant="outlined"
          style={{ textTransform: 'uppercase' }}
          onClick={props.onDiscardDraft}
        />
      </CardContent>
    </Card>
  );
};

export default DraftCard;
