import {
  Account,
  AccountInput,
  accountInputFactory,
  useCreateAccountMutation,
  useDeleteAccountMutation,
  useGetAccountQuery,
  useUpdateAccountMutation
} from "@sivis/identity/api";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { newId } from "../common/newId";
import { EntityType, useSingleEditMode } from "../components/breadcrumb/editModeContext";

export const useAccountApi = () => {
  const { id } = useParams<{ id: string }>();
  const isNew = !id || id === newId; // Determine if this is a new account
  const navigate = useNavigate();

  const { data, error, isLoading, refetch } = useGetAccountQuery({ id: id ?? "" }, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });

  const [deleteAccount] = useDeleteAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [createAccount] = useCreateAccountMutation();
  const [input, setInput] = useState<AccountInput>(accountInputFactory());
  const [account, setAccount] = useState<Account | undefined>();
  const { editMode, toggleEditMode } = useSingleEditMode(EntityType.IDENTITY, isNew);

  useEffect(() => {
    setInput(accountInputFactory(data?.account));
    setAccount(data?.account ?? undefined);
  }, [data?.account]);

  const goBack = () => navigate("..");

  const onSave = useCallback((value: Record<string, any>) => {
    const accountInput = accountInputFactory(value);

    if (isNew) {
      createAccount({
        input: accountInput
      }).then(() => {
        goBack();
      });
    } else if (id) {
      updateAccount({ id: id, input: accountInput })
        .then(() => {
          toggleEditMode();
          refetch();
        });
    }
  }, [isNew, id, createAccount, updateAccount, goBack, toggleEditMode, refetch]);

  const onCancel = () => {
    toggleEditMode();
    goBack();
  };

  const onDelete = () => {
    if (id) {
      deleteAccount({ id: id })
        .then(goBack);
    }
  };

  return {
    id: id,
    account,
    defaultInput: input,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode
  };
};
