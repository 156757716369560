import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetOetRelationTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOetRelationTypesQuery = { __typename?: 'Query', oetRelationTypes?: Array<{ __typename?: 'OetRelationType', id?: string | null, type?: string | null } | null> | null };


export const GetOetRelationTypesDocument = `
    query GetOetRelationTypes {
  oetRelationTypes {
    id
    type
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetOetRelationTypes: build.query<GetOetRelationTypesQuery, GetOetRelationTypesQueryVariables | void>({
      query: (variables) => ({ document: GetOetRelationTypesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOetRelationTypesQuery, useLazyGetOetRelationTypesQuery } = injectedRtkApi;

