import {Outlet, RouteObject} from "react-router-dom";
import {AddressList} from "./addressList";
import {
  AddressInformationTabWrapperComponent,
  AddressOrganizationalEntitiesTabWrapperComponent,
  AddressView
} from "./addressView";
import {useGetAddressQuery} from "@sivis/identity/api";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import {
  INFORMATION_TAB_PATHNAME,
  ORGANIZATIONAL_ENTITIES_TAB_PATHNAME,
} from "../components/routes/constants";
import {EntityType} from "../components/breadcrumb/editModeContext";


const useGetAddressName = (id: string, isNew: boolean) => {
  const {data} = useGetAddressQuery({id}, {skip: isNew});
  return data?.address?.name;
}
export const addressRoutes: RouteObject[] = [
  {
    index: true,
    element: <AddressList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetAddressName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <AddressView/>,
      },
      {
        element: <AddressView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <AddressInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.ADDRESS
              })
            }
          },
          {
            path: ORGANIZATIONAL_ENTITIES_TAB_PATHNAME,
            element: <AddressOrganizationalEntitiesTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.organizationalEntity.list.name",
                intlIdEdit: "fusion.breadcrumb.organizationalEntities.edit",
                entity: EntityType.ADDRESS
              })
            }
          },
        ]
      }
    ]
  },
];
