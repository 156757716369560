import {
  AttributeRowConfig,
  AttributeRows,
  EntityDetailsProps,
  EntityForm,
  EntityFormHandle,
  FormButtons
} from "@sivis/shared/components/entityView";
import styles from "./popup.module.scss";
import {DialogActions} from "@mui/material";
import {useRef} from "react";
import {getRelationEntityCard, RelationEntity, RelationEntityType} from "./relationUtils";
import {PlaceholderCard, RelationInfoPanel} from "./relationInfoPanel";

interface RelationInformationTabProps<T extends Record<string, any>> extends Omit<EntityDetailsProps<T>, 'onSave'> {
  formName?: string;
  configs: AttributeRowConfig[];
  onSave: (value: T) => Promise<void>;
  leftEntity?: RelationEntity;
  leftEntityType: RelationEntityType;
  rightEntity?: RelationEntity;
  rightEntityType: RelationEntityType;
  relationText: string;
  checkValueType: (value: Record<string, any>) => value is T;
}

export const RelationInformationTab = <T extends Record<string, any>, >(props: RelationInformationTabProps<T>) => {
  const ref = useRef<EntityFormHandle>(null);
  const linkToRightEntity = (props.rightEntityType && props.rightEntity) ? `/${props.rightEntityType.toLowerCase()}/${props.rightEntity.id}/information` : "";
  const linkToLeftEntity = (props.leftEntityType && props.leftEntity) ? `/${props.leftEntityType.toLowerCase()}/${props.leftEntity.id}/information` : "";

  const onSubmit = (value: Record<string, any>) => {
    if (props.checkValueType(value)) {
      if (props.isNew && props.onEditNew) {
        props.onEditNew(value);
        props.onCancel?.();
      } else {
        props.onSave(value).then(props.onCancel);
      }
    }
  };

  const submitFormFromOutside = () => {
    ref.current?.submit();
  };

  const leftCard = props.leftEntity ? getRelationEntityCard(props.leftEntity, props.leftEntityType, linkToLeftEntity) :
    <PlaceholderCard/>;
  const rightCard = props.rightEntity ? getRelationEntityCard(props.rightEntity, props.rightEntityType, linkToRightEntity) :
    <PlaceholderCard/>;

  return <div className={styles.relationInformationTab}>
    <div className={styles.relationDetailsContainer}>
      <div>
        {props.formName && <div className={styles.formName}>{props.formName}</div>}
        {props.editMode ? <EntityForm
          configs={props.configs}
          value={props.entity}
          onSubmit={onSubmit}
          onCancel={props.onCancel}
          hideButtons
          ref={ref}
        /> : <AttributeRows
          configs={props.configs}
          value={props.entity}
        />}
      </div>
      <RelationInfoPanel
        leftCard={leftCard}
        rightCard={rightCard}
        arrowText={props.relationText}
      />
    </div>
    {props.editMode ? <DialogActions>
      <FormButtons onCancel={props.onCancel} onSave={submitFormFromOutside}
                   saveInsteadOfSubmit={true}/>
    </DialogActions> : null}
  </div>;
};
