import {Outlet, RouteObject} from "react-router-dom";
import {ResourceList} from "./resourceList";
import {ResourceInformationTabWrapperComponent, ResourceView} from "./resourceView";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import {useGetResourceQuery} from "@sivis/identity/api";
import {
  IDENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  ROLES_TAB_PATHNAME
} from "../components/routes/constants";
import ResourceIdentitiesTab from "./resourceIdentitiesTab";
import ResourceRolesTab from "./resourceRolesTab";
import {EntityType} from "../components/breadcrumb/editModeContext";
import {ResourceOwnershipTab} from "./resourceOwnershipTab";

const useGetResourceName = (id: string, isNew: boolean) => {
  const {data} = useGetResourceQuery({id}, {skip: isNew});
  return data?.resource?.name;
};

export const resourceRoutes: RouteObject[] = [
  {
    index: true,
    element: <ResourceList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetResourceName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <ResourceView/>,
      },
      {
        element: <ResourceView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <ResourceInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.RESOURCE
              })
            }
          },
          {
            path: IDENTITIES_TAB_PATHNAME,
            element: <ResourceIdentitiesTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.identity.list.name",
                intlIdEdit: "fusion.breadcrumb.identities.edit",
                entity: EntityType.RESOURCE
              })
            }
          },
          {
            path: ROLES_TAB_PATHNAME,
            element: <ResourceRolesTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.role.list.name",
                intlIdEdit: "fusion.breadcrumb.roles.edit",
                entity: EntityType.RESOURCE
              })
            }
          },
          {
            path: OWNERSHIP_TAB_PATHNAME,
            element: <ResourceOwnershipTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.ownership",
                intlIdEdit: "fusion.breadcrumb.ownership.edit",
                entity: EntityType.RESOURCE
              })
            }
          }
        ]
      }
    ]
  }
];

