import {Box, Tab, Tabs} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import styles from "./tabs.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {useDeepEffect} from "@sivis/shared/utils";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  alwaysMount?: boolean;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, alwaysMount, ...other} = props;
  const mountContent = value === index || alwaysMount;

  return <div
    style={{display: value === index ? "flex" : undefined, flex: "1", flexDirection: "column"}}
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {mountContent && children}
  </div>;
};

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    key: `tab-${index}`
  };
}

export interface Tab {
  label: string;
  content?: ReactNode;
  path?: string;
}

interface TabBarProps {
  tabs: Tab[];
  /**
   * withNavigation: content of tabs is rendered directly with react-router and not by TabBar
   */
  withNavigation?: boolean;
  /**
   * mountAllTabs: tabs are always mounted, not selected tabs are only hidden. Used when state of
   *  deselected tabs should be kept.
   */
  mountAllTabs?: boolean;
  fullWidth?: boolean;
}

const getLastPathSection = (path: string) => {
  const sections = path.split("/");
  return sections[sections.length - 1];
};

export const TabBar = ({tabs, withNavigation, mountAllTabs, fullWidth}: TabBarProps) => {
  const [value, setValue] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useDeepEffect(() => {
    if (withNavigation && initialized) {
      const path = tabs[value]?.path;
      if (path && getLastPathSection(location.pathname) !== path) {
        navigate(path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, tabs, initialized]);

  // Called on initializing, set tab from URL
  useEffect(() => {
    if (withNavigation && !initialized) {
      changeTabFromUrl();
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Called when URL is changed after initializing without unmounting the TabBar,
  // e.g. when clicking on a breadcrumb.
  useEffect(() => {
    if (withNavigation && initialized) {
      changeTabFromUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const changeTabFromUrl = () => {
    const currentTabPath = getLastPathSection(location.pathname);
    let currentIndex = tabs.findIndex(tab => tab.path === currentTabPath);
    // Go to first available tab if the URL cannot be matched to any tab
    if (currentIndex < 0) {
      currentIndex = 0;
    }
    setValue(currentIndex);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{root: styles.tabs}}
        variant={fullWidth ? "fullWidth" : "standard"}
      >
        {tabs.map((tab, index) => <Tab label={tab.label} {...a11yProps(index)} />)}
      </Tabs>
    </Box>
    {!withNavigation && tabs.map((tab, index) => <TabPanel
      value={value}
      index={index}
      alwaysMount={mountAllTabs}
      key={tab.label}>{tab.content}</TabPanel>)}
  </Box>;
};
