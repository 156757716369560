import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";
import {
  EOwnershipEntity,
  FilterExpression,
  Identity,
  OwnershipQueryType,
  Role,
} from "@sivis/identity/api";
import {useOwnerOwnershipsApi} from "./useOwnerOwnershipsApi";
import {isIdentity} from "../ownershipUtils";


// TODO: fix filtering
export const useOwnershipApiForOwner = (owner: Identity | Role, extraFilter?: FilterExpression) => {
  const ownerId = owner?.id;
  const ownerFilter = {
    name: "ownerId",
    operator: FilterOperator.EQ,
    uuidValue: ownerId
  };

  const filter = extraFilter ? combineFilter(FilterOperator.AND, extraFilter, ownerFilter) : ownerFilter;

  let cacheType = EOwnershipEntity.IDENTITY;
  let queryType = OwnershipQueryType.IDENTITY_OWNERSHIPS;
  if (owner != undefined) {
    cacheType = isIdentity(owner) ? EOwnershipEntity.IDENTITY : EOwnershipEntity.ROLE;
    queryType = isIdentity(owner) ? OwnershipQueryType.IDENTITY_OWNERSHIPS : OwnershipQueryType.ROLE_OWNERSHIPS;
  }

  return useOwnerOwnershipsApi(
    cacheType,
    filter,
    queryType,
    ownerId
  );
}
