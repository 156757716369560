import {EntityDetailsProps, TabBar} from "@sivis/shared/components/entityView";
import {useIdentityResourceEntitlementApi} from "./api/useIdentityResourceEntitlementApi";
import {Entitlement} from "@sivis/identity/api";
import {EntitlementInformationTab} from "./entitlementInformationTab";
import {RelationEntityType} from "../components/popup/relationUtils";
import {useCustomIntl} from "@sivis/intl";

export const IdentityResourceEntitlementDetails = (props: EntityDetailsProps<Entitlement>) => {
  const intl = useCustomIntl();
  const tabs = [
    {
      label: intl.format("fusion.breadcrumb.information"), content: <EntitlementInformationTab
        {...props}
        useEntitlementQuery={useIdentityResourceEntitlementApi}
        entitledType={RelationEntityType.IDENTITY}
        targetType={RelationEntityType.RESOURCE}
      />
    },
    {label: intl.format("fusion.system.list.name"), content: <>placeholder</>}
  ];

  return <TabBar tabs={tabs}/>;
};
