import {Link} from "react-router-dom";
import {System} from "@sivis/identity/api";
import {useSystemsPageApi} from "./useSystemsPageApi";
import {GridColDef} from '@mui/x-data-grid';
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: System) => {
  return {
    id: entity.id,
    systemName: entity.systemName,
    systemType: entity.systemType?.systemTypeName,
    systemDescription: entity.systemDescription,
  };
};

const columns: GridColDef[] = [
  {
    field: "systemName",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.systemName}</Link>
  },
  {
    field: "systemType",
    flex: 1,
  },
  {
    field: "systemDescription",
    flex: 1,
  }
];

export const SystemList = () => {
  const intl = useCustomIntl();
  const {onSearch, useSystemsPageQuery, parseSystemsPageResult, pageSize} = useSystemsPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.system.propertyName");

  return <EntityView
    title={intl.format("fusion.system.list.title")}
    actionButtons={[<CreateButton key={"create"}/>]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useSystemsPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseSystemsPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
