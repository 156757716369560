import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetOwnershipsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetOwnershipsQuery = { __typename?: 'Query', ownerships?: { __typename?: 'OwnershipConnection', edges: Array<{ __typename?: 'OwnershipEdge', node: { __typename?: 'Ownership', id: string, ownerId: string, targetId: string, type: string, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetOwnershipQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetOwnershipQuery = { __typename?: 'Query', ownership?: { __typename?: 'Ownership', id: string, ownerId: string, targetId: string, type: string, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null } | null };

export type GetOwnershipTypesQueryVariables = Types.Exact<{
  ownerId: Types.Scalars['ID'];
  targetId: Types.Scalars['ID'];
}>;


export type GetOwnershipTypesQuery = { __typename?: 'Query', ownershipTypes: Array<{ __typename?: 'FilterEntry', id: string, count: number } | null> };

export type GetOwnershipsValidityQueryVariables = Types.Exact<{
  ownerId: Types.Scalars['ID'];
  targetId: Types.Scalars['ID'];
}>;


export type GetOwnershipsValidityQuery = { __typename?: 'Query', ownershipsValidity: Array<{ __typename?: 'FilterEntry', id: string, count: number, text?: string | null, filter?: { __typename?: 'FilterExpressionOutput', operator: string, logicalValue?: Array<{ __typename?: 'FilterExpressionOutput', name?: string | null, operator: string, dateValue?: string | null } | null> | null } | null } | null> };

export type CreateOwnershipMutationVariables = Types.Exact<{
  ownerId: Types.Scalars['ID'];
  targetId: Types.Scalars['ID'];
  type: Types.Scalars['String'];
  input?: Types.InputMaybe<Types.OwnershipInput>;
}>;


export type CreateOwnershipMutation = { __typename?: 'Mutation', createOwnership?: { __typename?: 'Ownership', id: string, ownerId: string, targetId: string, type: string, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null } | null };

export type UpdateOwnershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.OwnershipInput>;
}>;


export type UpdateOwnershipMutation = { __typename?: 'Mutation', updateOwnership?: { __typename?: 'Ownership', id: string, ownerId: string, targetId: string, type: string, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null } | null };

export type DeleteOwnershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteOwnershipMutation = { __typename?: 'Mutation', deleteOwnership?: { __typename?: 'Ownership', id: string, ownerId: string, targetId: string } | null };


export const GetOwnershipsDocument = `
    query GetOwnerships($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  ownerships(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        ownerId
        targetId
        type
        validity {
          validFrom
          validTo
        }
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetOwnershipDocument = `
    query GetOwnership($id: ID!) {
  ownership(id: $id) {
    id
    ownerId
    targetId
    type
    validity {
      validFrom
      validTo
    }
  }
}
    `;
export const GetOwnershipTypesDocument = `
    query GetOwnershipTypes($ownerId: ID!, $targetId: ID!) {
  ownershipTypes(ownerId: $ownerId, targetId: $targetId) {
    id
    count
  }
}
    `;
export const GetOwnershipsValidityDocument = `
    query GetOwnershipsValidity($ownerId: ID!, $targetId: ID!) {
  ownershipsValidity(ownerId: $ownerId, targetId: $targetId) {
    id
    count
    text
    filter {
      operator
      logicalValue {
        name
        operator
        dateValue
      }
    }
  }
}
    `;
export const CreateOwnershipDocument = `
    mutation CreateOwnership($ownerId: ID!, $targetId: ID!, $type: String!, $input: OwnershipInput) {
  createOwnership(
    ownerId: $ownerId
    targetId: $targetId
    type: $type
    input: $input
  ) {
    id
    ownerId
    targetId
    type
    validity {
      validFrom
      validTo
    }
  }
}
    `;
export const UpdateOwnershipDocument = `
    mutation UpdateOwnership($id: ID!, $input: OwnershipInput) {
  updateOwnership(id: $id, input: $input) {
    id
    ownerId
    targetId
    type
    validity {
      validFrom
      validTo
    }
  }
}
    `;
export const DeleteOwnershipDocument = `
    mutation DeleteOwnership($id: ID!) {
  deleteOwnership(id: $id) {
    id
    ownerId
    targetId
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetOwnerships: build.query<GetOwnershipsQuery, GetOwnershipsQueryVariables | void>({
      query: (variables) => ({ document: GetOwnershipsDocument, variables })
    }),
    GetOwnership: build.query<GetOwnershipQuery, GetOwnershipQueryVariables>({
      query: (variables) => ({ document: GetOwnershipDocument, variables })
    }),
    GetOwnershipTypes: build.query<GetOwnershipTypesQuery, GetOwnershipTypesQueryVariables>({
      query: (variables) => ({ document: GetOwnershipTypesDocument, variables })
    }),
    GetOwnershipsValidity: build.query<GetOwnershipsValidityQuery, GetOwnershipsValidityQueryVariables>({
      query: (variables) => ({ document: GetOwnershipsValidityDocument, variables })
    }),
    CreateOwnership: build.mutation<CreateOwnershipMutation, CreateOwnershipMutationVariables>({
      query: (variables) => ({ document: CreateOwnershipDocument, variables })
    }),
    UpdateOwnership: build.mutation<UpdateOwnershipMutation, UpdateOwnershipMutationVariables>({
      query: (variables) => ({ document: UpdateOwnershipDocument, variables })
    }),
    DeleteOwnership: build.mutation<DeleteOwnershipMutation, DeleteOwnershipMutationVariables>({
      query: (variables) => ({ document: DeleteOwnershipDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOwnershipsQuery, useLazyGetOwnershipsQuery, useGetOwnershipQuery, useLazyGetOwnershipQuery, useGetOwnershipTypesQuery, useLazyGetOwnershipTypesQuery, useGetOwnershipsValidityQuery, useLazyGetOwnershipsValidityQuery, useCreateOwnershipMutation, useUpdateOwnershipMutation, useDeleteOwnershipMutation } = injectedRtkApi;

