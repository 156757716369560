import React from 'react';
import { Grid, TextField, Switch, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import styles from './fieldDefinition.module.scss';
import { ExtendedFieldDefinitionFields, BaseFieldDefinition } from './dragDropComponent';

interface PopupContentProps<T extends BaseFieldDefinition> {
  isAddingField: boolean;
  selectedFieldToAdd: string;
  setSelectedFieldToAdd: (value: string) => void;
  currentItem: T | null;
  handleChange: (field: keyof ExtendedFieldDefinitionFields, value: any) => void;
  invisibleFields: T[];
}

const PopupContent = <T extends BaseFieldDefinition>({
  isAddingField,
  selectedFieldToAdd,
  setSelectedFieldToAdd,
  currentItem,
  handleChange,
  invisibleFields,
}: PopupContentProps<T>) => {
  return (
    <Grid container spacing={2} className={styles.popupContent}>
      {isAddingField && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select Field</InputLabel>
            <Select
              value={selectedFieldToAdd}
              onChange={(e) => setSelectedFieldToAdd(e.target.value as string)}
              label="Select Field"
            >
              {invisibleFields.map((field) => (
                <MenuItem
                  key={field.id}
                  value={field.fieldDefinitionFields.name}
                >
                  {field.fieldDefinitionFields.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {(currentItem || (isAddingField && selectedFieldToAdd)) &&
        Object.keys(currentItem?.fieldDefinitionFields || {})
          .filter(
            (key) =>
              !['__typename', 'uiPosition', 'fieldName'].includes(key)
          )
          .map((key) => {
            const fieldKey = key as keyof ExtendedFieldDefinitionFields;
            const value = currentItem?.fieldDefinitionFields[fieldKey];
            const isBoolean = typeof value === 'boolean';
            return (
              <Grid item xs={12} sm={6} key={key}>
                {isBoolean ? (
                  <>
                    <label>{key}: </label>
                    <Switch
                      checked={value as boolean}
                      onChange={(e) =>
                        handleChange(fieldKey, e.target.checked)
                      }
                    />
                  </>
                ) : (
                  <TextField
                    label={key}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={value || ''}
                    onChange={(e) => handleChange(fieldKey, e.target.value)}
                  />
                )}
              </Grid>
            );
          })}
    </Grid>
  );
};

export default PopupContent;
