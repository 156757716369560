import { GridColDef } from "@mui/x-data-grid-pro";
import { useTranslateColumns, useCustomIntl } from "@sivis/intl";

export const attributeSynchronizationMappingColumns: GridColDef[] = [
    {
        field: "pointsharpField",
        flex: 1
    },
    {
        field: "asMethods",
        flex: 1
    },
    {
        field: "externalField",
        flex: 1
    },
    {
        field: "priority",
        flex: 1
    },
];

export const useHandleSystemNameInExternalField = (columns: GridColDef[], systemName: string) => {
    const intl = useCustomIntl();
    let translatedColumns = useTranslateColumns(columns, "fusion.attributesynchronizationmapping.propertyName");
    let externalFieldIndex = translatedColumns.findIndex(column => column.field === "externalField");
    translatedColumns[externalFieldIndex].headerName = intl.format("fusion.attributesynchronizationmapping.propertyName.externalField", {system: systemName});
    return translatedColumns;
};

export enum EAttributeSynchronizationMappingCacheType 
{
    SYSTEM = "SYSTEM"
}