import {generatedPermissionApi} from "./permission.generated";

export const permissionApi = generatedPermissionApi.enhanceEndpoints({
  addTagTypes: ['permission'],
  endpoints: {
    GetPermissions: {providesTags: ["permission"]},
    CreatePermission: {invalidatesTags: ["permission"]},
    UpdatePermission: {invalidatesTags: ["permission"]},
    DeletePermission: {invalidatesTags: ["permission"]},
  }
});

export const {
  useGetPermissionQuery,
  useLazyGetPermissionQuery,
  useGetPermissionsQuery,
  useLazyGetPermissionsQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation
} = permissionApi;
