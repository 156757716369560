import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Card } from "@mui/material";
import { Identity, identityName, Resource, ResourceSet, Role, System } from '@sivis/identity/api';
import { Link } from "react-router-dom";
import { SelectedAccountDetails } from "../../system/systemAccountsTab";
import styles from "./popup.module.scss";
import { RelationEntity } from "./relationUtils";

interface RelationInfoPanelProps {
  leftCard: JSX.Element;
  rightCard: JSX.Element;
  arrowText: string;
}

export const RelationInfoPanel = (props: RelationInfoPanelProps) => {
  return <div className={styles.infoPanelContainer}>
    {props.leftCard}
    <OwnershipArrow text={props.arrowText}/>
    {props.rightCard}
  </div>;
};

const OwnershipArrow = ({text}: { text: string }) => {
  return <div className={styles.ownershipArrowContainer}>
    <div>
      <div>{text}</div>
      <ArrowForwardIcon/>
    </div>
  </div>;
};

export interface EntityCardProps {
  icon: JSX.Element;
  title: string;
  fields: { name: string, description: string }[];
  dense?: boolean;
  linkTo?: string;
}

export const EntityCard = (props: EntityCardProps) => {
  return <Card
    variant="outlined"
    sx={{paddingY: props.dense ? undefined : "10px", paddingX: "10px"}}
    className={styles.entityCard}>
    <div className={styles.entityCardTitle}>
      {props.icon}
      {props.title}
      <div className={styles.entityCardTitleArrow}>
        {props.linkTo && (
          <Link to={props.linkTo}>
            <ArrowForwardIcon/>
          </Link>
        )}
      </div>
    </div>
    {props.fields.map(property => <div key={property.name} className={styles.entityCardProperty}>
      <div>{property.name}</div>
      <div>{property.description}</div>
    </div>)}
  </Card>;
};

export const InformationCard = ({text}: { text: string }) => {
  return <EntityCard
    // eslint-disable-next-line react/jsx-no-useless-fragment
    icon={<></>}
    title=""
    fields={[{name: "", description: text}]}
    dense
  />;
};

export const PlaceholderCard = () => {
  return <EntityCard
    // eslint-disable-next-line react/jsx-no-useless-fragment
    icon={<></>}
    title=""
    fields={[]}
  />;
};

export const IdentityCard = ({entity, linkTo}: CardPropsWithLink) => {
  const identity = entity as Identity;
  return <EntityCard
    icon={<PersonOutlineOutlinedIcon/>}
    title="Identity"
    fields={[
      {name: identityName(identity), description: identity.primaryEmail ?? ""}
    ]}
    linkTo={linkTo}
  />;
};

export const RoleCard = ({entity, linkTo}: CardPropsWithLink) => {
  const role = entity as Role;
  return <EntityCard
    icon={<GroupOutlinedIcon/>}
    title="Role"
    fields={[
      {name: role.name, description: role.description ?? ""}
    ]}
    linkTo={linkTo}
  />;
};

interface CardPropsWithLink {
  entity: RelationEntity;
  linkTo?: string;
}

export const ResourceCard = ({entity, linkTo}: CardPropsWithLink) => {
  const resource = entity as Resource;
  const resourceField = {name: resource.name, description: resource.description ?? ""};
  const fields = resource.system ? [
    resourceField,
    {
      name: resource.system.systemName,
      description: resource.system.systemType?.systemTypeName ?? ""
    }
  ] : [resourceField];
  return <EntityCard
    icon={<Inventory2OutlinedIcon/>}
    title="Resource"
    fields={fields}
    linkTo={linkTo}
  />;
};

export const ResourceSetCard = ({entity, linkTo}: CardPropsWithLink) => {
  const resourceSet = entity as ResourceSet;
  const resourceSetField = {name: resourceSet.name, description: resourceSet.description ?? ""};
  const fields = [resourceSetField];
  return <EntityCard
    icon={<Inventory2OutlinedIcon/>}
    title="Resource Set"
    fields={fields}
    linkTo={linkTo}
  />;
};

export const SystemCard = ({entity, linkTo}: CardPropsWithLink) => {
  const system = entity as System;
  const systemTypeName = system.systemType?.systemTypeName ?? system.systemName;
  const systemDescription = system.systemDescription ?? "";
  return <EntityCard
    icon={<Inventory2OutlinedIcon/>}
    title={system.systemName}
    fields={[
      {name: systemTypeName, description: systemDescription}
    ]}
    linkTo={linkTo}
  />;
}

// TODO: use actual account email
export const AccountCard = ({entity, linkTo}: CardPropsWithLink) => {
  const account = entity as SelectedAccountDetails;
  return <EntityCard
    icon={<PersonOutlineOutlinedIcon/>}
    title={account.name}
    fields={[
      {name: "Account", description: account.email},
      {name: account.accountTypeName, description: account.accountTypeDescription ?? ""}
    ]}
    linkTo={linkTo}
  />;
}


