import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from "@sivis/shared/api-utils";
import {api as generatedResourceApi} from './resource.generated';

export const resourceApi = generatedResourceApi.enhanceEndpoints({
  endpoints: {
    GetResources: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("resources"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs
    }
  }
})

export const {
  useGetResourceQuery,
  useLazyGetResourceQuery,
  useGetResourcesQuery,
  useLazyGetResourcesQuery,
  useGetResourceTypeFilterEntriesQuery
} = resourceApi;
