import {ELocales, toELocale} from './ELocales';

const INTL_LANGUAGE_STORAGE_NAME = 'evoLanguage';

export const storeLanguage = (locale: ELocales) => {
  localStorage.setItem(INTL_LANGUAGE_STORAGE_NAME, locale);
}

export const getStoredLanguage = (fallbackToDefault = false): ELocales | null => {
  return toELocale(localStorage.getItem(INTL_LANGUAGE_STORAGE_NAME) || undefined, fallbackToDefault);
}

