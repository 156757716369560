import {
  FilterExpression,
  GeneratedIdentityTypes,
  Identity,
  searchIdentitiesFilter,
  useGetIdentitiesQuery
} from '@sivis/identity/api';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../common/parsePageResult';
import { getExistingOrCombinedFilter, useListSearch } from '../utils/filterUtils';

export const useIdentitiesPageApi = (extraFilter?: FilterExpression) => {
  const { filter, onSearch } = useListSearch(searchIdentitiesFilter);
  let identitiesFilter = getExistingOrCombinedFilter(filter, extraFilter);

  const useIdentitiesPageQuery = (cursor: string | null) =>
    useGetIdentitiesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: identitiesFilter
    });

  const parseIdentitiesPageResult = (res?: GeneratedIdentityTypes.GetIdentitiesQuery): CursorBasedPageResult<Identity> => {
    return parseCursorBasedPageResult('identities', res);
  };

  return {
    onSearch,
    pageSize: DEFAULT_PAGE_SIZE,
    useIdentitiesPageQuery,
    parseIdentitiesPageResult
  };
};
