import * as Types from '../securityApi.types';

import {securityApi} from '@sivis/security/api';

/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

export type GetPermissionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPermissionQuery = {
  __typename?: 'Query',
  permission?: {
    __typename?: 'Permission',
    id: string,
    name: string,
    description?: string | null
  } | null
};

export type GetPermissionsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.PermissionInput>;
}>;


export type GetPermissionsQuery = {
  __typename?: 'Query',
  permissions?: Array<{
    __typename?: 'Permission',
    id: string,
    name: string,
    description?: string | null,
    meta?: {
      __typename?: 'Metadata',
      createdAt?: string | null,
      createdBy?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null
    } | null
  } | null> | null
};

export type CreatePermissionMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.PermissionInput>;
}>;


export type CreatePermissionMutation = {
  __typename?: 'Mutation',
  createPermission: {
    __typename?: 'Permission',
    id: string,
    name: string,
    description?: string | null
  }
};

export type UpdatePermissionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.PermissionInput>;
}>;


export type UpdatePermissionMutation = {
  __typename?: 'Mutation',
  updatePermission: {
    __typename?: 'Permission',
    id: string,
    name: string,
    description?: string | null
  }
};

export type DeletePermissionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeletePermissionMutation = {
  __typename?: 'Mutation',
  deletePermission: { __typename?: 'Permission', id: string, name: string }
};


export const GetPermissionDocument = `
    query GetPermission($id: ID!) {
  permission(id: $id) {
    id
    name
    description
  }
}
    `;
export const GetPermissionsDocument = `
    query GetPermissions($input: PermissionInput) {
  permissions(input: $input) {
    id
    name
    description
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const CreatePermissionDocument = `
    mutation CreatePermission($input: PermissionInput) {
  createPermission(input: $input) {
    id
    name
    description
  }
}
    `;
export const UpdatePermissionDocument = `
    mutation UpdatePermission($id: ID!, $input: PermissionInput) {
  updatePermission(id: $id, input: $input) {
    id
    name
    description
  }
}
    `;
export const DeletePermissionDocument = `
    mutation DeletePermission($id: ID!) {
  deletePermission(id: $id) {
    id
    name
  }
}
    `;

const injectedRtkApi = securityApi.injectEndpoints({
  endpoints: (build) => ({
    GetPermission: build.query<GetPermissionQuery, GetPermissionQueryVariables>({
      query: (variables) => ({document: GetPermissionDocument, variables})
    }),
    GetPermissions: build.query<GetPermissionsQuery, GetPermissionsQueryVariables | void>({
      query: (variables) => ({document: GetPermissionsDocument, variables})
    }),
    CreatePermission: build.mutation<CreatePermissionMutation, CreatePermissionMutationVariables | void>({
      query: (variables) => ({document: CreatePermissionDocument, variables})
    }),
    UpdatePermission: build.mutation<UpdatePermissionMutation, UpdatePermissionMutationVariables>({
      query: (variables) => ({document: UpdatePermissionDocument, variables})
    }),
    DeletePermission: build.mutation<DeletePermissionMutation, DeletePermissionMutationVariables>({
      query: (variables) => ({document: DeletePermissionDocument, variables})
    }),
  }),
});

export {injectedRtkApi as generatedPermissionApi};
export const {
  useGetPermissionQuery,
  useLazyGetPermissionQuery,
  useGetPermissionsQuery,
  useLazyGetPermissionsQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation
} = injectedRtkApi;

