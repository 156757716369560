import {PropsWithChildren} from "react";
import {SearchField, SearchFieldProps} from "../searchField/searchField";
import styles from "./table.module.scss";
import {useIntl} from "react-intl";

export interface ToolbarProps extends SearchFieldProps {
  totalEntries: number;
  buttons?: JSX.Element[];
}

export const Toolbar = (props: PropsWithChildren<ToolbarProps>) => {
  const intl = useIntl();
  const entriesText = intl.formatMessage({id: "fusion.table.entry"}, {entries: props.totalEntries});

  return <div className={styles.toolbar}>
    <div>
      <SearchField placeholder={props.placeholder} onSearch={props.onSearch}/>
      <div className={styles.toolbarEntryCount}>{`${props.totalEntries} ${entriesText}`}</div>
    </div>
    <div>{props.buttons}</div>
  </div>;
};
