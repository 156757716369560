import {
  EntitlementType,
  ExtendedRoleEntitlement,
  Role,
  searchRolesFilter,
  useCreateEntitlementMutation,
  useDeleteEntitlementMutation,
  useGetEntitlementsQuery,
  useGetRolesQuery
} from "@sivis/identity/api";
import {useListSearch} from "../../utils/filterUtils";
import {useEffect, useState} from "react";
import {FilterExpression} from "@sivis/shared/api-utils";
import {parseValidityInput} from "../../utils/validityUtils";

export const useRoleEntitlementsApi = (
  type: EntitlementType,
  entitlementsFilter: FilterExpression,
  targetId?: string
) => {
  const {data: dataEntitlements, refetch: refetchEntitlements} = useGetEntitlementsQuery(
    {filter: entitlementsFilter}, {refetchOnMountOrArgChange: true, skip: !targetId}
  );
  const {data: dataRoles} = useGetRolesQuery({}, {
    refetchOnMountOrArgChange: true,
    skip: !dataEntitlements?.entitlements
  });
  const {filter, onSearch} = useListSearch(searchRolesFilter);
  const {data: dataAvailableRoles} = useGetRolesQuery({filter}, {refetchOnMountOrArgChange: true});
  const [deleteEntitlement] = useDeleteEntitlementMutation();
  const [createEntitlement] = useCreateEntitlementMutation();
  const [entitlements, setEntitlements] = useState<ExtendedRoleEntitlement[]>([]);
  const [options, setOptions] = useState<Role[]>([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const extendedEntitlements: ExtendedRoleEntitlement[] = [];
    dataEntitlements?.entitlements?.edges?.forEach(edge => {
      const entitlement = edge?.node;
      const role = dataRoles?.roles?.edges?.find(edge => edge?.node?.id === entitlement?.entitledId)?.node;
      if (entitlement && role) {
        extendedEntitlements.push({
          ...entitlement,
          roleName: role.name
        });
      }
    });
    setEntitlements(extendedEntitlements);
  }, [dataEntitlements?.entitlements, dataRoles?.roles]);

  useEffect(() => {
    const newOptions: Role[] = [];
    dataAvailableRoles?.roles?.edges?.forEach(edge => {
      const role = edge?.node;
      if (role && !entitlements.some(entitlement => entitlement.entitledId === role.id)) {
        newOptions.push(role);
      }
    });
    setOptions(newOptions);
  }, [dataAvailableRoles?.roles, entitlements]);

  const onSave = (added: ExtendedRoleEntitlement[], deleted: ExtendedRoleEntitlement[]) => {
    if (!targetId) {
      return;
    }
    Promise.all([
      ...added.map(entitlement => createEntitlement({
        entitledId: entitlement.entitledId,
        targetId, type,
        input: {
          validity: parseValidityInput(entitlement.validity ?? undefined)
        }
      })),
      ...deleted.map(entitlement => deleteEntitlement({
        id: entitlement.id,
        type
      }))
    ]).then(() => {
      refetchEntitlements();
      setSubmitted(true);
    });
  }

  return {
    entitlements,
    onSave,
    onSearchForAvailableRoles: onSearch,
    availableRoles: options,
    submitted,
    setSubmitted
  }
}
