import React from 'react';
import {renderRegistryEntry} from './renderRegistryEntry';
import {useRegistry} from "./useRegistry";
import {UserRegistryOptionsType} from "./UseRegistryOptions";

export interface RegistryRendererProps<P = unknown> {
  props?: P
}

export const useRegistryRenderer = <P, >() => {
  const registry = useRegistry();

  return (options: UserRegistryOptionsType, props?: RegistryRendererProps<P>) => {
    const components = registry(options);
    return <> {components.map(entry => renderRegistryEntry(entry, props?.props))} </>

  }
}
