import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetRoleQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetRoleQuery = { __typename?: 'Query', role?: { __typename?: 'Role', id: string, name: string, description?: string | null, identitiesCount?: number | null } | null };

export type GetRolesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetRolesQuery = { __typename?: 'Query', roles?: { __typename?: 'RoleConnection', edges: Array<{ __typename?: 'RoleEdge', node: { __typename?: 'Role', id: string, name: string, description?: string | null, identitiesCount?: number | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetRolesWithIdentitiesCountQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetRolesWithIdentitiesCountQuery = { __typename?: 'Query', roles?: { __typename?: 'RoleConnection', edges: Array<{ __typename?: 'RoleEdge', node: { __typename?: 'Role', id: string, name: string, description?: string | null, identitiesCount?: number | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetRoleCreationDatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRoleCreationDatesQuery = { __typename?: 'Query', roleCreationDates: Array<{ __typename?: 'FilterEntry', id: string, count: number, text?: string | null, filter?: { __typename?: 'FilterExpressionOutput', operator: string, logicalValue?: Array<{ __typename?: 'FilterExpressionOutput', name?: string | null, operator: string, dateValue?: string | null } | null> | null } | null } | null> };

export type GetRoleUpdateDatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRoleUpdateDatesQuery = { __typename?: 'Query', roleUpdateDates: Array<{ __typename?: 'FilterEntry', id: string, count: number, text?: string | null, filter?: { __typename?: 'FilterExpressionOutput', operator: string, logicalValue?: Array<{ __typename?: 'FilterExpressionOutput', name?: string | null, operator: string, dateValue?: string | null } | null> | null } | null } | null> };

export type CreateRoleMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.RoleInput>;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', id: string, name: string, description?: string | null } };

export type UpdateRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.RoleInput>;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', id: string, name: string, description?: string | null } };

export type DeleteRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: { __typename?: 'Role', id: string, name: string, description?: string | null } };


export const GetRoleDocument = `
    query GetRole($id: ID!) {
  role(id: $id) {
    id
    name
    description
    identitiesCount
  }
}
    `;
export const GetRolesDocument = `
    query GetRoles($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  roles(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        name
        description
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        identitiesCount
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetRolesWithIdentitiesCountDocument = `
    query GetRolesWithIdentitiesCount($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  roles(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        name
        description
        identitiesCount
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetRoleCreationDatesDocument = `
    query GetRoleCreationDates {
  roleCreationDates {
    id
    count
    text
    filter {
      operator
      logicalValue {
        name
        operator
        dateValue
      }
    }
  }
}
    `;
export const GetRoleUpdateDatesDocument = `
    query GetRoleUpdateDates {
  roleUpdateDates {
    id
    count
    text
    filter {
      operator
      logicalValue {
        name
        operator
        dateValue
      }
    }
  }
}
    `;
export const CreateRoleDocument = `
    mutation CreateRole($input: RoleInput) {
  createRole(input: $input) {
    id
    name
    description
  }
}
    `;
export const UpdateRoleDocument = `
    mutation UpdateRole($id: ID!, $input: RoleInput) {
  updateRole(id: $id, input: $input) {
    id
    name
    description
  }
}
    `;
export const DeleteRoleDocument = `
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id) {
    id
    name
    description
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetRole: build.query<GetRoleQuery, GetRoleQueryVariables>({
      query: (variables) => ({ document: GetRoleDocument, variables })
    }),
    GetRoles: build.query<GetRolesQuery, GetRolesQueryVariables | void>({
      query: (variables) => ({ document: GetRolesDocument, variables })
    }),
    GetRolesWithIdentitiesCount: build.query<GetRolesWithIdentitiesCountQuery, GetRolesWithIdentitiesCountQueryVariables | void>({
      query: (variables) => ({ document: GetRolesWithIdentitiesCountDocument, variables })
    }),
    GetRoleCreationDates: build.query<GetRoleCreationDatesQuery, GetRoleCreationDatesQueryVariables | void>({
      query: (variables) => ({ document: GetRoleCreationDatesDocument, variables })
    }),
    GetRoleUpdateDates: build.query<GetRoleUpdateDatesQuery, GetRoleUpdateDatesQueryVariables | void>({
      query: (variables) => ({ document: GetRoleUpdateDatesDocument, variables })
    }),
    CreateRole: build.mutation<CreateRoleMutation, CreateRoleMutationVariables | void>({
      query: (variables) => ({ document: CreateRoleDocument, variables })
    }),
    UpdateRole: build.mutation<UpdateRoleMutation, UpdateRoleMutationVariables>({
      query: (variables) => ({ document: UpdateRoleDocument, variables })
    }),
    DeleteRole: build.mutation<DeleteRoleMutation, DeleteRoleMutationVariables>({
      query: (variables) => ({ document: DeleteRoleDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRoleQuery, useLazyGetRoleQuery, useGetRolesQuery, useLazyGetRolesQuery, useGetRolesWithIdentitiesCountQuery, useLazyGetRolesWithIdentitiesCountQuery, useGetRoleCreationDatesQuery, useLazyGetRoleCreationDatesQuery, useGetRoleUpdateDatesQuery, useLazyGetRoleUpdateDatesQuery, useCreateRoleMutation, useUpdateRoleMutation, useDeleteRoleMutation } = injectedRtkApi;

