import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetResourceQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetResourceQuery = { __typename?: 'Query', resource: { __typename?: 'Resource', id: string, name: string, description?: string | null, resourceId?: string | null, resourceType?: { __typename?: 'ResourceType', id: string, name: string } | null, system?: { __typename?: 'System', id: string, systemName: string } | null } };

export type GetResourcesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetResourcesQuery = { __typename?: 'Query', resources?: { __typename?: 'ResourceConnection', edges: Array<{ __typename?: 'ResourceEdge', node: { __typename?: 'Resource', id: string, name: string, description?: string | null, resourceId?: string | null, resourceType?: { __typename?: 'ResourceType', id: string, name: string } | null, system?: { __typename?: 'System', id: string, systemName: string } | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetResourceTypeFilterEntriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetResourceTypeFilterEntriesQuery = { __typename?: 'Query', resourceTypeFilterEntries: Array<{ __typename?: 'FilterEntry', id: string, count: number, text?: string | null } | null> };


export const GetResourceDocument = `
    query GetResource($id: ID!) {
  resource(id: $id) {
    id
    name
    description
    resourceId
    resourceType {
      id
      name
    }
    system {
      id
      systemName
    }
  }
}
    `;
export const GetResourcesDocument = `
    query GetResources($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  resources(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        name
        description
        resourceId
        resourceType {
          id
          name
        }
        system {
          id
          systemName
        }
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetResourceTypeFilterEntriesDocument = `
    query GetResourceTypeFilterEntries {
  resourceTypeFilterEntries {
    id
    count
    text
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetResource: build.query<GetResourceQuery, GetResourceQueryVariables>({
      query: (variables) => ({ document: GetResourceDocument, variables })
    }),
    GetResources: build.query<GetResourcesQuery, GetResourcesQueryVariables | void>({
      query: (variables) => ({ document: GetResourcesDocument, variables })
    }),
    GetResourceTypeFilterEntries: build.query<GetResourceTypeFilterEntriesQuery, GetResourceTypeFilterEntriesQueryVariables | void>({
      query: (variables) => ({ document: GetResourceTypeFilterEntriesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetResourceQuery, useLazyGetResourceQuery, useGetResourcesQuery, useLazyGetResourcesQuery, useGetResourceTypeFilterEntriesQuery, useLazyGetResourceTypeFilterEntriesQuery } = injectedRtkApi;

