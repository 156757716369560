import {EStyleProperties, IStyles} from "./themeTypes";

export const applyStylesToDom = (checkedStyles: IStyles): IStyles => {
  document.documentElement.style.setProperty(EStyleProperties.primaryColor, checkedStyles.primaryColor);
  document.documentElement.style.setProperty(EStyleProperties.secondaryColor, checkedStyles.secondaryColor);
  document.documentElement.style.setProperty(
    EStyleProperties.topnavBackground,
    checkedStyles.topnavBackground
  );
  document.documentElement.style.setProperty(
    EStyleProperties.sidenavBackground,
    checkedStyles.sidenavBackground
  );
  document.documentElement.style.setProperty(EStyleProperties.tabbarColor, checkedStyles.tabbarColor);
  document.documentElement.style.setProperty(
    EStyleProperties.primaryBackground,
    checkedStyles.primaryBackground
  );
  document.documentElement.style.setProperty(
    EStyleProperties.primaryTextColor,
    checkedStyles.primaryTextColor
  );
  document.documentElement.style.setProperty(
    EStyleProperties.secondaryTextColor,
    checkedStyles.secondaryTextColor
  );
  document.documentElement.style.setProperty(
    EStyleProperties.primaryIconColor,
    checkedStyles.primaryIconColor
  );
  document.documentElement.style.setProperty(
    EStyleProperties.secondaryIconColor,
    checkedStyles.secondaryIconColor
  );
  document.documentElement.style.setProperty(EStyleProperties.shadowColor, checkedStyles.shadowColor);

  return checkedStyles;
}

export const applyProjectTheme = (theme: string) => {
  document.documentElement.setAttribute('theme', theme);
}
