import { TabBar } from '@sivis/shared/components/entityView';
import React, { useState } from 'react';
import { useCustomIntl } from '@sivis/intl';
import styles from './Login.module.scss';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import { ELogoPlace, Logo } from '@sivis/shared/components/logo';
import LoginError, { LoginErrors } from './LoginError';
import { ClickableText } from './clickableText/ClickableText';
import { LoadingButton } from '@sivis/shared/components/loading-button';
import { EAuthProvider, SAPSystem } from '@sivis/shared/types';

interface LoginFormProps {
  authProvider: EAuthProvider;
  adminEnabled: boolean;
  onAdminLogin: (email: string, password: string) => void;
  onUserLogin: (email: string, password: string, authProvider: EAuthProvider) => void;
  resetPasswordLink: string;
  /**
   * Link for the GoTo button
   */
  goToLink: string;
  showResetPassword: boolean;
  linkToSetProductivePassword?: string;
  /**
   * Loading state of authentication
   */
  loading: boolean;
  /**
   * A secondary provider when SSO is the primary one
   */
  alternativeAuthProvider: EAuthProvider;
  /**
   * If the Spring backend is running
   */
  springConnected: boolean;
  /**
   * Connected SAP systems, used to show the system information on login
   */
  sapSystems: SAPSystem[];
  isPasswordInvalid: boolean;
}

export interface LoginTabsProps extends LoginFormProps {
  isMobile: boolean;
  locationIsLoginPage: boolean;
  onLogoClick: () => void;
  authProvider: EAuthProvider;
  errors: LoginErrors;
  onSamlLogin: (path: string) => void;
}

export const isSmallLoginView = (isMobile: boolean, locationIsLoginPage: boolean) => !locationIsLoginPage || isMobile;

export const LoginForm = (props: LoginTabsProps) => {
  const {isMobile, locationIsLoginPage, onLogoClick} = props;
  const intl = useCustomIntl();

  const showLogo = (isMobile && locationIsLoginPage) || (!isMobile && !locationIsLoginPage);
  const smallView = isSmallLoginView(isMobile, locationIsLoginPage);

  const userLogin = <div className={styles.loginForm}>
    {props.authProvider === EAuthProvider.SAML ?
      <SamlLoginForm {...props} showGoToButton={!smallView}/> :
      <StandardLoginForm {...props} isAdmin={false} showGoToButton={!smallView}/>}
  </div>;

  const adminLogin = <div className={styles.loginForm}>
    <StandardLoginForm {...props} isAdmin={true} showGoToButton={!smallView}/>
  </div>;

  // Temporary for fusion: only show SIVIS admin login
  const adminOnly = props.adminEnabled &&
    props.authProvider === EAuthProvider.ADMIN &&
    props.alternativeAuthProvider === EAuthProvider.NONE;

  const tabs = [
    {
      label: intl.formatMessageNative({
        id: "login.userLogin",
        defaultMessage: "User"
      }),
      content: userLogin
    },
    {
      label: intl.formatMessageNative({
        id: "login.adminLogin",
        defaultMessage: "Administrator"
      }),
      content: adminLogin
    }
  ];

  let loginTabs = <TabBar tabs={tabs} mountAllTabs fullWidth />;
  if (!props.adminEnabled) {
    loginTabs = userLogin;
  } else if (adminOnly) {
    loginTabs = adminLogin;
  }

  return <main className={styles.loginContainer}>
    {showLogo && (
      <div className={styles.mobileLogoContainer}>
        <Logo place={ELogoPlace.LOGIN} alt="SIVIS GmbH" onDoubleClick={onLogoClick}/>
      </div>
    )}
    <Typography
      style={{
        marginBottom: smallView ? "15px" : "20px",
        marginLeft: smallView ? "auto" : 0,
        marginRight: "auto",
        color: "var(--primaryTextColor)",
        fontSize: smallView ? "1.85rem" : undefined,
        marginTop: smallView ? "-15px" : undefined
      }}
      variant="h4"
      className={props.adminEnabled ? styles.loginHeaderWithTabs : styles.loginHeader}
    >
      <FormattedMessage id="login.login"/>
    </Typography>

    {loginTabs}
    <LoginError errors={props.errors}/>

    {smallView && <div className={styles.websiteButtonContainer}>
      <GoToButton smallView link={props.goToLink}/>
    </div>}
  </main>;
};

const GoToButton = ({smallView, link}: { smallView: boolean; link: string }) => {
  const onClick = () => window.open(link);

  return smallView ? <ClickableText
    textId={"login.goToWebsite"}
    onClick={onClick}
  /> : <Button
    onClick={onClick}
    variant="outlined"
  >
    <FormattedMessage id={"login.goToWebsite"}/>
  </Button>;
};

interface StandardLoginFormProps extends LoginFormProps {
  isAdmin: boolean;
  showGoToButton: boolean;
  isAlternativeLogin?: boolean;
}

const StandardLoginForm = (props: StandardLoginFormProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const showResetPassword = props.showResetPassword && !props.isAdmin;
  const onResetPassword = () => window.open(props.resetPasswordLink);

  const linkToSetProductivePassword = props.linkToSetProductivePassword ?? "";
  const onInvalidPassword = () => window.open(linkToSetProductivePassword);

  const intl = useCustomIntl();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (props.isAdmin) {
      props.onAdminLogin(email, password);
    } else {
      props.onUserLogin(email, password, props.authProvider);
    }
  };

  const loginButtonOnRightSide = props.showGoToButton && !props.isAlternativeLogin;
  const loginButtonStyles = loginButtonOnRightSide ? styles.loginButtonContainerRightSide : styles.loginButtonContainer;
  const loginTextStyles = props.adminEnabled ? styles.loginTextWithTabs : styles.loginText;

  return <div className={styles.standardLoginForm}>
    {getLoginText(props.authProvider, props.springConnected, props.sapSystems, props.isAdmin,
      props.isAlternativeLogin ?? false, loginTextStyles, intl.format)}
    <form onSubmit={handleSubmit}>
      <label>
        <b>
          <FormattedMessage id="login.username" defaultMessage="Name"/>
        </b>
        <input
          className={styles.inputField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
          type="text"
          placeholder={intl.formatMessageNative({
            id: "login.usernameField",
            defaultMessage: "Enter name"
          })}
          name="uname"
          required
          disabled={!props.springConnected}
        />
      </label>
      <label>
        <b>
          <FormattedMessage id="general.password" defaultMessage="Password"/>
        </b>
        <input
          className={styles.inputField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.currentTarget.value)
          }
          type="password"
          placeholder={intl.formatMessageNative({
            id: "login.passwordField",
            defaultMessage: "Enter password"
          })}
          name="psw"
          required
          disabled={!props.springConnected}
        />
      </label>
      <div>
        {showResetPassword && (
          <ClickableText
            textId={"login.forgotPassword"}
            onClick={onResetPassword}
          />
        )}
      </div>
      <div>
        {props.isPasswordInvalid && (
          <ClickableText
            textId={"login.goToSetValidPassword"}
            onClick={onInvalidPassword}
          />
        )}
      </div>
      <div className={loginButtonStyles}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!validateForm(email, password)}
          type="submit"
          loading={props.loading}
        >
          <FormattedMessage id="login.login" defaultMessage="Login"/>
        </LoadingButton>

        {props.showGoToButton && <GoToButton smallView={false} link={props.goToLink}/>}
      </div>
    </form>
  </div>;
};

interface SamlLoginFormProps extends LoginFormProps {
  showGoToButton: boolean;
  onSamlLogin: (path: string) => void;
}

const SamlLoginForm = (props: SamlLoginFormProps) => {
  const [samlAlternativeExpanded, setSamlAlternativeExpanded] = useState(false);
  const location = useLocation();

  /**
   * Get current relative path without discarding query parameters
   * @returns relative path with query parameters, e.g. "/inbox?groupBy=CHANGE_REQUEST"
   */
  const getLocationWithQuery = () => {
    // Query parameters should not be discarded, even when logging in via SSO.
    // Inbox $linkToTask$ Email field relies on query parameters to be present.
    const query = location.search;
    return location.pathname + (query ?? "");
  };

  const login = () => props.onSamlLogin(getLocationWithQuery());

  const hasAlternativeLogin = props.alternativeAuthProvider !== EAuthProvider.NONE;

  return <div className={styles.samlLoginForm}>
    <Button variant="outlined" color="primary" onClick={login}
            style={{height: "48px"}}>
      <FormattedMessage id={"login.sso.button"}/>
    </Button>
    {hasAlternativeLogin ? <Accordion
      expanded={samlAlternativeExpanded}
      onChange={() => setSamlAlternativeExpanded(!samlAlternativeExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        <span className={styles.samlLoginAlternativeText}>
          <FormattedMessage id={"login.sso.alternativeUser"}/>
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <StandardLoginForm
          {...props}
          authProvider={props.alternativeAuthProvider}
          isAdmin={false}
          isAlternativeLogin />
      </AccordionDetails>
    </Accordion> : null}
  </div>;
};

const validateForm = (email: string, password: string) => {
  return email.length && password.length;
};

const getLoginText = (authProvider: EAuthProvider, springConnected: boolean, systems: SAPSystem[],
                      isAdmin: boolean, isAlternativeLogin: boolean, className: string,
                      formatMsg: (id: string) => string) => {
  // no backend found -> login impossible
  if (!springConnected) {
    return <div className={className}>{formatMsg("login.noBackend")}</div>;
  }

  if (authProvider === EAuthProvider.NONE) {
    return <div className={className}>{formatMsg("login.deactivated")}</div>;
  }

  if (isAdmin) {
    return <div className={className}>{formatMsg("login.adminLoginHint")}</div>;
  }

  // sap auth: needs leading system!
  if (authProvider === EAuthProvider.SAP || authProvider === EAuthProvider.SAML) {
    const sys = systems.find(s => s.leadingSystem === true);
    if (!sys) {
      return <div className={className}>
        {formatMsg("login.sapLoginNoSystem")}
      </div>;
    }

    return <div className={isAlternativeLogin ? styles.loginTextDense : className}>
      <FormattedMessage id={"login.sapLogin"}/>
      <div className={styles.loginTable}>
        <div>
          <div>{formatMsg("login.system")}:</div>
          <div>{sys.name}</div>
        </div>
        <div>
          <div>{formatMsg("login.system.client")}:</div>
          <div>{sys.client}</div>
        </div>
      </div>
    </div>;
  }

  // ldap auth: use windows user
  if (authProvider === EAuthProvider.AD) {
    return <div className={className}>{formatMsg("login.ldap")}</div>;
  }

  return null;
};
