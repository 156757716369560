import { EntityTableRow, InfiniteScrollTableCursor, CursorBasedPageResult } from '@sivis/shared/components/entityView';
import { GridColDef } from "@mui/x-data-grid-pro";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import { GeneratedAccountTypes, useGetSystemsQuery } from '@sivis/identity/api';
import { useAccountsByIdentityPageApi } from '../account/useAccountsPageApi';
import React, { useCallback, useEffect, useMemo } from 'react';


interface AccountData {
  id: string;
  name: string;
  systemId: string;
  systemName?: string;
  accountType: string;
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "systemName",
    headerName: "System ID",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "accountType",
    headerName: "Account Type",
    minWidth: 150,
    flex: 1,
  }
];

interface AccountListByIdentityProps {
  identityId: string;
}

export const AccountListByIdentity = ({ identityId }: AccountListByIdentityProps) => {
  const intl = useCustomIntl();

  const {
    onSearch,
    pageSize,
    useAccountsByIdentityQuery,
    parseAccountsPageResult,
  } = useAccountsByIdentityPageApi({ identityId });

  const translatedColumns = useTranslateColumns(
    columns,
    "fusion.account.propertyName"
  );

  // Create a ref for the system data map to maintain a stable reference
  const systemDataMap = useMemo(() => new Map<string, string>(), []);

  // Fetch all systems data that we need
  const { data: systemsData } = useGetSystemsQuery();

  // Update the systemDataMap when systemsData changes
  useEffect(() => {
    if (systemsData?.systems?.edges) {
      systemDataMap.clear(); // Clear existing entries
      systemsData.systems.edges.forEach(edge => {
        if (edge?.node) {
          systemDataMap.set(edge.node.id, edge.node.systemName);
        }
      });
    }
  }, [systemsData, systemDataMap]);

  const handleParseResult = useCallback((data?: GeneratedAccountTypes.GetAccountsQuery): CursorBasedPageResult<AccountData> => {
    if (!data) {
      return { entries: [], endCursor: null };
    }

    const result = parseAccountsPageResult(data);

    if (!result?.entries || !Array.isArray(result.entries)) {
      return { entries: [], endCursor: null };
    }

    return {
      entries: result.entries.map(entry => ({
        id: entry.id,
        name: entry.name,
        systemId: entry.systemId,
        systemName: systemDataMap.get(entry.systemId) || entry.systemId,
        accountType: entry.accountType?.name || ''
      })),
      endCursor: result.endCursor
    };
  }, [systemDataMap, parseAccountsPageResult]);

  const renderRow = useCallback((data: AccountData): EntityTableRow => {
    return {
      id: data.id,
      name: data.name,
      systemName: data.systemName,
      accountType: data.accountType,
      entityId: data.id,
      dataObject: data,
      isNew: false,
      active: false,
    };
  }, []);

  if (!identityId) {
    return <div>{intl.format("fusion.account.error.noIdentityId")}</div>;
  }

  return (
    <InfiniteScrollTableCursor<AccountData, GeneratedAccountTypes.GetAccountsQuery>
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useAccountsByIdentityQuery}
      parseResult={handleParseResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
      renderRow={renderRow}
    />
  );
};
