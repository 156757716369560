import { api as generatedOeRelationApi } from './oeRelation.generated';

export const resetGetOeRelationsActionCreator = (): ReturnType<typeof generatedOeRelationApi.util.upsertQueryData> =>
  generatedOeRelationApi.util.upsertQueryData('GetOeRelations', {
    endpointName: generatedOeRelationApi.endpoints.GetOeRelations.name
  } as any, {
    oeRelations: {
      edges: [],
      pageInfo: { hasNextPage: false, hasPreviousPage: false }
    }
  });

