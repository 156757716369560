import {AttributeRowConfig} from "./attributeRowConfig";
import {AttributeRow} from "./attributeRow";
import styles from "./form.module.scss";
import { getSingleProperty, splitConfigsInTwoColumns } from './utils';
import _ from "lodash";

export interface AttributeRowsProps {
  value: Record<string, any>
  configs: AttributeRowConfig[]
  displayInTwoColumns?: boolean
  widerRows?: boolean
}

const renderRow = (config: AttributeRowConfig, value: Record<string, any>, renderRow?: (rowValue: any) => string | JSX.Element) => {
  const render = renderRow ?? ((rowValue: string) => rowValue);
  return Array.isArray(config.property) ?
    <div>
      {config.property.map((singleProperty, index) => (
          <div key={index}>{render(_.get(value, singleProperty.property))}</div>
      ))}
    </div> :
    render(value[config.property]);
};

export const AttributeRows = (props: AttributeRowsProps) => {
  const {value, configs} = props;
  const containerClass = props.widerRows ? styles.widerRows : "";

  const renderConfig = (config: AttributeRowConfig) => <AttributeRow
    key={getSingleProperty(config.property)}
    {...config}
    value={renderRow(config, value, config.renderRow)}
  />;

  const renderRows = props.displayInTwoColumns ? <div className={styles.twoColumnsContainer}>
    {splitConfigsInTwoColumns(configs).map((group, index) => <div key={index}>
      {group.map(renderConfig)}
    </div>)}</div> : configs.map(renderConfig);

  return (
    <div role="list" className={containerClass}>
      {renderRows}
    </div>
  )
}
