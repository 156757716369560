import {DeleteButton, EditButton, EntityView} from "@sivis/shared/components/entityView";
import {useOrganizationalEntityTypeApi} from "./api/useOrganizationalEntityTypeApi";
import OrganizationalEntityTypeInformationTab, {
  OrganizationalEntityTypeInformationTabProps
} from "./organizationalEntityTypeInformationTab";
import OrganizationalEntityTypeOrganizationalEntitiesTab, {
  OrganizationalEntityTypeOrganizationalEntitiesTabProps
} from "./organizationalEntityTypeOrganizationalEntitiesTab";
import {
  INFORMATION_TAB_PATHNAME,
  ORGANIZATIONAL_ENTITIES_TAB_PATHNAME,
  TYPE_RELATIONS_TAB_PATHNAME
} from "../components/routes/constants";
import {Outlet, useOutletContext} from "react-router-dom";
import {useCustomIntl} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";
import OrganizationalEntityTypeOetRelationsTab, {
  OrganizationalEntityTypeOetRelationsTabProps
} from "../organizationalEntityTypeRelations/organizationalEntityTypeOetRelationsTab";

export const OrganizationalEntityTypeView = () => {
  const intl = useCustomIntl();
  const {
    defaultInput,
    organizationalEntityType,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
  } = useOrganizationalEntityTypeApi();

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };

  const organizationalEntitiesTab = {
    label: intl.format("fusion.organizationalEntity.list.name"),
    path: ORGANIZATIONAL_ENTITIES_TAB_PATHNAME
  }

  const organizationalEntityTypeRelationsTab = {
    label: intl.format("fusion.organizationalEntityType.relations"),
    path: TYPE_RELATIONS_TAB_PATHNAME
  }

  const tabs = isNew ? [informationTab] : [
    informationTab, organizationalEntitiesTab,organizationalEntityTypeRelationsTab
  ];

  const titleExisting = `${intl.format("fusion.organizationalEntityType.information.titleExisting")}${organizationalEntityType?.name}`;
  const title = isNew ? intl.format("fusion.organizationalEntityType.information.titleNew") : titleExisting;
  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit"/>,
    <DeleteButton onClick={onDelete} key="delete"/>
  ];

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.organizationalEntityType.error.notFound", intl.format) : undefined}>
    <Outlet context={{
      editMode,
      organizationalEntityType,
      isNew,
      onSave,
      onCancel,
      onDelete,
      defaultInput
    }}/>
  </EntityView>;
};

export const OrganizationalEntityTypeInformationTabWrapperComponent = () => {
  const props = useOutletContext<OrganizationalEntityTypeInformationTabProps>();
  return <OrganizationalEntityTypeInformationTab {...props} />;
};
export const OrganizationalEntityTypeOrganizationalEntitiesTabWrapperComponent = () => {
  const {organizationalEntityType} = useOutletContext<OrganizationalEntityTypeOrganizationalEntitiesTabProps>();
  return <OrganizationalEntityTypeOrganizationalEntitiesTab
    organizationalEntityType={organizationalEntityType}/>;
};

export const OrganizationalEntityTypeRelationsTabWrapperComponent = () => {
  const {editMode,organizationalEntityType} = useOutletContext<OrganizationalEntityTypeOetRelationsTabProps>();
  return <OrganizationalEntityTypeOetRelationsTab organizationalEntityType={organizationalEntityType} editMode={editMode}/>;
};


