import {Tooltip} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import styles from "./flags.module.scss";

export interface FlagDefinition {
  text: string;
  icon: JSX.Element;
}

interface FlagsProps {
  flags: FlagDefinition[];
}

export const Flags = ({flags}: FlagsProps) => {
  const text = flags.length ? <div>
    {flags.map((flag, i) => <div key={"flag" + i}>{flag.text}</div>)}
  </div> : "";
  return <Tooltip title={text}>
    <div className={styles.iconsContainer}>{flags.map(flag => flag.icon)}</div>
  </Tooltip>;
};

export const validityIcon = <CalendarTodayIcon key="validity"/>;
