import { CursorBasedPageResult } from '@sivis/shared/components/entityView';

// TODO: remove pagination in old way
export interface PageData {
  currentPageNumber?: number;
  pageSize?: number;
  totalEntries?: number;
  totalPages?: number;
}

export interface PageQueryResponse<T> {
  paging?: {
    pageData?: PageData;
    entries?: T[] | null;
  };
}

export const DEFAULT_PAGE_SIZE = 50;

export const parseCursorBasedPageResult = <T>(key: string, res?: any): CursorBasedPageResult<T> => ({
  entries: res?.[key]?.edges.filter((edge: any) => edge?.node).map((edge: any) => edge.node) ?? [],
  endCursor: res?.[key]?.pageInfo.hasNextPage ? res?.[key]?.pageInfo.endCursor : null
});

export const DEFAULT_ENTITY_SORT = "updatedAt,desc";
