import {ErrorResponse} from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import {SerializedError} from "@reduxjs/toolkit";

export interface ErrorInformationExtensions {
  messageId: string;
  translated: boolean;
  classification: string;
}

export interface ErrorInformationLocation {
  line: number;
  column: number;
}

export interface ErrorInformation {
  message: string;
  locations: ErrorInformationLocation[];
  path: string[];
  extensions: ErrorInformationExtensions;
}

export const extractErrorInfoFromGraphQLError = (error: ErrorResponse | SerializedError): ErrorInformation | ErrorResponse => {

  //use negated char class instead of wildcard '.' to prevent excessive backtracking
  const jsonMatch = error.message?.match(/\{[^}]*}/);
  if (jsonMatch) {
    const errorData = JSON.parse(jsonMatch[0]);
    return errorData.response.errors[0];
  } else {
    return error as ErrorResponse;
  }
}

export function isErrorInformation(object: any): object is ErrorInformation {
  return ('message' in object && 'locations' in object && 'path' in object && 'extensions' in object)
}

export function getTranslatedErrorMessage(
  error: any,
  placeholder: string,
  formatFn: (id: string) => string): string {
  if (isErrorInformation(error) && error.extensions.translated) {
    return error.message;
  } else {
    return formatFn(placeholder);
  }
}
