import {
    Entitlement,
    EntitlementType,
    GeneratedEntitlementTypes,
    RelationQueryType,
    Resource,
    useCreateEntitlementMutation,
    useDeleteEntitlementMutation
} from '@sivis/identity/api';
import { CursorBasedPageResult, refetchTable } from '@sivis/shared/components/entityView';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_PAGE_SIZE, parseCursorBasedPageResult } from '../../common/parsePageResult';
import { parseValidityInput } from '../../utils/validityUtils';

export const parseEntitlementsPageResult = (res?: GeneratedEntitlementTypes.GetEntitlementsQuery): CursorBasedPageResult<Entitlement> => {
  return parseCursorBasedPageResult('entitlements', res);
};

export const useEntitlementsApi = (
    skip: boolean,
    type: EntitlementType,
    tableQueryType: RelationQueryType,
    resource?: Resource
) => {
  const [deleteEntitlement] = useDeleteEntitlementMutation();
  const [createEntitlement] = useCreateEntitlementMutation();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const onSave = (added: Entitlement[], deleted: Entitlement[]) => {
    if (skip) {
      return Promise.resolve();
    }
    return Promise.all([
      ...added.map(entitlement => createEntitlement({
        entitledId: entitlement.entitledId,
        targetId: entitlement.targetId,
        type,
        input: {
          validity: parseValidityInput(entitlement.validity ?? undefined),
          resourceTypeId: resource?.resourceType?.id ?? "",
          systemId: resource?.system?.id
        }
      })),
      ...deleted.map(entitlement => deleteEntitlement({
        id: entitlement.id,
        type
      }))
    ]).then(() => {
      setSubmitted(true);
      dispatch(refetchTable(tableQueryType)());
    });
  };

  return {
    onSave,
    pageSize: DEFAULT_PAGE_SIZE,
    parseEntitlementsPageResult,
    submitted,
    setSubmitted
  };
};
