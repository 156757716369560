import { FilterExpression, FilterOperator } from "@sivis/shared/api-utils";
import { defaults, omit } from "lodash";
import { System, SystemInput } from "../identityApi.types";

export const emptySystemInput = (): SystemInput => ({
  systemName: '',
  systemDescription: '',
  systemType: ''
});

export const systemInputFactory = (system?: System | SystemInput | null): SystemInput => {
  if (!system) {
    return emptySystemInput();
  }

  let systemWithStrippedSystemType = {...system};

  if (system.systemType && typeof system.systemType === 'object' && 'id' in system.systemType) {
    systemWithStrippedSystemType.systemType = system.systemType.id;
  }


  return defaults({}, omit(systemWithStrippedSystemType, ['id', 'meta', 'systemTypeName']), emptySystemInput());
}

export const searchSystemsFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "systemName", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "systemDescription", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "systemType.systemTypeName", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});
