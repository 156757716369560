import {AttributeRowConfig, AttributeRows} from "@sivis/shared/components/entityView";
import {getRelationEntityCard, RelationEntity, RelationEntityType} from "./relationUtils";
import {PlaceholderCard} from "./relationInfoPanel";
import connectionStyles from "./connectionPopup.module.scss";
import styles from "./popup.module.scss";
import {SelectedAccountDetails} from "../../system/systemAccountsTab";

interface ConnectionInformationTabProps {
  configs: AttributeRowConfig[];
  entity: RelationEntity;
  entityType: RelationEntityType;
}

export const ConnectionInformationTab = (props: ConnectionInformationTabProps) => {
  let linkTo: string;
  if (props.entityType === RelationEntityType.ACCOUNT) {
    const account = props.entity as SelectedAccountDetails;
    linkTo = `/identity/${account.identityId}/accounts/${props.entity.id}/information`;
  } else {
    linkTo = `/${props.entityType.toLowerCase()}/${props.entity.id}/information`;
  }
  const entityCard = props.entity ? getRelationEntityCard(props.entity, props.entityType, linkTo) :
    <PlaceholderCard/>;

  return <div className={styles.relationInformationTab}>
    <div className={connectionStyles.relationDetailsContainer}>
      <div>
        <AttributeRows
          configs={props.configs}
          value={props.entity}
        />
      </div>
      <div className={connectionStyles.infoPanelContainer}>
        {entityCard}
      </div>
    </div>
  </div>;
}

