import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import {autoBatchEnhancer, configureStore} from '@reduxjs/toolkit';
import {Action, AnyAction, CombinedState, Middleware, PreloadedState, Reducer} from 'redux';
import {EnhancedStore} from '@reduxjs/toolkit/src/configureStore';
import {Persistor} from 'redux-persist/lib/types';
import {resetStateProxy, sessionListener, startSessionListener} from './middlewares/session';
import {
  ImmutableStateInvariantMiddlewareOptions
} from '@reduxjs/toolkit/src/immutableStateInvariantMiddleware';
import {
  SerializableStateInvariantMiddlewareOptions
} from '@reduxjs/toolkit/src/serializableStateInvariantMiddleware';
import {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';

const createPersistConfig = (key: string, blacklist: string[]) => {
  return {
    key,
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist
  }
}

interface PersistPartial {
  _persist: {
    version: number;
    rehydrated: boolean;
  };
}

export interface AppConfigureStoreOptions<S = any, A extends Action = AnyAction> {
  rootReducer: Reducer<S, A>;
  persistKey?: string;
  blacklist?: string[];
  immutableCheck?: boolean | ImmutableStateInvariantMiddlewareOptions;
  serializableCheck?: boolean | SerializableStateInvariantMiddlewareOptions;
  middleware?: Middleware[];
  preloadedState?: PreloadedState<CombinedState<NoInfer<S>>>;
  autobatch?: boolean;

}


export const appConfigureRedux = <S = any,
  A extends Action = AnyAction,
>(options: AppConfigureStoreOptions<S, A>): {
  store: EnhancedStore<S, A>,
  persistor: Persistor
} => {

  const {
    rootReducer,
    persistKey = 'root',
    blacklist = ['auth'],
    serializableCheck = {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    },
    immutableCheck = true,
    middleware = [],
    preloadedState,
    autobatch = true
  } = options;

  const sessionDestroyAware = resetStateProxy(rootReducer)
  const persistedRootReducer = persistReducer<S, A>(
    createPersistConfig(persistKey, blacklist),
    sessionDestroyAware
  );

  const store = configureStore({
      reducer: persistedRootReducer,
      middleware: getDefaultMiddleware => getDefaultMiddleware({
        // https://redux-toolkit.js.org/api/getDefaultMiddleware
        serializableCheck,
        immutableCheck,
      }).prepend(sessionListener.middleware)
      .concat(...middleware),
      preloadedState: preloadedState as PreloadedState<CombinedState<NoInfer<S & PersistPartial>>>,
      enhancers: defaultEnhancers => {
        if (autobatch) {
          return defaultEnhancers.concat(autoBatchEnhancer()) as any;
        }

        return defaultEnhancers;
      }
    }
  );

  const persistor = persistStore(store);

  startSessionListener(persistor, preloadedState);

  return {
    store,
    persistor
  }
}
