import styles from "./LoginError.module.scss";
import React from "react";

export interface LoginErrors {
  authError: string;
  startupError?: string;
}

interface LoginErrorProps {
  errors: LoginErrors;
}

const LoginError = ({errors}: LoginErrorProps) => {
  const authErrorComponent = errors.authError ?
    <p className={styles.error}> {errors.authError} </p> : null
  const startupErrorComponent = errors.startupError ?
    <p className={styles.error}> {errors.startupError} </p> : null
  return (
    <div className={styles.errorContainer}>
      {authErrorComponent}
      {startupErrorComponent}
    </div>
  )
}
export default LoginError;
