import * as Types from '../attributeSyncApi.types';


            /* eslint-disable */
              /**
               *
               * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
               *
               * instead, edit one of the `.graphql` files in this project and run
               *
               * npm run graphql-codegen
               *
               * for this file to be re-created
               */
               
export type SystemFieldFragmentFragment = { __typename?: 'SystemField', field?: string | null, id: any, systemId: any };

export const SystemFieldFragmentFragmentDoc = `
    fragment SystemFieldFragment on SystemField {
  field
  id
  systemId
}
    `;
