import { DeleteButton, EditButton, EntityView } from "@sivis/shared/components/entityView";
import IdentityTypeInformationTab, {
  IdentityTypeInformationTabProps
} from "./identityTypeInformationTab";
import IdentityTypeIdentitysTab from "./identityTypeIdentitiesTab";
import { useIdentityTypeApi } from "./useIdentityTypeApi";
import { Outlet, useOutletContext } from "react-router-dom";
import { IdentityType, IdentityTypeInput } from "@sivis/identity/api";
import { FIELDDEFINITION_TAB_PATHNAME, INFORMATION_TAB_PATHNAME } from "../components/routes/constants";
import { useCustomIntl } from "@sivis/intl";
import { getTranslatedErrorMessage } from "@sivis/shared/api-utils";
import IdentityTypeFieldDefinitionTab from "./identityTypeFielddefinitionTab";

export const IdentityTypeView = () => {
  const intl = useCustomIntl();
  const {
    identityType,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
    defaultInput
  } = useIdentityTypeApi();

  const informationTab = { label: intl.format("fusion.breadcrumb.information"), path: INFORMATION_TAB_PATHNAME };
  const fieldDefinitionTab = {
    label: intl.format("fusion.breadcrumb.fieldDefinitions"),
    path: FIELDDEFINITION_TAB_PATHNAME
  };

  const tabs = isNew ? [informationTab] : [informationTab, fieldDefinitionTab];
  const titleExisting = `${intl.format("fusion.identityType.information.titleExisting")}${identityType?.name ?? ''}`;
  const title = isNew ? intl.format("fusion.identityType.information.titleNew") : titleExisting;

  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit" />,
    <DeleteButton disabled={true} onClick={onDelete} key="delete" />
  ];

  return (
    <EntityView
      title={title}
      tabs={tabs}
      actionButtons={buttons}
      error={error ? getTranslatedErrorMessage(error, "fusion.identityType.error.notFound", intl.format) : undefined}>
      <Outlet context={{ editMode, identityType, isNew, onSave, onCancel, onDelete, defaultInput }} />
    </EntityView>
  );
};

interface ContextType {
  identityTypeInput: IdentityTypeInput;
  identityType: IdentityType;
  editMode: boolean;
  onCancel: () => void;
  isNew: boolean;
  onSave: (input: IdentityTypeInput) => Promise<void>;
  onDelete: () => Promise<void>;
  defaultInput: IdentityTypeInput;
}

export const IdentityTypeInformationTabWrapperComponent = () => {
  const props = useOutletContext<IdentityTypeInformationTabProps>();
  return <IdentityTypeInformationTab {...props} />;
};

export const IdentityTypeIdentitysTabWrapperComponent = () => {
  const { identityType } = useOutletContext<ContextType>();
  return <IdentityTypeIdentitysTab identityType={identityType} />;
};

export const IdentityTypeFieldDefinitionTabWrapperComponent = () => {
  const { identityType, editMode, onCancel } = useOutletContext<ContextType>();
  return (
    <IdentityTypeFieldDefinitionTab
      identityType={identityType}
      editMode={editMode}
      onCancel={onCancel}
    />
  );
};
