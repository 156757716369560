import {OetRelationType, useGetOetRelationTypesQuery} from "@sivis/identity/api";
import {useEffect, useState} from "react";

export const useOetRelationTypeApi = () => {

  const {data} = useGetOetRelationTypesQuery();
  const [oetRelationTypes, setOetRelationTypes] = useState<OetRelationType[]>([]);

  useEffect(() => {

    if (data?.oetRelationTypes) {
      setOetRelationTypes(
        data.oetRelationTypes.filter((oetRelationType) =>
          oetRelationType !== null) as OetRelationType[]
      )

    }

  }, [data?.oetRelationTypes]);

  return {
    oetRelationTypes
  };
};

