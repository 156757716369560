import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetOrganizationalEntityTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetOrganizationalEntityTypeQuery = { __typename?: 'Query', organizationalEntityType?: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type GetOrganizationalEntityTypesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetOrganizationalEntityTypesQuery = { __typename?: 'Query', organizationalEntityTypes?: { __typename?: 'OrganizationalEntityTypeConnection', edges: Array<{ __typename?: 'OrganizationalEntityTypeEdge', node: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type CreateOrganizationalEntityTypeMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.OrganizationalEntityTypeInput>;
}>;


export type CreateOrganizationalEntityTypeMutation = { __typename?: 'Mutation', createOrganizationalEntityType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null } };

export type UpdateOrganizationalEntityTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.OrganizationalEntityTypeInput;
}>;


export type UpdateOrganizationalEntityTypeMutation = { __typename?: 'Mutation', updateOrganizationalEntityType?: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null } | null };

export type DeleteOrganizationalEntityTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteOrganizationalEntityTypeMutation = { __typename?: 'Mutation', deleteOrganizationalEntityType: { __typename?: 'OrganizationalEntityType', id: string, name: string } };

export type OrganizationalEntityQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type OrganizationalEntityQuery = { __typename?: 'Query', organizationalEntity?: { __typename?: 'OrganizationalEntity', id: string, name: string, alternativeName?: string | null, customID?: string | null, organizationalEntityType?: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null } | null, address?: { __typename?: 'Address', id: string, name: string, searchTerm?: string | null, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, defaultTimeZone?: string | null, defaultLanguage?: string | null, phone?: string | null, phoneExtension?: string | null, mobilePhone?: string | null, fax?: string | null, faxExtension?: string | null, email?: string | null, standardCommunicationMethod?: string | null, description?: string | null } | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type OrganizationalEntitiesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type OrganizationalEntitiesQuery = { __typename?: 'Query', organizationalEntities?: { __typename?: 'OrganizationalEntityConnection', edges: Array<{ __typename?: 'OrganizationalEntityEdge', node: { __typename?: 'OrganizationalEntity', id: string, name: string, alternativeName?: string | null, customID?: string | null, organizationalEntityType?: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null } | null, address?: { __typename?: 'Address', id: string, name: string, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, description?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type CreateOrganizationalEntityMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.OrganizationalEntityInput>;
}>;


export type CreateOrganizationalEntityMutation = { __typename?: 'Mutation', createOrganizationalEntity: { __typename?: 'OrganizationalEntity', id: string, name: string, alternativeName?: string | null, customID?: string | null, organizationalEntityType?: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null } | null, address?: { __typename?: 'Address', id: string, name: string, searchTerm?: string | null, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, defaultTimeZone?: string | null, defaultLanguage?: string | null, phone?: string | null, phoneExtension?: string | null, mobilePhone?: string | null, fax?: string | null, faxExtension?: string | null, email?: string | null, standardCommunicationMethod?: string | null, description?: string | null } | null } };

export type UpdateOrganizationalEntityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.OrganizationalEntityInput;
}>;


export type UpdateOrganizationalEntityMutation = { __typename?: 'Mutation', updateOrganizationalEntity?: { __typename?: 'OrganizationalEntity', id: string, name: string, alternativeName?: string | null, customID?: string | null, organizationalEntityType?: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null } | null, address?: { __typename?: 'Address', id: string, name: string, searchTerm?: string | null, address1?: string | null, address2?: string | null, address3?: string | null, zipcode?: string | null, city?: string | null, region?: string | null, state?: string | null, country?: string | null, defaultTimeZone?: string | null, defaultLanguage?: string | null, phone?: string | null, phoneExtension?: string | null, mobilePhone?: string | null, fax?: string | null, faxExtension?: string | null, email?: string | null, standardCommunicationMethod?: string | null, description?: string | null } | null } | null };

export type DeleteOrganizationalEntityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteOrganizationalEntityMutation = { __typename?: 'Mutation', deleteOrganizationalEntity: { __typename?: 'OrganizationalEntity', id: string, name: string } };


export const GetOrganizationalEntityTypeDocument = `
    query GetOrganizationalEntityType($id: ID!) {
  organizationalEntityType(id: $id) {
    id
    name
    apiName
    description
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const GetOrganizationalEntityTypesDocument = `
    query GetOrganizationalEntityTypes($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  organizationalEntityTypes(
    first: $first
    after: $after
    sort: $sort
    filter: $filter
  ) {
    edges {
      node {
        id
        name
        apiName
        description
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const CreateOrganizationalEntityTypeDocument = `
    mutation CreateOrganizationalEntityType($input: OrganizationalEntityTypeInput) {
  createOrganizationalEntityType(input: $input) {
    id
    name
    apiName
    description
  }
}
    `;
export const UpdateOrganizationalEntityTypeDocument = `
    mutation UpdateOrganizationalEntityType($id: ID!, $input: OrganizationalEntityTypeInput!) {
  updateOrganizationalEntityType(id: $id, input: $input) {
    id
    name
    apiName
    description
  }
}
    `;
export const DeleteOrganizationalEntityTypeDocument = `
    mutation DeleteOrganizationalEntityType($id: ID!) {
  deleteOrganizationalEntityType(id: $id) {
    id
    name
  }
}
    `;
export const OrganizationalEntityDocument = `
    query OrganizationalEntity($id: ID!) {
  organizationalEntity(id: $id) {
    id
    name
    alternativeName
    customID
    organizationalEntityType {
      id
      name
      apiName
      description
    }
    address {
      id
      name
      searchTerm
      address1
      address2
      address3
      zipcode
      city
      region
      state
      country
      defaultTimeZone
      defaultLanguage
      phone
      phoneExtension
      mobilePhone
      fax
      faxExtension
      email
      standardCommunicationMethod
      description
    }
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const OrganizationalEntitiesDocument = `
    query OrganizationalEntities($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  organizationalEntities(
    first: $first
    after: $after
    sort: $sort
    filter: $filter
  ) {
    edges {
      node {
        id
        name
        alternativeName
        customID
        organizationalEntityType {
          id
          name
          apiName
          description
        }
        address {
          id
          name
          address1
          address2
          address3
          zipcode
          city
          region
          state
          country
          description
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const CreateOrganizationalEntityDocument = `
    mutation CreateOrganizationalEntity($input: OrganizationalEntityInput) {
  createOrganizationalEntity(input: $input) {
    id
    name
    alternativeName
    customID
    organizationalEntityType {
      id
      name
      apiName
      description
    }
    address {
      id
      name
      searchTerm
      address1
      address2
      address3
      zipcode
      city
      region
      state
      country
      defaultTimeZone
      defaultLanguage
      phone
      phoneExtension
      mobilePhone
      fax
      faxExtension
      email
      standardCommunicationMethod
      description
    }
  }
}
    `;
export const UpdateOrganizationalEntityDocument = `
    mutation UpdateOrganizationalEntity($id: ID!, $input: OrganizationalEntityInput!) {
  updateOrganizationalEntity(id: $id, input: $input) {
    id
    name
    alternativeName
    customID
    organizationalEntityType {
      id
      name
      apiName
      description
    }
    address {
      id
      name
      searchTerm
      address1
      address2
      address3
      zipcode
      city
      region
      state
      country
      defaultTimeZone
      defaultLanguage
      phone
      phoneExtension
      mobilePhone
      fax
      faxExtension
      email
      standardCommunicationMethod
      description
    }
  }
}
    `;
export const DeleteOrganizationalEntityDocument = `
    mutation DeleteOrganizationalEntity($id: ID!) {
  deleteOrganizationalEntity(id: $id) {
    id
    name
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationalEntityType: build.query<GetOrganizationalEntityTypeQuery, GetOrganizationalEntityTypeQueryVariables>({
      query: (variables) => ({ document: GetOrganizationalEntityTypeDocument, variables })
    }),
    GetOrganizationalEntityTypes: build.query<GetOrganizationalEntityTypesQuery, GetOrganizationalEntityTypesQueryVariables | void>({
      query: (variables) => ({ document: GetOrganizationalEntityTypesDocument, variables })
    }),
    CreateOrganizationalEntityType: build.mutation<CreateOrganizationalEntityTypeMutation, CreateOrganizationalEntityTypeMutationVariables | void>({
      query: (variables) => ({ document: CreateOrganizationalEntityTypeDocument, variables })
    }),
    UpdateOrganizationalEntityType: build.mutation<UpdateOrganizationalEntityTypeMutation, UpdateOrganizationalEntityTypeMutationVariables>({
      query: (variables) => ({ document: UpdateOrganizationalEntityTypeDocument, variables })
    }),
    DeleteOrganizationalEntityType: build.mutation<DeleteOrganizationalEntityTypeMutation, DeleteOrganizationalEntityTypeMutationVariables>({
      query: (variables) => ({ document: DeleteOrganizationalEntityTypeDocument, variables })
    }),
    OrganizationalEntity: build.query<OrganizationalEntityQuery, OrganizationalEntityQueryVariables>({
      query: (variables) => ({ document: OrganizationalEntityDocument, variables })
    }),
    OrganizationalEntities: build.query<OrganizationalEntitiesQuery, OrganizationalEntitiesQueryVariables | void>({
      query: (variables) => ({ document: OrganizationalEntitiesDocument, variables })
    }),
    CreateOrganizationalEntity: build.mutation<CreateOrganizationalEntityMutation, CreateOrganizationalEntityMutationVariables | void>({
      query: (variables) => ({ document: CreateOrganizationalEntityDocument, variables })
    }),
    UpdateOrganizationalEntity: build.mutation<UpdateOrganizationalEntityMutation, UpdateOrganizationalEntityMutationVariables>({
      query: (variables) => ({ document: UpdateOrganizationalEntityDocument, variables })
    }),
    DeleteOrganizationalEntity: build.mutation<DeleteOrganizationalEntityMutation, DeleteOrganizationalEntityMutationVariables>({
      query: (variables) => ({ document: DeleteOrganizationalEntityDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganizationalEntityTypeQuery, useLazyGetOrganizationalEntityTypeQuery, useGetOrganizationalEntityTypesQuery, useLazyGetOrganizationalEntityTypesQuery, useCreateOrganizationalEntityTypeMutation, useUpdateOrganizationalEntityTypeMutation, useDeleteOrganizationalEntityTypeMutation, useOrganizationalEntityQuery, useLazyOrganizationalEntityQuery, useOrganizationalEntitiesQuery, useLazyOrganizationalEntitiesQuery, useCreateOrganizationalEntityMutation, useUpdateOrganizationalEntityMutation, useDeleteOrganizationalEntityMutation } = injectedRtkApi;

