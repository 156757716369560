import { Ownership, ResourceSet, Role, useGetResourceSetQuery, useGetRoleQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";
import { RelationEntity } from "../../components/popup/relationUtils";
import { useOwnershipApi } from "./useOwnershipApi";

export const useRoleResourceSetOwnershipApi = ({id, ownerId, targetId}: Ownership) => {
  const {data: dataOwner} = useGetRoleQuery({id: ownerId}, {refetchOnMountOrArgChange: true});
  const {data: dataTarget} = useGetResourceSetQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [owner, setOwner] = useState<Role>();
  const [target, setTarget] = useState<ResourceSet>();

  useEffect(() => {
    setOwner(dataOwner?.role ?? undefined);
    setTarget(dataTarget?.resourceSet ?? undefined);
  }, [dataOwner?.role, dataTarget?.resourceSet]);

  const {ownership, onSave} = useOwnershipApi(id);

  return {ownership, owner, target, onSave} as {
    ownership: Ownership | undefined;
    owner?: RelationEntity;
    target?: RelationEntity;
    onSave: (value: Ownership) => Promise<void>;
  }
}
