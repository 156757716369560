import { api as generatedAccountRoleMembershipApi } from './accountRoleMembership.generated';
import {
  executeAfterQueryFulfilled,
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import {
  RelationQueryType,
  resetGetAccountRoleMembershipsActionCreator,
  resetGetIdentityRoleMembershipsActionCreator
} from '../action';
import { refetchTable } from '@sivis/shared/components/entityView';

export const accountRoleMembershipApi = generatedAccountRoleMembershipApi.enhanceEndpoints({
  endpoints: {
    GetAccountRoleMemberships: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("accountRoleMemberships"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs
    },
    UpdateAccountRoleMembership: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetAccountRoleMembershipsActionCreator,
        resetGetIdentityRoleMembershipsActionCreator,
        refetchTable(RelationQueryType.IDENTITY_ROLE_MEMBERSHIPS)
      ])
    }
  }
});

export const { useGetAccountRoleMembershipsQuery, useLazyGetAccountRoleMembershipsQuery, useUpdateAccountRoleMembershipMutation } = accountRoleMembershipApi;
