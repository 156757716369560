import {createApi} from '@reduxjs/toolkit/query/react';
import {createBaseQueryWithErrorHandling} from "@sivis/shared/api-utils";


export const securityApi = createApi({
  baseQuery: createBaseQueryWithErrorHandling('/graphql/security'),
  reducerPath: 'security',
  refetchOnMountOrArgChange: 5,
  endpoints: () => ({}),
});
