import {
  IdentityRoleMembership,
  useGetIdentityRoleMembershipQuery,
  useUpdateIdentityRoleMembershipMutation
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {parseValidityInput} from "../../utils/validityUtils";

export const useIdentityRoleMembershipApi = (id: string) => {
  const {data} = useGetIdentityRoleMembershipQuery({
    id
  }, {refetchOnMountOrArgChange: true});
  const [updateMembership] = useUpdateIdentityRoleMembershipMutation();
  const [membership, setMembership] = useState<IdentityRoleMembership>();

  useEffect(() => {
    setMembership(data?.identityRoleMembership ?? undefined);
  }, [data?.identityRoleMembership]);

  const onSave = (value: IdentityRoleMembership) => {
    return updateMembership({
      id, input: {
        validity: parseValidityInput(value?.validity ?? undefined)
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .then(() => {
    });
  };

  return {
    membership,
    onSave
  }
};
