import {Autocomplete, TextField} from "@mui/material";
import _ from "lodash";
import {useCallback, useState} from "react";
import {HasId} from "@sivis/shared/utils";

interface AutocompleteFieldProps<T extends HasId> {
  options: T[];
  onSelect: (newOption: T | null) => void;
  onInput: (input: string) => void;
  renderOption: (option: T) => string;
  placeholder: string;
}

const INPUT_DEBOUNCE_MS = 400;

export const AutocompleteField = <T extends { id: string }, >(props: AutocompleteFieldProps<T>) => {
  const [value, setValue] = useState<T | null>(null);
  const [inputValue, setInputValue] = useState('');

  const debounceInput = useCallback(_.debounce(props.onInput, INPUT_DEBOUNCE_MS), [_.debounce]);

  return <Autocomplete
    options={props.options}
    renderOption={(renderProps, option) => (
      // ensure each option has unique key
      <li {...renderProps} key={option.id}>
        {props.renderOption(option)}
      </li>
    )}
    getOptionLabel={props.renderOption}
    value={value}
    isOptionEqualToValue={(option: T, value: T) => _.isEqual(option, value)}
    onChange={(_event: any, newValue: T | null) => {
      setValue(newValue);
      props.onSelect(newValue);
    }}
    inputValue={inputValue}
    onInputChange={(event, newInputValue) => {
      setInputValue(newInputValue);
      debounceInput(newInputValue);
    }}
    sx={{width: 300}}
    renderInput={(params) => <TextField {...params} size="small" placeholder={props.placeholder}/>}
  />;
};
