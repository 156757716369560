import { IntlButton, useCustomIntl } from '@sivis/intl';
import { Card, Typography } from '@mui/material';
import styles from './rule.module.scss';
import { Stack } from '@mui/system';
import { CriterionForm } from './criterionForm';
import { getOperatorTextBold, WatchValues } from './ruleUtils';
import { CriteriaGroup, RuleLogicalOperator } from '@sivis/identity/api';

interface CriteriaGroupProps {
  group: CriteriaGroup;
  index: number;
  operatorOutsideGroup: RuleLogicalOperator;
  onAddNewCriterion: (groupId: string) => void;
  onDeleteCriterion: (groupId: string, criterionId: string) => void;
  onDeleteCriteriaGroup: (groupId: string) => void;
  setWatchValues: (value: (((prevState: WatchValues) => WatchValues) | WatchValues)) => void;
  onSave: (value: Record<string, any>) => void;
}

export const CriteriaGroupCard = ({
                                    group,
                                    index,
                                    operatorOutsideGroup,
                                    onAddNewCriterion,
                                    onDeleteCriterion,
                                    onDeleteCriteriaGroup,
                                    setWatchValues,
                                    onSave
                                  }: CriteriaGroupProps) => {
  const intl = useCustomIntl();
  const criteriaGroupTitle = intl.format('fusion.rule.criteriaGroupWithIndex', { index: index + 1 });
  const operatorInsideGroup = operatorOutsideGroup === RuleLogicalOperator.And ? RuleLogicalOperator.Or : RuleLogicalOperator.And;

  return (
    <Card
      className={styles.ruleMargin}
      key={group.id}
      title={criteriaGroupTitle}
      variant={'outlined'}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography className={styles.criteriaGroupTitle}>
          {criteriaGroupTitle}
        </Typography>
        <IntlButton
          className={styles.deleteCriteriaGroupButton}
          intlId={'fusion.general.delete.uppercase'}
          onClick={() => onDeleteCriteriaGroup(group.id)}
          size={'small'}
        />
      </Stack>
      {group.criteriaInsideGroup.map((criterion, criterionIndex) => (
        <div key={criterion.id}>
          <CriterionForm
            key={criterion.id}
            criterion={criterion}
            groupId={group.id}
            onDeleteCriterion={onDeleteCriterion}
            onSave={onSave}
            setWatchValues={setWatchValues}
          />
          {criterionIndex < group.criteriaInsideGroup.length - 1 && (
            <div className={styles.ruleOperator}>
              {getOperatorTextBold(operatorInsideGroup, intl.formatBold)}
            </div>
          )}
        </div>
      ))}
      <IntlButton className={styles.ruleMargin}
                  variant="outlined"
                  intlId={'fusion.rule.addCriterion'}
                  onClick={() => {
                    onAddNewCriterion(group.id);
                  }}
      />
    </Card>
  );
};
