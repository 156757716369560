import {ELocales, initialGeneralMessages, IText, JSONToText} from '@sivis/intl';
import {fusion_messages_de, fusion_messages_en} from "@sivis/translation";
import {LoggingService, Unfold} from "@sivis/shared/utils";
import {initialLoginMessages} from "@sivis/shared/components/login";

// code duplication needed to keep hover functionality within message.ts
type MessageIdEnType = keyof typeof fusion_messages_en;
// TODO: review german translations before release
type MessageIdDeType = keyof typeof fusion_messages_de;

type MissingTranslationMessageIds = KeysOnlyDefinedInGermanJSON | KeysOnlyDefinedInEnglishJSON;

type MissingTranslationMessageIdsCheck = Exclude<MissingTranslationMessageIds, ""> extends never ? "" : Exclude<MissingTranslationMessageIds, "">;

type KeysOnlyDefinedInEnglishJSON = Exclude<MessageIdEnType, MessageIdDeType> extends never ? "" : Exclude<MessageIdEnType, MessageIdDeType>;
type KeysOnlyDefinedInGermanJSON = Exclude<MessageIdDeType, MessageIdEnType> extends never ? "" : Exclude<MessageIdDeType, MessageIdEnType>;

/**
 * MISSING TRANSLATIONS!
 * CHECK en.json AND de.json FILES!
 *
 * If you are getting a type error, hover over
 * @type {KeysOnlyDefinedInEnglishJSON} or
 * @type {KeysOnlyDefinedInGermanJSON}
 * - you will see ids of the missing translations.
 * Please translate the missing texts.
 */
const missingTranslationMessageIds: MissingTranslationMessageIdsCheck & Unfold = "";
missingTranslationMessageIds === "" ? LoggingService.info("en.json and de.json contain no missing translations", "message.ts") : LoggingService.error("Missing translations in en.json and/or de.json: " + missingTranslationMessageIds, "message.ts");

export const initialMessages = (): IText[] => {
  return [
    ...initialSharedMessages(),
    ...JSONToText(fusion_messages_de, ELocales.DE),
    ...JSONToText(fusion_messages_en, ELocales.EN),
  ];
}

const initialSharedMessages = () => ([
  ...initialGeneralMessages(),
  ...initialLoginMessages()
]);
