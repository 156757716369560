import React from "react";
import styles from "./Spinner.module.scss";

interface ThisProps {
  positionAbsolute?: boolean;
}

export const Spinner: any = (props: ThisProps) => {
  const position: any = props.positionAbsolute ? "PositionAbsolute" : "PositionSticky";

  return (
    <div className={[styles.LoadingSpinner, styles[position]].join(" ")}>
      <div className={styles.ldsRoller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
