import {createSelector} from '@reduxjs/toolkit';
import {isEqual} from 'lodash';
import {IStylesState} from './themeTypes';
import {createTheme} from '@mui/material';
import {selectLanguage} from "@sivis/intl";
import {deDE, enUS} from "@mui/material/locale";
import {enUS as dataGridEnUS} from "@mui/x-data-grid/locales/enUS";
import {deDE as dataGridDeDE} from "@mui/x-data-grid/locales/deDE";

const selectThemeSlice = createSelector(
  (state: { theme: IStylesState }) => state.theme,
  theme => theme
);
export const selectThemeOptions = createSelector(
  selectThemeSlice,
  theme => theme.themeOptions,
  {
    memoizeOptions: {
      equalityCheck: isEqual
    }
  }
);
export const selectThemeStyles = createSelector(
  selectThemeSlice,
  theme => theme.styles
);

export const selectThemePrimaryIconColor = createSelector(
  selectThemeStyles,
  styles => styles.primaryIconColor
);

export const selectThemeSecondaryIconColor = createSelector(
  selectThemeStyles,
  styles => styles.secondaryIconColor
);

const LOCALES_MAP: Record<string, any> = {
  en: [enUS, dataGridEnUS],
  de: [deDE, dataGridDeDE]
};

export const selectTheme = createSelector(
  [selectThemeOptions, selectLanguage],
  (themeOptions, language) =>
    createTheme(themeOptions, ...LOCALES_MAP[language] ? LOCALES_MAP[language] : LOCALES_MAP["en"])
);
