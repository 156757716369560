import React from 'react';
import styles from './Sidebar.module.scss';
import {useSelector} from 'react-redux';
import {selectSidebarClosed} from './sidebarSlice';

interface SidebarProps {
  children: React.ReactNode;
}

const Sidebar = (props: SidebarProps) => {
  const closed = useSelector(selectSidebarClosed);
  const sidebarClosedClass = closed ? styles.closed : "";

  return <div id={styles.evoSideNavbar} className={sidebarClosedClass} role="complementary">
    {props.children}
  </div>;
};

export default Sidebar;
