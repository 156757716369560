import { FilterOperator } from "@sivis/shared/api-utils";
import { defaults, omit } from "lodash";
import { FilterExpression, Identity, IdentityInput, IdentityType, IdentityTypeInput } from "../identityApi.types";


export const identityTypeFactory = (identityTypeInput?: IdentityTypeInput): IdentityType => {
  if (!identityTypeInput) {
    return {
      id: '', // Scalars['ID']
      name: '', // Scalars['String']
    };
  }

  return {
    id: identityTypeInput.apiName ? identityTypeInput.apiName : '', // Scalars['ID']
    name: identityTypeInput.apiName ? identityTypeInput.apiName : '', // Scalars['String']
    apiName: identityTypeInput.apiName ? identityTypeInput.apiName : undefined, // Maybe<Scalars['String']>
    description: undefined, // Maybe<Scalars['String']>
    __typename: 'IdentityType', // Optional
    Identities: undefined, // Maybe<Array<Maybe<Identity>>>
    meta: undefined, // Maybe<Metadata>
  };
}

const emptyIdentityTypeInput = (): IdentityTypeInput => ({
  name: '',
  description: '',
  apiName: ''
});

export const identityTypeInputFactory = (identityType?: IdentityType | IdentityTypeInput | null | Record<string, any>): IdentityTypeInput => {
  if (!identityType) {
    return emptyIdentityTypeInput();
  }

  // Use defaults to fill in any missing properties with values from emptyIdentityTypeInput
  return defaults({}, omit(identityType, ['id', 'Identities', 'meta']), emptyIdentityTypeInput());
}

const emptyIdentityInput = (): IdentityInput => ({
  firstName: '',
  lastName: '',
  initials: '',
  employeeId: '',
  primaryEmail: '',
  identityType: ''
});

export const identityInputFactory = (identity?: Identity | IdentityInput | null | Record<string, any>): IdentityInput => {
  if (!identity) {
    return emptyIdentityInput();
  }

  return defaults({}, omit(identity, ['id', 'roles', 'meta','accountsCount']), emptyIdentityInput());
}

export const identityName = (identity: Identity) => {
  return identity.firstName ? identity.firstName + " " + identity.lastName : identity.lastName;
};

export const searchIdentitiesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    { name: "firstName", operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: "lastName", operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: "initials", operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: "employeeId", operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: "primaryEmail", operator: FilterOperator.CONTAINS, stringValue: searchStr }
  ]
});

export const searchIdentityTypesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    { name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: "description", operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: "apiName", operator: FilterOperator.CONTAINS, stringValue: searchStr },
  ]
});
