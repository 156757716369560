import { AsDefinition } from '@sivis/attributesync/api';
import { IntlButton, useCustomIntl, useTranslateColumns } from '@sivis/intl';
import {
  CreateButtonWithIcon,
  InfiniteScrollTableCursor,
} from '@sivis/shared/components/entityView';
import { System } from '@sivis/identity/api';
import { useNavigate } from 'react-router-dom';
import { attributeSynchronizationDefinitionColumns } from '../attributeSynchronizationDefinition/api/attributeSynchronizationDefinitionUtils';
import { useAttributeSyncDefinitionPageApi } from '../attributeSynchronizationDefinition/api/useAttributeSyncDefinitionPageApi';
import styles from '../../../../../libs/shared/components/entityView/src/lib/components/form/form.module.scss';

interface SystemAttributeSyncTabProps {
  system: System | undefined;
  editMode: boolean;
  onCancel: () => void;
}

export const SystemAttributeSynchronizationDefinitionTab = ({
  system,
  editMode,
  onCancel,
}: SystemAttributeSyncTabProps) => {
  const intl = useCustomIntl();
  const navigate = useNavigate();
  const props = { systemId: system?.id };
  const {
    onSearch,
    pageSize,
    useAttributeSyncDefinitionsForSystemPageQuery,
    parseAttributeSyncDefinitionsPageResult,
  } = useAttributeSyncDefinitionPageApi(props);

  const translatedColumns = useTranslateColumns(
    attributeSynchronizationDefinitionColumns,
    'fusion.attributesynchronizationdefinition.propertyName'
  );

  const onRowClick = (id: string) => navigate(id);
  const onCreate = () => navigate('new');

  const createButton = (
    <CreateButtonWithIcon
      intlId="fusion.attributesynchronizationdefinition.list.create"
      key="create"
      onClick={onCreate}
    />
  );

  const entityToTableRow = (entity: AsDefinition) => {
    return {
      id: entity.id,
      name: entity.name,
      priority: entity.priority,
      defined: entity.asMappings?.length,
      affected: 'Over 9000',
      description: entity.description,
      publishedId: entity.publishedId,
    };
  };

  return (
    <>
      <InfiniteScrollTableCursor
        columns={translatedColumns}
        pageSize={pageSize}
        usePageQuery={useAttributeSyncDefinitionsForSystemPageQuery}
        renderRow={entityToTableRow}
        parseResult={parseAttributeSyncDefinitionsPageResult}
        onSearch={onSearch}
        onRowClick={onRowClick}
        toolbarButtons={editMode ? [createButton] : []}
        placeholder={intl.format('fusion.general.searchPlaceholder')}
      ></InfiniteScrollTableCursor>
      {editMode && (
        <div className={styles.entityFooter}>
          <IntlButton
            variant="text"
            intlId="general.cancel"
            onClick={onCancel}
          />
        </div>
      )}
    </>
  );
};
