import { ErrorInformation, extractErrorInfoFromGraphQLError } from './errorUtils';
import { FilterExpression } from './generatedTypes';
import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { DocumentNode } from 'graphql/language';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { selectLanguage } from '@sivis/intl';

export enum FilterOperator {
  OR = "OR",
  AND = "AND",
  CONTAINS = "CONTAINS",
  EQ = 'EQ',
  ISNULL = 'ISNULL'
}

export type LogicalOperator = FilterOperator.OR | FilterOperator.AND;

export const combineFilter = (operator: LogicalOperator, ...filters: FilterExpression[]): FilterExpression => {
  return {
    operator,
    logicalValue: filters
  }
};

export function createBaseQueryWithErrorHandling(url: string) {
  const baseQuery = graphqlRequestBaseQuery({
    url,
    prepareHeaders: (headers, {getState}) => {
      const language = selectLanguage(getState() as any);
      headers.set('Accept-Language', language);
      return headers;
    }
  });

  return async (args: {
    document: string | DocumentNode;
    variables?: any;
  }, api: BaseQueryApi, extraOptions = {}) => {
    const result = await baseQuery(args, api, extraOptions);
    if ('error' in result && result.error) {
      const errorInfo = extractErrorInfoFromGraphQLError(result.error);
      return {...result, error: errorInfo as ErrorInformation | ErrorResponse};
    }
    return result;
  };
}
