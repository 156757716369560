import {
  Entitlement,
  useGetEntitlementQuery,
  useUpdateEntitlementMutation
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {parseValidityInput} from "../../utils/validityUtils";

export const useEntitlementApi = (
  id: string
) => {
  const {data} = useGetEntitlementQuery({id}, {refetchOnMountOrArgChange: true});
  const [updateEntitlement] = useUpdateEntitlementMutation();
  const [entitlement, setEntitlement] = useState<Entitlement>();

  useEffect(() => {
    setEntitlement(data?.entitlement ?? undefined);
  }, [data?.entitlement]);

  const onSave = (value: Entitlement) => {
    return updateEntitlement({
      id: value.id,
      input: {
        validity: parseValidityInput(value.validity ?? undefined)
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).then(() => {
    });
  };

  return {
    entitlement,
    onSave
  }
};
