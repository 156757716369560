import React, {FunctionComponent} from 'react';
import {HasIntlId} from './HasIntlId';
import {useSelector} from 'react-redux';
import {selectMessageTextById} from '../intlSelectors';
import {Button, ButtonProps} from "@mui/material";


interface IconProps {
  icon?: JSX.Element;
}

export const IntlButton: FunctionComponent<HasIntlId & ButtonProps & IconProps> =
  ({intlId, icon, ...props}) => {
    const text = useSelector(state => selectMessageTextById(state, intlId));

    return (
      <Button {...props}>
        {icon} {/* icon rendering */}
        {text}
      </Button>
    );
  };
