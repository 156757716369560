import {
  FilterEntry,
  FilterExpression,
  OwnershipQueryType,
  useGetOwnershipsValidityQuery,
  useGetOwnershipTypesQuery
} from '@sivis/identity/api';
import { placeholderFilter } from '../../utils/filterUtils';
import { useEffect, useState } from 'react';
import { useCustomIntl } from '@sivis/intl';
import { useDispatch } from 'react-redux';
import { resetTable, SideFilterNew } from '@sivis/shared/components/entityView';
import { FilterOperator } from '@sivis/shared/api-utils';

const buildSourceFilterGroup = (filters: FilterEntry[], title: string, getOwnershipTypeFilterText: (text: string) => string) => ({
  id: "ownershipType",
  title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: getOwnershipTypeFilterText(entry.id),
    count: entry.count,
    filter: {
      name: "type",
      operator: FilterOperator.EQ,
      stringValue: entry.id
    }
  }))
});

const buildValidityFilterGroup = (filters: FilterEntry[], title: string, translate: (id: string) => string) => ({
  id: "validity",
  title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: translate("fusion.general." + entry.id),
    count: entry.count,
    filter: entry.filter ?? placeholderFilter
  }))
});

export const compareFilterEntries = (a: FilterEntry, b: FilterEntry) => b.count - a.count;

export const useOwnershipsSideFilter = (queryType: OwnershipQueryType, getOwnershipTypeFilterText: (text: string) => string, ownerId?: string, targetId?: string) => {
  const [filter, setFilter] = useState<FilterExpression | null>(null);
  const [ownershipTypeFilters, setOwnershipTypeFilters] = useState<FilterEntry[]>([]);
  const [validityFilters, setValidityFilters] = useState<FilterEntry[]>([]);
  const intl = useCustomIntl();

  const {data: dataOwnershipTypes} = useGetOwnershipTypesQuery({
    ownerId: ownerId ?? "", targetId: targetId ?? ""
  }, {skip: !ownerId || !targetId});

  const {data: dataValidity} = useGetOwnershipsValidityQuery({
    ownerId: ownerId ?? "", targetId: targetId ?? ""
  }, {skip: !ownerId || !targetId});

  useEffect(() => {
    const newOwnershipTypesFilter = (dataOwnershipTypes?.ownershipTypes?.filter(entry => entry != null) ?? []) as FilterEntry[];
    newOwnershipTypesFilter.sort(compareFilterEntries);
    setOwnershipTypeFilters(newOwnershipTypesFilter);

    const newValidityFilter = (dataValidity?.ownershipsValidity?.filter(entry => entry !== null) ?? []) as FilterEntry[];
    setValidityFilters(newValidityFilter);
  }, [dataOwnershipTypes?.ownershipTypes, dataValidity?.ownershipsValidity]);

  const dispatch = useDispatch();
  const onSetFilter = (newFilter: FilterExpression | null) => {
    dispatch(resetTable(queryType)());
    setFilter(newFilter);
  }

  const ownershipsFilter = <SideFilterNew
    title={(intl.format("fusion.general.filter")).toUpperCase()}
    onFilterChange={onSetFilter}
    filterGroups={[
      buildSourceFilterGroup(
        ownershipTypeFilters,
        intl.format("fusion.ownership.source"),
        getOwnershipTypeFilterText
      ),
      buildValidityFilterGroup(
        validityFilters,
        intl.format("fusion.ownership.expiration"),
        intl.format
      )
    ]}
  />;

  return {
    activeFilter: filter,
    filterComponent: ownershipsFilter
  }
}
