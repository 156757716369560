import {Link} from "react-router-dom";
import {IdentityType} from "@sivis/identity/api";
import {useIdentityTypesPageApi} from "./useIdentityTypePageApi";
import {GridColDef} from '@mui/x-data-grid';
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: IdentityType) => {
  return {
    id: entity.id,
    name: entity.name,
    description: entity.description,
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "description",
    flex: 1,
  },
];



export const IdentityTypeList = () => {
  const intl = useCustomIntl();
  const { onSearch, pageSize, useIdentityTypesPageQuery, parseIdentityTypesPageResult } = useIdentityTypesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.identityType.propertyName");

  const { refetch } = useIdentityTypesPageQuery(null);

  return <EntityView
    title={intl.format("fusion.identityType.list.title")}
    actionButtons={[<CreateButton key={"create"} />]}
  >
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useIdentityTypesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseIdentityTypesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
