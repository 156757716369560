import { api as generatedIdentityRoleMembershipApi } from './identityRoleMembership.generated';
import {
  executeAfterQueryFulfilled,
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import {
  RelationQueryType,
  resetGetAccountRoleMembershipsActionCreator,
  resetGetIdentityRoleMembershipsActionCreator
} from '../action';
import { refetchTable } from '@sivis/shared/components/entityView';

export const identityRoleMembershipApi = generatedIdentityRoleMembershipApi.enhanceEndpoints({
  endpoints: {
    GetIdentityRoleMemberships: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("identityRoleMemberships"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
    },
    CreateIdentityRoleMembership: {
      onQueryStarted: executeAfterQueryFulfilled([
        // Clear cache after the query is finished
        resetGetIdentityRoleMembershipsActionCreator,
        // Trigger refetching table data (only necessary when the table is currently
        // mounted - CreateIdentityRoleMembership is executed from a popup)
        refetchTable(RelationQueryType.IDENTITY_ROLE_MEMBERSHIPS)
      ])
    },
    UpdateIdentityRoleMembership: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetAccountRoleMembershipsActionCreator,
        resetGetIdentityRoleMembershipsActionCreator,
        refetchTable(RelationQueryType.IDENTITY_ROLE_MEMBERSHIPS)
      ])
    },
    DeleteIdentityRoleMembership: {
      onQueryStarted: executeAfterQueryFulfilled([
        resetGetIdentityRoleMembershipsActionCreator,
        refetchTable(RelationQueryType.IDENTITY_ROLE_MEMBERSHIPS)
      ])
    }
  }
});

export const {
  useGetIdentityRoleMembershipQuery,
  useLazyGetIdentityRoleMembershipQuery,
  useGetIdentityRoleMembershipsQuery,
  useLazyGetIdentityRoleMembershipsQuery,
  useCreateIdentityRoleMembershipMutation,
  useUpdateIdentityRoleMembershipMutation,
  useDeleteIdentityRoleMembershipMutation
} = identityRoleMembershipApi;
