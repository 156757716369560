import { Link } from "react-router-dom";
import {
  CreateButton,
  EntityView,
  InfiniteScrollTableCursor
} from "@sivis/shared/components/entityView";
import { Account } from "@sivis/identity/api";
import { useAccountsPageApi } from "./useAccountsPageApi";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";

const entityToTableRow = ({ id, name }: Account) => ({ id, name });

const columns: GridColDef[] = [{
  field: "name",
  minWidth: 200,
  flex: 1,
  renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
}];

export const AccountList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    pageSize,
    useAccountsPageQuery,
    parseAccountsPageResult
  } = useAccountsPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.account.propertyName");

  return <EntityView
    title={intl.format("fusion.account.list.title")}
    actionButtons={[<CreateButton key={"create"} />]}>
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useAccountsPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseAccountsPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
