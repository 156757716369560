import { Entitlement, EntitlementType, Identity, Resource, SideFilterQueryType } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import { ListAddView, TabBar } from "@sivis/shared/components/entityView";
import { useResourcesSideFilter } from "../ownership/components/useResourcesSideFilter";
import { useResourcePageApi } from "../resource/useResourcePageApi";
import styles from "./entitlement.module.scss";
import { resourceEntitlementColumns, resourceEntityToTableRow } from "./entitlementUtils";

interface CreateResourceEntitlementForIdentityProps {
  identity: Identity;
  onAdd: (added: Entitlement[]) => void;
}

export const CreateResourceEntitlementForIdentity = (props: CreateResourceEntitlementForIdentityProps) => {
  const intl = useCustomIntl();

  const tabs = [
    {
      label: intl.format("fusion.resource.list.name"),
      content: <CreateEntitlementResourceList
          {...props}
          entitledId={props.identity.id}
          type={EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT}/>
    }
  ];

  return <TabBar tabs={tabs}/>;
};

interface CreateResourceEntitlementProps {
  entitledId: string;
  type: EntitlementType;
  onAdd: (added: Entitlement[]) => void;
}

// TODO: how to filter out existing entitlements?
export const CreateEntitlementResourceList = (props: CreateResourceEntitlementProps) => {
  const {activeFilter, filterComponent} = useResourcesSideFilter(SideFilterQueryType.RESOURCES);
  const {
    onSearch,
    useResourcePageQuery,
    parseResourcePageResult,
    pageSize
  } = useResourcePageApi(activeFilter ?? undefined);
  const translatedColumns = useTranslateColumns(resourceEntitlementColumns, "fusion.entitlement.resourceEntitlement.propertyName");

  const onAdd = (resources: Resource[]) => {
    props.onAdd(resources.map(resource => ({
      id: props.entitledId + resource.id,
      entitledId: props.entitledId,
      targetId: resource.id,
      type: props.type,
      resourceName: resource.name,
      resourceTypeName: resource.resourceType?.name ?? ""
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
        columns={translatedColumns}
        entityToTableRow={resourceEntityToTableRow}
        onChange={onAdd}
        onSearch={onSearch}
        pageSize={pageSize}
        usePageQuery={useResourcePageQuery}
        parseResult={parseResourcePageResult}
        sideFilter={filterComponent}
        excludedItems={[]}
        tableType={SideFilterQueryType.RESOURCES}
    />
  </div>;
};
