import {
  EntitlementType,
  ExtendedResourceEntitlement,
  GeneratedResourceTypes,
  RelationQueryType,
  resourceApi,
  useCreateEntitlementMutation,
  useDeleteEntitlementMutation,
  useGetResourceEntitlementsQuery
} from '@sivis/identity/api';
import { combineFilter, FilterExpression, FilterOperator } from '@sivis/shared/api-utils';
import { refetchTable } from '@sivis/shared/components/entityView';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE } from '../../common/parsePageResult';
import { parseValidityInput } from '../../utils/validityUtils';
import { parseEntitlementsPageResult } from './useEntitlementsApi';

export const useResourceEntitlementsApi = (
  type: EntitlementType,
  entitlementsFilter: FilterExpression,
  queryType: RelationQueryType,
  entitledId?: string
) => {
  const [deleteEntitlement] = useDeleteEntitlementMutation();
  const [createEntitlement] = useCreateEntitlementMutation();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const useEntitlementsPageQuery = (cursor: string | null) =>
    useGetResourceEntitlementsQuery(
      {
        first: DEFAULT_PAGE_SIZE,
        after: cursor,
        sort: DEFAULT_ENTITY_SORT,
        filter: entitlementsFilter,
        cacheKey: type
      },
      {skip: !entitledId}
    );

  const onAdd = async (added: ExtendedResourceEntitlement[]) => {
    const resourceFilter = added.length ? combineFilter(FilterOperator.OR, ...added.map(addedEntitlement => ({
      name: "id",
      operator: FilterOperator.EQ,
      uuidValue: addedEntitlement.targetId
    }))) : undefined;
    // TODO: type thunk action correctly
    const promise: any = dispatch(resourceApi.endpoints.GetResources.initiate({filter: resourceFilter}));
    const {data: dataResources} = await promise;
    promise.unsubscribe();

    const relatedResources: GeneratedResourceTypes.GetResourcesQuery = dataResources;
    const createPromises = added.filter(entitlement => relatedResources?.resources?.edges?.some(edge => edge?.node?.id === entitlement.targetId)).map(entitlement => {
      const resource = relatedResources.resources?.edges?.find(edge => edge?.node?.id === entitlement.targetId)?.node;
      return createEntitlement({
        entitledId: entitledId ?? "",
        targetId: entitlement.targetId,
        type,
        input: {
          validity: parseValidityInput(entitlement.validity ?? undefined),
          resourceTypeId: resource?.resourceType?.id ?? "",
          systemId: resource?.system?.id ?? ""
        }
      })
    });

    return Promise.all(createPromises);
  };

  const onSave = (added: ExtendedResourceEntitlement[], deleted: ExtendedResourceEntitlement[]) => {
    if (!entitledId) {
      return Promise.resolve();
    }
    return Promise.all([
      onAdd(added),
      ...deleted.map(entitlement => deleteEntitlement({
        id: entitlement.id,
        type
      }))
    ]).then(() => {
      setSubmitted(true);
      dispatch(refetchTable(queryType)());
    });
  };

  return {
    onSave,
    pageSize: DEFAULT_PAGE_SIZE,
    useEntitlementsPageQuery,
    parseEntitlementsPageResult,
    submitted,
    setSubmitted
  };
};
