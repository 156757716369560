import React from "react";
import {Button, ButtonProps, CircularProgress} from "@mui/material";
import styles from "./loadingButton.module.scss";

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

export const LoadingButton = (props: LoadingButtonProps) => {
  const {loading, ...otherProps} = props;

  return <div className={styles.loadingButtonContainer}>
    <Button {...otherProps} disabled={props.disabled || loading}/>
    {props.loading && <CircularProgress size={24} className={styles.buttonProgress}/>}
  </div>;
};
