import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetSystemTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetSystemTypeQuery = { __typename?: 'Query', systemType?: { __typename?: 'SystemType', id: string, systemTypeName: string, systemTypeDescription?: string | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type GetSystemTypesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetSystemTypesQuery = { __typename?: 'Query', systemTypes?: { __typename?: 'SystemTypeConnection', edges: Array<{ __typename?: 'SystemTypeEdge', node: { __typename?: 'SystemType', id: string, systemTypeName: string, systemTypeDescription?: string | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };


export const GetSystemTypeDocument = `
    query GetSystemType($id: ID!) {
  systemType(id: $id) {
    id
    systemTypeName
    systemTypeDescription
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const GetSystemTypesDocument = `
    query GetSystemTypes($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  systemTypes(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        systemTypeName
        systemTypeDescription
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetSystemType: build.query<GetSystemTypeQuery, GetSystemTypeQueryVariables>({
      query: (variables) => ({ document: GetSystemTypeDocument, variables })
    }),
    GetSystemTypes: build.query<GetSystemTypesQuery, GetSystemTypesQueryVariables | void>({
      query: (variables) => ({ document: GetSystemTypesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSystemTypeQuery, useLazyGetSystemTypeQuery, useGetSystemTypesQuery, useLazyGetSystemTypesQuery } = injectedRtkApi;

