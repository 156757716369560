import {Registry, RegistryEntry} from '@sivis/shared/components/registry';

export const prepareRegisterComponent = <ID extends string = string, P = unknown>(entry: RegistryEntry<ID, P>): CallableFunction => {
  return () => {
    Registry.add(entry);
    return entry;
  }
}

export type PreparedRegistration = CallableFunction
