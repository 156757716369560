
import React from 'react';
import { TextField } from '@mui/material';
import styles from './fieldDefinition.module.scss';
import { BaseFieldDefinition } from './dragDropComponent';


interface FieldItemProps<T extends BaseFieldDefinition> {
  item: T;
  editMode: boolean;
  selectedItemId: string | null;
  grabbedItemId: string | null;
  setSelectedItemId: (id: string | null) => void;
  handleOpenPopup: (item: T) => void;
  handleMakeInvisible: (fieldName: string) => void;
  setSortedItems: React.Dispatch<React.SetStateAction<T[]>>;
}

const FieldItem = <T extends BaseFieldDefinition>({
  item,
  editMode,
  selectedItemId,
  grabbedItemId,
  setSelectedItemId,
  handleOpenPopup,
  handleMakeInvisible,
  setSortedItems
}: FieldItemProps<T>) => {

  const handleClick = (e: React.MouseEvent) => {
    if (!editMode) return;

    // Don't update selection if we're dragging
    const isDragging = e.currentTarget.closest('.sortable-drag');
    if (!isDragging) {
      setSelectedItemId(item.id);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!editMode) return;

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // Only prevent default, don't stop propagation
      setSelectedItemId(item.id);
    }
  };

  const handleMoreClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Keep this to prevent selection when clicking "more"
    if (editMode) {
      handleOpenPopup(item);
    }
  };

  const handleRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Keep this to prevent selection when clicking remove
    if (editMode) {
      handleMakeInvisible(item.fieldDefinitionFields.name);
    }
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSortedItems((prevItems) =>
      prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? {
            ...prevItem,
            fieldDefinitionFields: {
              ...prevItem.fieldDefinitionFields,
              name: newValue,
            },
          }
          : prevItem
      )
    );
  };

  return (
    <div
      key={item.id}
      className={`${styles.draggableItem} ${
        editMode && selectedItemId === item.id ? styles.selectedItem : ''
      } ${grabbedItemId === item.id ? styles.grabbedItem : ''} ${
        !editMode ? styles.nonInteractive : 'dragHandle'
      }`}
      data-item-id={item.id}
      onClick={editMode ? handleClick : undefined}
      onKeyDown={editMode ? handleKeyDown : undefined}
      tabIndex={editMode ? 0 : -1}
      role={editMode ? 'button' : undefined}
      aria-pressed={selectedItemId === item.id && editMode}
    >
      <div className={`${styles.draggableItemContent}`}>
        <div className={styles.draggableItemHeader}>
          {editMode && (
            <div
              className={`${styles.dragHandle}`}
              data-handle="true"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                style={{ color: '#666' }}
              >
                <path
                  d="M12 2L12 22M12 2L8 6M12 2L16 6M12 22L8 18M12 22L16 18M2 12L22 12M2 12L6 8M2 12L6 16M22 12L18 8M22 12L18 16" />
              </svg>
            </div>
          )}
          <div className={styles.draggableItemText}>
            <p
              className={`${styles.textSm} ${styles.fontMedium} ${styles.textGray700}`}
            >
              {item.fieldDefinitionFields.name}
            </p>
          </div>
          <div className={styles.draggableItemButtons}>
            <button
              type="button"
              disabled={!editMode}
              onClick={handleMoreClick}
              className={`${styles.textXs} ${styles.fontMedium} ${styles.textGray700} ${styles.moreButton}`}
            >
              more
            </button>
            <button
              type="button"
              disabled={!editMode}
              onClick={handleRemoveClick}
              className={styles.closeButton}
              title="Remove field"
            >
              ×
            </button>
          </div>
        </div>
        <div className={styles.draggableItemInputContainer}>
          <TextField
            disabled={!editMode}
            size="small"
            value={item.fieldDefinitionFields.name}
            onChange={handleTextFieldChange}
            onClick={(e) => e.stopPropagation()} // Keep this to prevent selection when clicking the TextField
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                width: '100%',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FieldItem;
