import { ThemeOptions } from '@mui/material';
import {
  applyProjectTheme,
  getCurrentDomStyles,
  initialPointsharpThemeOptions,
  IStylesState
} from '@sivis/shared/theme';

export const initializeFusionTheme = (): IStylesState => {
  applyProjectTheme("fusion");

  const styles = getCurrentDomStyles();
  const defaultOptions = initialPointsharpThemeOptions();

  const themeOptions: ThemeOptions = {
    ...defaultOptions,
    palette: {
      ...defaultOptions.palette,
      primary: {
        main: styles.primaryColor
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            color: undefined
          }
        }
      }
    }
  };

  return {
    themeType: "light",
    themeOptions,
    styles,
    loading: false,
    error: null,
    isHelpButtonVisible: false
  };
};
