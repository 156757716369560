import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";
import {EOwnershipEntity, FilterExpression, OwnershipQueryType, Role,} from "@sivis/identity/api";
import {useRoleOwnershipsApi} from "./useRoleOwnershipsApi";


export const useOwnershipApiForRole = (role: Role, extraFilter?: FilterExpression) => {
  const roleId = role?.id;
  const ownerFilter = {
    name: "ownerId",
    operator: FilterOperator.EQ,
    uuidValue: roleId
  };
  const targetFilter = {
    name: "targetId",
    operator: FilterOperator.EQ,
    uuidValue: roleId
  };

  const roleOwnershipsFilter = combineFilter(FilterOperator.OR, ownerFilter, targetFilter);
  const filter = extraFilter ? combineFilter(FilterOperator.AND, extraFilter, roleOwnershipsFilter) : roleOwnershipsFilter;

  let cacheType = EOwnershipEntity.ROLE
  let queryType = OwnershipQueryType.ROLE_OWNERSHIPS;

  return useRoleOwnershipsApi(
    cacheType,
    filter,
    queryType,
    roleId
  );
}
