import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';
import { api as generatedAccountApi } from './account.generated';

export const accountApi = generatedAccountApi.enhanceEndpoints({
  endpoints: {
    GetAccounts: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("accounts"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ['account']
    },
    CreateAccount: { invalidatesTags: ["account"] },
    UpdateAccount: { invalidatesTags: ["account"] },
    DeleteAccount: { invalidatesTags: ["account"] },
    GetAccountTypes: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("accountTypes"),
      forceRefetch: forceRefetchPaging,
      providesTags: ["accountType"]
    },
    GetAccountType: { providesTags: ["accountType"] },
    CreateAccountType: { invalidatesTags: ["accountType"] },
    UpdateAccountType: { invalidatesTags: ["accountType"] },
    DeleteAccountType: { invalidatesTags: ["accountType"] },
  }

});

export const {
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useGetAccountsQuery,
  useLazyGetAccountsQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useGetAccountTypesQuery,
  useLazyGetAccountTypesQuery,
  useGetAccountTypeQuery,
  useLazyGetAccountTypeQuery,
  useCreateAccountTypeMutation,
  useUpdateAccountTypeMutation,
  useGetAccountFieldDefinitionsQuery,
  useUpdateAccountFieldDefinitionsMutation,
  useDeleteAccountTypeMutation
} = accountApi;
