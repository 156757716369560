import {Link} from "react-router-dom";
import {SystemType} from "@sivis/identity/api";
import {useSystemTypesPageApi} from "./useSystemTypesPageApi";
import {GridColDef} from '@mui/x-data-grid';
import {EntityView, InfiniteScrollTableCursor} from "@sivis/shared/components/entityView";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: SystemType) => {
  return {
    id: entity.id,
    systemTypeName: entity.systemTypeName,
    systemTypeDescription: entity.systemTypeDescription
  };
};

const columns: GridColDef[] = [
  {
    field: "systemTypeName",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.systemTypeName}</Link>
  },
  {
    field: "systemTypeDescription",
    flex: 2,
  }
];

export const SystemTypeList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useSystemTypesPageQuery,
    parseSystemTypesPageResult,
    pageSize
  } = useSystemTypesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.systemType.propertyName");

  return <EntityView title={intl.format("fusion.systemType.list.title")}>
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useSystemTypesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseSystemTypesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};
