import { PreparedRegistration, prepareRegisterComponent } from '@sivis/shared/components/registry';
import { HEADER_ITEM_RIGHT } from '@sivis/app-shell';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { IntlButton } from '@sivis/intl';
import { LOGOUT_INTL_ID } from '@sivis/shared/components/login';

export const LogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return <IntlButton intlId={LOGOUT_INTL_ID} variant="outlined" sx={{ marginLeft: '5px' }}
                     onClick={onLogout} />;
};

export const logoutButtonRegistration: PreparedRegistration = prepareRegisterComponent(
  {
    id: `${HEADER_ITEM_RIGHT}/${LogoutButton.name}`,
    name: LOGOUT_INTL_ID,
    component: LogoutButton,
    type: HEADER_ITEM_RIGHT,
    order: 1
  }
);
