import { api as generatedBusinessRuleApi } from './businessRule.generated';
import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from '@sivis/shared/api-utils';


export const businessRuleApi = generatedBusinessRuleApi.enhanceEndpoints({
    endpoints: {
      GetBusinessRules: {
        serializeQueryArgs: serializeQueryArgsPaging,
        merge: mergePaging('businessRules'),
        forceRefetch: forceRefetchPaging,
        transformResponse: transformResponseWithArgs,
        providesTags: ['businessRule']
      },
      GetBusinessRuleStatusFilterEntries: {
        providesTags: ['businessRule']
      },
      CreateBusinessRule: {
        invalidatesTags: ['businessRule']
      },
      UpdateBusinessRule: {
        invalidatesTags: ['businessRule']
      },
      DeleteBusinessRule: {
        invalidatesTags: ['businessRule']
      }
    }
  }
);

export const {
  useGetBusinessRuleQuery, useLazyGetBusinessRuleQuery,
  useGetBusinessRulesQuery, useLazyGetBusinessRulesQuery,
  useCreateBusinessRuleMutation,
  useUpdateBusinessRuleMutation,
  useDeleteBusinessRuleMutation,
  useGetBusinessRuleStatusFilterEntriesQuery,
  useGetDraftForBusinessRuleQuery
} = businessRuleApi;
