import {AttributeRows, RowGroup} from "@sivis/shared/components/entityView";
import {ResourceType} from "@sivis/identity/api";
import styles from "./resourceType.module.scss";
import {useTranslateConfigs} from "@sivis/intl";

interface ResourceTypeInformationTabProps {
  resourceType?: ResourceType,
}

const ResourceTypeInformationTab = (props: ResourceTypeInformationTabProps) => {

  const informationConfigs = [
    {
      property: 'name',
      required: true,
      disabled: true
    },
    {
      property: 'description',
      disabled: true
    },
  ];

  const translatedConfigs = useTranslateConfigs(informationConfigs, "fusion.resourceType.propertyName");

  const informationDetails = props.resourceType ? <RowGroup>
    <AttributeRows
      configs={translatedConfigs}
      value={props.resourceType}
    />
  </RowGroup> : <></>;

  return <div className={styles.listContainer}>
    {informationDetails}
  </div>;
}

export default ResourceTypeInformationTab;

