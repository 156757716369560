import {createSelector} from '@reduxjs/toolkit';
import {BackendSliceState} from './BackendState';
import {HealthState} from './HealthState';

const selectBackendSlice = createSelector(
  (state: BackendSliceState) => state,
  state => state.backend
);

export const selectBackendSystems = createSelector(
  selectBackendSlice,
  state => state.systems
);

export const selectDefaultSystem = createSelector(
  selectBackendSlice,
  state => state.defaultSystem
);

export const selectDefaultBackend = createSelector(
  selectDefaultSystem,
  selectBackendSystems,
  (defaultSystem, systems) => systems[defaultSystem]
);

export const selectBackendSystem = createSelector(
  [
    selectBackendSystems,
    (state, systemId?: string) => systemId,
    selectDefaultSystem
  ],
  (systems, systemId, defaultSystemId) => systems[systemId || defaultSystemId]
);

export const selectBackendHeathState = createSelector(
  selectBackendSystem,
  system => system.healthState
);

export const selectIsBackendUp = createSelector(
  selectBackendHeathState,
  healthState => HealthState.up === healthState
);

export const selectIsBackendChecking = createSelector(
  selectBackendHeathState,
  healthState => HealthState.checking === healthState
);

export const selectIsBackendDown = createSelector(
  selectBackendHeathState,
  healthState => HealthState.down === healthState
);
