import {Outlet, RouteObject} from "react-router-dom";
import {SystemTypeList} from "./systemTypeList";
import {
  SystemTypeInformationTabWrapperComponent,
  SystemTypeSystemsTabWrapperComponent,
  SystemTypeView
} from "./systemTypeView";
import {DynamicEntityViewBreadcrumb} from "../components/breadcrumb/breadcrumb";
import {useGetSystemTypeQuery} from "@sivis/identity/api";
import {INFORMATION_TAB_PATHNAME, SYSTEMS_TAB_PATHNAME} from "../components/routes/constants";
import {intlSimpleBreadcrumb} from "@sivis/shared/components/breadcrumb-renderer";

const useGetSystemTypeName = (id: string, isNew: boolean) => {
  const {data} = useGetSystemTypeQuery({id}, {skip: isNew});
  return data?.systemType?.systemTypeName;
};

export const systemTypeRoutes: RouteObject[] = [
  {
    index: true,
    element: <SystemTypeList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetSystemTypeName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <SystemTypeView/>,
      },
      {
        element: <SystemTypeView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <SystemTypeInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumb({intlId: "fusion.breadcrumb.information"})
            }
          },
          {
            path: SYSTEMS_TAB_PATHNAME,
            element: <SystemTypeSystemsTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumb({intlId: "fusion.system.list.name"})
            }
          }
        ]
      }
    ]
  },
];
