import {EntityDetailsProps} from "@sivis/shared/components/entityView";
import {Entitlement, EntitlementType} from "@sivis/identity/api";
import {createEntitlementFormConfigs, isEntitlement} from "./entitlementUtils";
import {RelationInformationTab} from "../components/popup/relationInformationTab";
import {RelationEntity, RelationEntityType} from "../components/popup/relationUtils";
import {useCustomIntl, useTranslateConfigs} from "@sivis/intl";
import styles from "./entitlement.module.scss";
import {InformationCard} from "../components/popup/relationInfoPanel";

interface EntitlementInformationTabProps extends EntityDetailsProps<Entitlement> {
  useEntitlementQuery: (entitlement: Entitlement) =>
    {
      entitled?: RelationEntity;
      target?: RelationEntity;
      onSave: (value: Entitlement) => Promise<void>;
    };
  entitledType: RelationEntityType;
  targetType: RelationEntityType;
}

export const EntitlementInformationTab = (props: EntitlementInformationTabProps) => {
  const entitlement = props.entity;
  const intl = useCustomIntl();
  const {
    onSave,
    entitled,
    target
  } = props.useEntitlementQuery(entitlement);
  const entitlementFormConfigs = createEntitlementFormConfigs(intl.format);
  const translatedEntitlementFormConfigs = useTranslateConfigs(entitlementFormConfigs, "fusion.entitlement.entitlementForm.propertyName");
  const relationText = intl.format("fusion.entitlement.entitlementRelation.access");

  const isIdentityEntitlement = props.entity.type === EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT ||
    props.entity.type === EntitlementType.IDENTITY_RESOURCE_SET_ENTITLEMENT;
  const infoText = isIdentityEntitlement ? <div className={styles.informationCardContainer}>
    <InformationCard text={intl.format("fusion.entitlement.identityEntitlement.editInfo")}/>
  </div> : null;

  const formName = isIdentityEntitlement ?
    intl.format("fusion.entitlement.entitlementForm.name.allSystems") :
    "placeholder name";

  return <div className={styles.tabContainer}>
    {infoText}
    <RelationInformationTab
      {...props}
      configs={translatedEntitlementFormConfigs}
      onSave={onSave}
      leftEntityType={props.entitledType}
      leftEntity={entitled}
      rightEntityType={props.targetType}
      rightEntity={target}
      relationText={relationText}
      entity={entitlement}
      checkValueType={isEntitlement}
      formName={formName}
    />
  </div>;
};
