import {
  api as generatedIdentityRoleMembershipApi
} from './identityRoleMembership/identityRoleMembership.generated';
import {
  api as generatedAccountRoleMembershipApi
} from './accountRoleMembership/accountRoleMembership.generated';

/**
 * Action creator to clear cache of the infinite scroll endpoint. To reload the data,
 * mount the component again to trigger new fetch or dispatch a refetch manually.
 */
export const resetGetIdentityRoleMembershipsActionCreator = (): ReturnType<typeof generatedIdentityRoleMembershipApi.util.upsertQueryData> =>
  generatedIdentityRoleMembershipApi.util.upsertQueryData('GetIdentityRoleMemberships', {
    // Workaround: use endpoint name as the only arg to find the correct cache entry set by
    // serializeQueryArgs. This is not the actual args for the query.
    endpointName: generatedIdentityRoleMembershipApi.endpoints.GetIdentityRoleMemberships.name
  } as any, {
    identityRoleMemberships: {
      edges: [],
      pageInfo: {hasNextPage: false, hasPreviousPage: false}
    }
  });

export const resetGetAccountRoleMembershipsActionCreator = (): ReturnType<typeof generatedAccountRoleMembershipApi.util.upsertQueryData> =>
  generatedAccountRoleMembershipApi.util.upsertQueryData('GetAccountRoleMemberships', {
    endpointName: generatedAccountRoleMembershipApi.endpoints.GetAccountRoleMemberships.name
  } as any, {
    accountRoleMemberships: {
      edges: [],
      pageInfo: {hasNextPage: false, hasPreviousPage: false}
    }
  });

export enum RelationQueryType {
  IDENTITY_ROLE_MEMBERSHIPS = "IDENTITY_ROLE_MEMBERSHIPS",
  IDENTITY_RESOURCE_ENTITLEMENTS = "IDENTITY_RESOURCE_ENTITLEMENTS",
  ROLE_RESOURCE_ENTITLEMENTS = "ROLE_RESOURCE_ENTITLEMENTS",
  OE_RELATIONS = 'OE_RELATIONS',
  OET_RELATIONS = 'OET_RELATIONS',
}

export enum OwnershipQueryType {
  IDENTITY_OWNERSHIPS = "IDENTITY_OWNERSHIPS",
  ROLE_OWNERSHIPS = "ROLE_OWNERSHIPS",
  RESOURCE_OWNERSHIPS = "RESOURCE_OWNERSHIPS",
  RESOURCE_SET_OWNERSHIPS = "RESOURCE_SET_OWNERSHIPS",
  SYSTEM_OWNERSHIPS = "SYSTEM_OWNERSHIPS",
}

export enum SideFilterQueryType {
  RESOURCES = "RESOURCES",
  SYSTEMS = "SYSTEMS",
  ROLES = "ROLES",
  IDENTITIES = "IDENTITIES",
  RULES = 'RULES',
}
