import { Outlet, RouteObject } from "react-router-dom";
import { IdentityList } from "./identityList";
import {
  IdentityAccountTab,
  IdentityInformationTab,
  IdentityOwnershipTab,
  IdentityResourceTab,
  IdentityRoleTab,
  IdentityView
} from "./identityView";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import { useGetIdentityQuery } from "@sivis/identity/api";
import {
  ACCOUNTS_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCES_TAB_PATHNAME,
  ROLES_TAB_PATHNAME
} from "../components/routes/constants";
import { EntityType } from "../components/breadcrumb/editModeContext";
import { intlSimpleBreadcrumb } from "@sivis/shared/components/breadcrumb-renderer";

const useGetIdentityName = (id: string, isNew: boolean) => {
  const { data } = useGetIdentityQuery({ id }, { skip: isNew });
  return data?.identity ? data?.identity?.firstName + " " + data?.identity?.lastName : undefined;
};

export const identityRoutes: RouteObject[] = [
  {
    index: true,
    element: <IdentityList />
  },
  {
    path: ":id",
    element: <Outlet />,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetIdentityName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <IdentityView />,
      },
      {
        element: <IdentityView />,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <IdentityInformationTab />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.IDENTITY
              })
            }
          },
          {
            path: ACCOUNTS_TAB_PATHNAME,
            element: <IdentityAccountTab />,
            handle: {
              crumb: intlSimpleBreadcrumb({ intlId: "fusion.account.list.name" })
            }
          },
          {
            path: ROLES_TAB_PATHNAME,
            element: <IdentityRoleTab />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.role.list.name",
                intlIdEdit: "fusion.breadcrumb.roles.edit",
                entity: EntityType.IDENTITY
              })
            }
          },
          {
            path: RESOURCES_TAB_PATHNAME,
            element: <IdentityResourceTab />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.resource.list.name",
                intlIdEdit: "fusion.breadcrumb.resources.edit",
                entity: EntityType.IDENTITY
              })
            }
          },
          {
            path: OWNERSHIP_TAB_PATHNAME,
            element: <IdentityOwnershipTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.ownership.list.name",
                intlIdEdit: "fusion.breadcrumb.ownership.edit",
                entity: EntityType.IDENTITY
              })
            }
          }
        ]
      }
    ]
  }
];
