import {EntityInformation} from "@sivis/shared/components/entityView";
import {System, SystemInput, SystemType} from "@sivis/identity/api";
import styles from "./system.module.scss";
import {useTranslateConfigs} from "@sivis/intl";

export interface SystemInformationTabProps {
  system: System | undefined,
  systemTypes: SystemType[],
  isNew: boolean,
  onSave: (value: Record<string, any>) => void,
  onDelete: (() => void) | undefined,
  onCancel: () => void,
  editMode: boolean,
  defaultInput: SystemInput,
}

const SystemInformationTab = (props: SystemInformationTabProps) => {
  const flattenedSystem = props.system ? {
    ...props.system,
    systemType: props.system.systemType ? props.system.systemType.id : null,
  } : {};

  const systemTypeReadOnly = props.system?.systemType ? props.system.systemType.systemTypeName : "";

  const configs = [
    {
      property: 'systemName',
      required: true
    },
    {
      property: 'systemDescription',
    },
    {
      property: 'systemType',
      disabled: !props.isNew,
      required: true,
      options: props.systemTypes.map(systemType => ({
        value: systemType.id,
        text: systemType.systemTypeName
      })),
      renderRow: (_: string) => (systemTypeReadOnly)
    }
  ];

  const translatedConfigs = useTranslateConfigs(configs, "fusion.system.propertyName");

  const entityInformation = <EntityInformation
    editMode={props.editMode}
    configs={translatedConfigs}
    value={props.isNew ? props.defaultInput : flattenedSystem}
    onSubmit={props.onSave}
    onCancel={props.onCancel}
    onDelete={props.onDelete}
  />;

  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
};

export default SystemInformationTab;

