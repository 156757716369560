import { UseFormRegister, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useCustomIntl } from '@sivis/intl';
import { Box, MenuItem, TextField, Typography, Divider } from '@mui/material';
import { System } from '@sivis/identity/api';
import { IFormInput } from './attributeSyncMappingForm';
import { AsMapping } from '@sivis/attributesync/api';
import { useSystemFieldApi } from '../api/useSystemFieldApi';
import { useState, useEffect } from 'react';
import styles from './attributeSyncMappingForm.module.scss';

export interface AttributeSyncMappingExternalFieldProps {
  register: UseFormRegister<IFormInput>;
  errors: FieldErrors<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
  system: System;
  editedMapping: AsMapping | null;
}

export const AttributeSyncMappingExternalField = (
  props: AttributeSyncMappingExternalFieldProps
) => {
  const intl = useCustomIntl();

  const [selectedValue, setSelectedValue] = useState<string>('');

  const { systemFields } = useSystemFieldApi(props.system.id);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    // Wait until systemFields are loaded before setting selected value to avoid out-of-range error
    if (systemFields.length > 0 && props.editedMapping?.externalAttribute) {
      const value = props.editedMapping.externalAttribute;
      if (systemFields.some((field) => field.field === value)) {
        setSelectedValue(value);
        props.setValue('externalAttribute', value);
      } else {
        setSelectedValue('');
      }
    }
  }, [props.editedMapping, systemFields]);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Typography
        variant="h6"
        gutterBottom
        color={'black'}
        className={styles.header}
      >
        {intl.format(
          'fusion.attributesynchronizationmapping.propertyName.externalField',
          { system: props.system?.systemName }
        )}
      </Typography>
      <Box className={styles.card}>
        <TextField
          select
          required
          label={intl.format(
            'fusion.attributesynchronizationmapping.popup.selectField'
          )}
          {...props.register('externalAttribute', {
            required: intl.format(
              'fusion.attributesynchronizationmapping.popup.selectFieldError'
            ),
          })}
          margin="normal"
          className={styles.cardField}
          error={!!props.errors.externalAttribute}
          helperText={props.errors.externalAttribute?.message}
          value={selectedValue}
          onChange={handleChange}
        >
          {systemFields.map((field) => (
            <MenuItem key={field.id} value={field.field!}>
              {field.field}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Divider className={styles.dividerMargin} />
    </Box>
  );
};
