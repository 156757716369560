import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ExtendedOwnership, Identity, Ownership, Resource, ResourceSet, Role, System } from "@sivis/identity/api";
import { useCustomIntl } from "@sivis/intl";
import { EntityTableRow } from "@sivis/shared/components/entityView";
import { getEntitlementFlags } from "../entitlement/entitlementUtils";

export const useOwnershipMessages = (): {
  deleteOwnershipMessage: string;
  addResourceMessage: string;
  addRoleMessage: string;
  addSystemMessage: string;
} => {
  const intl = useCustomIntl();
  const deleteOwnershipMessage = intl.format("fusion.ownership.message.deleteOwnership");
  const addResourceMessage = intl.format("fusion.ownership.message.addResource");
  const addRoleMessage = intl.format("fusion.ownership.message.addRole");
  const addSystemMessage = intl.format("fusion.ownership.message.addSystem");

  return {deleteOwnershipMessage, addResourceMessage, addRoleMessage, addSystemMessage};
}

export const ownershipColumns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "type",
    flex: 1
  },
  {
    field: "flags",
    flex: 1,
    sortable: false,
    renderCell: params => params.row.flags
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const onSearch = () => {
};

export const isOwnership = (value: Record<string, any>): value is Ownership => {
  return (value.hasOwnProperty('ownerId') && value.hasOwnProperty('targetId') && value.hasOwnProperty('type'));
};

export const isIdentity = (value: Record<string, any>): value is Identity => {
  return (value.hasOwnProperty('id') && value.hasOwnProperty('firstName') && value.hasOwnProperty('lastName') && value.hasOwnProperty('primaryEmail'));
}

export const isRole = (value: Record<string, any>): value is Role => {
  return (value.hasOwnProperty('id') && value.hasOwnProperty('name') && value.hasOwnProperty('description') && !value.hasOwnProperty('resources') && !value.hasOwnProperty('resourceId') && !value.hasOwnProperty('resourceType') && !value.hasOwnProperty('resources'));
};

export const isResource = (value: Record<string, any>): value is Resource => {
  return (value.hasOwnProperty('id') && value.hasOwnProperty('name') && value.hasOwnProperty('description') && value.hasOwnProperty('resourceId') && value.hasOwnProperty('resourceType'));
};

export const isResourceSet = (value: Record<string, any>): value is ResourceSet => {
  return (value.hasOwnProperty('id') && value.hasOwnProperty('name') && value.hasOwnProperty('description') && value.hasOwnProperty('resources'));
};

export const isSystem = (value: Record<string, any>): value is System => {
  return (value.hasOwnProperty('id') && value.hasOwnProperty('systemName') && value.hasOwnProperty('systemDescription') && value.hasOwnProperty('systemType'));
}

export const systemOwnershipColumns: GridColDef[] = [
  {
    field: "systemName",
    flex: 1
  },
  {
    field: "systemType",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const identityOwnershipColumns: GridColDef[] = [
  {
    field: "fullName",
    flex: 1
  },
  {
    field: "numAccounts",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const roleOwnershipColumns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "description",
    flex: 1
  },
  {
    field: "identitiesCount",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const ownershipsToTableRow = (typeFunction: (entity: ExtendedOwnership) => string, formatFunction: (id: string) => string) => (
  entity: ExtendedOwnership,
  isNew: boolean,
  onEdit?: (entity: Ownership) => void,
  onDelete?: (entity: Ownership) => void
): EntityTableRow => {
  return {
    id: entity.id,
    name: entity.targetName,
    type: typeFunction(entity),
    isNew: isNew,
    flags: getEntitlementFlags({validity: entity.validity}, formatFunction),
    action: <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onEdit?.(entity);
        }}
        aria-label="edit">
        <EditIcon/>
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onDelete?.(entity);
        }}
        aria-label="delete">
        <ClearIcon/>
      </IconButton>
    </Box>
  };
};

export const mapTableRowToOwnership = (rowId: string, ownership: Ownership) => rowId === ownership.id;
