import {
  OrganizationalEntity,
  OrganizationalEntityType,
  searchOrganizationalEntitiesFilter,
  useOrganizationalEntitiesQuery
} from "@sivis/identity/api";
import {useEffect, useState} from "react";
import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";
import {DEFAULT_PAGE_SIZE} from "../../common/parsePageResult";
import {useListSearch} from "../../utils/filterUtils";


interface UseOrganizationalEntityPageForOrganizationalEntityTypeApiProps {
  organizationalEntityType?: OrganizationalEntityType;
}

export const useOrganizationalEntityPageForOrganizationalEntityTypeApi = (props: UseOrganizationalEntityPageForOrganizationalEntityTypeApiProps) => {
  const {filter: searchFilter, onSearch} = useListSearch(searchOrganizationalEntitiesFilter);
  const organizationalEntityTypeFilter = {
    operator: FilterOperator.EQ,
    name: "organizationalEntityType.id",
    uuidValue: props.organizationalEntityType?.id
  };
  const filter = searchFilter ? combineFilter(FilterOperator.AND, searchFilter, organizationalEntityTypeFilter)
    : organizationalEntityTypeFilter;
  const {data} = useOrganizationalEntitiesQuery({filter}, {skip: !props.organizationalEntityType?.id});
  const [organizationalEntities, setOrganizationalEntities] = useState<OrganizationalEntity[]>([]);

  useEffect(() => {
    setOrganizationalEntities(
      (data?.organizationalEntities?.edges ?? [])
      .filter(edge => edge?.node)
      .map(edge => edge!.node)
    );
  }, [data?.organizationalEntities]);

  return {
    organizationalEntities,
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch
  };
};

