import {EntityInformation} from "@sivis/shared/components/entityView";
import {ResourceSetInput} from "@sivis/identity/api";
import styles from "./resourceSet.module.scss";
import {useTranslateConfigs} from "@sivis/intl";

interface ResourceSetInformationTabProps {
  resourceSetInput: ResourceSetInput,
  handleOnSubmit: (value: Record<string, any>) => void,
  onCancel: () => void,
  editMode: boolean,
}

const ResourceSetInformationTab = (props: ResourceSetInformationTabProps) => {

  const configs = [
    {
      property: 'name',
      required: true
    },
    {
      property: 'description',
    },
  ];

  const translatedConfigs = useTranslateConfigs(configs, "fusion.resourceSet.propertyName");

  const entityInformation = <EntityInformation
    editMode={props.editMode}
    configs={translatedConfigs}
    value={props.resourceSetInput}
    onSubmit={props.handleOnSubmit}
    onCancel={props.onCancel}
  />;

  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
};

export default ResourceSetInformationTab;

