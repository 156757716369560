import { Identity, Ownership, Resource, useGetIdentityQuery, useGetResourceQuery } from "@sivis/identity/api";
import { useEffect, useState } from "react";
import { useOwnershipApi } from "./useOwnershipApi";

export const useIdentityResourceOwnershipApi = ({id, ownerId, targetId}: Ownership) => {
  const {data: dataOwner} = useGetIdentityQuery({id: ownerId}, {refetchOnMountOrArgChange: true});
  const {data: dataTarget} = useGetResourceQuery({id: targetId}, {refetchOnMountOrArgChange: true});
  const [owner, setOwner] = useState<Identity>();
  const [target, setTarget] = useState<Resource>();

  useEffect(() => {
    setOwner(dataOwner?.identity ?? undefined);
    setTarget(dataTarget?.resource ?? undefined);
  }, [dataOwner?.identity, dataTarget?.resource]);

  const {ownership, onSave} = useOwnershipApi(id);

  return {ownership, owner, target, onSave}
}
