import { api as generatedOetRelationApi } from './oetRelation.generated';

export const resetGetOetRelationsActionCreator = (): ReturnType<typeof generatedOetRelationApi.util.upsertQueryData> =>
  generatedOetRelationApi.util.upsertQueryData('GetOetRelations', {
    endpointName: generatedOetRelationApi.endpoints.GetOetRelations.name
  } as any, {
    oetRelations: {
      edges: [],
      pageInfo: { hasNextPage: false, hasPreviousPage: false }
    }
  });

