import {
  RegistryRendererProps,
  UseRegistryOptions,
  useRegistryRenderer
} from '@sivis/shared/components/registry';
import {HeaderItemProps} from './headerItem';
import {headerItemType} from './HeaderItems';
import styles from './HeaderItems.module.scss';

interface HeaderItemsRegistryRendererProps {
  type: headerItemType;
}

export const HeaderItemsRegistryRenderer = (props: HeaderItemsRegistryRendererProps) => {
  const registryRenderer = useRegistryRenderer();

  const registryProps: RegistryRendererProps<HeaderItemProps> = {
    props: {
      sideNav: false,
      className: styles.headerButton
    }
  }

  const renderOptions: UseRegistryOptions = {
    byType: props.type,
    ordered: true
  }

  return (
    <div className={styles.headerItemsContainer}>
      {registryRenderer(renderOptions, registryProps)}
    </div>
  );
}
