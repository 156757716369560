import {GridColDef} from "@mui/x-data-grid-pro";
import {
  EntityDetailsProps,
  EntityTableRow,
  InfiniteScrollTableCursor,
  Popup,
  TabBar
} from "@sivis/shared/components/entityView";
import {Resource, ResourceType, useGetResourceQuery} from "@sivis/identity/api";
import {useResourcePageForResourceTypeApi} from "./useResourcePageForResourceTypeApi";
import {useCustomIntl, useTranslateColumns, useTranslateConfigs} from "@sivis/intl";
import {ConnectionInformationTab} from "../components/popup/connectionInformationTab";
import {RelationEntityType} from "../components/popup/relationUtils";
import {useEffect, useState} from "react";

interface ResourceTypeResourcesTabProps {
  resourceType?: ResourceType,
}

const entityToTableRow = (entity: Resource): EntityTableRow => {
  return {
    id: entity.id,
    name: entity.name,
    resourceId: entity.resourceId,
    systemName: entity.system?.systemName ?? "",
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
  },
  {
    field: "resourceId",
    flex: 1,
  },
  {
    field: "systemName",
    flex: 1,
  },
];

const EntityDetails = ({entity}: EntityDetailsProps<Resource>) => {
  const intl = useCustomIntl();
  const configs = [{property: "name"}, {property: "description"}];
  const translatedConfigs = useTranslateConfigs(configs, "fusion.resource.propertyName");
  const tabs = [
    {
      label: intl.format("fusion.resource.propertyName.name"),
      content: <ConnectionInformationTab
        configs={translatedConfigs}
        entity={entity}
        entityType={RelationEntityType.RESOURCE}
      />
    }
  ];
  return entity ? <TabBar tabs={tabs}/> : null;
}

const ResourceTypeResourcesTab = (props: ResourceTypeResourcesTabProps) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [resourceId, setResourceId] = useState<string>();
  const {data} = useGetResourceQuery({id: resourceId || ""}, {skip: !resourceId});
  const [selectedResource, setSelectedResource] = useState<Resource | null>(null);

  const intl = useCustomIntl();
  const translatedColumns = useTranslateColumns(columns, "fusion.resource.propertyName");

  const {
    onSearch,
    useResourcePageForResourceTypePageQuery,
    parseResourcePageForResourceTypePageResult,
    pageSize
  } =
    useResourcePageForResourceTypeApi(props.resourceType?.id);

  useEffect(() => {
    if (data?.resource) {
      setSelectedResource(data.resource);
    }
  }, [data?.resource]);

  const onRowClick = (id: string) => {
    if (id !== resourceId) {
      setSelectedResource(null);
    }
    setResourceId(id);
    setPopupOpen(true);
  }

  const onClosePopup = () => {
    setPopupOpen(false);
  }

  return (
    <>
      <InfiniteScrollTableCursor
        columns={translatedColumns}
        pageSize={pageSize}
        usePageQuery={useResourcePageForResourceTypePageQuery}
        renderRow={entityToTableRow}
        parseResult={parseResourcePageForResourceTypePageResult}
        onSearch={onSearch}
        placeholder={intl.format("fusion.general.searchPlaceholder")}
        onRowClick={onRowClick}
      />
      <Popup
        open={popupOpen}
        primaryTitle={selectedResource?.name ?? ""}
        secondaryTitle={intl.format("fusion.resourceType.resource.popup.secondaryTitle", {
          resource: selectedResource?.name, resourceType: props.resourceType?.name,
        })}
        onClose={onClosePopup}
        hideButtons
        styleType={"edit"}
      >
        {selectedResource ? <EntityDetails
          entity={selectedResource}
          editMode={false}
        /> : null}
      </Popup>
    </>
  );
}

export default ResourceTypeResourcesTab;

