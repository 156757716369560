import {Outlet, RouteObject} from "react-router-dom";
import {SystemList} from "./systemList";
import {
  AccountTypeFieldDefinitionTabWrapperComponent,
  SystemAccountsTabWrapperComponent,
  SystemAttributeSynchronizationDefinitionTabWrapperComponent,
  SystemInformationTabWrapperComponent,
  SystemOwnershipTabWrapperComponent,
  SystemResourcesTabWrapperComponent,
  SystemRolesTabWrapperComponent,
  SystemView
} from './systemView';
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode,
} from "../components/breadcrumb/breadcrumb";
import {
  ACCOUNTS_TAB_PATHNAME,
  ATTRIBUTE_SYNCHRONIZATION_DEFINITION_TAB_PATHNAME, FIELDDEFINITION_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCES_TAB_PATHNAME,
  ROLES_TAB_PATHNAME
} from '../components/routes/constants';
import {useGetSystemQuery} from "@sivis/identity/api";
import {EntityType} from "../components/breadcrumb/editModeContext";
import {attributeSyncDefinitionRoutes} from "../attributeSynchronizationDefinition/attributeSyncDefinitionRoutes";

const useGetSystemName = (id: string, isNew: boolean) => {
  const {data} = useGetSystemQuery({id}, {skip: isNew});
  return data?.system?.systemName;
};

export const systemRoutes: RouteObject[] = [
  {
    index: true,
    element: <SystemList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetSystemName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <SystemView/>,
      },
      {
        element: <SystemView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <SystemInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.SYSTEM
              })
            }
          },
          {
            path: ACCOUNTS_TAB_PATHNAME,
            element: <SystemAccountsTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.account.list.name",
                intlIdEdit: "fusion.breadcrumb.accounts.edit",
                entity: EntityType.SYSTEM
              })
            }
          },
          {
            path: ROLES_TAB_PATHNAME,
            element: <SystemRolesTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.role.list.name",
                intlIdEdit: "fusion.breadcrumb.roles.edit",
                entity: EntityType.SYSTEM
              })
            }
          },
          {
            path: RESOURCES_TAB_PATHNAME,
            element: <SystemResourcesTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.resource.list.name",
                intlIdEdit: "fusion.breadcrumb.resources.edit",
                entity: EntityType.SYSTEM
              })
            }
          },
          {
            path: OWNERSHIP_TAB_PATHNAME,
            element: <SystemOwnershipTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.ownership.list.name",
                intlIdEdit: "fusion.breadcrumb.ownership.edit",
                entity: EntityType.SYSTEM
              })
            }
          },
          {
            path: ATTRIBUTE_SYNCHRONIZATION_DEFINITION_TAB_PATHNAME,
            element: <SystemAttributeSynchronizationDefinitionTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.attributesynchronizationdefinition.list.name",
                intlIdEdit: "fusion.breadcrumb.attributesynchronizationdefinition.edit",
                entity: EntityType.SYSTEM
              })
            }
          },
          {
            path: FIELDDEFINITION_TAB_PATHNAME,
            element: <AccountTypeFieldDefinitionTabWrapperComponent />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: 'fusion.breadcrumb.fieldDefinitions',
                intlIdEdit: "fusion.breadcrumb.accounts.edit",
                entity: EntityType.FIELD_DEFINITION
              })
            }
          }
        ]
      },
      attributeSyncDefinitionRoutes
    ]
  },
];
