import {
  createAsyncThunk,
  createSelector,
  createSlice,
  Draft,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction
} from '@reduxjs/toolkit';
import defaultLogoLogin from './pointsharp-horizontal-logo-blue.png';
import defaultLogoNavbar from './pointsharp-horizontal-logo-almost-white.png';
import {showError, showSuccess} from '@sivis/redux';
import {getMessageOrSelf, LoggingService} from '@sivis/shared/utils';
import {httpDelete, httpGet, httpPost} from '@sivis/http-client';

export const LOGO_SLICE_NAME = 'logo';
const LOGO_BASE_PATH = '/customization/logo'
const LOGO_LOGGING_SOURCE = "LogoAction";

export interface ILogoState {
  logos: { [key in ELogoPlace]: ILogo };
  loading: boolean;
  error: any;
}

export interface ILogo {
  id?: number;
  image: string;
  place: ELogoPlace;
}

export enum ELogoPlace {
  LOGIN = 'LOGIN',
  NAVBAR = 'NAVBAR',
}

export interface LogoSliceState {
  logo: ILogoState
}

const initialState: ILogoState = {
  logos: {
    [ELogoPlace.LOGIN]: {image: defaultLogoLogin, place: ELogoPlace.LOGIN},
    [ELogoPlace.NAVBAR]: {image: defaultLogoNavbar, place: ELogoPlace.NAVBAR}
  },
  loading: false,
  error: null,
};


export const fetchCustomLogo = createAsyncThunk(
  `${LOGO_SLICE_NAME}/fetchCustomLogo`,
  async (id: number | undefined, {rejectWithValue}) => {
    if (!id) {
      return rejectWithValue("No logo id present");
    }

    try {
      const newLogo = await httpGet<ILogo>(`${LOGO_BASE_PATH}/${id}`);
      LoggingService.trace(`Loaded logo: ${newLogo.id} => ${newLogo.place}`, LOGO_LOGGING_SOURCE);
      return newLogo;
    } catch (e) {
      LoggingService.error(e, LOGO_LOGGING_SOURCE);
      return rejectWithValue(getMessageOrSelf(e));
    }
  }
);

export const fetchCustomLogoState = createAsyncThunk<void, void, { state: LogoSliceState }>(
  `${LOGO_SLICE_NAME}/fetchCustomLogoState`,
  async (_, {getState, dispatch}) => {
    const newLogos = await httpGet<ILogo[]>(`${LOGO_BASE_PATH}/state`);
    if (!newLogos?.length) {
      return;
    }
    const {logo: {logos}} = getState();

    const notLoaded = newLogos.filter(
      newLogo => logos[newLogo.place]?.id !== newLogo.id
    );

    notLoaded.forEach(logo => dispatch(fetchCustomLogo(logo.id)))
  }
);

export const saveCustomLogo = createAsyncThunk(
  `${LOGO_SLICE_NAME}/saveCustomLogo`,
  async (newLogo: ILogo, {dispatch, rejectWithValue}) => {
    try {
      const savedLogo = await httpPost<ILogo>(LOGO_BASE_PATH, newLogo);
      dispatch(showSuccess('customize.logo.success'));
      return savedLogo;
    } catch (e) {
      dispatch(showError('customize.logo.fail'));
      LoggingService.error(e, LOGO_LOGGING_SOURCE);
      return rejectWithValue(getMessageOrSelf(e));
    }
  });

export const deleteCustomLogo = createAsyncThunk<ILogo | undefined, ELogoPlace, {
  state: LogoSliceState
}>(
  `${LOGO_SLICE_NAME}/deleteCustomLogo`,
  async (place: ELogoPlace, {dispatch, rejectWithValue, getState}) => {
    const {logo: {logos}} = getState();
    const logoToDelete = logos[place];

    if (!logoToDelete?.id) {
      return;
    }

    try {
      await httpDelete(`${LOGO_BASE_PATH}/${logoToDelete.id}`);
      dispatch(showSuccess('general.settings.resetToDefaults.success'));
      return initialState.logos[place];
    } catch (e) {
      dispatch(showError('customize.logo.fail'));
      LoggingService.error(e, LOGO_LOGGING_SOURCE);
      return rejectWithValue(getMessageOrSelf(e));
    }
  }
);

const isFetchPending = isPending(fetchCustomLogo, fetchCustomLogoState, saveCustomLogo, deleteCustomLogo);
const isFetchRejected = isRejected(fetchCustomLogo, fetchCustomLogoState, saveCustomLogo, deleteCustomLogo);
const isLogoFulfilled = isFulfilled(fetchCustomLogo, saveCustomLogo, deleteCustomLogo);

export const logoSlice = createSlice({
    name: LOGO_SLICE_NAME,
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder.addMatcher(isLogoFulfilled, (state: Draft<ILogoState>, action: PayloadAction<ILogo | undefined>) => {
        const newLogo = action.payload;

        if (!newLogo) {
          return;
        }

        state.logos[newLogo.place] = newLogo;
      })
      .addMatcher(isFetchPending, (state: Draft<ILogoState>) => {
        state.loading = true;
      })
      .addMatcher(isFetchRejected, (state: Draft<ILogoState>, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    }
  }
);


export const logoReducer = logoSlice.reducer;

const selectLogoSlice = createSelector(
  (state: LogoSliceState) => state,
  state => state.logo
);

export const selectLogos = createSelector(
  selectLogoSlice,
  state => state.logos
);

export const selectLogoByPlace = createSelector(
  [
    selectLogos,
    (logos, place: ELogoPlace) => place
  ],
  (logos, place) => logos[place]
);

export const selectLoginLogo = createSelector(
  selectLogos,
  logos => logos[ELogoPlace.LOGIN]
);

export const selectNavbarLogo = createSelector(
  selectLogos,
  logos => logos[ELogoPlace.NAVBAR]
);

export const selectLogoLoading = createSelector(
  selectLogoSlice,
  logo => logo.loading
);
