import {MatchedCrumb} from "@sivis/shared/components/breadcrumb";
import styles from "./breadcrumb.module.scss";
import {Fragment} from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const BreadcrumbsRenderer = (crumbs: MatchedCrumb[]) => {
  return <div className={styles.breadcrumbContainer}>
    {crumbs.map((crumb, index) => {
      const divider = <KeyboardArrowRightIcon/>;
      const isLast = index === crumbs.length - 1;
      const crumbClassName = isLast ? styles.breadcrumb + " " + styles.breadcrumbLast : styles.breadcrumb;
      const singleBreadcrumb = crumb.crumb instanceof Function ?
        <crumb.crumb params={crumb.params} path={crumb.path}/> : crumb.crumb;
      return <Fragment key={crumb.path}>
        <span className={crumbClassName}>{singleBreadcrumb}</span>
        {!isLast && divider}
      </Fragment>;
    })}
  </div>
};
