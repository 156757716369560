interface ResourceSetIdentitiesTabProps {

}

const ResourceSetIdentitiesTab = (props: ResourceSetIdentitiesTabProps) => {

  return <div>
    Placeholder for identities
  </div>
}

export default ResourceSetIdentitiesTab;
