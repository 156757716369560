export interface OrganizationalEntityChildrenTabProps {

}

const OrganizationalEntityChildrenTab = (props: OrganizationalEntityChildrenTabProps) => {

  return <div>
    Placeholder
  </div>
}

export default OrganizationalEntityChildrenTab;
