import { ThemeOptions } from '@mui/material/styles';
import { PaletteColorOptions } from '@mui/material/styles/createPalette';
import { createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { getMessageOrSelf } from '@sivis/shared/utils';
import { castDraft } from 'immer';
import { cloneDeep } from 'lodash';
import { initialPointsharpThemeOptions } from './PointsharpTheme';
import { IStyles, IStylesState, ThemeType } from './themeTypes';
import { applyStylesToDom } from './themeUtils';

export const THEME_SLICE_NAME = 'theme';

export const getCurrentDomStyles = (): IStyles => ({
  primaryColor: getComputedStyle(document.documentElement).getPropertyValue('--primaryColor').replace(/\s/g, '') || '#0047BB',
  secondaryColor: getComputedStyle(document.documentElement).getPropertyValue('--secondaryColor').replace(/\s/g, '') || '#7F56C5',
  primaryTextColor: getComputedStyle(document.documentElement).getPropertyValue('--primaryTextColor').replace(/\s/g, '') || '#1D1F29',
  secondaryTextColor: getComputedStyle(document.documentElement).getPropertyValue('--secondaryTextColor').replace(/\s/g, '') || '#FFFFFF',
  primaryBackground: getComputedStyle(document.documentElement).getPropertyValue('--primaryBackground').replace(/\s/g, '') || '#FFFFFF',
  topnavBackground: getComputedStyle(document.documentElement).getPropertyValue('--topnavBackground').replace(/\s/g, '') || '#1D1F29',
  sidenavBackground: getComputedStyle(document.documentElement).getPropertyValue('--sidenavBackground').replace(/\s/g, '') || '#FFFFFF',
  tabbarColor: getComputedStyle(document.documentElement).getPropertyValue('--tabbarColor').replace(/\s/g, '') || '#F4F7F8',
  primaryIconColor: getComputedStyle(document.documentElement).getPropertyValue('--primaryIconColor').replace(/\s/g, '') || '#F4F7F8',
  secondaryIconColor: getComputedStyle(document.documentElement).getPropertyValue('--secondaryIconColor').replace(/\s/g, '') || '#1D1F29',
  shadowColor: getComputedStyle(document.documentElement).getPropertyValue('--shadowColor').replace(/\s/g, '') || '#DDE1E9'
});

const initialState: IStylesState = {
  themeType: 'light',
  themeOptions: initialPointsharpThemeOptions(),
  styles: getCurrentDomStyles(),
  loading: false,
  error: null,
  isHelpButtonVisible: true
};

const applyColorsPaletteMuiTheme = (
  primaryColor: string,
  secondaryColor: string,
  errorColor?: PaletteColorOptions
): ThemeOptions => {
  // Initialize theme options again to apply current DOM styles
  const currentThemeOptions = initialPointsharpThemeOptions();
  return {
    palette: {
      ...currentThemeOptions.palette,
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      error: errorColor ?? currentThemeOptions.palette?.error
    },
    typography: currentThemeOptions.typography,
    components: currentThemeOptions.components
  };
};

export const fetchCustomStyles = createAsyncThunk(
  `${THEME_SLICE_NAME}/fetchCustomStyles`,
  async (customStyleLoader: (getState: any) => Promise<IStyles>, {rejectWithValue, getState}) => {
    try {
      return await customStyleLoader(getState);
    } catch (e) {
      return rejectWithValue(getMessageOrSelf(e));
    }
  }
);


export const saveCustomStyles = createAsyncThunk(
  `${THEME_SLICE_NAME}/saveCustomStyles`,
  async (
    customStylesSaver: (dispatch: any, getState: any) => Promise<IStyles | void>,
    {rejectWithValue, dispatch, getState}
  ) => {
    return await customStylesSaver(dispatch, getState);
  }
)

export const resetTheme = createAsyncThunk(
  `${THEME_SLICE_NAME}/resetTheme`,
  async (
    customStylesSaverFactory: (styles: IStyles, resetToDefault?: boolean) => (dispatch: any, getState: any) => Promise<IStyles | void>,
    {dispatch}
  ) => {
    const customStylesSaver = customStylesSaverFactory(initialState.styles, true);
    return dispatch(saveCustomStyles(customStylesSaver));
  }
)


const evaluateStyles = (styles: IStyles): IStyles => {
  const checkedStyles: IStyles = cloneDeep(initialState.styles);
  Object.keys(checkedStyles).forEach((key: string) => {
    if (key in styles) {
      if (new RegExp(/^#[0-9A-F]{6}$/i).test(styles[key as keyof IStyles])) {
        checkedStyles[key as keyof IStyles] = styles[key as keyof IStyles];
      }
    }
  });
  return checkedStyles;
}

const setStyles = (state: Draft<IStylesState>, newStyles: IStyles) => {
  const styles = evaluateStyles(newStyles);
  applyStylesToDom(styles);
  state.styles = styles;
  state.themeOptions = castDraft(applyColorsPaletteMuiTheme(styles.primaryColor, styles.secondaryColor));
}

const themeSlice = createSlice({
    name: THEME_SLICE_NAME,
    initialState,
    reducers: {
      toggleTheme: (state: Draft<IStylesState>, action: PayloadAction<ThemeType>) => {
        state.themeType = action.payload;
      },
      toggleHelpButtonVisibility: (state: Draft<IStylesState>) => {
        state.isHelpButtonVisible = !state.isHelpButtonVisible;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchCustomStyles.pending, (state: Draft<IStylesState>) => {
        state.loading = true;
      })
      .addCase(fetchCustomStyles.fulfilled, (state: Draft<IStylesState>, action: PayloadAction<IStyles>) => {
        state.loading = false;
        setStyles(state, action.payload);
      })
      .addCase(fetchCustomStyles.rejected, (state: Draft<IStylesState>, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(saveCustomStyles.fulfilled, (state: Draft<IStylesState>, action: PayloadAction<IStyles | void>) => {
        if (action.payload) {
          setStyles(state, action.payload)
        }
      })
    }
  }
);

export const {toggleTheme, toggleHelpButtonVisibility} = themeSlice.actions;


export const themeReducer = themeSlice.reducer;
