import {
  Address,
  AddressInput,
  GeneratedAddressTypes,
  useGetAddressesQuery,
} from "@sivis/identity/api";
import {CursorBasedPageResult} from "@sivis/shared/components/entityView";
import {useCallback, useState} from "react";
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from "../common/parsePageResult";

export const useAddressesPageApi = () => {
  const [addressInput, setAddressInput] = useState<AddressInput | undefined>();

  const useAddressesPageQuery = (cursor: string | null) =>
    useGetAddressesQuery({ first: DEFAULT_PAGE_SIZE, after: cursor, sort: DEFAULT_ENTITY_SORT });

  const onSearch = useCallback((searchStr: string) => {
    setAddressInput({ name: searchStr });
  }, []);

  const parseAddressesPageResult = (res?: GeneratedAddressTypes.GetAddressesQuery): CursorBasedPageResult<Address> => {
    return parseCursorBasedPageResult('addresses', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useAddressesPageQuery,
    parseAddressesPageResult
  };
};
