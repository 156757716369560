import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetResourceTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetResourceTypeQuery = { __typename?: 'Query', resourceType?: { __typename?: 'ResourceType', id: string, name: string, description?: string | null } | null };

export type GetResourceTypesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetResourceTypesQuery = { __typename?: 'Query', resourceTypes?: { __typename?: 'ResourceTypeConnection', edges: Array<{ __typename?: 'ResourceTypeEdge', node: { __typename?: 'ResourceType', id: string, name: string, description?: string | null, systemType?: { __typename?: 'SystemType', id: string, systemTypeName: string } | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };


export const GetResourceTypeDocument = `
    query GetResourceType($id: ID!) {
  resourceType(id: $id) {
    id
    name
    description
  }
}
    `;
export const GetResourceTypesDocument = `
    query GetResourceTypes($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  resourceTypes(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        name
        description
        systemType {
          id
          systemTypeName
        }
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetResourceType: build.query<GetResourceTypeQuery, GetResourceTypeQueryVariables>({
      query: (variables) => ({ document: GetResourceTypeDocument, variables })
    }),
    GetResourceTypes: build.query<GetResourceTypesQuery, GetResourceTypesQueryVariables | void>({
      query: (variables) => ({ document: GetResourceTypesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetResourceTypeQuery, useLazyGetResourceTypeQuery, useGetResourceTypesQuery, useLazyGetResourceTypesQuery } = injectedRtkApi;

