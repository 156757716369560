import {Popup} from "@sivis/shared/components/entityView";

interface ActionPopupProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  primaryTitle: string;
  secondaryTitle?: string;
  alert: JSX.Element;
}

export const ActionPopup = (props: ActionPopupProps) => {
  return <Popup
    open={props.open}
    onConfirm={props.onConfirm}
    onClose={props.onClose}
    primaryTitle={props.primaryTitle}
    secondaryTitle={props.secondaryTitle}
    styleType="confirm"
    hideButtons={false}
  >
    {props.alert}
  </Popup>
}
