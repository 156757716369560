import {createSlice, Draft, isFulfilled, isRejected, PayloadAction} from '@reduxjs/toolkit';
import {IntlState, IText} from './types';
import {mergeTexts} from './utils';
import {
  activateLanguage,
  deactivateLanguage,
  downloadTexts,
  fetchLanguagesIfNeeded,
  fetchTexts,
  INTL_SLICE_NAME,
  saveText,
  saveUserLanguage,
  uploadText
} from './intlActions';
import {LoggingService} from '@sivis/shared/utils';
import Axios from 'axios';
import {getStoredLanguage, storeLanguage} from './internal';
import {detectBrowserLocale, ELocales, toELocale} from './ELocales';
import {initialGeneralMessages} from './translations/generalMessages';


export const initialTextState = (): IntlState => ({
  messages: initialGeneralMessages(),
  loading: true,
  error: null,
  language: ELocales.EN,
  availableLanguages: [ELocales.EN, ELocales.DE],
  showWarningOnSwitch: false
});

export const isTextFulfilled = isFulfilled(fetchTexts, saveText, uploadText);
export const isTextOrLanguageRejected = isRejected(
  fetchTexts,
  saveText,
  uploadText,
  downloadTexts,
  activateLanguage,
  deactivateLanguage,
  fetchLanguagesIfNeeded,
  saveUserLanguage
);

const onLocaleChange = (state: Draft<IntlState>, newLocale: ELocales | string) => {
  const locale = toELocale(newLocale);
  if (!locale) {
    //This can never happen but we want to make the transpiler happy
    return;
  }
  storeLanguage(locale);
  //TODO: move this to a central place to configure http defaults
  Axios.defaults.headers.common['Accept-Language'] = locale;
  LoggingService.info(`Setting locale to "${locale}"`, 'IntlAction');
  state.language = locale;
  state.loading = false;
}

export const intlSlice = createSlice({
  name: INTL_SLICE_NAME,
  initialState: initialTextState,
  reducers: {
    addText: (state: Draft<IntlState>, action: PayloadAction<IText>) => {
      state.messages.push(action.payload);
    },
    addTexts: (state: Draft<IntlState>, action: PayloadAction<IText[]>) => {
      state.messages.push(...action.payload);
    },
    setTexts: (state: Draft<IntlState>, action: PayloadAction<IText[]>) => {
      state.messages = [...action.payload]
    },
    setLanguage: (state: Draft<IntlState>, action: PayloadAction<ELocales | string>) => {
      onLocaleChange(state, action.payload);
    },
    checkLanguage: (state: Draft<IntlState>) => {
      const locale = getStoredLanguage() || detectBrowserLocale(state.availableLanguages);
      onLocaleChange(state, locale);
    },
    showWarningOnSwitchLanguage: (state: Draft<IntlState>, action: PayloadAction<boolean>) => {
      state.showWarningOnSwitch = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchTexts.pending, (state: Draft<IntlState>) => {
      state.loading = true
    })
    .addCase(activateLanguage.fulfilled, (state: Draft<IntlState>, action: PayloadAction<ELocales>) => {
      const activatedLanguage = action.payload;
      if (activatedLanguage) {
        state.availableLanguages.push(activatedLanguage);
      }
    })
    .addCase(deactivateLanguage.fulfilled, (state: Draft<IntlState>, action: PayloadAction<ELocales>) => {
      const deactivationIndex = state.availableLanguages.findIndex(locale => locale === action.payload);
      if (deactivationIndex < 0) {
        return;
      }
      state.availableLanguages.splice(deactivationIndex, 1)
    })
    .addCase(fetchLanguagesIfNeeded.fulfilled, (state: Draft<IntlState>, action) => {
      const locales = action.payload
      state.availableLanguages = locales;
    })
    .addCase(saveUserLanguage.fulfilled, (state: Draft<IntlState>, action) => {
      onLocaleChange(state, action.payload);
    })
    .addMatcher(isTextFulfilled, (state: Draft<IntlState>, action) => {
      state.loading = false;
      if (!action.payload) {
        return;
      }
      const mergedTexts = mergeTexts(state.messages, action.payload)
      state.messages = [...mergedTexts]
    })
    .addMatcher(isTextOrLanguageRejected, (state: Draft<IntlState>, action) => {
      state.loading = false;
      state.error = action.payload
    })

  }
})

export const resetText = (messages: IText[]) => {
  return async (dispatch: any) => {
    dispatch(saveText([...messages, ...(initialGeneralMessages())]));
  };
};

export const {
  addText,
  addTexts,
  setTexts,
  setLanguage,
  checkLanguage,
  showWarningOnSwitchLanguage
} = intlSlice.actions;

export const intlReducer = intlSlice.reducer;
