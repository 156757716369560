import {Outlet, RouteObject} from "react-router-dom";
import {ResourceTypeList} from "./resourceTypeList";
import {
  ResourceTypeInformationTabWrapperComponent,
  ResourceTypeResourcesTabWrapperComponent,
  ResourceTypeView
} from "./resourceTypeView";
import {DynamicEntityViewBreadcrumb} from "../components/breadcrumb/breadcrumb";
import {useGetResourceTypeQuery} from "@sivis/identity/api";
import {INFORMATION_TAB_PATHNAME, RESOURCES_TAB_PATHNAME} from "../components/routes/constants";
import {intlSimpleBreadcrumb} from "@sivis/shared/components/breadcrumb-renderer";

const useGetResourceTypeName = (id: string, isNew: boolean) => {
  const {data} = useGetResourceTypeQuery({id}, {skip: isNew});
  return data?.resourceType?.name;
};

export const resourceTypeRoutes: RouteObject[] = [
  {
    index: true,
    element: <ResourceTypeList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetResourceTypeName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <ResourceTypeView/>,
      },
      {
        element: <ResourceTypeView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <ResourceTypeInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumb({intlId: "fusion.breadcrumb.information"})
            }
          },
          {
            path: RESOURCES_TAB_PATHNAME,
            element: <ResourceTypeResourcesTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumb({intlId: "fusion.resource.list.name"})
            }
          }
        ]
      }
    ]
  }
];
