import {CreateButtonWithIcon} from "@sivis/shared/components/entityView";
import {Menu, MenuItem} from "@mui/material";
import {useRef, useState} from "react";

export interface DropdownOption {
  entityToAdd: any;
  label: string;
}

interface CreateButtonWithDropdownProps<T> {
  onSelect: (type: any) => void;
  menuItems: DropdownOption[];
}

export const CreateButtonWithDropdown = <T, >({
                                                onSelect, menuItems
                                              }: CreateButtonWithDropdownProps<T>) => {
  const [openAddOptions, setOpenAddOptions] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleSelect = (type: any) => {
    setOpenAddOptions(false);
    onSelect(type);
  };

  return (
    <div>
      <div ref={buttonRef}>
        <CreateButtonWithIcon
          intlId={"fusion.ownership.add"}
          key="create"
          onClick={() => {
            setOpenAddOptions(true);
          }}
        />
      </div>
      <Menu
        open={openAddOptions}
        onClose={() => setOpenAddOptions(false)}
        anchorEl={buttonRef.current}
        slotProps={{
          paper: {
            style: {
              minWidth: buttonRef.current ? buttonRef.current.offsetWidth : 'auto'
            }
          }
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.entityToAdd} onClick={() => handleSelect(item.entityToAdd)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
