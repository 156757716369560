import {Alert, Button} from "@mui/material";
import {AutocompleteField} from "./components/autocompleteField/autocompleteField";
import {EntityTable, EntityTableRow} from "./components/table";
import styles from "./entityView.module.scss";
import {useEffect, useState} from "react";
import {FormButtons, FormButtonsProps} from "./components/form/FormButtons";
import {GridColDef} from "@mui/x-data-grid-pro";
import {Popup} from "./components/popup/popup";
import {containsListItem} from "./components/utils";
import {useCustomIntl} from "@sivis/intl";

export interface EntityDetailsProps<T> extends FormButtonsProps {
  entity: T;
  isNew?: boolean;
  onEditNew?: (entity: T) => void;
  editMode: boolean;
}

interface ListModifyViewProps<ListItem, Option extends { id: string }> {
  columns: GridColDef[];
  entities: ListItem[];
  entityToTableRow: (entity: ListItem, isNew: boolean, onEdit?: (entity: ListItem) => void,
                     onDelete?: (entity: ListItem) => void) => EntityTableRow;
  options: Option[];
  onSearchForOptions: (input: string) => void;
  renderOption: (option: Option) => string;
  onSave: (toAdd: ListItem[], toDelete: ListItem[]) => void;
  onSearch: (searchStr: string) => void;
  editMode: boolean;
  getEntityTitle: (entity: ListItem) => {
    primaryTitle: string;
    secondaryTitle: JSX.Element | string;
  };
  optionToEntity: (option: Option) => ListItem;
  isEqual: (a: ListItem, b: ListItem) => boolean;
  entityDetails: React.FunctionComponent<EntityDetailsProps<ListItem>>;
  optionPlaceholder?: string;
  deleteMessage?: string;
  // To find the chosen entity on row click
  mapTableRowToEntity: (rowId: string, entity: ListItem) => boolean;
  sideFilter?: JSX.Element;
}

export const ListModifyView = <ListItem, Option extends {
  id: string
}>(props: ListModifyViewProps<ListItem, Option>) => {
  const intl = useCustomIntl();
  const [addedEntities, setAddedEntities] = useState<ListItem[]>([]);
  const [deletedEntities, setDeletedEntities] = useState<ListItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [rows, setRows] = useState<EntityTableRow[]>([]);
  const [openEditPopup, setOpenEditPopup] = useState<ListItem | null>(null);
  const [openDeletePopup, setOpenDeletePopup] = useState<ListItem | null>(null);
  const isEqual = props.isEqual;

  useEffect(() => {
    reset();
  }, [props.entities]);

  useEffect(() => {
    setRows([
      ...addedEntities.map(entity => props.entityToTableRow(entity, true, onOpenEditPopup, onOpenDeletePopup)),
      ...props.entities.filter(entity => !isDeleted(entity))
      .map(entity => props.entityToTableRow(entity, false, onOpenEditPopup, onOpenDeletePopup))
    ]);
  }, [addedEntities, deletedEntities]);

  const modified = addedEntities.length > 0 || deletedEntities.length > 0;
  const isAdded = containsListItem(addedEntities, isEqual);
  const isDeleted = containsListItem(deletedEntities, isEqual);
  const isExisting = containsListItem(props.entities, isEqual);

  const addEntity = (toAdd: ListItem) => {
    if (!isAdded(toAdd) && !isExisting(toAdd)) {
      setAddedEntities([...addedEntities, toAdd]);
    }
  };

  const onAdd = () => {
    if (selectedOption) {
      addEntity(props.optionToEntity(selectedOption));
    }
  };

  const editAddedEntity = (toEdit: ListItem) => {
    if (isAdded(toEdit)) {
      setAddedEntities([...addedEntities.filter(added => !isEqual(added, toEdit)), toEdit]);
    }
  };

  // Ask for confirmation only for saved membership
  const onOpenDeletePopup = (toDelete: ListItem) => {
    if (isAdded(toDelete)) {
      onDelete(toDelete);
    } else if (isExisting(toDelete)) {
      setOpenDeletePopup(toDelete);
    }
  };

  const onDelete = (toDelete: ListItem) => {
    if (!isDeleted(toDelete) && isExisting(toDelete)) {
      setDeletedEntities([...deletedEntities, toDelete]);
    } else if (!isDeleted(toDelete) && isAdded(toDelete)) {
      setAddedEntities(addedEntities.filter(added => !isEqual(added, toDelete)));
    }
  };

  const onOpenEditPopup = (toEdit: ListItem) => {
    if (isAdded(toEdit) || isExisting(toEdit)) {
      setOpenEditPopup(toEdit);
    }
  };

  const onRowClick = (id: string) => {
    const foundEntity = addedEntities.find(added => props.mapTableRowToEntity(id, added)) ??
      props.entities.find(existed => props.mapTableRowToEntity(id, existed));
    if (foundEntity) {
      setOpenEditPopup(foundEntity);
    }
  };

  const reset = () => {
    setAddedEntities([]);
    setDeletedEntities([]);
  };

  const save = () => {
    if (modified) {
      props.onSave(addedEntities, deletedEntities);
    }
  };

  const closeDeletePopup = () => setOpenDeletePopup(null);
  const closeEditPopup = () => setOpenEditPopup(null);

  const confirmDelete = () => {
    if (openDeletePopup) {
      onDelete(openDeletePopup ?? "");
    }
    closeDeletePopup();
  };

  const deleteEntityTitle = openDeletePopup ? props.getEntityTitle(openDeletePopup) : undefined;
  const editEntityTitle = openEditPopup ? props.getEntityTitle(openEditPopup) : undefined;
  const deleteEntityMessage = props.deleteMessage ?? "fusion.general.deleteConnection";

  const addItemPanel = <div className={styles.addItemPanel}>
    <AutocompleteField
      placeholder={props.optionPlaceholder ?? ""}
      options={props.options}
      onSelect={setSelectedOption}
      renderOption={props.renderOption}
      onInput={props.onSearchForOptions}
    />
    <Button variant="outlined" disabled={!selectedOption} onClick={onAdd}>Add</Button>
  </div>;

  const deletePopup = <Popup
    open={openDeletePopup !== null}
    onClose={closeDeletePopup}
    onConfirm={confirmDelete}
    primaryTitle={deleteEntityTitle?.primaryTitle ?? ""}
    secondaryTitle={deleteEntityTitle?.secondaryTitle}
    styleType="message"
  >
    <Alert severity="error">
      {intl.formatMappedMessage(deleteEntityMessage)}
    </Alert>
  </Popup>

  const editPopup = <Popup
    open={openEditPopup !== null}
    primaryTitle={editEntityTitle?.primaryTitle ?? ""}
    secondaryTitle={editEntityTitle?.secondaryTitle}
    onClose={closeEditPopup}
    hideButtons
    styleType="edit"
  >
    {openEditPopup ? <props.entityDetails
      entity={openEditPopup}
      onCancel={closeEditPopup}
      isNew={isAdded(openEditPopup)}
      onEditNew={editAddedEntity}
      editMode={props.editMode}
    /> : null}
  </Popup>

  return <div>
    <EntityTable
      columns={props.columns}
      rows={rows}
      controlPanel={props.editMode ? addItemPanel : undefined}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
      onSearch={props.onSearch}
      totalEntries={rows.length}
      onRowClick={onRowClick}
      sideFilter={props.sideFilter}
    />
    {props.editMode && <FormButtons onSave={save} onCancel={reset} disabled={!modified}/>}

    {deletePopup}
    {editPopup}
  </div>
};
