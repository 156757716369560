import {Ownership} from "@sivis/identity/api";
import {
  CreateOwnershipMutation,
  GetOwnershipsQuery,
  UpdateOwnershipMutation
} from "./ownership.generated";
import {injectedOwnershipApi} from "./ownershipEnhanced";
import {MaybeDrafted} from "@reduxjs/toolkit/dist/query/core/buildThunks";


export enum EOwnershipType {
  IDENTITY_RESOURCE_OWNERSHIP = "IDENTITY_RESOURCE_OWNERSHIP",
  IDENTITY_RESOURCE_SET_OWNERSHIP = "IDENTITY_RESOURCE_SET_OWNERSHIP",
  IDENTITY_SYSTEM_OWNERSHIP = "IDENTITY_SYSTEM_OWNERSHIP",
  IDENTITY_ROLE_OWNERSHIP = "IDENTITY_ROLE_OWNERSHIP",
  ROLE_RESOURCE_OWNERSHIP = "ROLE_RESOURCE_OWNERSHIP",
  ROLE_RESOURCE_SET_OWNERSHIP = "ROLE_RESOURCE_SET_OWNERSHIP",
  ROLE_SYSTEM_OWNERSHIP = "ROLE_SYSTEM_OWNERSHIP"
}

export enum EOwnershipEntity {
  RESOURCE = "RESOURCE",
  RESOURCE_SET = "RESOURCE_SET",
  SYSTEM = "SYSTEM",
  IDENTITY = "IDENTITY",
  ROLE = "ROLE"
}

export const OwnershipTargetTypes = {
  RESOURCE: [EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP.valueOf(), EOwnershipType.ROLE_RESOURCE_OWNERSHIP.valueOf()],
  RESOURCE_SET: [EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP.valueOf(), EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP.valueOf()],
  SYSTEM: [EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP.valueOf(), EOwnershipType.ROLE_SYSTEM_OWNERSHIP.valueOf()],
  ROLE: [EOwnershipType.IDENTITY_ROLE_OWNERSHIP.valueOf()],
}

export const OwnershipOwnerTypes = {
  IDENTITY: [EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP.valueOf(), EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP, EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP.valueOf(), EOwnershipType.IDENTITY_ROLE_OWNERSHIP.valueOf()],
  ROLE: [EOwnershipType.ROLE_RESOURCE_OWNERSHIP.valueOf(), EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP, EOwnershipType.ROLE_SYSTEM_OWNERSHIP.valueOf()],
}

export interface ExtendedOwnership extends Ownership {
  targetName?: string;
}

export const getOwnershipTargetType = (entity: Ownership) => {
  let targetType;
  if (OwnershipTargetTypes.RESOURCE.includes(entity.type)) {
    targetType = "Resource";
  } else if (OwnershipTargetTypes.RESOURCE_SET.includes(entity.type)) {
    targetType = "Resource Set";
  } else if (OwnershipTargetTypes.SYSTEM.includes(entity.type)) {
    targetType = "System";
  } else if (OwnershipTargetTypes.ROLE.includes(entity.type)) {
    targetType = "Role";
  } else {
    targetType = "Unknown";
  }
  return targetType;
}

export const getOwnershipOwnerType = (entity: Ownership) => {
  let targetType;
  if (OwnershipOwnerTypes.IDENTITY.includes(entity.type)) {
    targetType = "Identity";
  } else if (OwnershipOwnerTypes.ROLE.includes(entity.type)) {
    targetType = "Role";
  } else {
    targetType = "Unknown";
  }
  return targetType;
}

export const getOwnershipTypesForRole = (entity: Ownership) => {
  let targetType;
  if (OwnershipTargetTypes.RESOURCE.includes(entity.type)) {
    targetType = "Resource";
  } else if (OwnershipTargetTypes.RESOURCE_SET.includes(entity.type)) {
    targetType = "Resource Set";
  } else if (OwnershipTargetTypes.SYSTEM.includes(entity.type)) {
    targetType = "System";
  } else if (OwnershipOwnerTypes.IDENTITY.includes(entity.type)) {
    targetType = "Identity";
  } else {
    targetType = "Unknown";
  }
  return targetType;
}

export const resetGetIdentityOwnershipsActionCreator = (_data: CreateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.upsertQueryData> => injectedOwnershipApi.util.upsertQueryData('GetIdentityOwnerships', {
  endpointName: 'GetIdentityOwnerships',
  cacheKey: EOwnershipEntity.IDENTITY
} as any, {
  ownerships: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

export const resetGetRoleOwnershipsActionCreator = (_data: CreateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.upsertQueryData> => injectedOwnershipApi.util.upsertQueryData('GetRoleOwnerships', {
  endpointName: 'GetRoleOwnerships',
  cacheKey: EOwnershipEntity.ROLE
} as any, {
  ownerships: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

export const resetGetResourceOwnershipsActionCreator = (_data: CreateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.upsertQueryData> => injectedOwnershipApi.util.upsertQueryData('GetResourceOwnerships', {
  endpointName: 'GetResourceOwnerships',
  cacheKey: EOwnershipEntity.RESOURCE
} as any, {
  ownerships: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

export const resetGetResourceSetOwnershipsActionCreator = (_data: CreateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.upsertQueryData> => injectedOwnershipApi.util.upsertQueryData('GetResourceSetOwnerships', {
  endpointName: 'GetResourceSetOwnerships',
  cacheKey: EOwnershipEntity.RESOURCE_SET
} as any, {
  ownerships: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

export const resetGetSystemOwnershipsActionCreator = (_data: CreateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.upsertQueryData> => injectedOwnershipApi.util.upsertQueryData('GetSystemOwnerships', {
  endpointName: 'GetSystemOwnerships',
  cacheKey: EOwnershipEntity.SYSTEM
} as any, {
  ownerships: {
    edges: [],
    pageInfo: {hasNextPage: false, hasPreviousPage: false}
  }
});

const updateOwnershipsCache = (data: UpdateOwnershipMutation, currentCacheData: MaybeDrafted<GetOwnershipsQuery>) => {
  const updatedOwnership = data.updateOwnership;
  if (updatedOwnership) {
    (currentCacheData?.ownerships?.edges ?? []).forEach(ownership => {
      if (ownership?.node.id === updatedOwnership.id) {
        ownership.node = {
          ...ownership.node,
          ...updatedOwnership
        };
      }
    });
  }
}

export const updateGetIdentityOwnershipsActionCreator = (data: UpdateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.updateQueryData> =>
  injectedOwnershipApi.util.updateQueryData('GetIdentityOwnerships', {
    endpointName: injectedOwnershipApi.endpoints.GetIdentityOwnerships.name,
    cacheKey: EOwnershipEntity.IDENTITY
  } as any, currentCacheData => {
    updateOwnershipsCache(data, currentCacheData);
  });

export const updateGetRoleOwnershipsActionCreator = (data: UpdateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.updateQueryData> =>
  injectedOwnershipApi.util.updateQueryData('GetRoleOwnerships', {
    endpointName: injectedOwnershipApi.endpoints.GetRoleOwnerships.name,
    cacheKey: EOwnershipEntity.ROLE
  } as any, currentCacheData => {
    updateOwnershipsCache(data, currentCacheData);
  });

export const updateGetResourceOwnershipsActionCreator = (data: UpdateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.updateQueryData> =>
  injectedOwnershipApi.util.updateQueryData('GetResourceOwnerships', {
    endpointName: injectedOwnershipApi.endpoints.GetResourceOwnerships.name,
    cacheKey: EOwnershipEntity.RESOURCE
  } as any, currentCacheData => {
    updateOwnershipsCache(data, currentCacheData);
  });

export const updateGetResourceSetOwnershipsActionCreator = (data: UpdateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.updateQueryData> =>
  injectedOwnershipApi.util.updateQueryData('GetResourceSetOwnerships', {
    endpointName: injectedOwnershipApi.endpoints.GetResourceSetOwnerships.name,
    cacheKey: EOwnershipEntity.RESOURCE_SET
  } as any, currentCacheData => {
    updateOwnershipsCache(data, currentCacheData);
  });

export const updateGetSystemOwnershipsActionCreator = (data: UpdateOwnershipMutation): ReturnType<typeof injectedOwnershipApi.util.updateQueryData> =>
  injectedOwnershipApi.util.updateQueryData('GetSystemOwnerships', {
    endpointName: injectedOwnershipApi.endpoints.GetSystemOwnerships.name,
    cacheKey: EOwnershipEntity.SYSTEM
  } as any, currentCacheData => {
    updateOwnershipsCache(data, currentCacheData);
  });

export const isEOwnershipEntity = (value: any): value is EOwnershipEntity => {
  return Object.values(EOwnershipEntity).includes(value);
};
