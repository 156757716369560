import React, {FunctionComponent} from 'react';
import styles from "./Header.module.scss";
import SideBarToggleButton from "./SideBarToggleButton";
import {useDispatch, useSelector} from 'react-redux';
import {selectHome, selectSidebarClosed, sidebarToggle} from '@sivis/app-shell';
import {DEFAULT_HEADER_TITLE_INTL_ID, HeaderItems} from './HeaderItems';
import {useNavigate} from 'react-router-dom';
import {IntlTooltip, useCustomIntl} from "@sivis/intl";
import {useRegistryNavigator} from "@sivis/shared/components/registry";
import {ELogoPlace, Logo} from '@sivis/shared/components/logo';


interface HeaderProps {
  alwaysShowSidebar?: boolean;
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useCustomIntl();
  const registryNavigate = useRegistryNavigator();

  const sidebarClosed = useSelector(selectSidebarClosed);
  const home = useSelector(selectHome);

  const onLogoClick = () => {
    home && registryNavigate(home);
  };

  const toggleSidebar = () => {
    dispatch(sidebarToggle());
  };

  const onToggleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleSidebar();
    }
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const backArrow = (
    <IntlTooltip intlId="general.back">
      <div className={styles.backArrow} onClick={handleBackNavigation}>
        <div className={styles.innerArrow}></div>
      </div>
    </IntlTooltip>
  );

  return <div className={styles.header} role="navigation">
    {!props.alwaysShowSidebar && <SideBarToggleButton clicked={toggleSidebar}
                                                      isClosed={sidebarClosed}
                                                      keyDown={onToggleButtonKeyDown}/>}
    <IntlTooltip intlId={DEFAULT_HEADER_TITLE_INTL_ID}>
      <div className={styles.company} onClick={onLogoClick}
           onKeyDown={e => (e.key === "Enter" || e.key === " ") && onLogoClick()}
           role="button" tabIndex={0}
           aria-label={intl.formatMappedMessage(DEFAULT_HEADER_TITLE_INTL_ID)}>
        <Logo place={ELogoPlace.NAVBAR} alt="Logo" className={styles.sivislogo}/>
      </div>
    </IntlTooltip>
    <HeaderItems>
      {props.children}
    </HeaderItems>
    {backArrow}
  </div>;
};

export default Header;
