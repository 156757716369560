import { FilterEntry, FilterExpression, SideFilterQueryType, useGetSystemTypeFilterEntriesQuery } from '@sivis/identity/api';
import { useCustomIntl } from '@sivis/intl';
import { resetTable, SideFilterNew } from '@sivis/shared/components/entityView';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { compareFilterEntries } from './useOwnershipsSideFilter';

const buildSystemTypeFilterGroup = (filters: FilterEntry[], title: string) => ({
  id: "systemType",
  title,
  filters: filters.map(entry => ({
    id: entry.id,
    text: entry.text ?? "",
    count: entry.count,
    filter: {
      name: "systemType.id",
      operator: "EQ",
      uuidValue: entry.id
    }
  }))
});

export const useSystemsSideFilter = (queryType: SideFilterQueryType) => {
  const [filter, setFilter] = useState<FilterExpression | null>(null);
  const [systemTypeFilters, setSystemTypeFilters] = useState<FilterEntry[]>([]);
  const intl = useCustomIntl();

  const {data: dataSystemTypeFilters} = useGetSystemTypeFilterEntriesQuery();

  useEffect(() => {
    const newSystemTypeFilters = (dataSystemTypeFilters?.systemTypeFilterEntries?.filter(entry => entry != null) ?? []) as FilterEntry[];
    newSystemTypeFilters.sort(compareFilterEntries);
    setSystemTypeFilters(newSystemTypeFilters);
  }, [dataSystemTypeFilters?.systemTypeFilterEntries]);

  const dispatch = useDispatch();
  const onSetFilter = (newFilter: FilterExpression | null) => {
    dispatch(resetTable(queryType)());
    setFilter(newFilter);
  }

  const systemsFilter = <SideFilterNew
    title={(intl.format("fusion.general.filter")).toUpperCase()}
    onFilterChange={onSetFilter}
    filterGroups={[
      buildSystemTypeFilterGroup(systemTypeFilters, intl.format("fusion.system.propertyName.systemType"))
    ]}
  />;

  return {
    activeFilter: filter,
    filterComponent: systemsFilter
  }
}

