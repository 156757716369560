import {
  emptyResourceTypeInput,
  ResourceType,
  ResourceTypeInput,
  resourceTypeInputFactory,
  useGetResourceTypeQuery
} from '@sivis/identity/api';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {newId} from "../common/newId";


export const useResourceTypeApi = () => {

  const {id = newId} = useParams<{ id: string }>();
  const isNew = id === newId;
  const {data, error, isLoading, refetch} = useGetResourceTypeQuery({id}, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [resourceTypeInput, setResourceTypeInput] = useState<ResourceTypeInput>(emptyResourceTypeInput());
  const [resourceType, setResourceType] = useState<ResourceType>();

  useEffect(() => {
    setResourceTypeInput(resourceTypeInputFactory(data?.resourceType));
    setResourceType(data?.resourceType ?? undefined);
  }, [data?.resourceType]);

  const goBack = () => navigate("/resource-type");

  return {
    id,
    resourceType,
    resourceTypeInput,
    isNew,
    error,
    onCancel: goBack,
    isLoading,
    refetch
  };
};

