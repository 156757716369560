import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetEntitlementsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetEntitlementsQuery = { __typename?: 'Query', entitlements?: { __typename?: 'EntitlementConnection', edges: Array<{ __typename?: 'EntitlementEdge', node: { __typename?: 'Entitlement', id: string, entitledId: string, targetId: string, type: string, resourceTypeId?: string | null, systemId?: string | null, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetEntitlementQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetEntitlementQuery = { __typename?: 'Query', entitlement?: { __typename?: 'Entitlement', id: string, entitledId: string, targetId: string, type: string, resourceTypeId?: string | null, systemId?: string | null, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null } | null };

export type GetEntitlementsResourceTypesQueryVariables = Types.Exact<{
  entitledId: Types.Scalars['ID'];
  type: Types.Scalars['String'];
}>;


export type GetEntitlementsResourceTypesQuery = { __typename?: 'Query', entitlementsResourceTypes: Array<{ __typename?: 'FilterEntry', id: string, count: number } | null> };

export type GetEntitlementsSystemsQueryVariables = Types.Exact<{
  entitledId: Types.Scalars['ID'];
  type: Types.Scalars['String'];
}>;


export type GetEntitlementsSystemsQuery = { __typename?: 'Query', entitlementsSystems: Array<{ __typename?: 'FilterEntry', id: string, count: number } | null> };

export type GetEntitlementsValidityQueryVariables = Types.Exact<{
  entitledId: Types.Scalars['ID'];
  type: Types.Scalars['String'];
}>;


export type GetEntitlementsValidityQuery = { __typename?: 'Query', entitlementsValidity: Array<{ __typename?: 'FilterEntry', id: string, count: number, text?: string | null, filter?: { __typename?: 'FilterExpressionOutput', operator: string, logicalValue?: Array<{ __typename?: 'FilterExpressionOutput', name?: string | null, operator: string, dateValue?: string | null } | null> | null } | null } | null> };

export type CreateEntitlementMutationVariables = Types.Exact<{
  entitledId: Types.Scalars['ID'];
  targetId: Types.Scalars['ID'];
  type: Types.Scalars['String'];
  input?: Types.InputMaybe<Types.EntitlementInput>;
}>;


export type CreateEntitlementMutation = { __typename?: 'Mutation', createEntitlement: { __typename?: 'Entitlement', id: string, entitledId: string, targetId: string, type: string, resourceTypeId?: string | null, systemId?: string | null, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null } };

export type UpdateEntitlementMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.EntitlementInput>;
}>;


export type UpdateEntitlementMutation = { __typename?: 'Mutation', updateEntitlement: { __typename?: 'Entitlement', id: string, entitledId: string, targetId: string, type: string, resourceTypeId?: string | null, systemId?: string | null, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null } };

export type DeleteEntitlementMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  type?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DeleteEntitlementMutation = { __typename?: 'Mutation', deleteEntitlement: { __typename?: 'Entitlement', id: string, entitledId: string, targetId: string, type: string } };


export const GetEntitlementsDocument = `
    query GetEntitlements($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  entitlements(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        entitledId
        targetId
        type
        validity {
          validFrom
          validTo
        }
        resourceTypeId
        systemId
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetEntitlementDocument = `
    query GetEntitlement($id: ID!) {
  entitlement(id: $id) {
    id
    entitledId
    targetId
    type
    validity {
      validFrom
      validTo
    }
    resourceTypeId
    systemId
  }
}
    `;
export const GetEntitlementsResourceTypesDocument = `
    query GetEntitlementsResourceTypes($entitledId: ID!, $type: String!) {
  entitlementsResourceTypes(entitledId: $entitledId, type: $type) {
    id
    count
  }
}
    `;
export const GetEntitlementsSystemsDocument = `
    query GetEntitlementsSystems($entitledId: ID!, $type: String!) {
  entitlementsSystems(entitledId: $entitledId, type: $type) {
    id
    count
  }
}
    `;
export const GetEntitlementsValidityDocument = `
    query GetEntitlementsValidity($entitledId: ID!, $type: String!) {
  entitlementsValidity(entitledId: $entitledId, type: $type) {
    id
    count
    text
    filter {
      operator
      logicalValue {
        name
        operator
        dateValue
      }
    }
  }
}
    `;
export const CreateEntitlementDocument = `
    mutation CreateEntitlement($entitledId: ID!, $targetId: ID!, $type: String!, $input: EntitlementInput) {
  createEntitlement(
    entitledId: $entitledId
    targetId: $targetId
    type: $type
    input: $input
  ) {
    id
    entitledId
    targetId
    type
    validity {
      validFrom
      validTo
    }
    resourceTypeId
    systemId
  }
}
    `;
export const UpdateEntitlementDocument = `
    mutation UpdateEntitlement($id: ID!, $input: EntitlementInput) {
  updateEntitlement(id: $id, input: $input) {
    id
    entitledId
    targetId
    type
    validity {
      validFrom
      validTo
    }
    resourceTypeId
    systemId
  }
}
    `;
export const DeleteEntitlementDocument = `
    mutation DeleteEntitlement($id: ID!, $type: String) {
  deleteEntitlement(id: $id, type: $type) {
    id
    entitledId
    targetId
    type
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetEntitlements: build.query<GetEntitlementsQuery, GetEntitlementsQueryVariables | void>({
      query: (variables) => ({ document: GetEntitlementsDocument, variables })
    }),
    GetEntitlement: build.query<GetEntitlementQuery, GetEntitlementQueryVariables>({
      query: (variables) => ({ document: GetEntitlementDocument, variables })
    }),
    GetEntitlementsResourceTypes: build.query<GetEntitlementsResourceTypesQuery, GetEntitlementsResourceTypesQueryVariables>({
      query: (variables) => ({ document: GetEntitlementsResourceTypesDocument, variables })
    }),
    GetEntitlementsSystems: build.query<GetEntitlementsSystemsQuery, GetEntitlementsSystemsQueryVariables>({
      query: (variables) => ({ document: GetEntitlementsSystemsDocument, variables })
    }),
    GetEntitlementsValidity: build.query<GetEntitlementsValidityQuery, GetEntitlementsValidityQueryVariables>({
      query: (variables) => ({ document: GetEntitlementsValidityDocument, variables })
    }),
    CreateEntitlement: build.mutation<CreateEntitlementMutation, CreateEntitlementMutationVariables>({
      query: (variables) => ({ document: CreateEntitlementDocument, variables })
    }),
    UpdateEntitlement: build.mutation<UpdateEntitlementMutation, UpdateEntitlementMutationVariables>({
      query: (variables) => ({ document: UpdateEntitlementDocument, variables })
    }),
    DeleteEntitlement: build.mutation<DeleteEntitlementMutation, DeleteEntitlementMutationVariables>({
      query: (variables) => ({ document: DeleteEntitlementDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEntitlementsQuery, useLazyGetEntitlementsQuery, useGetEntitlementQuery, useLazyGetEntitlementQuery, useGetEntitlementsResourceTypesQuery, useLazyGetEntitlementsResourceTypesQuery, useGetEntitlementsSystemsQuery, useLazyGetEntitlementsSystemsQuery, useGetEntitlementsValidityQuery, useLazyGetEntitlementsValidityQuery, useCreateEntitlementMutation, useUpdateEntitlementMutation, useDeleteEntitlementMutation } = injectedRtkApi;

