import { httpPost } from '@sivis/http-client';
import { b64EncodeUnicode } from '@sivis/shared/utils';
import { EAuthProvider } from '@sivis/shared/types';

export const LOGIN_URL = '/login';

const SIVIS_WEB_AUTH_PATH = '/fusion/auth/login';

interface SivisWebTokenResponse {
  token: string;
}

export const adminLogin = (username: string, password: string) => {
  const encryptedPassword = b64EncodeUnicode(password);
  const authConfig = {
    usernameOrEmail: username,
    password: encryptedPassword,
    authenticationProvider: EAuthProvider.ADMIN
  };
  return httpPost<SivisWebTokenResponse>(SIVIS_WEB_AUTH_PATH, authConfig);
};
