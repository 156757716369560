import {useEffect, useState} from 'react';
import {OetRelation, useLazyGetOetRelationQuery} from '@sivis/identity/api';

export const useGetOetRelation = (id?: string) => {

  const [trigger] = useLazyGetOetRelationQuery();
  const [oetRelation, setOetRelation] = useState<OetRelation | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {

    if (id) {
      setLoading(true)
      trigger({id}).then(
        (res) => {
          setOetRelation(res.data?.oetRelation ?? undefined)
        }
      ).catch((err) => {
          console.error(err)
        }
      ).finally(() => {
          setLoading(false)
        }
      )
    }
  }, [id]);

  return {oetRelation, loading}
};
