import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetBusinessRuleQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBusinessRuleQuery = { __typename?: 'Query', businessRule?: { __typename?: 'BusinessRule', id: string, name: string, targetValue: string, ruleAction: Types.RuleAction, status: Types.RuleStatus, changes?: string | null, operatorOutsideGroup: Types.RuleLogicalOperator, isPublished?: boolean | null, publishedId?: string | null, criteriaGroups?: Array<{ __typename?: 'CriteriaGroup', id: string, criteriaInsideGroup: Array<{ __typename?: 'Criterion', id: string, entityField: string, value: string, operation: Types.RuleOperation, negate?: boolean | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null }> }> | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type GetBusinessRulesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetBusinessRulesQuery = { __typename?: 'Query', businessRules?: { __typename?: 'BusinessRuleConnection', edges: Array<{ __typename?: 'BusinessRuleEdge', node: { __typename?: 'BusinessRule', id: string, name: string, targetValue: string, ruleAction: Types.RuleAction, status: Types.RuleStatus, changes?: string | null, operatorOutsideGroup: Types.RuleLogicalOperator, isPublished?: boolean | null, publishedId?: string | null, criteriaGroups?: Array<{ __typename?: 'CriteriaGroup', id: string, criteriaInsideGroup: Array<{ __typename?: 'Criterion', id: string, entityField: string, value: string, operation: Types.RuleOperation, negate?: boolean | null }> }> | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetBusinessRuleStatusFilterEntriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessRuleStatusFilterEntriesQuery = { __typename?: 'Query', businessRuleStatusFilterEntries: Array<{ __typename?: 'FilterEntry', id: string, count: number, text?: string | null } | null> };

export type GetDraftForBusinessRuleQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetDraftForBusinessRuleQuery = { __typename?: 'Query', getDraftForBusinessRule?: { __typename?: 'BusinessRule', id: string, name: string, targetValue: string, ruleAction: Types.RuleAction, status: Types.RuleStatus, changes?: string | null, operatorOutsideGroup: Types.RuleLogicalOperator, isPublished?: boolean | null, publishedId?: string | null, criteriaGroups?: Array<{ __typename?: 'CriteriaGroup', id: string, criteriaInsideGroup: Array<{ __typename?: 'Criterion', id: string, entityField: string, value: string, operation: Types.RuleOperation, negate?: boolean | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null }> }> | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type CreateBusinessRuleMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.BusinessRuleInput>;
}>;


export type CreateBusinessRuleMutation = { __typename?: 'Mutation', createBusinessRule: { __typename?: 'BusinessRule', id: string, name: string, targetValue: string, ruleAction: Types.RuleAction } };

export type UpdateBusinessRuleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.BusinessRuleInput>;
}>;


export type UpdateBusinessRuleMutation = { __typename?: 'Mutation', updateBusinessRule: { __typename?: 'BusinessRule', id: string, name: string, targetValue: string, ruleAction: Types.RuleAction } };

export type DeleteBusinessRuleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteBusinessRuleMutation = { __typename?: 'Mutation', deleteBusinessRule: { __typename?: 'BusinessRule', id: string, name: string } };


export const GetBusinessRuleDocument = `
    query GetBusinessRule($id: ID!) {
  businessRule(id: $id) {
    id
    name
    targetValue
    ruleAction
    status
    changes
    operatorOutsideGroup
    criteriaGroups {
      id
      criteriaInsideGroup {
        id
        entityField
        value
        operation
        negate
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    isPublished
    publishedId
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const GetBusinessRulesDocument = `
    query GetBusinessRules($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  businessRules(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        name
        targetValue
        ruleAction
        status
        changes
        operatorOutsideGroup
        criteriaGroups {
          id
          criteriaInsideGroup {
            id
            entityField
            value
            operation
            negate
          }
        }
        isPublished
        publishedId
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetBusinessRuleStatusFilterEntriesDocument = `
    query GetBusinessRuleStatusFilterEntries {
  businessRuleStatusFilterEntries {
    id
    count
    text
  }
}
    `;
export const GetDraftForBusinessRuleDocument = `
    query GetDraftForBusinessRule($id: ID!) {
  getDraftForBusinessRule(id: $id) {
    id
    name
    targetValue
    ruleAction
    status
    changes
    operatorOutsideGroup
    criteriaGroups {
      id
      criteriaInsideGroup {
        id
        entityField
        value
        operation
        negate
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    isPublished
    publishedId
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const CreateBusinessRuleDocument = `
    mutation CreateBusinessRule($input: BusinessRuleInput) {
  createBusinessRule(input: $input) {
    id
    name
    targetValue
    ruleAction
  }
}
    `;
export const UpdateBusinessRuleDocument = `
    mutation UpdateBusinessRule($id: ID!, $input: BusinessRuleInput) {
  updateBusinessRule(id: $id, input: $input) {
    id
    name
    targetValue
    ruleAction
  }
}
    `;
export const DeleteBusinessRuleDocument = `
    mutation DeleteBusinessRule($id: ID!) {
  deleteBusinessRule(id: $id) {
    id
    name
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetBusinessRule: build.query<GetBusinessRuleQuery, GetBusinessRuleQueryVariables>({
      query: (variables) => ({ document: GetBusinessRuleDocument, variables })
    }),
    GetBusinessRules: build.query<GetBusinessRulesQuery, GetBusinessRulesQueryVariables | void>({
      query: (variables) => ({ document: GetBusinessRulesDocument, variables })
    }),
    GetBusinessRuleStatusFilterEntries: build.query<GetBusinessRuleStatusFilterEntriesQuery, GetBusinessRuleStatusFilterEntriesQueryVariables | void>({
      query: (variables) => ({ document: GetBusinessRuleStatusFilterEntriesDocument, variables })
    }),
    GetDraftForBusinessRule: build.query<GetDraftForBusinessRuleQuery, GetDraftForBusinessRuleQueryVariables>({
      query: (variables) => ({ document: GetDraftForBusinessRuleDocument, variables })
    }),
    CreateBusinessRule: build.mutation<CreateBusinessRuleMutation, CreateBusinessRuleMutationVariables | void>({
      query: (variables) => ({ document: CreateBusinessRuleDocument, variables })
    }),
    UpdateBusinessRule: build.mutation<UpdateBusinessRuleMutation, UpdateBusinessRuleMutationVariables>({
      query: (variables) => ({ document: UpdateBusinessRuleDocument, variables })
    }),
    DeleteBusinessRule: build.mutation<DeleteBusinessRuleMutation, DeleteBusinessRuleMutationVariables>({
      query: (variables) => ({ document: DeleteBusinessRuleDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetBusinessRuleQuery, useLazyGetBusinessRuleQuery, useGetBusinessRulesQuery, useLazyGetBusinessRulesQuery, useGetBusinessRuleStatusFilterEntriesQuery, useLazyGetBusinessRuleStatusFilterEntriesQuery, useGetDraftForBusinessRuleQuery, useLazyGetDraftForBusinessRuleQuery, useCreateBusinessRuleMutation, useUpdateBusinessRuleMutation, useDeleteBusinessRuleMutation } = injectedRtkApi;

