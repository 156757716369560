import {
  Identity,
  IdentityInput,
  identityInputFactory,
  useCreateIdentityMutation,
  useDeleteIdentityMutation,
  useGetIdentityQuery,
  useUpdateIdentityMutation
} from "@sivis/identity/api";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {newId} from "../common/newId";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";

export const useIdentityApi = () => {
  const { id = newId } = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const { data, error, isLoading, refetch } = useGetIdentityQuery({ id }, { skip: isNew, refetchOnMountOrArgChange: true });
  const navigate = useNavigate();
  const [deleteIdentity] = useDeleteIdentityMutation()
  const [updateIdentity] = useUpdateIdentityMutation();
  const [createIdentity] = useCreateIdentityMutation();
  const [input, setInput] = useState<IdentityInput>(identityInputFactory());
  const [identity, setIdentity] = useState<Identity>();
  const { editMode, toggleEditMode } = useSingleEditMode(EntityType.IDENTITY, isNew);

  useEffect(() => {
    if (data?.identity) {
      // Initialize input with current identity values
      const initialInput = identityInputFactory(data.identity);
      // Set the identityType to the current value
      initialInput.identityType = data.identity.identityType?.id || '';
      setInput(initialInput);
    } else {
      // Reset input for new identity
      setInput(identityInputFactory());
    }
    setIdentity(data?.identity ?? undefined);
  }, [data?.identity]);


  const goBack = () => navigate("/identity");

  let onSave;
  let onDelete;
  let onCancel;

  if (isNew) {
    onSave = (value: Record<string, any>) => {
      createIdentity({ input: identityInputFactory(value) }).then(goBack);
    };
    onCancel = () => {
      goBack();
    };
  } else {
    onSave = (value: Record<string, any>) => {
      updateIdentity({ id, input: identityInputFactory(value) })
        .then(() => {
          toggleEditMode();
          refetch();
        });
    };
    onCancel = () => {
      toggleEditMode();
      refetch();
    };
    onDelete = () => {
      deleteIdentity({ id }).then(goBack);
    };
  }


  return {
    id,
    identity,
    defaultInput: input,
    error,
    isNew,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
}
