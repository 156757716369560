import { useListSearch } from '../../utils/filterUtils';
import {
  AccountRoleMembership,
  GeneratedAccountRoleMembershipTypes,
  getIdentityAndRoleFilter,
  searchMembershipAccountsFilter,
  useGetAccountRoleMembershipsQuery,
  useUpdateAccountRoleMembershipMutation
} from '@sivis/identity/api';
import { combineFilter, FilterOperator } from '@sivis/shared/api-utils';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../../common/parsePageResult';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import { useState } from 'react';
import { parseValidityInput } from '../../utils/validityUtils';

export const isEqual = (a: AccountRoleMembership, b: AccountRoleMembership) => a.id === b.id;

export const useAccountRoleMembershipsApi = ({identityId, roleId}: {
  identityId: string;
  roleId: string;
}) => {
  const {filter: searchFilter, onSearch} = useListSearch(searchMembershipAccountsFilter);
  const identityAndRoleFilter = getIdentityAndRoleFilter(identityId, roleId);
  const filter = searchFilter ? combineFilter(FilterOperator.AND, searchFilter, identityAndRoleFilter)
    : identityAndRoleFilter;
  const [updateAccountRoleMembership] = useUpdateAccountRoleMembershipMutation();
  const [membershipsToUpdate, setMembershipsToUpdate] = useState<AccountRoleMembership[]>([]);

  const useAccountRoleMembershipsPageQuery = (cursor: string | null) => useGetAccountRoleMembershipsQuery(
    {first: DEFAULT_PAGE_SIZE, after: cursor, sort: DEFAULT_ENTITY_SORT, filter}
  );

  const parseAccountRoleMembershipsPageResult = (res?: GeneratedAccountRoleMembershipTypes.GetAccountRoleMembershipsQuery): CursorBasedPageResult<AccountRoleMembership> => {
    return parseCursorBasedPageResult('accountRoleMemberships', res);
  };

  const updateMembership = (toUpdate: AccountRoleMembership) => {
    setMembershipsToUpdate([
      ...membershipsToUpdate.filter(membership => !isEqual(membership, toUpdate)),
      toUpdate
    ]);
  };

  const submitUpdates = (): Promise<void> => {
    return Promise.all(membershipsToUpdate.map(membership => updateAccountRoleMembership({
      id: membership.id,
      input: {
        validity: parseValidityInput(membership.validity ?? undefined),
        active: membership.active
      }
    }))).then(() => {
    });
  };

  return {
    onSearch,
    pageSize: DEFAULT_PAGE_SIZE,
    useAccountRoleMembershipsPageQuery,
    parseAccountRoleMembershipsPageResult,
    updateMembership,
    membershipsToUpdate,
    submitUpdates
  };
};
