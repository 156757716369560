import * as Types from '../attributeSyncApi.types';

import { attributeSyncBaseApi } from '../attributeSyncBaseApi';

            /* eslint-disable */
              /**
               *
               * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
               *
               * instead, edit one of the `.graphql` files in this project and run
               *
               * npm run graphql-codegen
               *
               * for this file to be re-created
               */
               
export type GetAttributeSyncMappingsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAttributeSyncMappingsQuery = { __typename?: 'Query', asMappings?: { __typename?: 'AsMappingsConnection', edges?: Array<{ __typename?: 'AsMappingsEdge', node: { __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };

export type CreateAttributeSyncMappingMutationVariables = Types.Exact<{
  input: Types.AsmInput;
}>;


export type CreateAttributeSyncMappingMutation = { __typename?: 'Mutation', addASMapping: { __typename?: 'AsMappingPayload', asMapping: { __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any } } };

export type UpdateAttributeSyncMappingMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.AsmInput;
}>;


export type UpdateAttributeSyncMappingMutation = { __typename?: 'Mutation', updateASMapping: { __typename?: 'AsMappingPayload', asMapping: { __typename?: 'ASMapping', id: any, fieldType?: string | null, internalAttribute?: string | null, externalAttribute?: string | null, direction: Types.AsDirections, synchronizationMethod: Types.AsMethods, priority?: number | null, asDefinitionId: any } } };

export type DeleteAttributeSyncMappingMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteAttributeSyncMappingMutation = { __typename?: 'Mutation', deleteASMapping: any };


export const GetAttributeSyncMappingsDocument = `
    query GetAttributeSyncMappings($first: Int, $after: String) {
  asMappings(first: $first, after: $after) {
    edges {
      node {
        id
        fieldType
        internalAttribute
        externalAttribute
        direction
        synchronizationMethod
        priority
        asDefinitionId
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;
export const CreateAttributeSyncMappingDocument = `
    mutation CreateAttributeSyncMapping($input: AsmInput!) {
  addASMapping(input: $input) {
    asMapping {
      id
      fieldType
      internalAttribute
      externalAttribute
      direction
      synchronizationMethod
      priority
      asDefinitionId
    }
  }
}
    `;
export const UpdateAttributeSyncMappingDocument = `
    mutation UpdateAttributeSyncMapping($id: UUID!, $input: AsmInput!) {
  updateASMapping(id: $id, input: $input) {
    asMapping {
      id
      fieldType
      internalAttribute
      externalAttribute
      direction
      synchronizationMethod
      priority
      asDefinitionId
    }
  }
}
    `;
export const DeleteAttributeSyncMappingDocument = `
    mutation DeleteAttributeSyncMapping($id: UUID!) {
  deleteASMapping(id: $id)
}
    `;

const injectedRtkApi = attributeSyncBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetAttributeSyncMappings: build.query<GetAttributeSyncMappingsQuery, GetAttributeSyncMappingsQueryVariables | void>({
      query: (variables) => ({ document: GetAttributeSyncMappingsDocument, variables })
    }),
    CreateAttributeSyncMapping: build.mutation<CreateAttributeSyncMappingMutation, CreateAttributeSyncMappingMutationVariables>({
      query: (variables) => ({ document: CreateAttributeSyncMappingDocument, variables })
    }),
    UpdateAttributeSyncMapping: build.mutation<UpdateAttributeSyncMappingMutation, UpdateAttributeSyncMappingMutationVariables>({
      query: (variables) => ({ document: UpdateAttributeSyncMappingDocument, variables })
    }),
    DeleteAttributeSyncMapping: build.mutation<DeleteAttributeSyncMappingMutation, DeleteAttributeSyncMappingMutationVariables>({
      query: (variables) => ({ document: DeleteAttributeSyncMappingDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAttributeSyncMappingsQuery, useLazyGetAttributeSyncMappingsQuery, useCreateAttributeSyncMappingMutation, useUpdateAttributeSyncMappingMutation, useDeleteAttributeSyncMappingMutation } = injectedRtkApi;

