import {Outlet, RouteObject} from "react-router-dom";
import {ResourceSetList} from "./resourceSetList";
import {
  ResourceSetInformationTabWrapperComponent,
  ResourceSetOwnershipTabWrapperComponent,
  ResourceSetResourcesTabWrapperComponent,
  ResourceSetView
} from "./resourceSetView";
import {useGetResourceSetQuery} from "@sivis/identity/api";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import {
  IDENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCES_TAB_PATHNAME,
  ROLES_TAB_PATHNAME
} from "../components/routes/constants";
import {EntityType} from "../components/breadcrumb/editModeContext";
import ResourceSetIdentitiesTab from "./resourceSetIdentitiesTab";
import ResourceSetRolesTab from "./resourceSetRolesTab";

const useGetResourceSetName = (id: string, isNew: boolean) => {
  const {data} = useGetResourceSetQuery({id}, {skip: isNew});
  return data?.resourceSet?.name;
};

export const resourceSetRoutes: RouteObject[] = [
  {
    index: true,
    element: <ResourceSetList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetResourceSetName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <ResourceSetView/>,
      },
      {
        element: <ResourceSetView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <ResourceSetInformationTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.RESOURCE_SET
              })
            }
          },
          {
            path: IDENTITIES_TAB_PATHNAME,
            element: <ResourceSetIdentitiesTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.identity.list.name",
                intlIdEdit: "fusion.breadcrumb.identities.edit",
                entity: EntityType.RESOURCE_SET
              })
            }
          },
          {
            path: ROLES_TAB_PATHNAME,
            element: <ResourceSetRolesTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.role.list.name",
                intlIdEdit: "fusion.breadcrumb.roles.edit",
                entity: EntityType.RESOURCE_SET
              })
            }
          },
          {
            path: RESOURCES_TAB_PATHNAME,
            element: <ResourceSetResourcesTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.resource.list.name",
                intlIdEdit: "fusion.breadcrumb.resources.edit",
                entity: EntityType.RESOURCE_SET
              })
            }
          },
          {
            path: OWNERSHIP_TAB_PATHNAME,
            element: <ResourceSetOwnershipTabWrapperComponent/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.ownership",
                intlIdEdit: "fusion.breadcrumb.ownership.edit",
                entity: EntityType.RESOURCE_SET
              })
            }
          }
        ]
      }
    ]
  }
];
