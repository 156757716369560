import { AccountType, accountTypeInputFactory } from "@sivis/identity/api";
import { EntityInformation } from "@sivis/shared/components/entityView";
import styles from "./accountType.module.scss";
import { useTranslateConfigs } from "@sivis/intl";

export interface AccountTypeInformationTabProps {
  accountType: AccountType | undefined,
  isNew: boolean,
  onSave: (value: Record<string, any>) => void,
  onCancel: () => void,
  editMode: boolean,
}

const AccountTypeInformationTab = (props: AccountTypeInformationTabProps) => {

  const flattenedAccountType = props.accountType ? {
    ...props.accountType
  } : {};

  const informationConfigs = [
    {
      property: 'name',
      required: true,
    },
    {
      property: 'apiName',
    },
    {
      property: 'description'
    },
  ];

  const translatedConfigs = useTranslateConfigs(informationConfigs, "fusion.accountType.propertyName");

  const entityInformation = <EntityInformation
    editMode={props.editMode}
    configs={translatedConfigs}
    value={props.isNew ? accountTypeInputFactory() : flattenedAccountType}
    onSubmit={props.onSave}
    onCancel={props.onCancel}
  />;

  return <div className={styles.listContainer}>
    {entityInformation}
  </div>;
};

export default AccountTypeInformationTab;
