import {searchSystemsFilter, useGetSystemsQuery} from "@sivis/identity/api";
import {DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE} from "../common/parsePageResult";
import {useListSearch} from "../utils/filterUtils";
import {combineFilter, FilterOperator} from "@sivis/shared/api-utils";
import {parseSystemsPageResult} from "../system/useSystemsPageApi";

export const useSystemPageForSystemTypeApi = (systemTypeId?: string) => {
  const {filter: searchFilter, onSearch} = useListSearch(searchSystemsFilter);
  const systemTypeFilter = {
    operator: FilterOperator.EQ,
    name: "systemType.id",
    uuidValue: systemTypeId
  };
  const filter = searchFilter ? combineFilter(FilterOperator.AND, searchFilter, systemTypeFilter)
    : systemTypeFilter;

  const useSystemPageForSystemTypePageQuery = (cursor: string | null) => useGetSystemsQuery(
    {
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter
    }, {skip: !systemTypeId});

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useSystemPageForSystemTypePageQuery,
    parseSystemsPageResult
  };
};

