import {
  Identity,
  identityName,
  IdentityRoleMembership,
  Role,
  SideFilterQueryType
} from "@sivis/identity/api";
import React from "react";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";
import {ListAddView, TabBar} from "@sivis/shared/components/entityView";
import styles from "./membership.module.scss";
import {GridColDef} from "@mui/x-data-grid";
import {useIdentitiesPageApi} from "../identity/useIdentitiesPageApi";
import {addButton, removeButton} from "./createMembershipForIdentity";
import {useGetIdentitiesForRoleApi} from "./api/useExistingMembershipsApi";
import {useIdentitiesSideFilter} from "../ownership/components/useIdentitiesSideFilter";

interface CreateMembershipRoleProps {
  role: Role;
  onAdd: (added: IdentityRoleMembership[]) => void;
}

export const CreateMembershipForRole = (props: CreateMembershipRoleProps) => {
  const intl = useCustomIntl();

  const tabs = [
    {
      label: intl.format("fusion.identity.list.name"),
      content: <CreateMembershipIdentityList {...props} />
    }
  ];

  return <TabBar tabs={tabs}/>;
};

const columns: GridColDef[] = [
  {
    field: "fullName",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

const CreateMembershipIdentityList = (props: CreateMembershipRoleProps) => {
  const {activeFilter, filterComponent} = useIdentitiesSideFilter(SideFilterQueryType.IDENTITIES);
  const {
    onSearch,
    useIdentitiesPageQuery,
    parseIdentitiesPageResult,
    pageSize
  } = useIdentitiesPageApi(activeFilter ?? undefined);
  const {identities} = useGetIdentitiesForRoleApi(props.role.id);
  const translatedColumns = useTranslateColumns(columns, "fusion.identity.propertyName");

  const entityToTableRow = (entity: Identity, isSelected: boolean) => {
    return {
      id: entity.id,
      fullName: identityName(entity),
      dataObject: entity,
      isNew: isSelected,
      action: isSelected ? removeButton : addButton
    };
  };

  const onAdd = (identities: Identity[]) => {
    props.onAdd(identities.map(identity => ({
      id: identity.id + props.role.id,
      template: true,
      role: props.role,
      identity
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={entityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useIdentitiesPageQuery}
      parseResult={parseIdentitiesPageResult}
      sideFilter={filterComponent}
      excludedItems={identities}
      tableType={SideFilterQueryType.IDENTITIES}
    />
  </div>;
};
