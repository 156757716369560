import {GridColDef} from '@mui/x-data-grid';
import {EntityView, InfiniteScrollTableCursor} from "@sivis/shared/components/entityView";
import {ResourceType} from "@sivis/identity/api";
import {Link} from "react-router-dom";
import {useResourceTypesPageApi} from "./useResourceTypePageApi";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: ResourceType) => {
  return {
    id: entity.id,
    name: entity.name,
    systemTypeName: entity.systemType?.systemTypeName,
    description: entity.description,
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "systemTypeName",
    flex: 1,
  },
  {
    field: "description",
    flex: 1,
  }];

export const ResourceTypeList = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useResourceTypesPageQuery,
    parseResourceTypesPageResult,
    pageSize
  } = useResourceTypesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.resourceType.propertyName");

  return <EntityView title={intl.format("fusion.resourceType.list.title")}>
    <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useResourceTypesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseResourceTypesPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
    />
  </EntityView>;
};

