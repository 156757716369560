import {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
  CreateButton,
  EntityTable,
  EntityTableRow,
  EntityView
} from '@sivis/shared/components/entityView';
import {
  GetPermissionsQueryVariables,
  Permission,
  useGetPermissionsQuery
} from "@sivis/security/api";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";

const entityToTableRow = (entity: Permission) => {
  return {
    id: entity.id,
    name: entity.name,
    description: entity.description ?? ""
  };
};

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 0.5,
    renderCell: params => <Link to={`${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: "description",
    flex: 1
  }
];

export const PermissionList = () => {
  const intl = useCustomIntl();
  const [queryVariables, setQueryVariables] = useState<GetPermissionsQueryVariables>();
  const {data} = useGetPermissionsQuery(queryVariables);
  const [rows, setRows] = useState<EntityTableRow[]>([]);
  const translatedColumns = useTranslateColumns(columns, "fusion.permission.propertyName");

  useEffect(() => {
    const newRows: EntityTableRow[] = [];
    data?.permissions?.forEach(permission => {
      if (permission) {
        newRows.push(entityToTableRow(permission));
      }
    });
    setRows(newRows);
  }, [data?.permissions]);

  const onSearch = useCallback((searchStr: string) => {
    setQueryVariables({input: {name: searchStr}});
  }, []);

  return <EntityView
    title={intl.format("fusion.permission.list.title")}
    actionButtons={[<CreateButton key={"create"}/>]}
  >
    <EntityTable columns={translatedColumns} rows={rows}
                 placeholder={intl.format("fusion.general.searchPlaceholder")}
                 onSearch={onSearch} totalEntries={rows.length}/>
  </EntityView>;
};
