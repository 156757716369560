import {Outlet, RouteObject} from "react-router-dom";
import {RoleList} from "./roleList";
import {
  RoleIdentityTab,
  RoleInformationTab,
  RoleOwnershipTab,
  RoleResourceTab,
  RoleView
} from "./roleView";
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from "../components/breadcrumb/breadcrumb";
import {useGetRoleQuery} from "@sivis/identity/api";
import {
  IDENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCES_TAB_PATHNAME
} from "../components/routes/constants";
import {EntityType} from "../components/breadcrumb/editModeContext";

const useGetRoleName = (id: string, isNew: boolean) => {
  const {data} = useGetRoleQuery({id}, {skip: isNew});
  return data?.role?.name;
};

export const roleRoutes: RouteObject[] = [
  {
    index: true,
    element: <RoleList/>
  },
  {
    path: ":id",
    element: <Outlet/>,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetRoleName,
        pathSuffix: "/information"
      })
    },
    children: [
      {
        index: true,
        element: <RoleView/>,
      },
      {
        element: <RoleView/>,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <RoleInformationTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.ROLE
              })
            }
          },
          {
            path: IDENTITIES_TAB_PATHNAME,
            element: <RoleIdentityTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.identity.list.name",
                intlIdEdit: "fusion.breadcrumb.identities.edit",
                entity: EntityType.ROLE
              })
            }
          },
          {
            path: RESOURCES_TAB_PATHNAME,
            element: <RoleResourceTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.resource.list.name",
                intlIdEdit: "fusion.breadcrumb.resources.edit",
                entity: EntityType.ROLE
              })
            }
          },
          {
            path: OWNERSHIP_TAB_PATHNAME,
            element: <RoleOwnershipTab/>,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.ownership.list.name",
                intlIdEdit: "fusion.breadcrumb.ownership.edit",
                entity: EntityType.ROLE
              })
            }
          }
        ]
      }
    ]
  }
];
