import React, { useEffect, useState } from 'react';
import DragDropComponent from '../components/fieldDefinitions/dragDropComponent';
import {
  IdentityType,
  useGetIdentityFieldDefinitionsQuery,
  IdentityFieldDefinition
} from '@sivis/identity/api';
import { useUpdateIdentityFieldDefinitionsMutation } from 'libs/identity/api/src/lib/identity/identityEnhanced';

interface IdentityTypeFieldDefinitionTabProps {
  identityType: IdentityType | undefined;
  onCancel: () => void;
  editMode: boolean;
}

const adaptData = (apiData: any, identityType: string): IdentityFieldDefinition[] => {
  return apiData.identityFieldDefinitions
    .filter((def: any) => def.identityType === identityType)
    .map((def: any) => ({
      id: def.id,
      identityType: def.identityType,
      fieldDefinitionFields: {
        ...def.fieldDefinitionFields,
        readOnly: Boolean(def.fieldDefinitionFields.readOnly),
        required: Boolean(def.fieldDefinitionFields.required),
        searchable: Boolean(def.fieldDefinitionFields.searchable),
        visible: Boolean(def.fieldDefinitionFields.visible),
        uniqueField: Boolean(def.fieldDefinitionFields.uniqueField),
        uiPosition: parseInt(def.fieldDefinitionFields.uiPosition, 10),
        uiMaxCharacters: parseInt(def.fieldDefinitionFields.uiMaxCharacters, 10),
      }
    }));
};

const IdentityTypeFieldDefinitionTab = (props: IdentityTypeFieldDefinitionTabProps) => {
  const [identityFieldDefinitions, setIdentityFieldDefinitions] = useState<IdentityFieldDefinition[]>([]);
  const { data: fieldData, error, isFetching, refetch } = useGetIdentityFieldDefinitionsQuery(undefined, {
    skip: !props.identityType,
  });
  const [updateFieldDefinitions] = useUpdateIdentityFieldDefinitionsMutation();

  useEffect(() => {
    if (error) {
      console.error("Error fetching identity field definitions:", error);
    }
    if (fieldData && props.identityType) {
      setIdentityFieldDefinitions(adaptData(fieldData, props.identityType.id.toString()));
    }
  }, [fieldData, error, props.identityType]);

  useEffect(() => {
    if (props.identityType) {
      refetch();
    }
  }, [props.identityType, refetch]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <DragDropComponent
      items={identityFieldDefinitions}
      fetchFunction={refetch}
      saveFunction={() => [updateFieldDefinitions]}
      editMode={props.editMode}
      onCancel={props.onCancel}
    />
  );
};

export default IdentityTypeFieldDefinitionTab;
