import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetSystemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetSystemQuery = { __typename?: 'Query', system?: { __typename?: 'System', id: string, systemName: string, systemDescription?: string | null, systemType?: { __typename?: 'SystemType', id: string, systemTypeName: string } | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type GetSystemsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetSystemsQuery = { __typename?: 'Query', systems?: { __typename?: 'SystemConnection', edges: Array<{ __typename?: 'SystemEdge', node: { __typename?: 'System', id: string, systemName: string, systemDescription?: string | null, systemType?: { __typename?: 'SystemType', id: string, systemTypeName: string } | null, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type GetSystemTypeFilterEntriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSystemTypeFilterEntriesQuery = { __typename?: 'Query', systemTypeFilterEntries: Array<{ __typename?: 'FilterEntry', id: string, count: number, text?: string | null } | null> };

export type CreateSystemMutationVariables = Types.Exact<{
  input: Types.SystemInput;
}>;


export type CreateSystemMutation = { __typename?: 'Mutation', createSystem: { __typename?: 'System', id: string, systemName: string, systemDescription?: string | null, systemType?: { __typename?: 'SystemType', id: string, systemTypeName: string } | null } };

export type UpdateSystemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.SystemInput;
}>;


export type UpdateSystemMutation = { __typename?: 'Mutation', updateSystem: { __typename?: 'System', id: string, systemName: string, systemDescription?: string | null, systemType?: { __typename?: 'SystemType', id: string, systemTypeName: string } | null } };

export type DeleteSystemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSystemMutation = { __typename?: 'Mutation', deleteSystem: { __typename?: 'System', id: string, systemName: string } };


export const GetSystemDocument = `
    query GetSystem($id: ID!) {
  system(id: $id) {
    id
    systemName
    systemDescription
    systemType {
      id
      systemTypeName
    }
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const GetSystemsDocument = `
    query GetSystems($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  systems(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        systemName
        systemDescription
        systemType {
          id
          systemTypeName
        }
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const GetSystemTypeFilterEntriesDocument = `
    query GetSystemTypeFilterEntries {
  systemTypeFilterEntries {
    id
    count
    text
  }
}
    `;
export const CreateSystemDocument = `
    mutation CreateSystem($input: SystemInput!) {
  createSystem(input: $input) {
    id
    systemName
    systemDescription
    systemType {
      id
      systemTypeName
    }
  }
}
    `;
export const UpdateSystemDocument = `
    mutation UpdateSystem($id: ID!, $input: SystemInput!) {
  updateSystem(id: $id, input: $input) {
    id
    systemName
    systemDescription
    systemType {
      id
      systemTypeName
    }
  }
}
    `;
export const DeleteSystemDocument = `
    mutation DeleteSystem($id: ID!) {
  deleteSystem(id: $id) {
    id
    systemName
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetSystem: build.query<GetSystemQuery, GetSystemQueryVariables>({
      query: (variables) => ({ document: GetSystemDocument, variables })
    }),
    GetSystems: build.query<GetSystemsQuery, GetSystemsQueryVariables | void>({
      query: (variables) => ({ document: GetSystemsDocument, variables })
    }),
    GetSystemTypeFilterEntries: build.query<GetSystemTypeFilterEntriesQuery, GetSystemTypeFilterEntriesQueryVariables | void>({
      query: (variables) => ({ document: GetSystemTypeFilterEntriesDocument, variables })
    }),
    CreateSystem: build.mutation<CreateSystemMutation, CreateSystemMutationVariables>({
      query: (variables) => ({ document: CreateSystemDocument, variables })
    }),
    UpdateSystem: build.mutation<UpdateSystemMutation, UpdateSystemMutationVariables>({
      query: (variables) => ({ document: UpdateSystemDocument, variables })
    }),
    DeleteSystem: build.mutation<DeleteSystemMutation, DeleteSystemMutationVariables>({
      query: (variables) => ({ document: DeleteSystemDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSystemQuery, useLazyGetSystemQuery, useGetSystemsQuery, useLazyGetSystemsQuery, useGetSystemTypeFilterEntriesQuery, useLazyGetSystemTypeFilterEntriesQuery, useCreateSystemMutation, useUpdateSystemMutation, useDeleteSystemMutation } = injectedRtkApi;

