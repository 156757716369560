import {Identity} from "@sivis/identity/api";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";
import {useIdentitiesPageApi} from "../identity/useIdentitiesPageApi";
import {InfiniteScrollTableCursor} from "@sivis/shared/components/entityView";

const entityToTableRow = (entity: Identity) => {
  return {
    id: entity.id,
    fullName: entity.firstName + " " + entity.lastName,
    email: entity.primaryEmail,
    numAccounts: "tbd"
  };
};

const columns: GridColDef[] = [{
  field: "fullName",
  flex: 1
}, {
  field: "email",
  flex: 1
}, {
  field: "numAccounts",
  flex: 1
}];

// currently all identities are shown, regardless if affected or not
// only placeholder, clarify if done in MVP
export const RuleAffectedIdentitiesTab = () => {
  const intl = useCustomIntl();
  const {
    onSearch,
    pageSize,
    useIdentitiesPageQuery,
    parseIdentitiesPageResult
  } = useIdentitiesPageApi();
  const translatedColumns = useTranslateColumns(columns, "fusion.identity.propertyName");

  return <InfiniteScrollTableCursor
    columns={translatedColumns}
    pageSize={pageSize}
    usePageQuery={useIdentitiesPageQuery}
    parseResult={parseIdentitiesPageResult}
    placeholder={intl.format("fusion.general.searchPlaceholder")}
    onSearch={onSearch}
    renderRow={entityToTableRow}
  />;
}
