import { Outlet, useLocation } from 'react-router-dom';
import { AppShell, setReady } from '@sivis/app-shell';
import { FunctionComponent, useCallback } from 'react';
import { Breadcrumbs } from '@sivis/shared/components/breadcrumb';
import { BreadcrumbsRenderer } from '@sivis/shared/components/breadcrumb-renderer';
import { FusionSidebar } from './components/sidebar/fusionSidebar';
import { LOGIN_URL } from './components/login/sivisWebApi';
import { useDispatch } from 'react-redux';
import { authSuccess, checkAuthState } from './redux/authSlice';

const authEnabled = process.env.REACT_APP_AUTH_ENABLED === 'true';

const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const initialize = useCallback(() => {
    // Temporary solution to disable auth from environment variable
    if (authEnabled) {
      dispatch(checkAuthState());
    } else {
      dispatch(authSuccess());
      dispatch(setReady());
    }
  }, [])

  const locationIsLoginPage = location.pathname === LOGIN_URL || location.pathname === '/';

  return (
    <AppShell
      initialize={initialize}
      sidebarItems={<FusionSidebar/>}
      showContentOnly={locationIsLoginPage}
      breadcrumbBarItems={<Breadcrumbs renderer={BreadcrumbsRenderer}/>}
      alwaysShowSidebar={true}
      // dialogItems={<FusionLoginDialog />}
    >
      <Outlet/>
    </AppShell>
  )
}

export default App;
