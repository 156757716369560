import {createSelector, createSlice} from '@reduxjs/toolkit';


interface SidebarState {
  closed: boolean;
}

export interface SidebarSliceState {
  sidebar: SidebarState
}

const initialState: SidebarState = {
  closed: true
};

export const SIDEBAR_SLICE_NAME = 'sidebar';

const sidebarSlice = createSlice({
    name: SIDEBAR_SLICE_NAME,
    initialState,
    reducers: {
      sidebarToggle: (state) => {
        state.closed = !state.closed;
      },
      setSidebarClosed: (state, action) => {
        state.closed = action.payload
      },
      sidebarOpen: (state) => {
        state.closed = false;
      },
      sidebarClose: (state) => {
        state.closed = true;
      }
    }
  }
);

const selectSidebarState = createSelector(
  (state: SidebarSliceState) => state,
  state => state.sidebar
);

export const selectSidebarClosed = createSelector(
  selectSidebarState,
  state => state.closed
);

export const {sidebarToggle, setSidebarClosed, sidebarOpen, sidebarClose} = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
