import {Identity, IdentityRoleMembership, Role, SideFilterQueryType} from "@sivis/identity/api";
import {GridColDef} from "@mui/x-data-grid";
import React from "react";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";
import {useRolesPageApi} from "../role/useRolesPageApi";
import {ListAddView, TabBar} from "@sivis/shared/components/entityView";
import styles from "./membership.module.scss";
import {IconButton} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {useGetRolesForIdentityApi} from "./api/useExistingMembershipsApi";
import {useRolesSideFilter} from "../ownership/components/useRolesSideFilter";

interface CreateMembershipIdentityProps {
  identity: Identity;
  onAdd: (added: IdentityRoleMembership[]) => void;
}

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "description",
    flex: 1
  },
  {
    field: "identitiesCount",
    flex: 1
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const addButton = <IconButton aria-label="add">
  <AddIcon/>
</IconButton>;

export const removeButton = <IconButton aria-label="remove">
  <CloseIcon/>
</IconButton>;

export const CreateMembershipForIdentity = (props: CreateMembershipIdentityProps) => {
  const intl = useCustomIntl();

  const tabs = [
    {
      label: intl.format("fusion.role.list.name"),
      content: <CreateMembershipRoleList {...props} />
    }
  ];

  return <TabBar tabs={tabs}/>;
};

const CreateMembershipRoleList = (props: CreateMembershipIdentityProps) => {
  const {activeFilter, filterComponent} = useRolesSideFilter(SideFilterQueryType.ROLES);
  const {
    onSearch,
    useRolesWithIdentitiesCountPageQuery,
    parseRolesPageResult,
    pageSize
  } = useRolesPageApi(activeFilter ?? undefined);
  const {roles} = useGetRolesForIdentityApi(props.identity.id);
  const translatedColumns = useTranslateColumns(columns, "fusion.role.propertyName");

  const entityToTableRow = (entity: Role, isSelected: boolean) => {
    return {
      id: entity.id,
      name: entity.name,
      description: entity.description,
      identitiesCount: entity.identitiesCount,
      dataObject: entity,
      isNew: isSelected,
      action: isSelected ? removeButton : addButton
    };
  };

  const onAdd = (roles: Role[]) => {
    props.onAdd(roles.map(role => ({
      id: props.identity.id + role.id,
      template: true,
      role,
      identity: props.identity
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={entityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useRolesWithIdentitiesCountPageQuery}
      parseResult={parseRolesPageResult}
      sideFilter={filterComponent}
      excludedItems={roles}
      tableType={SideFilterQueryType.ROLES}
    />
  </div>;
};
