import React from "react";
import "./BreadcrumbBar.module.scss";

interface BreadcrumbBarProps {
  children: React.ReactNode;
}

const BreadcrumbBar = (props: BreadcrumbBarProps) => {
  return <div className="appShell-breadcrumbBar" aria-label="breadcrumb" role="navigation">
    {props.children}
  </div>;
};

export default BreadcrumbBar;
