import { HttpClientRequest } from './httpClientRequest';
import Axios from 'axios';
import { HttpClientInvokerFactory } from './httpClientInvoker';
import { pathToUrl } from '@sivis/shared/utils';

export const invokeAxiosFactory: HttpClientInvokerFactory = <R, D = any>() => {
  return async <R, D>(request: HttpClientRequest<R, D>): Promise<R> => {
    const {method, data, params, path, baseUrl, transformer, options} = request;
    const url = pathToUrl(path);

    const resultData = await Axios.request<R>({
        method: method ?? 'get',
        url,
        baseURL: baseUrl,
        data,
        params,
        transformResponse: transformer,
        ...options
      }
    ).then(res => res.data);

    return resultData;
  }
}
