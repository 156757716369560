import {
  forceRefetchPaging,
  mergePaging,
  serializeQueryArgsPaging,
  transformResponseWithArgs
} from "@sivis/shared/api-utils";
import {api as generatedRoleApi} from "./role.generated";

export const roleApi = generatedRoleApi.enhanceEndpoints({
  endpoints: {
    GetRoles: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("roles"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ["role"]
    },
    GetRolesWithIdentitiesCount: {
      serializeQueryArgs: serializeQueryArgsPaging,
      merge: mergePaging("roles"),
      forceRefetch: forceRefetchPaging,
      transformResponse: transformResponseWithArgs,
      providesTags: ["role"]
    },
    GetRoleCreationDates: {providesTags: ["role"]},
    GetRoleUpdateDates: {providesTags: ["role"]},
    CreateRole: { invalidatesTags: ["role"] },
    UpdateRole: { invalidatesTags: ["role"] },
    DeleteRole: { invalidatesTags: ["role"] },
  }
});

export const {
  useGetRoleQuery,
  useLazyGetRoleQuery,
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useGetRolesWithIdentitiesCountQuery,
  useLazyGetRolesWithIdentitiesCountQuery,
  useGetRoleCreationDatesQuery,
  useGetRoleUpdateDatesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation
} = roleApi;
