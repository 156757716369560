import { FilterOperator } from "@sivis/shared/api-utils";
import { defaults, omit } from "lodash";
import { FilterExpression, ResourceSet, ResourceSetInput } from "../identityApi.types";

export const emptyResourceSetInput = (): ResourceSetInput => ({
  name: '',
  description: '',
  resources: []
});

export const resourceSetInputFactory = (resourceSet?: ResourceSet | ResourceSetInput | Record<string, any> | null): ResourceSetInput => {

  if (!resourceSet) {
    return emptyResourceSetInput();
  }

  return defaults({}, omit(resourceSet, ['id']), emptyResourceSetInput());
}

export const searchResourceSetsFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    {name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr},
    {name: "description", operator: FilterOperator.CONTAINS, stringValue: searchStr}
  ]
});
