import {EOwnershipType, Ownership} from "@sivis/identity/api";
import {EntityDetailsProps} from "@sivis/shared/components/entityView";
import {RelationEntity, RelationEntityType} from "../components/popup/relationUtils";
import {useCustomIntl, useTranslateConfigs} from "@sivis/intl";
import {createEntitlementFormConfigs} from "../entitlement/entitlementUtils";
import styles from "../entitlement/entitlement.module.scss";
import {InformationCard} from "../components/popup/relationInfoPanel";
import {RelationInformationTab} from "../components/popup/relationInformationTab";
import {isOwnership} from "./ownershipUtils";

interface OwnershipInformationTabProps extends EntityDetailsProps<Ownership> {
  useOwnershipQuery: (ownership: Ownership) =>
    {
      owner?: RelationEntity;
      target?: RelationEntity;
      onSave: (value: Ownership) => Promise<void>;
    };
  ownerType: RelationEntityType;
  targetType: RelationEntityType;
  isOwner?: boolean;
}

const getOwnershipInfoText = (props: OwnershipInformationTabProps, formatFn: (id: string) => string) => {
  const {type} = props.entity;
  const isResourceOwnership = type === EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP || type === EOwnershipType.ROLE_RESOURCE_OWNERSHIP;
  const isIdentityRoleOwnership = type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP;

  if (!props.isOwner && isResourceOwnership) {
    return <div className={styles.informationCardContainer}>
      <InformationCard text={formatFn("fusion.ownership.identityResourceOwnership.editInfo")}/>
    </div>
  } else if (props.isOwner && isIdentityRoleOwnership) {
    return <div className={styles.informationCardContainer}>
      <InformationCard text={formatFn("fusion.ownership.identityRoleOwnership.editInfo")}/>
    </div>
  } else {
    return null;
  }
}

const getIdentityFormText = (type: string, formatFn: (id: string) => string) => {
  const typeMap: Record<string, string> = {
    [EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP.valueOf()]: "fusion.ownership.ownershipForm.name.allSystems",
    [EOwnershipType.ROLE_RESOURCE_OWNERSHIP.valueOf()]: "fusion.ownership.ownershipForm.name.allSystems",
    [EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP.valueOf()]: "fusion.ownership.ownershipForm.name.allResources",
    [EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP.valueOf()]: "fusion.ownership.ownershipForm.name.allResources",
    [EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP.valueOf()]: "fusion.ownership.ownershipForm.name.ownedSystem",
    [EOwnershipType.ROLE_SYSTEM_OWNERSHIP.valueOf()]: "fusion.ownership.ownershipForm.name.ownedSystem",
    [EOwnershipType.IDENTITY_ROLE_OWNERSHIP.valueOf()]: "fusion.ownership.ownershipForm.name.ownedRole",
  };
  return typeMap[type] ? formatFn(typeMap[type]) : "";
};

const getOwnershipFormText = (props: OwnershipInformationTabProps, formatFn: (id: string) => string) => {
  const {type} = props.entity;
  return getIdentityFormText(type, formatFn);
}

export const OwnershipInformationTab = (props: OwnershipInformationTabProps) => {
  const ownership = props.entity;
  const intl = useCustomIntl();
  const {
    onSave,
    owner,
    target
  } = props.useOwnershipQuery(ownership);
  const ownershipFormConfigs = createEntitlementFormConfigs(intl.format);
  const translatedOwnershipFormConfigs = useTranslateConfigs(ownershipFormConfigs, "fusion.entitlement.entitlementForm.propertyName");
  const relationText = intl.format("fusion.ownership.ownershipPopup.isOwnerOf");

  const infoText = getOwnershipInfoText(props, intl.format);
  const formName = getOwnershipFormText(props, intl.format)

  return <div className={styles.tabContainer}>
    {infoText}
    <RelationInformationTab
      {...props}
      configs={translatedOwnershipFormConfigs}
      onSave={onSave}
      leftEntityType={props.ownerType}
      leftEntity={owner}
      rightEntityType={props.targetType}
      rightEntity={target}
      relationText={relationText}
      entity={ownership}
      checkValueType={isOwnership}
      formName={formName}
    />
  </div>;
}
