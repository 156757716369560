// multiple properties: an array of input fields in a row
// currently used for validity
import { ReactNode } from "react";

export type AttributeRowProperty = string | AttributeConfig[];

export enum FieldType {
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  TEXTAREA = "TEXTAREA",
  READONLY = "READONLY",
  AUTOCOMPLETE = "AUTOCOMPLETE"
}

export interface AttributeRowConfig {
  name: string;
  property: AttributeRowProperty;
  required?: boolean;
  disabled?: boolean;
  options?: { value: string | boolean, text: string | ReactNode }[];
  // Renders input field according to field type. Default type is string.
  fieldType?: FieldType;
  // For read-only AttributeRows
  renderRow?: (rowValue: any) => string | JSX.Element;
  // Renders a field conditionally based on inputs of other fields
  // If set, the field will only be rendered if the callback returns true
  renderCondition?: (values: Record<string, any>) => boolean;
  placeholder?: string | ReactNode;
  validate?: (value: any, allValues: Record<string, any>) => boolean | string;
  useSwitch?: boolean;
}

export type AttributeConfig = Omit<AttributeRowConfig, "property"> & {
  property: string;
};
