import {
    EntitlementType,
    RelationQueryType,
    Resource,
    useGetIdentityEntitlementsQuery
} from '@sivis/identity/api';
import { combineFilter, FilterOperator } from '@sivis/shared/api-utils';
import { DEFAULT_ENTITY_SORT, DEFAULT_PAGE_SIZE } from '../../common/parsePageResult';
import { useEntitlementsApi } from './useEntitlementsApi';

export const useResourceIdentityEntitlementsApi = (resource?: Resource) => {
  const resourceId = resource?.id;
  const resourceEntitlementsFilter = combineFilter(FilterOperator.AND, {
    name: "type",
    operator: FilterOperator.EQ,
    stringValue: EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT
  }, {
    name: "targetId",
    operator: FilterOperator.EQ,
    uuidValue: resourceId
  });

  const useEntitlementsPageQuery = (cursor: string | null) =>
    useGetIdentityEntitlementsQuery(
      {
        first: DEFAULT_PAGE_SIZE,
        after: cursor,
        sort: DEFAULT_ENTITY_SORT,
        filter: resourceEntitlementsFilter,
        cacheKey: EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT
      },
      {skip: !resourceId}
    );

  const {onSave, pageSize, parseEntitlementsPageResult, submitted, setSubmitted} = useEntitlementsApi(
      !resourceId,
      EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT,
    RelationQueryType.IDENTITY_RESOURCE_ENTITLEMENTS,
      resource
  );

  return {
    onSave,
    pageSize,
    useEntitlementsPageQuery,
    parseEntitlementsPageResult,
    submitted,
    setSubmitted
  };
};
