import { EOwnershipType, ResourceSet } from "@sivis/identity/api";
import { useCustomIntl, useTranslateColumns } from "@sivis/intl";
import { ListAddView } from "@sivis/shared/components/entityView";
import { resourceEntitlementColumns } from "../entitlement/entitlementUtils";
import { addButton, removeButton } from "../membership/createMembershipForIdentity";
import { useResourceSetPageApi } from "../resourceSet/useResourceSetPageApi";
import { CreateOwnershipProps } from "./createOwnership";
import styles from "./ownership.module.scss";
import { isIdentity } from "./ownershipUtils";

export const CreateOwnershipResourceSetsList = (props: CreateOwnershipProps) => {
  const intl = useCustomIntl();
  const {
    onSearch,
    useResourceSetsPageQuery,
    parseResourceSetsPageResult,
    pageSize
  } = useResourceSetPageApi();
  const translatedColumns = useTranslateColumns(resourceEntitlementColumns, "fusion.entitlement.resourceEntitlement.propertyName");

  const entityToTableRow = (entity: ResourceSet, isSelected: boolean) => {
    return {
      id: entity.id,
      resourceName: entity.name,
      resourceTypeName: intl.format("fusion.resourceSet.propertyName.name"),
      context: "",
      dataObject: entity,
      isNew: isSelected,
      action: isSelected ? removeButton : addButton
    };
  };

  const onAdd = (resourceSets: ResourceSet[]) => {
    props.onAdd(resourceSets.map(resourceSet => ({
      id: props.selectedEntity.id + resourceSet.id,
      ownerId: props.selectedEntity.id,
      targetId: resourceSet.id,
      type: isIdentity(props.selectedEntity) ? EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP : EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP,
      targetName: resourceSet.name
    })));
  };

  return <div className={styles.listContainer}>
    <ListAddView
      columns={translatedColumns}
      entityToTableRow={entityToTableRow}
      onChange={onAdd}
      onSearch={onSearch}
      pageSize={pageSize}
      usePageQuery={useResourceSetsPageQuery}
      parseResult={parseResourceSetsPageResult}
      excludedItems={[]}
    />
  </div>;
};
