import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {
  IdentityType,
  identityTypeFactory,
  IdentityTypeInput,
  identityTypeInputFactory,
  useCreateIdentityTypeMutation,
  useDeleteIdentityTypeMutation,
  useGetIdentityTypeQuery,
  useUpdateIdentityTypeMutation,
} from '@sivis/identity/api';
import {newId} from "../common/newId";
import {EntityType, useSingleEditMode} from '../components/breadcrumb/editModeContext';

export const useIdentityTypeApi = () => {
  const { id = newId } = useParams<{ id: string }>();
  const isNew = id === newId;
  const { data, error, isLoading, refetch } = useGetIdentityTypeQuery({ id }, { skip: isNew });
  const navigate = useNavigate();

  const [createIdentityType] = useCreateIdentityTypeMutation();
  const [updateIdentityType] = useUpdateIdentityTypeMutation();
  const [deleteIdentityType] = useDeleteIdentityTypeMutation();

  const [identityTypeInput, setIdentityTypeInput] = useState<IdentityTypeInput>(identityTypeInputFactory());
  const [identityType, setIdentityType] = useState<IdentityType>();
  const { editMode, toggleEditMode } = useSingleEditMode(EntityType.IDENTITY_TYPE, isNew);

  useEffect(() => {
    if (data?.identityType) {
      const initialInput = identityTypeInputFactory(data.identityType);
      setIdentityTypeInput(initialInput);
      setIdentityType(data?.identityType as IdentityType ?? undefined);
    } else {
      setIdentityTypeInput(identityTypeInputFactory());
      setIdentityType(identityTypeFactory(identityTypeInput));
    }
  }, [data?.identityType]);

  const goBack = () => navigate("/identity-type");

  let onSave, onDelete, onCancel;

  if (isNew) {
    onSave = (value: IdentityTypeInput | IdentityType) => {
      createIdentityType({ input: identityTypeInputFactory(value) }).then(goBack);
    };
    onCancel = goBack;
  } else {
    onSave = (value: IdentityTypeInput | IdentityType) => {
      updateIdentityType({ id, input: identityTypeInputFactory(value) })
        .then(() => {
          toggleEditMode();
          refetch();
        });
    };
    onCancel = () => {
      if (editMode) toggleEditMode();
      else goBack();
    };
    onDelete = () => deleteIdentityType({ id }).then(goBack);
  }

  return {
    id,
    identityType,
    identityTypeInput,
    defaultInput: identityTypeInputFactory(),
    isNew,
    error,
    onSave,
    onDelete,
    onCancel,
    isLoading,
    refetch,
    editMode,
    toggleEditMode,
  };
};
