import React, { useEffect, useState } from 'react';
import DragDropComponent from '../components/fieldDefinitions/dragDropComponent';
import {
  System,
  AccountFieldDefinition,
  useGetAccountFieldDefinitionsQuery,
  useUpdateAccountFieldDefinitionsMutation,
} from '@sivis/identity/api';

interface SystemFielddefinitionTabProps {
  system: System | undefined,
  onCancel: () => void,
  editMode: boolean,
}


const adaptAccountData = (apiData: any, system: string): AccountFieldDefinition[] => {
  return apiData.accountFieldDefinitions
    .filter((def: any) => def.system === system)
    .map((def: any) => ({
      id: def.id,
      system: def.system,
      fieldDefinitionFields: {
        ...def.fieldDefinitionFields,
        readOnly: Boolean(def.fieldDefinitionFields.readOnly),
        required: Boolean(def.fieldDefinitionFields.required),
        searchable: Boolean(def.fieldDefinitionFields.searchable),
        visible: Boolean(def.fieldDefinitionFields.visible),
        uniqueField: Boolean(def.fieldDefinitionFields.uniqueField),
        uiPosition: parseInt(def.fieldDefinitionFields.uiPosition, 10),
        uiMaxCharacters: parseInt(def.fieldDefinitionFields.uiMaxCharacters, 10),
      }
    }));
};

const SystemFielddefinitionTab = (props: SystemFielddefinitionTabProps) => {
  const [accountFieldDefinitions, setAccountFieldDefinitions] = useState<AccountFieldDefinition[]>([]);
  const { data: fieldData, error, isFetching, refetch } = useGetAccountFieldDefinitionsQuery(undefined, {
    skip: !props.system,
  });
  const [updateFieldDefinitions] = useUpdateAccountFieldDefinitionsMutation();

  useEffect(() => {
    if (fieldData && props.system) {
      setAccountFieldDefinitions(adaptAccountData(fieldData, props.system.id.toString()));
    }
  }, [fieldData, error, props.system]);

  useEffect(() => {
    if (props.system) {
      refetch();
    }
  }, [props.system, refetch]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <DragDropComponent
      items={accountFieldDefinitions}
      fetchFunction={refetch}
      saveFunction={() => [updateFieldDefinitions]}
      editMode={props.editMode}
      onCancel={props.onCancel}
    />
  );
};

export default SystemFielddefinitionTab;
