import { EOwnershipEntity, FilterExpression, OwnershipQueryType, Resource, ResourceSet, System } from "@sivis/identity/api";
import { combineFilter, FilterOperator } from "@sivis/shared/api-utils";
import { isResourceSet, isSystem } from "../ownershipUtils";
import { useTargetOwnershipsApi } from "./useTargetOwnershipsApi";


export const useOwnershipApiForTarget = (target: Resource | ResourceSet | System, extraFilter?: FilterExpression) => {
  const targetId = target?.id;
  const targetFilter = {
    name: "targetId",
    operator: FilterOperator.EQ,
    uuidValue: targetId
  };
  const filter = extraFilter ? combineFilter(FilterOperator.AND, extraFilter, targetFilter) : targetFilter;

  let cacheType = EOwnershipEntity.RESOURCE;
  let queryType = OwnershipQueryType.RESOURCE_OWNERSHIPS;

  if (target !== undefined) {
    if (isSystem(target)) {
      cacheType = EOwnershipEntity.RESOURCE;
      queryType = OwnershipQueryType.RESOURCE_OWNERSHIPS;
    } else if (isResourceSet(target)) {
      cacheType = EOwnershipEntity.RESOURCE_SET;
      queryType = OwnershipQueryType.RESOURCE_SET_OWNERSHIPS;
    } else {
      cacheType = EOwnershipEntity.SYSTEM;
      queryType = OwnershipQueryType.SYSTEM_OWNERSHIPS;
    }
  }
  return useTargetOwnershipsApi(cacheType, filter, queryType, targetId);
}
