import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetOetRelationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetOetRelationQuery = { __typename?: 'Query', oetRelation?: { __typename?: 'OetRelation', id: string, isRequired?: boolean | null, ownerType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, assignedType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, oetRelationType: { __typename?: 'OetRelationType', id?: string | null, type?: string | null }, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null };

export type GetOetRelationsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetOetRelationsQuery = { __typename?: 'Query', oetRelations?: { __typename?: 'OetRelationConnection', edges?: Array<{ __typename?: 'OetRelationEdge', node?: { __typename?: 'OetRelation', id: string, isRequired?: boolean | null, ownerType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, assignedType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, oetRelationType: { __typename?: 'OetRelationType', id?: string | null, type?: string | null }, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type CreateOetRelationMutationVariables = Types.Exact<{
  ownerTypeId: Types.Scalars['ID'];
  assignedTypeId: Types.Scalars['ID'];
  oetRelationTypeId: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.OetRelationInput>;
}>;


export type CreateOetRelationMutation = { __typename?: 'Mutation', createOetRelation: { __typename?: 'OetRelation', id: string, isRequired?: boolean | null, ownerType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, assignedType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, oetRelationType: { __typename?: 'OetRelationType', id?: string | null, type?: string | null } } };

export type UpdateOetRelationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.OetRelationInput>;
}>;


export type UpdateOetRelationMutation = { __typename?: 'Mutation', updateOetRelation: { __typename?: 'OetRelation', id: string, isRequired?: boolean | null, ownerType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, assignedType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, oetRelationType: { __typename?: 'OetRelationType', id?: string | null, type?: string | null } } };

export type DeleteOetRelationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteOetRelationMutation = { __typename?: 'Mutation', deleteOetRelation: { __typename?: 'OetRelation', id: string, isRequired?: boolean | null, ownerType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, assignedType: { __typename?: 'OrganizationalEntityType', id: string, name: string, apiName?: string | null, description?: string | null }, oetRelationType: { __typename?: 'OetRelationType', id?: string | null, type?: string | null } } };


export const GetOetRelationDocument = `
    query GetOetRelation($id: ID!) {
  oetRelation(id: $id) {
    id
    ownerType {
      id
      name
      apiName
      description
    }
    assignedType {
      id
      name
      apiName
      description
    }
    oetRelationType {
      id
      type
    }
    isRequired
    meta {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;
export const GetOetRelationsDocument = `
    query GetOetRelations($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  oetRelations(first: $first, after: $after, sort: $sort, filter: $filter) {
    edges {
      node {
        id
        ownerType {
          id
          name
          apiName
          description
        }
        assignedType {
          id
          name
          apiName
          description
        }
        oetRelationType {
          id
          type
        }
        isRequired
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;
export const CreateOetRelationDocument = `
    mutation CreateOetRelation($ownerTypeId: ID!, $assignedTypeId: ID!, $oetRelationTypeId: ID!, $input: OetRelationInput) {
  createOetRelation(
    ownerTypeId: $ownerTypeId
    assignedTypeId: $assignedTypeId
    oetRelationTypeId: $oetRelationTypeId
    input: $input
  ) {
    id
    ownerType {
      id
      name
      apiName
      description
    }
    assignedType {
      id
      name
      apiName
      description
    }
    oetRelationType {
      id
      type
    }
    isRequired
  }
}
    `;
export const UpdateOetRelationDocument = `
    mutation UpdateOetRelation($id: ID!, $input: OetRelationInput) {
  updateOetRelation(id: $id, input: $input) {
    id
    ownerType {
      id
      name
      apiName
      description
    }
    assignedType {
      id
      name
      apiName
      description
    }
    oetRelationType {
      id
      type
    }
    isRequired
  }
}
    `;
export const DeleteOetRelationDocument = `
    mutation DeleteOetRelation($id: ID!) {
  deleteOetRelation(id: $id) {
    id
    ownerType {
      id
      name
      apiName
      description
    }
    assignedType {
      id
      name
      apiName
      description
    }
    oetRelationType {
      id
      type
    }
    isRequired
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetOetRelation: build.query<GetOetRelationQuery, GetOetRelationQueryVariables>({
      query: (variables) => ({ document: GetOetRelationDocument, variables })
    }),
    GetOetRelations: build.query<GetOetRelationsQuery, GetOetRelationsQueryVariables | void>({
      query: (variables) => ({ document: GetOetRelationsDocument, variables })
    }),
    CreateOetRelation: build.mutation<CreateOetRelationMutation, CreateOetRelationMutationVariables>({
      query: (variables) => ({ document: CreateOetRelationDocument, variables })
    }),
    UpdateOetRelation: build.mutation<UpdateOetRelationMutation, UpdateOetRelationMutationVariables>({
      query: (variables) => ({ document: UpdateOetRelationDocument, variables })
    }),
    DeleteOetRelation: build.mutation<DeleteOetRelationMutation, DeleteOetRelationMutationVariables>({
      query: (variables) => ({ document: DeleteOetRelationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOetRelationQuery, useLazyGetOetRelationQuery, useGetOetRelationsQuery, useLazyGetOetRelationsQuery, useCreateOetRelationMutation, useUpdateOetRelationMutation, useDeleteOetRelationMutation } = injectedRtkApi;

