import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../../app';
import { permissionRoutes } from '../../permission/permissionRoutes';
import { identityRoutes } from '../../identity/identityRoutes';
import { Home } from '../home/home';
import { NotFound } from '@sivis/shared/components/not-found';
import { roleRoutes } from '../../role/roleRoutes';
import { systemRoutes } from '../../system/systemRoutes';
import { systemTypeRoutes } from '../../systemType/systemTypeRoutes';
import { resourceRoutes } from '../../resource/resourceRoutes';
import { resourceTypeRoutes } from '../../resourceType/resourceTypeRoutes';
import { resourceSetRoutes } from '../../resourceSet/resourceSetRoutes';
import { organizationalEntityRoutes } from '../../organizationalEntity/organizationalEntityRoutes';
import {
  organizationalEntityTypeRoutes
} from '../../organizationalEntityType/organizationalEntityTypeRoutes';
import { EditModeContext, useCreateEditModeContext } from '../breadcrumb/editModeContext';
import { addressRoutes } from '../../address/addressRoutes';
import { intlSimpleBreadcrumb } from '@sivis/shared/components/breadcrumb-renderer';
import { identityTypeRoutes } from '../../identityType/identityTypeRoutes';
import { accountRoutes } from '../../account/accountRoutes';
import { accountTypeRoutes } from '../../accountType/accountTypeRoutes';
import React from 'react';
import { LOGIN_URL } from '../login/sivisWebApi';
import { FusionLogin } from '../login/fusionLogin';
import { ProtectedOutlet } from '../login/protectedRoute';
import { AuthRedirect } from '../login/authRedirect';
import { ruleRoutes } from '../../rule/ruleRoutes';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    handle: {
      crumb: intlSimpleBreadcrumb({ intlId: "fusion.breadcrumb.home" })
    },
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: LOGIN_URL,
        element: <AuthRedirect><FusionLogin /></AuthRedirect>
      },
      {
        path: "permission",
        element: <ProtectedOutlet />,
        children: permissionRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.permission.list.name" })
        }
      },
      {
        path: "identity",
        element: <ProtectedOutlet />,
        children: identityRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.identity.list.name" })
        }
      },
      {
        path: "role",
        element: <ProtectedOutlet />,
        children: roleRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.role.list.name" })
        }
      },
      {
        path: "system",
        element: <ProtectedOutlet />,
        children: systemRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.system.list.name" })
        }
      },
      {
        path: "system-type",
        element: <ProtectedOutlet />,
        children: systemTypeRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.systemType.list.name" })
        }
      },
      {
        path: "resource",
        element: <ProtectedOutlet />,
        children: resourceRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.resource.list.name" })
        }
      },
      {
        path: "resource-set",
        element: <ProtectedOutlet />,
        children: resourceSetRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.resourceSet.list.name" })
        }
      },
      {
        path: "resource-type",
        element: <ProtectedOutlet />,
        children: resourceTypeRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.resourceType.list.name" })
        }
      },
      {
        path: "identity-type",
        element: <ProtectedOutlet />,
        children: identityTypeRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.identityType.list.name" })
        }
      },
      {
        path: "account",
        element: <ProtectedOutlet />,
        children: accountRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.account.list.name" })
        }
      },
      {
        path: "account-type",
        element: <ProtectedOutlet />,
        children: accountTypeRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.accountType.list.name" })
        }
      },
      {
        path: "organizational-entity",
        element: <ProtectedOutlet />,
        children: organizationalEntityRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.organizationalEntity.list.name" })
        }
      },
      {
        path: "organizational-entity-type",
        element: <ProtectedOutlet />,
        children: organizationalEntityTypeRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.organizationalEntityType.list.name" })
        }
      },
      {
        path: "address",
        element: <ProtectedOutlet />,
        children: addressRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: "fusion.address.list.name" })
        }
      },
      {
        path: 'rule',
        element: <ProtectedOutlet />,
        children: ruleRoutes,
        handle: {
          crumb: intlSimpleBreadcrumb({ intlId: 'fusion.rule.list.name' })
        }
      }
    ]
  }
]);

export const Routes = () => {
  const { editMode, setEditMode } = useCreateEditModeContext();
  return <EditModeContext.Provider value={{ editMode, setEditMode }}>
    <RouterProvider router={router} />
  </EditModeContext.Provider>;
};
