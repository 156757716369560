import {DynamicBreadcrumb} from "@sivis/shared/components/breadcrumb";
import {Link, useParams} from "react-router-dom";
import {newId} from "../../permission/usePermissionApi";
import {EntityType, useSingleEditMode} from "./editModeContext";
import {selectMessageTextById, useCustomIntl} from "@sivis/intl";
import {useSelector} from "react-redux";

interface SimpleBreadcrumbProps {
  text: string;
}

export const SimpleBreadcrumb = ({text}: SimpleBreadcrumbProps) => ({path}: { path: string; }) => {
  return <Link to={path}>{text}</Link>;
};

interface SimpleBreadcrumbEditModeProps extends SimpleBreadcrumbProps {
  entity: EntityType;
}

export const SimpleBreadcrumbEditMode = ({
                                           text,
                                           entity
                                         }: SimpleBreadcrumbEditModeProps) => function Comp({path}: {
  path: string;
}) {
  const intl = useCustomIntl();
  const {editMode} = useSingleEditMode(entity);
  return <Link
    to={path}>{editMode ? intl.format("fusion.breadcrumb.edit", {text: text}) : text}</Link>;
};

type IntlSimpleBreadcrumbEditModeProps =
  Omit<SimpleBreadcrumbEditModeProps, "text">
  & { intlId: string; intlIdEdit: string; };

export const intlSimpleBreadcrumbEditMode = ({
                                               entity,
                                               intlId,
                                               intlIdEdit
                                             }: IntlSimpleBreadcrumbEditModeProps) => function Comp({
                                                                                                      path
                                                                                                    }: {
  path: string;
}) {
  const id = useParams().id ?? "";
  const isNew = id === newId;
  const {editMode} = useSingleEditMode(entity);
  const text = useSelector(state => selectMessageTextById(state, intlId));
  const editText = useSelector(state => selectMessageTextById(state, isNew ? intlId : intlIdEdit));
  return <Link to={path}>{editMode ? editText : text}</Link>;
};

interface DynamicEntityViewBreadcrumbProps {
  useGetEntityName: (id: string, isNew: boolean) => string | undefined;
  pathSuffix?: string;
}

export const DynamicEntityViewBreadcrumb: (props: DynamicEntityViewBreadcrumbProps) => DynamicBreadcrumb = ({
                                                                                                              useGetEntityName,
                                                                                                              pathSuffix
                                                                                                            }) => function Comp({
                                                                                                                                  params,
                                                                                                                                  path
                                                                                                                                }) {
  const id = params.id ?? "";
  const isNew = id === newId;
  const intl = useCustomIntl();
  let name = isNew ? intl.format("fusion.breadcrumb.new") : id;

  const fetchedName = useGetEntityName(id, isNew);
  if (fetchedName) {
    name = fetchedName;
  }
  return <Link to={path + pathSuffix}>{name}</Link>;
};

