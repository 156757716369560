import {selectSidebarClosed} from "@sivis/app-shell";
import {useSelector} from "react-redux";
import {IntlSidebarCategory} from "./sidebarCategory";
import {IntlSidebarItem} from "./sidebarItem";
import styles from "./sidebar.module.scss";
import {useState} from "react";

export interface Item {
  name: string;
  url: string;
}

export interface Category {
  name: string;
  items: Item[];
}

export interface SidebarProps {
  categories: Category[];
}


export const Sidebar = ({categories}: SidebarProps) => {
  const categoryOpenState = (key: string): [string, boolean] => [
    key,
    key === categories[0]?.name,
  ];

  const openSingleCategoryMap = () =>
    new Map<string, boolean>(
      categories.map(({name}) => categoryOpenState(name))
    );

  const closed = useSelector(selectSidebarClosed);
  const [categoryOpen, setCategoryOpen] = useState(openSingleCategoryMap());

  const toggleCategory = (category: string) => {
    let newOpenCategoriesMap = new Map(categoryOpen);
    newOpenCategoriesMap.forEach((_, key, map) => {
      map.set(key, key === category && !map.get(key));
    });
    setCategoryOpen(newOpenCategoriesMap);
  }

  const selectItem = (category: string) => {
    let newCategoryMap = new Map(categoryOpen);
    newCategoryMap.forEach((value, key, map) => {
      if (key === category) {
        map.set(key, true);
      } else {
        map.set(key, false);
      }
    });
    setCategoryOpen(newCategoryMap);
  }

  return closed ? <></> : <div className={styles["sidebar"]}>
    {categories.map((category) =>
      <IntlSidebarCategory intlId={category.name} open={!!categoryOpen.get(category.name)}
                           toggleOpen={() => toggleCategory(category.name)} key={category.name}>
        {category.items.map((item) =>
          <IntlSidebarItem intlId={item.name} url={item.url}
                           onSelect={() => selectItem(category.name)} key={item.name}/>
        )}
      </IntlSidebarCategory>
    )
    }
  </div>;
};

